import React, { FC } from 'react';
import { Button } from 'src/components/Forms/Button';
import { useTranslation } from 'react-i18next';
import { LogoLight } from 'src/components/Icons';
import { ReactComponent as MarketIcon } from './assets/market-icons.svg';
import { ReactComponent as TaskIcon } from './assets/taks-icon.svg';
import { ReactComponent as ChatsIcon } from './assets/chats-icon.svg';
import { ReactComponent as SearchIcon } from './assets/search-icons.svg';
import './styles.scss';

type Props = {
  onCLick: () => void;
};
export const BusinessItem: FC<Props> = ({ onCLick }) => {
  const { t } = useTranslation();

  return (
    <div className="business-item">
      <div className="business-item__wrapper">
        <div className="business-item__wrapper-content">
          <div className="business-item__media first">
            <div className="business-item__media--content"></div>
          </div>
          <div className="business-item__media">
            <div className="business-item__media--content"></div>
          </div>
          <div className="business-item__media last">
            <div className="business-item__media--content">
              <div className="business-item__media-title">
                <span>{t('supportForDevelopers')}</span>
                <LogoLight className="offer-object__logo" />
              </div>
              <div className="business-item__media-description">{t('businessSubscription')} 1$</div>
              <div className="business-item__media-split"></div>
              <div className="business-item__media-items">
                <div className="business-item__media-item">
                  <div className="business-item__media-item--icon">
                    <MarketIcon />
                  </div>
                  <div className="business-item__media-item--text">{t('fileMarket')}</div>
                </div>

                <div className="business-item__media-item">
                  <div className="business-item__media-item--icon">
                    <TaskIcon />
                  </div>
                  <div className="business-item__media-item--text">{t('taskBoards')}</div>
                </div>

                <div className="business-item__media-item">
                  <div className="business-item__media-item--icon">
                    <ChatsIcon />
                  </div>
                  <div className="business-item__media-item--text">{t('groupChats')}</div>
                </div>

                <div className="business-item__media-item">
                  <div className="business-item__media-item--icon">
                    <SearchIcon />
                  </div>
                  <div className="business-item__media-item--text">{t('fileSearch')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="business-item__title">{t('beAPartText')}</div>
        <div className="business-item__description">{t('supportText')}</div>
      </div>
      <Button
        type="primary"
        content={t('supportTeam')}
        fullWidth
        className="business-item__btn skip-outside"
        onClick={onCLick}
      />
    </div>
  );
};
