import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import {
  RestoreFromTrashPayload,
  useDeleteFromTrash,
  useGetTrashObjects,
  useRestoreFromTrash,
  useTrashCache,
} from 'src/api/trash';
import { useSort } from 'src/hooks/useSort';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TableItemType } from 'src/components/Table/types';
import { FileType, FolderType } from 'src/types';
import { usePrevious } from 'src/hooks/usePrevious';
import { isEqual } from 'lodash';
import { useDiskViewState } from 'src/pages/diskPage/hooks/useDiskViewState';

export const useTrashState = () => {
  const { t } = useTranslation();
  const { openConfirmation, openDestinationModal, filterType } = useAppContext();
  const { invalidateCache, invalidateAllCaches } = useDiskViewState();
  const { selectedIds, clearSelectedIds } = useGlobalContext();

  const { mutateAsync: deleteFromTrash } = useDeleteFromTrash();
  const { mutateAsync: restoreFile } = useRestoreFromTrash();
  const { handleSort, sortItems } = useSort();

  const { diskId, folderId } = useParams();
  const [tableItems, setTableItems] = useState<TableItemType[]>([]);

  const { data: filesData } = useGetTrashObjects({
    type: 'files',
    parent_id: folderId || diskId || '',
    limit: 1000,
    offset: 0,
    extension_type: filterType !== 'all' ? filterType : undefined,
  });

  const { data: foldersData } = useGetTrashObjects({
    type: 'folders',
    parent_id: folderId || diskId || '',
    limit: 1000,
    offset: 0,
  });

  const { removeFromAllCaches, deleteAllFromCash } = useTrashCache({
    type: 'files',
    diskId: diskId || '',
    limit: 1000,
  });

  const files: FileType[] = filesData?.objects || [];
  const folders: FolderType[] = foldersData?.objects || [];

  const prevFiles = usePrevious(files);
  const prevFolders = usePrevious(folders);

  useEffect(() => {
    if (isEqual(prevFiles, files) && isEqual(prevFolders, folders)) return;

    const itemsFolders: FolderType[] = folders.map((folder: FolderType) => ({
      size: 0,
      type: t('folderWithFiles'),
      innerType: 'folder',
      favorite: folder.is_favorite,
      actions: [],
      ...folder,
    }));

    const items: FolderType[] = filterType === 'all' || filterType === 'FOLDER' ? itemsFolders : [];

    setTableItems(() => {
      return [
        ...items,
        ...files.map((file: FileType) => ({
          favorite: file.is_favorite,
          actions: [],
          ...file,
        })),
      ] as any[];
    });
  }, [folders, files, t]);

  const onRestoreClick = (item: TableItemType) => {
    openDestinationModal({
      open: true,
      title: `${t('restore')}: ${item.name}`,
      btnText: t('restore'),
      operation: t('restoreTo'),
      onMove: async (disk, destFolder) => {
        const payloadMove: RestoreFromTrashPayload = {
          item_ids: [item.id],
          destination_id: destFolder?.id || disk.id,
        };
        const resp = await restoreFile(payloadMove);

        openDestinationModal({ open: false });
        if (resp.success) {
          removeFromAllCaches(item as FileType);
          return;
        }
        openConfirmation({
          content: t('restoreError'),
          onSure: () => {
            openConfirmation({ open: false });
          },
        });
      },
    });
  };

  const onDeleteClick = (item: TableItemType) => {
    openConfirmation({
      content: `${t('deleteConfirmation')}: ${item.name}?`,
      secondContent: `${item.name} ${t('willBePermanentlyDeleted')}`,
      onSure: async () => {
        openConfirmation({ open: false });
        await deleteFromTrash({
          item_ids: [item.id],
        });
        removeFromAllCaches(item as FileType);
      },
    });
  };

  const onDeleteAllClick = () => {
    const ids = selectedIds.length > 0 ? selectedIds : tableItems.map(({ id }) => id);
    const length = ids.length;
    const itemsLabel = t('items');
    const itemLabel = t('item');

    openConfirmation({
      content: `${t('deleteConfirmation')}: ${length} ${length === 1 ? itemLabel : itemsLabel}?`,
      secondContent: ` ${length} ${length === 1 ? itemLabel : itemsLabel} ${t('willBePermanentlyDeleted')}`,
      onSure: async () => {
        openConfirmation({ open: false });
        await deleteFromTrash({
          item_ids: ids,
        });
        clearSelectedIds();
        deleteAllFromCash();
      },
    });
  };

  const onRestoreAllClick = () => {
    const ids = selectedIds.length > 0 ? selectedIds : tableItems.map(({ id }) => id);
    const length = ids.length;
    const itemsLabel = t('items');
    const itemLabel = t('item');
    openDestinationModal({
      open: true,
      title: `${t('restore')}: ${length} ${length === 1 ? itemLabel : itemsLabel}`,
      btnText: t('restore'),
      operation: t('restoreTo'),
      onMove: async (disk, folder) => {
        const payloadMove: RestoreFromTrashPayload = {
          item_ids: ids,
          destination_id: folder?.id || disk.id,
        };
        const resp = await restoreFile(payloadMove);
        openDestinationModal({ open: false });
        if (resp.success) {
          deleteAllFromCash();
          invalidateCache();
          invalidateAllCaches();
          clearSelectedIds();
          return;
        }
        openConfirmation({
          content: t('restoreError'),
          onSure: () => {
            openConfirmation({ open: false });
          },
        });
      },
    });
  };

  return {
    tableItems,
    handleSort,
    sortItems,
    onRestoreClick,
    onDeleteClick,
    onDeleteAllClick,
    onRestoreAllClick,
  };
};
