import React, { FC } from 'react';
import { getFolderStyles } from 'src/pages/diskPage/components/DragLayer/helpers';
import { FolderFavoriteIcon, FolderIcon } from 'src/components/Icons';
import { FolderType } from 'src/types';
import './styles.scss';

type Props = {
  folder: FolderType;
  position: { x: number; y: number };
  i: number;
  filesLength: number;
  foldersLength: number;
};

export const IconFolder: FC<Props> = ({ folder, position, i, filesLength, foldersLength }) => {
  return (
    <div
      key={folder.id}
      className={'single-folder draggFolder'}
      style={getFolderStyles(position, i, foldersLength, filesLength === 0)}
    >
      <div className="single-folder__media">
        {folder.is_favorite ? (
          <FolderFavoriteIcon className="folder-icon" />
        ) : (
          <FolderIcon className="folder-icon" />
        )}
      </div>
      <div className="single-folder__name" title={folder.name}>
        {folder.name}
      </div>
    </div>
  );
};
