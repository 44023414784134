import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import React, { useEffect } from 'react';
import { useFileCache } from 'src/api/files';

export const useDragState = () => {
  const {
    toggleSection,
    selectedIds,
    updateState,
    isDragging: isGroupDragging,
    isSelected,
    clearSelectedIds,
  } = useGlobalContext();

  const { getFilesFoldersFromCache } = useFileCache({});
  const { files, folders } = getFilesFoldersFromCache();

  const filesRef = React.useRef(files.filter((f) => selectedIds.includes(f.id)));
  const foldersRef = React.useRef(folders.filter((f) => selectedIds.includes(f.id)));
  const selectedIdsRef = React.useRef(selectedIds);

  useEffect(() => {
    filesRef.current = files.filter((f) => selectedIds.includes(f.id));
    foldersRef.current = folders.filter((f) => selectedIds.includes(f.id));
    selectedIdsRef.current = selectedIds;
  }, [selectedIds]);
  // end

  return {
    isGroupDragging,
    filesRef,
    foldersRef,
    selectedIdsRef,
    selectedIds,
    toggleSection,
    updateState,
    isSelected,
    clearSelectedIds,
  };
};
