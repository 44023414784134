import React, { FC, useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import { ReactComponent as TooltipTop } from './assets/tooltip-top.svg';
import { getConvertedSize } from 'src/helpers';
import './styles.scss';
import classNames from 'classnames';

type Props = {
  onChange: (value: number) => void;
  value?: number;
  min: number;
  max: number;
};

export const Slider: FC<Props> = ({ onChange, value, min, max }) => {
  const step = min * 4;
  const minValue = min;
  const maxValue = max;
  const initialCapacity = value ? value : minValue;
  const [currentValue, setCurrentValue] = useState<number | string>(initialCapacity);

  useEffect(() => {
    setCurrentValue(value ? value : minValue);
  }, [value]);

  const handleChange = (value: number) => {
    const newValue = Math.min(max, value);
    setCurrentValue(newValue);
    onChange(newValue);
  };

  // Calculate the number of steps and generate an array for markers
  const numSteps = Math.floor((max - min) / step);
  const marks = Array.from({ length: numSteps + 1 }, (_, index) => min + index * step);

  return (
    <div className="storage-slider form-slider">
      <div className="slider-container">
        <div className="slider-labels-top">
          <span className="label-top" style={{ left: '0%' }}>
            {getConvertedSize(min)}
          </span>
          <span className="label-top" style={{ left: '100%' }}>
            {getConvertedSize(max)}
          </span>
        </div>

        <ReactSlider
          className="custom-slider"
          min={minValue}
          max={maxValue}
          step={step}
          thumbClassName="custom-thumb"
          trackClassName="custom-track"
          value={typeof currentValue === 'number' ? currentValue : minValue}
          onChange={handleChange}
          renderThumb={(props, state) => (
            <div {...props}>
              <div className="tooltip">
                <TooltipTop className="tooltip-marker" />
                {getConvertedSize(Math.min(max, state.valueNow))}
              </div>
            </div>
          )}
        />

        <div className="slider-marks">
          {marks.map((mark, index) => {
            const position = (mark * 100) / maxValue;
            return (
              <span
                key={index}
                className={classNames('slider-mark', {
                  'slider-mark--active': mark <= currentValue,
                })}
                style={{ left: `calc(${position}%)` }}
              ></span>
            );
          })}
        </div>
      </div>
    </div>
  );
};
