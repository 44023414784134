import React, { FC } from 'react';
import { CloseIcon } from 'src/components/Icons';
import { FolderType } from 'src/types';
import { useTranslation } from 'react-i18next';
import './folderInfo.scss';
import { FolderInfo } from 'src/components/InfoComponents';

type Props = {
  folder: FolderType;
  className?: string;
  onClose?: () => void;
};

export const NewFolderInfo: FC<Props> = ({ folder, className, onClose }) => {
  const { t } = useTranslation();

  if (!folder) return <></>;
  return (
    <div className={'info-component'}>
      <div className="right-title">
        <span>{t('properties')}</span>
        <CloseIcon className="right-title__close" onClick={onClose} />
      </div>
      <FolderInfo folder={folder} className={className} />
    </div>
  );
};
