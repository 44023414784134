import React, { FC } from 'react';
import { Button } from 'src/components/Forms/Button';
import { ReactComponent as Logo } from './assets/footer-logo.svg';
import { ReactComponent as Linkedin } from './assets/linkedin.svg';
import { ReactComponent as Facebook } from './assets/facebook.svg';
import { ReactComponent as Instagram } from './assets/instagram.svg';
import { ReactComponent as Telegram } from './assets/telegram.svg';
import { configLinks } from 'src/pages/landings/components/LandingFooter/config';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL, TELEGRAM_URL } from 'src/config/urls';
import { ReactComponent as TopIcon } from './assets/top-icon.svg';
import './styles.scss';

type Props = {
  onClick: () => void;
};

export const LandingFooter: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  const onTopClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className="landing-footer">
      <div className="landing-footer__content">
        <div className="landing-container">
          <div className="landing-footer__title">{t('unifiedMediaInterface')}</div>
          <div className="landing-footer__btn-wrapper">
            <Button
              type="basic"
              content={t('getStart')}
              className="landing-footer__btn"
              onClick={onClick}
            />
          </div>
          <div className="landing-footer__separator"></div>
          <div className="landing-footer__links-wrapper">
            <div className="landing-footer__social-links">
              <Logo className="landing-footer__social-logo" />
              <p className="landing-footer__social-description">{t('socialDescription')}</p>
              <ul className="landing-footer__social-list">
                <li>
                  <Link target="_blank" to={LINKEDIN_URL}>
                    <Linkedin />
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to={FACEBOOK_URL}>
                    <Facebook />
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to={INSTAGRAM_URL}>
                    <Instagram />
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to={TELEGRAM_URL}>
                    <Telegram />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="landing-footer__links">
              {configLinks(t).map((item, index) => (
                <div key={index} className="landing-footer__links-column">
                  <div className="landing-footer__links-title">{item.title}</div>
                  <ul className="landing-footer__links-list">
                    {item.links.map((link, index) => (
                      <li key={index}>
                        <a href={link.url}>{link.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="landing-footer__top" onClick={onTopClick}>
        <TopIcon />
      </div>
    </div>
  );
};
