import React from 'react';
import { Button } from 'src/components/Forms/Button';
import { ReactComponent as DataCenters } from './assets/data-cetnters.svg';
import { useTranslation } from 'react-i18next';
import AnimatedWrapper from 'src/pages/landings/Cloud/components/Datacenters/Animation';
import './styles.scss';

export const Datacenters = () => {
  const { t } = useTranslation();

  return (
    <div className="datacenters">
      <h2 className="datacenters__title">{t('ourNetworkDC')}</h2>
      <p className="datacenters__description">{t('dataCentersDescription')}</p>
      <div className="datacenters__list">
        <AnimatedWrapper speed={25}>
          <DataCenters />
        </AnimatedWrapper>
      </div>
      <div className="datacenters__control">
        <Button
          type="secondary"
          content={t('submitAnApplication')}
          className="datacenters__button"
        />
      </div>
    </div>
  );
};
