import React, { FC } from 'react';
import classNames from 'classnames';
import './styles.scss';

type Props = {
  label: string;
  id?: string;
  className?: string;
  onLabelClick?: () => void;
  onChange?: () => void;
};

export const Checkbox: FC<Props> = ({
  label,
  className,
  onLabelClick,
  onChange,
  id = 'checkbox',
}) => {
  const onClick = () => {
    if (onLabelClick) {
      onLabelClick();
    }
  };

  const onChangeHandler = () => {
    if (onChange) {
      onChange();
    }
  };
  return (
    <div className={classNames('checkbox', className)}>
      <input type="checkbox" id={id} className="checkbox__input" onChange={onChangeHandler} />
      <label
        htmlFor={onLabelClick ? '' : id}
        className={classNames('checkbox__label', { hasAction: onLabelClick })}
        onClick={onClick}
      >
        {label}
      </label>
    </div>
  );
};
