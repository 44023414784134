import React, { FC, useState } from 'react';
import { ReactComponent as Logo } from '../../assets/UMI-search-logo-blue.svg';
import { Link } from 'react-router-dom';
import { Button } from 'src/components/Forms/Button';
import { ReactComponent as MenuIcon } from './assets/menu-icon.svg';
import { ReactComponent as LogoWhite } from './assets/logo-white.svg';
import { ReactComponent as CloseMenuIcon } from './assets/close-menu-icon.svg';
import { ReactComponent as BottomChevron } from './assets/bottom-chevrone.svg';
import { useTranslation } from 'react-i18next';
import { ContextDropdown } from 'src/components/Dropdown/context';
import { DropDownItem } from 'src/components/Dropdown/types';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { FullModal } from 'src/components/Modals/FullModal';
import './styles.scss';

type Props = {
  onClick: (isShare?: boolean) => void;
};

export const LandingNavigation: FC<Props> = ({ onClick }) => {
  const { hideProduction } = useFeatureFlags();
  const { i18n, t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const currentLanguage = i18n.language;

  const [showModal, setShowModal] = useState(false);

  const changeLanguage = async (language: string) => {
    await i18n.changeLanguage(language);
  };

  const items: DropDownItem[] = [
    {
      value: '',
      label: 'English',
      onClick: () => {
        changeLanguage('en');
      },
    },
    {
      value: '',
      label: 'Ukrainian',
      onClick: () => {
        changeLanguage('ua');
      },
    },
    {
      value: '',
      label: 'French',
      onClick: () => {
        changeLanguage('fr');
      },
    },
    {
      value: '',
      label: 'Russian',
      onClick: () => {
        changeLanguage('ru');
      },
    },
  ];

  const renderMenuItems = (showMenu: boolean = false) => {
    return (
      <ul>
        <li>
          <Link to="/">{t('main')}</Link>
        </li>
        <li>
          <Link to="/cloud">{t('cloud')}</Link>
        </li>
        <li>
          <Link
            to=""
            onClick={(e) => {
              e.preventDefault();
              setShowModal(true);
            }}
          >
            {t('disks')}
          </Link>
        </li>
        {showMenu && (
          <>
            <li>
              <div className="landing-header__language">
                <ContextDropdown
                  className=""
                  items={items}
                  trigger={
                    <>
                      <div className="landing-navigation__ln mobile-ln">{currentLanguage}</div>{' '}
                      <BottomChevron className="mobile-chevron" />
                    </>
                  }
                  id={'user-card-dropdown-mobile'}
                  leftClick
                  offset={{ x: 100, y: 40 }}
                />
              </div>
            </li>
            {!hideProduction && (
              <li>
                <Button
                  type="secondary"
                  className="landing-navigation__button landing-navigation__button--fileshare mobile"
                  content={t('fileSharing')}
                  onClick={() => onClick(true)}
                />
              </li>
            )}
            <li>
              <Button
                type="primary"
                className="landing-navigation__button"
                content={t('signIn')}
                onClick={onClick}
              />
            </li>
          </>
        )}
      </ul>
    );
  };

  return (
    <>
      <div className="landing-navigation hide-flex">
        <div className="landing-navigation__logo">
          <Link to="/">
            <Logo className="landing-header__logo" />
          </Link>
        </div>
        <div className="landing-navigation__menu">{renderMenuItems()}</div>
        <div className="landing-navigation__controls">
          <div className="landing-navigation__language">
            <ContextDropdown
              className=""
              items={items}
              trigger={
                <>
                  <div className="landing-navigation__ln">{currentLanguage}</div> <BottomChevron />
                </>
              }
              id={'user-card-dropdown-mobile'}
              leftClick
              offset={{ x: 100, y: 40 }}
            />
          </div>
          {!hideProduction && (
            <Button
              type="secondary"
              className="landing-navigation__button landing-navigation__button--fileshare"
              content={t('fileSharing')}
              onClick={() => onClick(true)}
            />
          )}
          <Button
            type="primary"
            className="landing-navigation__button"
            content={t('signIn')}
            onClick={onClick}
          />
        </div>
      </div>
      <div className="landing-navigation show-flex">
        {!showMenu && (
          <>
            <div className="landing-navigation__logo">
              <Logo />
            </div>
            <div className="landing-navigation__controls landing-navigation__menu-btn">
              <MenuIcon onClick={() => setShowMenu(!showMenu)} />
            </div>
          </>
        )}

        {showMenu && (
          <div className="landing-navigation__menu-list">
            <div className="landing-navigation__menu-list--header">
              <div>
                <LogoWhite />
              </div>
              <div>
                <CloseMenuIcon
                  onClick={() => setShowMenu(!showMenu)}
                  className="landing-navigation__close-menu"
                />
              </div>
            </div>
            <div className="landing-navigation__menu-list--content">{renderMenuItems(true)}</div>
          </div>
        )}
      </div>

      <FullModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onAction={() => {
          setShowModal(false);
          onClick();
        }}
      />
    </>
  );
};
