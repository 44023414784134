const translation = {
  welcome: 'Welcome to UMI!',
  description: 'This is a multilingual application.',
  loginDescription: 'more than just cloud storage',
  searchByFiles: 'Search by files',
  settings: 'Settings',
  editProfile: 'Edit Profile',
  security: 'Security',
  notifications: 'Notifications',
  contacts: 'Contacts',
  support: 'Support',
  extension: 'Extension',
  paymentsHistory: 'Payments History',
  removedFiles: 'Removed Files',
  storageSelection: 'Storage Selection',
  personalData: 'Personal Data',
  saveChanges: 'Save Changes',
  nameLastName: 'Full Name',
  dateOfBirth: 'Date of Birth',
  username: 'Username',
  department: 'Department',
  position: 'Position',
  belgium: 'Belgium',
  bulgaria: 'Bulgaria',
  croatia: 'Croatia',
  cyprus: 'Cyprus',
  czechRepublic: 'Czech Republic',
  denmark: 'Denmark',
  estonia: 'Estonia',
  finland: 'Finland',
  france: 'France',
  germany: 'Germany',
  greece: 'Greece',
  hungary: 'Hungary',
  ireland: 'Ireland',
  italy: 'Italy',
  latvia: 'Latvia',
  lithuania: 'Lithuania',
  luxembourg: 'Luxembourg',
  malta: 'Malta',
  netherlands: 'Netherlands',
  poland: 'Poland',
  portugal: 'Portugal',
  romania: 'Romania',
  slovakia: 'Slovakia',
  slovenia: 'Slovenia',
  spain: 'Spain',
  ukraine: 'Ukraine',
  phoneNumber: 'Phone number',
  signIn: 'Sign in',
  openQRCode: 'Open the QR code',
  continueWithApple: 'Continue with Apple',
  continueWithGoogle: 'Continue with Google',
  enter: 'Enter',
  enterCodeFromSMS: 'Enter code from SMS',
  sendingSMSWithTheCodeAgain: 'Sending SMS with the code again',
  smsHasBeenSent: 'SMS has been sent',
  chooseALanguage: 'Choose a language',
  enterUniqueUsername: 'Enter unique username',
  usernameLetsPeopleContactYou: 'Username lets people contact you on UMI',
  yourUsername: 'Your username',
  invalidPhoneNumber:
    'The phone number entered is not in the correct format. Please enter the number in international format.',
  phoneNumberIsRequired: 'Phone number is required',
  invalidOtp: 'Invalid OTP',
  main: 'Main',
  mySubscriptions: 'My Subscriptions',
  loading: 'Loading',
  profile: 'Profile',
  help: 'Help',
  privacyPolicy: 'Privacy Policy',
  recycleBin: 'Recycle Bin',
  balance: 'Balance',
  refill: 'Refill',
  addYourID: 'Add your ID',
  id: 'ID',
  email: 'Email',
  addYourFullName: 'Add your Full Name',
  addYourEmail: 'Add your Email',
  addYourPosition: 'Add your Position',
  addYourDepartment: 'Add your Department',
  accountConnection: 'Account Connection',
  logOut: 'Log out',
  deleteAccount: 'Delete account',
  no: 'No',
  yes: 'Yes',
  logOutConfirmation: 'Are you sure you want to log out of your account?',
  profileUpdatedSuccessfully: 'Profile updated successfully',
  setYourPhoto: 'Set your photo',
  addYourUserName: 'Add your username',
  fullNameIsRequired: 'Full name is required',
  usernameIsRequired: 'Username is required',
  departmentIsRequired: 'Department is required',
  positionIsRequired: 'Position is required',
  validEmailError: 'must be a valid email',
  changeTheCover: 'Change the cover',
  addToFavorite: 'Add to favorite',
  renameDisk: 'Rename disk',
  downloadDisk: 'Download disk',
  diskName: 'Disk name',
  cancel: 'Cancel',
  createDis: 'Create a disk',
  deleteConfirmation: 'Are you sure you want to delete',
  deleteDisk: 'Delete disk',
  download: 'Download',
  select: 'Select',
  share: 'Share',
  copy: 'Copy',
  move: 'Move',
  properties: 'Properties',
  rename: 'Rename',
  delete: 'Delete',
  owner: 'Owner',
  moveSelectTheFilesUpload: 'Move or select the files you want to upload from your computer',
  folderName: 'Folder name',
  createFolder: 'Create a folder',
  deleteFolder: 'Delete folder',
  renameFolder: 'Rename folder',
  uploadFiles: 'Upload files',
  restore: 'Restore',
  restoreTo: 'Restore to',
  deletePermanently: 'Delete permanently',
  sorting: 'sorting',
  name: 'Name',
  uploaded: 'Uploaded',
  uploading: 'Uploading',
  canceled: 'Canceled',
  uploadYourFirstFile: 'Upload your first file',
  areYouSureWantDeleteAll: 'Are you sure you want to delete all files and folders?',
  filesWillBePermanentlyDeleted: 'All files will be permanently deleted from UMI Cloud storage',
  willBePermanentlyDeleted: 'will be permanently deleted from UMI Cloud storage',
  currentPosition: 'Current position',
  createNewFolder: 'Create a new folder',
  selectLocation: 'Select location',
  moveTo: 'Move to',
  folderWithFiles: 'FOLDER',
  dateOfEditing: 'Date of editing',
  size: 'Size',
  type: 'Type',
  byName: 'By name',
  bySize: 'By size',
  yourFiles: 'Your files',
  createANewDisk: 'Create a new disk',
  subscription: 'Subscription',
  save: 'Save',
  accessControlTo: 'Access control to',
  trash: 'Trash',
  fileTypes: 'File Types',
  totalDiskVolume: 'Total Disk Volume',
  creationDate: 'Creation Date',
  parentFolderName: 'Parent Folder Name',
  parentDiskName: 'Parent Disk Name',
  fileExtension: 'File Extension',
  fileSize: 'File Size',
  totalFolderVolume: 'Total Folder Volume',
  fileSharedSuccessfully: 'File shared successfully',
  editors: 'Editors',
  readers: 'Readers',
  files: 'files',
  myDisks: 'My Disks',
  createDisk: 'Create Disk',
  chat: 'Chat',
  search: 'Search',
  folders: 'Folders',
  restoreAllFiles: 'Restore all files',
  deleteAll: 'Delete all',
  preview: 'Preview',
  uMIMoreThanCloudStorage: 'More than cloud storage',
  landingPageDescription:
    "We don't just store files, we provide the opportunity to create a unified library and connect your team to it",
  getStart: 'Get start',
  moreAboutTheService: 'More about the service',
  ourNetworkDC: 'Our network of data centers',
  dataCentersDescription:
    'We are tirelessly expanding our global network of data centers to ensure the most secure data storage and optimize storage costs.',
  submitAnApplication: 'Submit an application',
  dataStructuring: 'Data structuring',
  selectingCostAndStorageCapacity: 'Selection of cost and storage capacity',
  tagAndHashtagSystemFiles: 'Tag and hashtag system for files',
  electronicDocumentSigning: 'Electronic document signing',
  connectionOfYourStorage: 'Connecting your own storage',
  chatForCorporateClients: 'Chats for corporate clients',
  storageCapabilities: 'Storage capabilities',
  months: 'months',
  month: 'month',
  storageDatacenter: 'Data storage center',
  dataBackup: 'Data backup',
  numberOfGigabytes: 'Number of gigabytes',
  costPerGigabyte: 'Cost per gigabyte',
  subscriptionTerm: 'Subscription term',
  discountReceived: 'Discount received',
  selectedStorageParameters: 'Selected storage parameters',
  monthly: 'Monthly',
  costFor: 'Cost for',
  storageCapacity: 'Storage Capacity',
  price: 'Price',
  storageTime: 'Storage time',
  reservationOptions: 'Booking options',
  noReservation: 'No reservation',
  reservation: 'Booking',
  chooseServerStorage: 'Choose a server storage',
  AWSStorage: 'AWS Storage',
  downloadAPP: 'Download APP',
  appDescription:
    'Manage your files from anywhere in the world. The mobile app for your device is available on marketplaces',
  unifiedMediaInterface: 'Universal media interface',
  footerDescription:
    'An integrated and unified way to manage and share multimedia data in cloud storage.',
  socialDescription:
    'UMI is a cloud service for mobile devices and computers. Fast, secure, functional.',
  cloud: 'Cloud',
  teamWork: 'Team Work',
  fileSearch: 'File Search',

  basicFunctionalityStorage: 'Basic functionality of the storage',
  tiredOfSwitchingBetweenMultipleCloudStorages:
    'Tired of switching between multiple cloud storages?',
  nowAllYourFilesAreInOnePlace:
    'Now all your files are in one place! Our service unites popular cloud platforms, providing a user-friendly and intuitive interface to manage all your data.',
  createYourFirstDiscParameters: 'Create your first disc with optimal storage parameters',
  creatingStorageDisk: 'Creating a disk for data storage',

  annually: 'Annually',
  invoice: 'Invoice',
  homeDescription: 'Functional storage, file marketplace, tools for teamwork',
  homeTitle: 'Future Digital Asset Library',
  fileSharing: 'File Sharing',
  homeSlide1Title: 'Unified Digital Asset Library',
  homeSlide1Subtitle: 'We put all our experience into creating a convenient storage solution',
  homeSlide1Description:
    'Simple monetization, structuring of accumulated archives, a multi-level access system, disk creation and customization, ticketing system, document signing—which previously required visiting different resources—are now all in one library!',
  homeSlide2Title: 'Functional Storage and Management',
  homeSlide2Subtitle: 'Optimize costs while increasing efficiency',
  homeSlide2Description:
    'We don’t just store files, we offer the opportunity to create a unified library and connect your team to it',
  homeSlide3Title: 'Manage and Monetize Your Digital Assets',
  homeSlide3Subtitle: 'We don’t just store, we monetize!',
  homeSlide3Description:
    'We have created all the tools for sorting old archives so that you can see the potential of old data and give it new life',
  downloadApp: 'Download APP',
  homeAppsDescription: 'Manage your files from anywhere in the world.',
  convenienceTitle: 'Convenience',
  convenienceDescription: 'Simple, intuitive interface',
  goalsTitle: 'Task Management',
  goalsDescription: 'Monitoring work progress',
  syncTitle: 'Synchronization',
  syncDescription: 'Access from multiple devices simultaneously',
  speedTitle: 'Speed',
  speedDescription: 'High file upload speed',
  noBreaksTitle: 'No Breaks',
  noBreaksDescription: 'No limits on the size of uploaded and downloaded files',
  opennessTitle: 'Openness',
  opennessDescription: 'Open API for developers',
  securityTitle: 'Security',
  securityDescription: 'Double data encryption during transmission',
  communicationTitle: 'Communication',
  communicationDescription: 'Group chats for teams and personal use',
  flexTitle: 'Flexibility',
  flexDescription: 'Choice of storage volume and cost',
  newsTitle: 'Fresh News',
  reasonsTitle: 'Why UMI?',
  cloudSlide1Title: 'Main Features',
  cloudSlide1Subtitle: 'No need to pay for extra gigabytes anymore',
  cloudSlide1Description:
    'Choose the volume, cost, and storage location yourself by creating separate disks for various projects and tasks within a single interface',
  cloudSlide2Title: 'All Necessary Tools in One Library',
  cloudSlide2Subtitle: 'Tired of switching between multiple cloud storage services?',
  cloudSlide2Description:
    'Functional file display, file category filter, search by tags, hashtags—all this and much more is available in our interface',
  cloudSlide3Title: 'Creating Disks for Various Projects and Teams',
  cloudSlide3Subtitle: 'Optimize costs by using storage functionality',
  cloudSlide3Description:
    'We are the first to offer the ability to create disks in a unified interface for separate projects and teams, choosing the volume, cost, and backup options yourself',
  cloudSlide4Title: 'Multi-Level Access and Permissions System',
  cloudSlide4Subtitle: 'Tired of switching between multiple cloud storage services?',
  cloudSlide4Description:
    'You store everything in one place, but each team member sees files within the scope of their role',
  cloudSlide5Title: 'Ticketing System for Task Management',
  cloudSlide5Subtitle: 'Tired of switching between multiple cloud storage services?',
  cloudSlide5Description:
    'We help gather all employees, tasks, and tools in one place. Create boards, assign tasks, and attach files in a unified file library',
  cloudOption1Title: 'Main Functions',
  cloudOption1Subtitle: 'No need to pay for extra gigabytes and features anymore',
  cloudOption1Description:
    'We removed all unnecessary elements and gave each user the right to independently decide what the storage should be like, based on personal needs rather than service templates. You define it yourself:',
  cloudOption1Param1: 'Where to store',
  cloudOption1Param2: 'How to back up',
  cloudOption1Param3: 'What storage volume',
  cloudOption1Param4: 'Number of disks for storage',

  cloudOption2Title: 'Simple functionality for complex tasks',
  cloudOption2Subtitle: '',
  cloudOption2Description:
    'We created simple functionality that will help solve complex tasks, from finding the right files to structuring large data sets in an intuitive interface',
  cloudOption2Param1: 'Your files in a visual format',
  cloudOption2Param2: 'File category filter',
  cloudOption2Param3: 'Search by hashtags',
  cloudOption2Param4: 'Create a gallery and library with link access',

  cloudOption3Title: "Don't waste your budget on storage",
  cloudOption3Subtitle: '',
  cloudOption3Description:
    'We are the first to provide the ability to create drives in a unified interface for individual projects and teams, allowing users to independently choose the volume, cost, and backup options',
  cloudOption3Param1: 'Create a drive for the project',
  cloudOption3Param2: 'Choose the volume and storage location',
  cloudOption3Param3: 'Connect separate teams to each disk',
  cloudOption3Param4: 'Choose how to back up',

  cloudOption4Title: 'Task Ticketing System',
  cloudOption4Subtitle: 'Don’t waste time on notes, set tasks in one place',
  cloudOption4Description:
    'Don’t waste time on calls to keep tasks in one place. We will help gather all employees, tasks, and tools in one folder. Create disks, assign tasks, and attach files in one file library.',
  cloudOption4Param1: 'Group and personal chats',
  cloudOption4Param2: 'Comments, notes, notifications',
  cloudOption4Param3: 'Attach files to tasks',
  cloudOption4Param4: 'Document signing',
  landingPageSubtitle: 'Optimize costs by increasing efficiency!',
  aboutUmi: 'About UMI',
  faq: 'FAQ',
  partnership: 'Partnership',
  forMedia: 'For media',
  products: 'Products',
  mobileApp: 'Mobile app',
  forComputers: 'For computers',
  webVersion: 'Web version',
  infrastructure: 'Infrastructure',
  api: 'API',
  translations: 'Translations',
  instantView: 'Instant View',
  development: 'Development',
  jobs: 'Jobs',
  focusGroup: 'Focus Group',
  askAQuestion: 'Ask a question',
  personalInformation: 'Personal Information',
  agreements: 'Agreements',
  today: 'Today',
  yesterday: 'Yesterday',
  paymentAmount: 'Payment amount',
  newDisk: 'New disk',
  myBalance: 'My balance',
  supportForDevelopers: 'Support for developers',
  businessSubscription: 'Business subscription',
  recentOperations: 'Recent operations',
  uploadsAndDownloads: 'Uploads and Downloads',
  cancelAll: 'Cancel all',
  resume: 'Resume',
  finished: 'Finished',
  file: 'File',
  language: 'Language',
  termsOfUse: 'Terms of Use',
  noUploads: 'No uploading and downloading',
  create: 'Create',
  upload: 'Upload',
  occupancy: 'Occupancy',
  diskCapacity: 'Disk capacity',
  items: 'items',
  item: 'item',
  digitalAssetLibrary: 'Digital Asset Library',
  timeOfDeletion: 'Time of deletion',
  functions: 'Functions',

  highPerformance: 'High-performance storage for regularly used data',
  requestProcessing: 'Request processing in less than 10 milliseconds',
  increaseAccessSpeed:
    'Increases access speed by 10x, reduces query processing costs by 50% compared to S3 Standard',
  availabilityZone:
    'By selecting AWS Availability Zone, customers can co-locate storage and compute resources to dramatically reduce latency and processing time, and improve compute resource utilization to reduce total TCO',
  accelerateAnalytics:
    'Accelerate analytics and machine learning workloads with AWS service integration',
  scaleRequests: 'Scale to handle millions of requests per minute',
  optimizeDatasets: 'Optimize for large datasets with many small objects',
  leverageS3:
    'Leverage existing Amazon S3 APIs with a different type of shopping cart - catalog garbage cans',
  storageAvailability:
    'Storage is designed to provide 99.95% availability under the terms of a 99.9% service level agreement',
  generalPurposeStandard:
    'General purpose standard for frequently used data. Offers high reliability, availability and performance object storage for storing frequently used data. Providing low latency and high throughput.',
  standard: 'Standard',

  generalPurposeStorage: 'General purpose storage for frequently used data',
  lowLatencyHighThroughput: 'Low latency and high throughput',
  storageAvailabilityAgreement:
    'Storage designed to provide 99.99% availability in accordance with the terms of the 99.9% service level agreement',
  highPerformanceShort: 'High Performance',

  expressOneZone:
    'Express One Zone is a high-performance, single-availability storage class specifically designed to provide consistent, millisecond-speed access to regularly used data and latency-sensitive applications.',
  infrequentAccess: 'Infrequent Access',
  standardIA:
    'Standard - IA is the ideal choice for data storage that is accessed relatively infrequently but must be accessed quickly. High reliability, high throughput and low latency, while offering low cost per gigabyte. Ideal for long-term data storage, backup.',

  rarelyUsedData: 'Rarely used data that requires instant access',
  lowLatencyHighThroughputS3: 'Low latency and high throughput at the S3 Standard storage level',
  storageAvailabilityAgreement99:
    'Storage is designed to provide 99.9% availability in accordance with the terms of the 99% service level agreement',
  archiving: 'Archiving',

  glacierArchiving:
    'Glacier are designed specifically for data archiving and feature maximum performance, retrieval flexibility and minimal cost of cloud archive storage',
  flexibleRetrieval: 'Flexible Retrieval',
  retrievalSupport:
    'Supports retrieval in minutes for small volumes and 5-12 hours for large packages.',

  longTermStorage:
    'Long-term storage data that is accessed several times a year and retrieved instantly',
  retrieveMilliseconds:
    'Retrieve data in a few milliseconds with the same performance as S3 Standard.',
  storageAvailabilityAgreement992:
    'Storage is designed for 99.9% availability in accordance with the terms of the 99% service level agreement',
  minimumObjectSize: 'Minimum object size: 128 KB',
  s3PutApi:
    'S3 PUT API for direct upload to S3 Glacier Instant Retrieval and S3 lifecycle management for automatic object migration',

  deepArchive: 'Deep Archive',

  minimalCloudCosts:
    'Which features minimal cloud storage costs and the ability to retrieve data within 12-48 hours.',

  lowCostBackupArchiving: 'Low-cost backup and archiving of infrequently used data',
  storageAvailabilityAgreement9999:
    'Storage designed for 99.99% availability in accordance with the terms of the 99.9% service level agreement',
  sslSupport: 'SSL support for data in motion and on-premises data encryption',
  backupDisasterRecovery:
    'Great for backup and disaster recovery when you need ad hoc retrieval of large data sets in minutes at no additional cost.',
  customizableRetrievalTimes:
    'Customizable retrieval times - from a few minutes to several hours - and batch retrieval capability.',
  s3PutApiFlexibleRetrieval:
    'S3 PUT API for direct upload to S3 Glacier Flexible Retrieval and S3 lifecycle management for automatic object migration',

  instantRetrieval: 'Instant Retrieval',

  minimizesLongTermStorageCost:
    'Minimizes the cost of long-term storage for data that needs to be accessed infrequently and retrieved in a few milliseconds.',

  rarelyUsedArchivalData: 'Exceptionally rarely used archival data at extremely low cost',
  repositoryAvailabilityAgreement9999:
    'The repository is designed for 99.99% availability under the terms of a 99.9% service level agreement',
  idealAlternativeToTape: 'Ideal alternative to tape libraries',
  retrievalTime12Hours: 'Retrieval time within 12 hours',
  s3PutApiDeepArchive:
    'S3 PUT API for direct upload to S3 Glacier Deep Archive and S3 lifecycle management for automatic object migration',

  readMore: 'Read more',
  keyFeatures: 'Key features',
  diskParameters: 'Disk parameters',

  confirmParameters: 'Confirm parameters',
  subscribe: 'Subscribe',
  costGigabyte: 'Cost gigabyte',
  amount: 'Amount',
  repeat: 'Repeat',
  failedPayment:
    'Oops! Your payment could not be processed. This may be due to insufficient funds or an expired card. Please try again or update your payment details.',
  paymentFailed: 'Payment Failed',
  paymentSuccessful: 'Payment Successful',
  successPaymentMsg:
    'Thank you for subscribing! Your payment has been successfully processed, and your subscription is now active. Enjoy all premium features!',
  serverAWS: 'Server AWS',
  connectDisk: 'Connect disk',
  freedoms: 'Freedoms',
  storageOffers: 'Storage offers',
  open: 'Open',
  completeFreedom: 'Complete freedom of action',
  customizeStorage: 'Customize storage the way you want and manage your data without restrictions',
  editDisk: 'Edit disk',
  noNotifications: 'No notifications',
  diskInformation: 'Disk information',
  storage: 'Storage',
  capacity: 'Capacity',
  cost: 'Cost',
  clearDiskMessage: 'Are you sure you want to clear the disk?',
  removeFromFavorite: 'Remove from favorite',
  folderInformation: 'Folder information',
  fileInformation: 'File information',
  totalVolume: 'Total volume',
  hashtags: 'Hashtags',
  add: 'Add',
  lastModifiedDate: 'Last Modified Date',
  dates: 'Dates',
  noHashtags: 'No hashtags',
  edit: 'Edit',
  popularHashtags: 'Popular hashtags',
  functionality: 'Functionality',
  marketplace: 'Marketplace',
  taskBoards: 'Task boards',
  groupChats: 'Group chats',
  unsubscribe: 'Unsubscribe',
  futureFunctionality: 'Future functionality',
  monthlySupportDevelopers: 'Monthly support for developers',
  beAPartText: 'Be a part of our shared success!',
  supportText:
    'Support the development of new features and functionality by subscribing for just $1 per month',
  supportTeam: 'Support the team',
  fileMarket: 'File market',
  fileCopiedSuccessfully: 'File copied successfully',
  folderCopiedSuccessfully: 'Folder copied successfully',
  disks: 'Disks',
  freeDisk: 'Free disk',
  personalLibraryText: 'Your personal digital library is waiting for you!',
  signUpAndGet10Text: 'Sign up and get 10 GB of free space for your files at once',
  signUp: 'Sign Up',
  taskBoardsText:
    'Create and track tasks, control access to information, securely store documents and communicate with colleagues in real time.',
  groupChatsText:
    'We will create chats to communicate with the team, clients, customers and suppliers without leaving the storage that can be linked to drives and teams.',
  fileSearchText:
    'Our goal is to create a storage based file finder where every user can manage, monetize and promote their files through an open search engine. Get reviews, ratings and orders directly into the repository.',
  marketplaceText:
    'We are building cloud-based functionality that will help monetize any type of file uploaded to storage',
  days: 'Days',
  Hours: 'Hours',
  Minutes: 'Minutes',
  Attention: 'Attention!!!',
  attentionText1:
    'To continue working with the disk, you must pay for the occupied volume of disk space.',
  attentionText2:
    'If you do not renew your subscription for the occupied disk space, the disk and all its data will be deleted.',
  DeletionVia: 'Deletion via',
  PayForDisk: 'Pay for disk',
  diskWillBeDeleted: 'The disk will be deleted after',
  day: 'day',
  Pay: 'Pay',
  payments: 'Payments',
  file_uploaded: 'File uploaded',
  devices: 'Devices',
  thisDevices: 'This device',
  terminateRest: 'Terminate all other sessions',
  drive_deleted: 'Drive has been deleted',
  drive_created: 'Drive has been created',
  folder_created: 'Folder has been created',
  file_downloaded: 'File has been downloaded',
  file_moved: 'Files have been moved',
  file_deleted: 'Files have been deleted',
  item_renamed: 'Item was renamed',
  drive_cleared: 'Drive was cleared',
  item_deleted: 'Files have been deleted',
  invoice_succeeded: 'Subscription payment succeeded',
  invoice_failed: 'Invoice payment failed',
  subscription_unpaid: 'Subscription is unpaid',
  subscription_cancelled: 'Subscription has been cancelled',
  subscription_restored: 'Subscription has been restored',
  welcome_message: 'Welcome to the service',
  trashed_cleared: 'Basket has been cleared',
  app_update_available: 'App update is available',
  quantity: 'Quantity',
  readAll: 'Read all',
  total_transactions_message: 'A total of {{count}} transactions were made',
  total_transaction_message: 'A total of {{count}} transaction were made',
  downloaded: 'Downloaded',
  noOperations: 'No operations',
  volume: 'Volume',
  theOfficialUmi: 'The official UMI App is available for iPhone, iPad, Android, macOS and Windows',
  logOutAllDevices: 'Logs out all devices except for this one.',
  activeSessions: 'Active sessions',
  terminateThisSession: 'Do you want to terminate this session?',
  terminate: 'Terminate',
  sureTerminateAll: 'Are you sure you want to terminate all your devices?',
};

export default translation;
