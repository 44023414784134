import { API } from 'src/utils/API';
import { useMutation, useQuery } from '@tanstack/react-query';
import { HashTagType } from 'src/types';

type ResponseHashtags = {
  success: boolean;
  hashtags: HashTagType[];
};

export const useGetHashtags = (diskId: string, query?: string) => {
  const sendData = async (): Promise<ResponseHashtags> => {
    const url = query
      ? `/items/hashtags/search?hashtag=${query}&drive_id=${diskId}`
      : `/items/hashtags/list/${diskId}`;

    const resp = await API.get(url, 'archive');
    if (resp.success) {
      return {
        success: true,
        hashtags: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useQuery({
    queryKey: ['hashtags', query],
    queryFn: sendData,
    retry: 0,
  });
};

type CreateHashTagPayload = {
  item_id: string;
  hashtag: string;
  drive_id: string;
};

export const useCreateHashtag = () => {
  const sendData = async (payload: CreateHashTagPayload) => {
    const resp = await API.post('/items/hashtags/add', payload, 'archive');
    if (resp.success) {
      return {
        success: true,
        hashtag: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export type EditHashtagPayload = {
  hashtag_id: string;
  hashtag: string;
};

export const useEditHashtag = (diskId: string) => {
  const sendData = async (payload: EditHashtagPayload) => {
    const resp = await API.put(`/items/hashtags/edit/${diskId}`, payload, 'archive');
    if (resp.success) {
      return {
        success: true,
        hashtag: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

type DeleteFromFilePayload = {
  item_id: string;
  hashtag_id: string;
};

export const useDeleteHashtagFormFile = () => {
  const sendData = async ({ item_id, hashtag_id }: DeleteFromFilePayload) => {
    const resp = await API.delete(
      `/items/hashtags/?item_id=${item_id}&hashtag_id=${hashtag_id}`,
      'archive',
    );
    if (resp.success) {
      return {
        success: true,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};
