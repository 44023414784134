import React, { FC } from 'react';
import './styles.scss';
import classNames from 'classnames';
import { Button } from 'src/components/Forms/Button';
import { useTranslation } from 'react-i18next';

export type ModalPropsType = {
  onSure: () => void;
  onCancel: () => void;
  content: string | React.ReactNode;
  secondContent?: string | React.ReactNode;
  okText?: string;
  cancelText?: string;
  className?: string;
};
export const Confirmation: FC<ModalPropsType> = ({
  onSure,
  onCancel,
  content,
  okText,
  cancelText,
  secondContent,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('modal', 'skip-outside', className)}>
      <div className="modal__body">
        <div className="modal__content">{content}</div>
        {!!secondContent && <div className="modal__second-content">{secondContent}</div>}
        <div className="modal__footer">
          <Button
            content={okText || t('yes')}
            type="basic-danger"
            className=""
            onClick={onSure}
            isDisabled={false}
          />
          <Button
            content={cancelText || t('no')}
            type="basic"
            className=""
            onClick={onCancel}
            isDisabled={false}
          />
        </div>
      </div>
    </div>
  );
};
