import React, { FC } from 'react';
import { FileType } from 'src/types';
import { ContextDropdown } from 'src/components/Dropdown/context';
import { ActionButton } from 'src/components/Forms/ActionButton';
import { FileFavoriteIcon } from 'src/components/Icons';
import { FileMedia } from 'src/components/FileMedia';
import { useFileState } from 'src/pages/diskPage/hooks/useFileState';
import { DragPreviewImage, useDrag } from 'react-dnd';
import classNames from 'classnames';
import { transparentImage } from 'src/pages/diskPage/components/DragLayer/DragLayer';
import { useDragState } from 'src/pages/diskPage/hooks/useDragState';
import './styles.scss';

type Props = {
  file: FileType;
  onClick: (file: FileType) => void;
};

export const FileComponent: FC<Props> = ({ file, onClick }) => {
  const {
    updateState,
    isSelected,
    selectedIdsRef,
    filesRef,
    foldersRef,
    selectedIds,
    toggleSection,
    isGroupDragging,
  } = useDragState();

  const { items } = useFileState(file);
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'file',
    item: () => {
      updateState({ isDragging: true });
      return {
        files: isSelected(selectedIdsRef.current, file.id)
          ? filesRef.current
          : [...filesRef.current, file],
        folders: foldersRef.current,
      };
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: () => updateState({ isDragging: false }),
  }));

  const onFileClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (selectedIds.length > 0) {
      toggleSection(file.id);
      return;
    }
    onClick(file);
  };

  const onContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.ctrlKey || event.metaKey) {
      toggleSection(file.id);
    }
  };

  return (
    <ContextDropdown
      trigger={
        <>
          <DragPreviewImage connect={preview} src={transparentImage} />
          <div
            className={classNames('file select-item', 'drag-item', {
              dragging: isDragging || selectedIds.includes(file.id),
              selected: selectedIds.includes(file.id),
              groupDragging: (isGroupDragging && selectedIds.includes(file.id)) || isDragging,
            })}
            ref={drag}
            onClick={onFileClick}
            onContextMenu={onContextMenu}
            id={file.id}
          >
            <FileMedia fileId={file.id} extension={file.extension} className={'file__media'}>
              {file.is_favorite && <FileFavoriteIcon className="file__favorite" />}
            </FileMedia>

            <div className="file__details">
              <div style={{ width: 'calc(100% - 20px)' }}>
                <div className="file__name" title={file.name}>
                  {file.name}
                </div>
              </div>
              <ActionButton items={items} className="file__abtn" id={file.id} />
            </div>
          </div>
        </>
      }
      items={items}
      id={file.id}
    />
  );
};
