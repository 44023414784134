import React, { FC } from 'react';
import { filterIcons } from 'src/components/FilterTypes/config';
import classNames from 'classnames';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { useDiskViewState } from 'src/pages/diskPage/hooks/useDiskViewState';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import './styles.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  isTrash?: boolean;
};
export const FilterTypes: FC<Props> = ({ isTrash }) => {
  const { t } = useTranslation();
  const { view } = useDiskContext();
  const { filterType, setFileType } = useAppContext();
  const { folders, files } = useDiskViewState(isTrash);

  const total = folders.length + files.length;
  const foldersCount = folders.length;
  const filesCount = files.length;

  const onFilterClick = (filter: string) => {
    setFileType(filter);
  };

  const showCount = () => {
    return !isTrash && view !== 'columns';
  };

  return (
    <div className="filter-types">
      <div className="filter-types__wrapper">
        <div className="filter-types__item-current">
          <div
            className={classNames('filter-types__item-content', { selected: 'all' === filterType })}
            onClick={() => onFilterClick('all')}
          >
            All
          </div>
          {'all' === filterType && showCount() && (
            <div className="filter-types__item-count">{total}</div>
          )}
        </div>

        {Object.keys(filterIcons).map((key) => {
          return (
            <div
              key={key}
              className={classNames('filter-types__item', { selected: key === filterType })}
            >
              <div className="filter-types__item-filter">
                <div className="filter-types__item-content" onClick={() => onFilterClick(key)}>
                  {filterIcons[key]}
                </div>
                <div className="filter-types__item-title">{key.toLowerCase()}</div>
                {showCount() && (
                  <div className="filter-types__item-count">
                    {key === 'FOLDER' ? foldersCount : filesCount}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
