import React, { FC, ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import LocalStorage from 'src/utils/Storage';
import { AppProvider } from 'src/context/AppContext/AppProvider';
import { DiskProvider } from 'src/context/DiskContext/DiskProvider';
import { useGetSettings } from 'src/api/settings';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

type Props = {
  children: ReactNode;
};

export const PrivateRoute: FC<Props> = ({ children }) => {
  const isAuthenticated = LocalStorage.isAuthorised();
  const { data, isLoading } = useGetSettings(!LocalStorage.isAuthorised());
  const language = get(data, 'settings.language', 'en');
  const { i18n } = useTranslation();
  useEffect(() => {
    if (!isLoading) {
      i18n.changeLanguage(language);
    }
  }, [language, isLoading]);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <AppProvider>
        <DiskProvider>{children}</DiskProvider>
      </AppProvider>
    </>
  );
};
