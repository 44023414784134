import React from 'react';
import { AnimatedClip } from 'src/components/AnimatedClip';
import './styles.scss';
import { RoundPlus } from 'src/components/Icons';

export const Uploader = () => {
  return (
    <div className="uploader">
      <div className="uploader__dropzone">
        <div className="uploader__dropzone-content">
          <div className="uploader__cta">
            <RoundPlus />
          </div>
          <div className="uploader__dropzone-title">Загрузить файлы</div>
          <div className="uploader__dropzone-description">
            Нажмите + для выбора файловна устройстве или перетащите выбранные файлы в это окно
          </div>
          <div className="uploader__dropzone-media">
            <AnimatedClip type="speed" />
          </div>
        </div>
      </div>
      <div className="uploader__description">Максимальный объём без регистрации 1 TB</div>
    </div>
  );
};
