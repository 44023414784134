import React, { FC } from 'react';
import { ArrowRightSmallIcon } from 'src/components/Icons';
import { FolderIconComp } from 'src/pages/diskPage/components/ItemIcons/FolderIcon';
import { FolderType } from 'src/types';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { useFolderState } from 'src/pages/diskPage/hooks/useFolderState';
import { ActionButton } from 'src/components/Forms/ActionButton';
import { ContextDropdown } from 'src/components/Dropdown/context';
import { useFolderDrop } from 'src/pages/diskPage/hooks/useFolderDrop';

type Props = {
  folder: FolderType;
  children?: React.ReactNode;
  level: number;
};

export const FolderTree: FC<Props> = ({ folder, children, level }) => {
  const { folderId, diskId } = useParams();
  const navigation = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const { items } = useFolderState(folder);
  const { drop, isOver } = useFolderDrop(folder.id, () => {});

  return (
    <ContextDropdown
      trigger={
        <div key={folder.id} className="disk-folders-tree__item">
          <div
            ref={(node) => drop(node)}
            className={classNames('disk-folders-tree__item-content', {
              active: folder.id === folderId || isOver,
              over: isOver,
            })}
            onClick={() => {
              navigation(`/disk/${diskId}/folder/${folder.id}`);
            }}
            style={{ paddingLeft: `${level * 20}px` }}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}
              className={classNames('disk-folders-tree__item--control', { open: isOpen })}
            >
              <ArrowRightSmallIcon className="disk-folders-tree__controll" />
            </div>
            <FolderIconComp folder={folder} />
            <div className="disk-folders-tree__item-name">{folder.name}</div>
            <div className="disk-folders-tree__abtns">
              <ActionButton items={items} className="" id={folder.id} />
            </div>
          </div>
          {isOpen && <div className="disk-folders-tree__children">{children}</div>}
        </div>
      }
      id={folder.id}
      items={items}
    />
  );
};
