import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { useUploadDiskAvatar } from 'src/api/disks';
import React, { useEffect, useState } from 'react';
import { DiskType } from 'src/types';
import { useTranslation } from 'react-i18next';
import { DropDownItem } from 'src/components/Dropdown/types';
import {
  AddToFavoriteIcon,
  RecycleIcon,
  RenameIcon,
  UploadFileDarkIcon,
} from 'src/components/Icons';
import { UploadFileModal } from 'src/components/Header/components/UploadFileModal';
import { useManageDisk } from 'src/pages/diskPage/components/Disk/hooks/useManageDisk';
import { useNavigate } from 'react-router-dom';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import { useGetDisks } from 'src/hooks/useGetDisks';
import { CreateDisk } from 'src/components/CreateDisk';

type Props = {
  disk: DiskType;
  minimized?: boolean;
};

export const useDiskState = ({ disk, minimized }: Props) => {
  const navigate = useNavigate();
  const { mutateAsync: uploadAvatar } = useUploadDiskAvatar(disk.id);
  const { openConfirmation, openModal, setRightMenuState, rightMenuState } = useAppContext();
  const { setDisk, disk: selectedDisk } = useDiskContext();
  const { updateDiskCache, refetch } = useGetDisks();
  const { deleteFavorite, deleteDisk, createFavorite, renameDisk, isProcessing } = useManageDisk();

  const [hasError, setHasError] = useState(false);
  const [newName, setNewName] = useState(disk.name);

  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (minimized) {
      setEditMode(false);
    }
  }, [minimized]);

  const onAddToFavorite = async () => {
    if (disk.is_favorite) {
      updateDiskCache({
        ...disk,
        is_favorite: false,
      });
      const resp = await deleteFavorite(disk.id);
      if (resp.success) {
        await refetch();
      }
      return;
    }
    updateDiskCache({
      ...disk,
      is_favorite: true,
    });
    const response = await createFavorite({
      id: disk.id,
    });
    if (!response.success) {
      updateDiskCache({
        ...disk,
        is_favorite: false,
      });
    }
    await refetch();
  };

  const onRenameDisk = () => {
    setRightMenuState({
      ...rightMenuState,
      open: true,
      content: (
        <CreateDisk
          onCLose={() => {
            setRightMenuState({
              ...rightMenuState,
              open: false,
              content: null,
            });
          }}
          parameters={disk}
        />
      ),
    });
  };

  const onDeleteHandler = () => {
    openConfirmation({
      content: `${t('deleteConfirmation')}: ${disk.name}?`,
      onSure: async () => {
        openConfirmation({ open: false });
        await deleteDisk(disk.id);
        const { data } = await refetch();
        if (disk.id !== selectedDisk.id) return;
        const disks: DiskType[] = data?.disks || [];
        if (disks.length > 0) {
          setDisk(disks[0]);
          navigate(`/disk/${disks[0].id}`);
        } else {
          navigate('/disk/');
        }
      },
    });
  };

  const items: DropDownItem[] = [
    {
      label: t('uploadFiles'),
      icon: <UploadFileDarkIcon className="dropdown-svg-icon" />,
      isActive: true,
      onClick: () => {
        openModal({
          content: (
            <UploadFileModal
              diskId={disk.id as string}
              folderId={''}
              onFinish={async () => {
                openModal({ open: false });
              }}
            />
          ),
          title: t('uploadFiles'),
          className: 'create-folder-modal',
        });
      },
    },
    {
      label: disk.is_favorite ? 'Remove from favorite' : t('addToFavorite'),
      onClick: onAddToFavorite,
      icon: <AddToFavoriteIcon className="favorite-icon" />,
    },
    {
      label: t('renameDisk'),
      onClick: onRenameDisk,
      icon: <RenameIcon className="rename-icon" />,
      id: 'rename-disk',
    },
    {
      label: t('deleteDisk'),
      onClick: onDeleteHandler,
      icon: <RecycleIcon className={'disk__delete'} />,
      color: '#E20001',
    },
  ];

  const onChangeName = (value: string) => {
    setNewName(value);
  };

  const onSaveDisk = async () => {
    const response = await renameDisk({ id: disk.id, name: newName });
    if (response.success) {
      setEditMode(false);
      updateDiskCache({
        ...disk,
        ...response.disk,
      });
    } else {
      setHasError(true);
    }
  };

  return {
    editMode,
    disk,
    newName,
    hasError,
    isProcessing,
    items,
    setEditMode,
    onChangeName,
    onSaveDisk,
  };
};
