import React, { FC } from 'react';
import { getFileIcon } from 'src/config/fileTypes';
import { ProgressIcon } from '../Progress';
import { ReactComponent as CancelIcon } from '../../assets/close-icon.svg';
import { ReactComponent as ResumeIcon } from '../../assets/resume-icon.svg';
import './styles.scss';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import { UploadFileType } from 'src/context/GlobalContext';

type Props = {
  file: UploadFileType;
  type: 'active' | 'finished' | 'failed';
};

export const UploadingFile: FC<Props> = ({ file, type }) => {
  const { updateUploadManager, uploadManager } = useGlobalContext();

  const getFileConfig = () => {
    return getFileIcon(file.fileName.split('.').pop() || file.fileName);
  };

  const onCancelClick = () => {
    updateUploadManager({
      processingFiles: uploadManager.processingFiles.map((f) =>
        f.id === file.id ? { ...f, isCanceled: true, volumeUploaded: 0, isRetrying: false } : f,
      ),
    });
  };

  const onResumeClick = () => {
    updateUploadManager({
      processingFiles: uploadManager.processingFiles.map((f) =>
        f.id === file.id ? { ...f, isCanceled: false, volumeUploaded: 0, isRetrying: true } : f,
      ),
    });
  };

  const actions = {
    active: (
      <div className="uploading-file__cnt" onClick={onCancelClick}>
        <CancelIcon />
      </div>
    ),
    failed: (
      <div className="uploading-file__cnt" onClick={onResumeClick}>
        <ResumeIcon />
      </div>
    ),
    finished: <></>,
  };

  const onPauseClick = () => {
    updateUploadManager({
      processingFiles: uploadManager.processingFiles.map((f) => ({
        ...f,
        isPaused: f.id === file.id ? !f.isPaused : f.isPaused,
      })),
    });
  };

  return (
    <div className="uploading-file">
      <div className="uploading-file__icon">
        <div
          className="uploading-file__icon-image"
          style={{
            backgroundColor: `${getFileConfig().color || '#A00001'}`,
          }}
        >
          {file.progress !== 100 && file.isStarted && !file.isCanceled && (
            <ProgressIcon
              onClick={onPauseClick}
              progress={file.progress || 0}
              isPaused={file.isPaused}
            />
          )}
          <div className="uploading-file__icon-svg">{getFileConfig().icon}</div>
        </div>
      </div>
      <div className="uploading-file__filename">{file.fileName}</div>
      <div className="uploading-file__controls">{actions[type]}</div>
    </div>
  );
};
