import React, { FC } from 'react';
import classNames from 'classnames';
import { getFileIcon } from 'src/config/fileTypes';
import './styles.scss';

type Props = {
  extension: string;
  children?: React.ReactNode;
  className?: string;
};

export const FileIcon: FC<Props> = ({ extension, children, className }) => {
  const getFileConfig = () => {
    return getFileIcon(extension);
  };

  return (
    <div
      className={classNames('simple-file-icon', className)}
      style={{
        backgroundColor: `${getFileConfig().color || '#A00001'}`,
      }}
    >
      {getFileConfig().icon}
      {children}
    </div>
  );
};
