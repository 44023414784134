const translation = {
  welcome: 'Bienvenue chez UMI!',
  description: "C'est une application multilingue.",
  loginDescription: 'plus qu’un simple stockage cloud',
  searchByFiles: 'Recherche par fichiers',
  settings: 'Paramètres',
  editProfile: 'Modifier le profil',
  security: 'Sécurité',
  notifications: 'Notifications',
  contacts: 'Contacts',
  support: 'Support',
  extension: 'Extension',
  paymentsHistory: 'Historique des paiements',
  removedFiles: 'Fichiers supprimés',
  storageSelection: 'Sélection du stockage',
  personalData: 'Données personnelles',
  saveChanges: 'Enregistrer les modifications',
  nameLastName: 'Nom complet',
  dateOfBirth: 'Date de naissance',
  username: "Nom d'utilisateur",
  department: 'Département',
  position: 'Position',
  belgium: 'Belgique',
  bulgaria: 'Bulgarie',
  croatia: 'Croatie',
  cyprus: 'Chypre',
  czechRepublic: 'République tchèque',
  denmark: 'Danemark',
  estonia: 'Estonie',
  finland: 'Finlande',
  france: 'France',
  germany: 'Allemagne',
  greece: 'Grèce',
  hungary: 'Hongrie',
  ireland: 'Irlande',
  italy: 'Italie',
  latvia: 'Lettonie',
  lithuania: 'Lituanie',
  luxembourg: 'Luxembourg',
  malta: 'Malte',
  netherlands: 'Pays-Bas',
  poland: 'Pologne',
  portugal: 'Portugal',
  romania: 'Roumanie',
  slovakia: 'Slovaquie',
  slovenia: 'Slovénie',
  spain: 'Espagne',
  ukraine: 'Ukraine',
  phoneNumber: 'Numéro de téléphone',
  signIn: 'Se connecter',
  openQRCode: 'Ouvrir le code QR',
  continueWithApple: 'Continuer avec Apple',
  continueWithGoogle: 'Continuer avec Google',
  enter: 'Entrer',
  enterCodeFromSMS: 'Entrez le code reçu par SMS',
  sendingSMSWithTheCodeAgain: "Envoi de l'SMS avec le code à nouveau",
  smsHasBeenSent: 'SMS envoyé',
  chooseALanguage: 'Choisir une langue',
  enterUniqueUsername: "Entrez un nom d'utilisateur unique",
  usernameLetsPeopleContactYou: "Le nom d'utilisateur permet aux gens de vous contacter sur UMI",
  yourUsername: "Votre nom d'utilisateur",
  invalidPhoneNumber:
    "Le numéro de téléphone entré n'est pas au bon format. Veuillez entrer le numéro au format international.",
  phoneNumberIsRequired: 'Le numéro de téléphone est requis',
  invalidOtp: 'OTP invalide',
  main: 'Principal',
  mySubscriptions: 'Mes abonnements',
  loading: 'Chargement',
  profile: 'Profil',
  help: 'Aide',
  privacyPolicy: 'Politique de confidentialité',
  recycleBin: 'Corbeille',
  balance: 'Solde',
  refill: 'Recharger',
  addYourID: 'Ajoutez votre ID',
  id: 'ID',
  email: 'Email',
  addYourFullName: 'Ajoutez votre nom complet',
  addYourEmail: 'Ajoutez votre Email',
  addYourPosition: 'Ajoutez votre position',
  addYourDepartment: 'Ajoutez votre département',
  accountConnection: 'Connexion au compte',
  logOut: 'Se déconnecter',
  deleteAccount: 'Supprimer le compte',
  no: 'Non',
  yes: 'Oui',
  logOutConfirmation: 'Êtes-vous sûr de vouloir vous déconnecter de votre compte?',
  profileUpdatedSuccessfully: 'Profil mis à jour avec succès',
  setYourPhoto: 'Définissez votre photo',
  addYourUserName: "Ajoutez votre nom d'utilisateur",
  fullNameIsRequired: 'Le nom complet est requis',
  usernameIsRequired: "Le nom d'utilisateur est requis",
  departmentIsRequired: 'Le département est requis',
  positionIsRequired: 'La position est requise',
  validEmailError: 'doit être un email valide',
  changeTheCover: 'Changer la couverture',
  addToFavorite: 'Ajouter aux favoris',
  renameDisk: 'Renommer le disque',
  downloadDisk: 'Télécharger le disque',
  diskName: 'Nom du disque',
  cancel: 'Annuler',
  createDis: 'Créer un disque',
  deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer',
  deleteDisk: 'Supprimer le disque',
  download: 'Télécharger',
  select: 'Sélectionner',
  share: 'Partager',
  copy: 'Copier',
  move: 'Déplacer',
  properties: 'Propriétés',
  rename: 'Renommer',
  delete: 'Supprimer',
  owner: 'Propriétaire',
  moveSelectTheFilesUpload:
    'Déplacez ou sélectionnez les fichiers que vous souhaitez télécharger depuis votre ordinateur',
  folderName: 'Nom du dossier',
  createFolder: 'Créer un dossier',
  deleteFolder: 'Supprimer le dossier',
  renameFolder: 'Renommer le dossier',
  uploadFiles: 'Télécharger des fichiers',
  restore: 'Restaurer',
  restoreTo: 'Restaurer vers',
  deletePermanently: 'Supprimer définitivement',
  sorting: 'Tri',
  name: 'Nom',
  uploaded: 'Téléchargé',
  uploading: 'Téléchargement en cours',
  canceled: 'Annulé',
  uploadYourFirstFile: 'Téléchargez votre premier fichier',
  areYouSureWantDeleteAll: 'Êtes-vous sûr de vouloir supprimer tous les fichiers et dossiers?',
  filesWillBePermanentlyDeleted:
    'Tous les fichiers seront définitivement supprimés du stockage UMI Cloud',
  willBePermanentlyDeleted: 'sera définitivement supprimé du stockage UMI Cloud',
  currentPosition: 'Position actuelle',
  createNewFolder: 'Créer un nouveau dossier',
  selectLocation: 'Sélectionnez un emplacement',
  moveTo: 'Déplacer vers',
  folderWithFiles: 'DOSSIER',
  dateOfEditing: 'Date de modification',
  size: 'Taille',
  type: 'Type',
  byName: 'Par nom',
  bySize: 'Par taille',
  yourFiles: 'Vos fichiers',
  createANewDisk: 'Créer un nouveau disque',
  subscription: 'Abonnement',
  save: 'Enregistrer',
  accessControlTo: "Contrôle d'accès à",
  trash: 'Corbeille',
  fileTypes: 'Types de fichiers',
  totalDiskVolume: 'Volume total du disque',
  creationDate: 'Date de création',
  parentFolderName: 'Nom du dossier parent',
  parentDiskName: 'Nom du disque parent',
  fileExtension: 'Extension de fichier',
  fileSize: 'Taille du fichier',
  totalFolderVolume: 'Volume total du dossier',
  fileSharedSuccessfully: 'Fichier partagé avec succès',
  editors: 'Éditeurs',
  readers: 'Lecteurs',
  files: 'fichiers',
  myDisks: 'Mes disques',
  createDisk: 'Créer un disque',
  chat: 'Chat',
  search: 'Recherche',
  folders: 'Dossiers',
  restoreAllFiles: 'Restaurer tous les fichiers',
  deleteAll: 'Tout supprimer',
  preview: 'Aperçu',
  uMIMoreThanCloudStorage: 'Plus qu’un stockage cloud',
  landingPageDescription:
    "Nous ne faisons pas que stocker des fichiers, nous offrons la possibilité de créer une bibliothèque unifiée et d'y connecter votre équipe",
  getStart: 'Commencer',
  moreAboutTheService: 'En savoir plus sur le service',
  ourNetworkDC: 'Notre réseau de centres de données',
  dataCentersDescription:
    'Nous élargissons sans relâche notre réseau mondial de centres de données pour garantir le stockage le plus sécurisé de vos données et optimiser les coûts de stockage.',
  submitAnApplication: 'Soumettre une demande',
  dataStructuring: 'Structuration des données',
  selectingCostAndStorageCapacity: 'Choix du coût et de la capacité de stockage',
  tagAndHashtagSystemFiles: 'Système de tags et de hashtags pour les fichiers',
  electronicDocumentSigning: 'Signature électronique de documents',
  connectionOfYourStorage: 'Connexion de votre propre stockage',
  chatForCorporateClients: 'Chats pour les clients d’entreprise',
  storageCapabilities: 'Capacités de stockage',
  months: 'mois',
  month: 'mois',
  storageDatacenter: 'Centre de stockage de données',
  dataBackup: 'Sauvegarde des données',
  numberOfGigabytes: 'Nombre de gigaoctets',
  costPerGigabyte: 'Coût par gigaoctet',
  subscriptionTerm: 'Durée de l’abonnement',
  discountReceived: 'Réduction obtenue',
  selectedStorageParameters: 'Paramètres de stockage sélectionnés',
  monthly: 'Mensuellement',
  costFor: 'Coût pour',
  storageCapacity: 'Capacité de stockage',
  price: 'Prix',
  storageTime: 'Durée de stockage',
  reservationOptions: 'Options de réservation',
  noReservation: 'Pas de réservation',
  reservation: 'Réservation',
  chooseServerStorage: 'Choisissez un stockage serveur',
  AWSStorage: 'Stockage AWS',
  downloadAPP: 'Télécharger l’APP',
  appDescription:
    'Gérez vos fichiers de n’importe où dans le monde. L’application mobile pour votre appareil est disponible sur les marketplaces',
  unifiedMediaInterface: 'Interface Média Unifiée',
  footerDescription:
    'Une méthode intégrée et unifiée pour gérer et partager des données multimédias dans le stockage cloud.',
  socialDescription:
    'UMI est un service cloud pour les appareils mobiles et les ordinateurs. Rapide, sécurisé, fonctionnel.',
  cloud: 'Cloud',
  teamWork: 'Travail en équipe',
  fileSearch: 'Recherche de fichiers',
  basicFunctionalityStorage: 'Fonctionnalité de base du stockage',
  tiredOfSwitchingBetweenMultipleCloudStorages: 'Marre de passer d’un stockage cloud à un autre?',
  nowAllYourFilesAreInOnePlace:
    'Maintenant, tous vos fichiers sont en un seul endroit! Notre service unifie les plateformes cloud populaires, offrant une interface conviviale et intuitive pour gérer toutes vos données.',
  createYourFirstDiscParameters:
    'Créez votre premier disque avec des paramètres de stockage optimaux',
  creatingStorageDisk: "Création d'un disque pour le stockage de données",
  annually: 'Annuellement',
  invoice: 'Facture',
  homeDescription:
    'Stockage fonctionnel, marketplace de fichiers, outils pour le travail en équipe',
  homeTitle: 'La Bibliothèque Numérique du Futur',
  fileSharing: 'Partage de fichiers',
  homeSlide1Title: 'Bibliothèque Unifiée des Actifs Numériques',
  homeSlide1Subtitle:
    "Nous avons mis toute notre expérience dans la création d'une solution de stockage pratique",
  homeSlide1Description:
    "Monétisation simple, structuration des archives accumulées, système d'accès multi-niveaux, création et personnalisation de disques, système de tickets, signature de documents—qui nécessitaient auparavant de visiter différentes ressources—sont désormais tous réunis dans une seule bibliothèque !",
  homeSlide2Title: 'Stockage et Gestion Fonctionnels',
  homeSlide2Subtitle: "Optimisez les coûts tout en augmentant l'efficacité",
  homeSlide2Description:
    "Nous ne faisons pas que stocker des fichiers, nous offrons la possibilité de créer une bibliothèque unifiée et d'y connecter votre équipe",
  homeSlide3Title: 'Gérez et Monétisez Vos Actifs Numériques',
  homeSlide3Subtitle: 'Nous ne faisons pas que stocker, nous monétisons !',
  homeSlide3Description:
    'Nous avons créé tous les outils pour trier les anciens archives afin que vous puissiez voir le potentiel des anciennes données et leur donner une nouvelle vie',
  downloadApp: 'Télécharger l’APP',
  homeAppsDescription: 'Gérez vos fichiers de n’importe où dans le monde.',
  convenienceTitle: 'Commodité',
  convenienceDescription: 'Interface simple et intuitive',
  goalsTitle: 'Gestion des tâches',
  goalsDescription: "Suivi de l'avancement des travaux",
  syncTitle: 'Synchronisation',
  syncDescription: 'Accès depuis plusieurs appareils simultanément',
  speedTitle: 'Vitesse',
  speedDescription: 'Haute vitesse de téléchargement des fichiers',
  noBreaksTitle: 'Pas de limitations',
  noBreaksDescription: 'Aucune limite sur la taille des fichiers téléchargés et téléchargés',
  opennessTitle: 'Ouverture',
  opennessDescription: 'API ouverte pour les développeurs',
  securityTitle: 'Sécurité',
  securityDescription: 'Double chiffrement des données lors de la transmission',
  communicationTitle: 'Communication',
  communicationDescription: 'Chats de groupe pour les équipes et un usage personnel',
  flexTitle: 'Flexibilité',
  flexDescription: 'Choix du volume de stockage et du coût',
  newsTitle: 'Nouvelles',
  reasonsTitle: 'Pourquoi UMI?',
  cloudSlide1Title: 'Fonctionnalités Principales',
  cloudSlide1Subtitle: 'Plus besoin de payer pour des gigaoctets supplémentaires',
  cloudSlide1Description:
    "Choisissez vous-même le volume, le coût et l'emplacement de stockage en créant des disques séparés pour différents projets et tâches dans une seule interface",
  cloudSlide2Title: 'Tous les Outils Nécessaires dans une Seule Bibliothèque',
  cloudSlide2Subtitle: "Vous en avez marre de passer d'un service de stockage cloud à un autre?",
  cloudSlide2Description:
    'Affichage fonctionnel des fichiers, filtre de catégories de fichiers, recherche par tags, hashtags—tout cela et bien plus encore est disponible dans notre interface',
  cloudSlide3Title: 'Création de Disques pour Différents Projets et Équipes',
  cloudSlide3Subtitle: 'Optimisez les coûts en utilisant la fonctionnalité de stockage',
  cloudSlide3Description:
    'Nous sommes les premiers à offrir la possibilité de créer des disques dans une interface unifiée pour des projets et équipes distincts, en choisissant vous-même le volume, le coût et les options de sauvegarde',
  cloudSlide4Title: "Système de Droits et d'Accès à Plusieurs Niveaux",
  cloudSlide4Subtitle: "Vous en avez marre de passer d'un service de stockage cloud à un autre?",
  cloudSlide4Description:
    "Vous stockez tout en un seul endroit, mais chaque membre de l'équipe voit les fichiers dans le cadre de son rôle",
  cloudSlide5Title: 'Système de Billetterie pour la Gestion des Tâches',
  cloudSlide5Subtitle: "Vous en avez marre de passer d'un service de stockage cloud à un autre?",
  cloudSlide5Description:
    'Nous aidons à rassembler tous les employés, tâches et outils en un seul endroit. Créez des tableaux, attribuez des tâches et joignez des fichiers dans une bibliothèque de fichiers unifiée',
  cloudOption1Title: 'Fonctions principales',
  cloudOption1Subtitle: 'Plus besoin de payer pour des gigaoctets et des fonctionnalités inutiles',
  cloudOption1Description:
    "Nous avons supprimé tout ce qui était superflu et avons donné à chaque utilisateur le droit de décider de manière autonome à quoi devrait ressembler le stockage, en fonction de ses besoins personnels et non des modèles de services. C'est toi qui décides :",
  cloudOption1Param1: 'Où stocker',
  cloudOption1Param2: 'Comment sauvegarder',
  cloudOption1Param3: 'Quel volume de stockage',
  cloudOption1Param4: 'Nombre de disques pour le stockage',

  cloudOption2Title: 'Fonctionnalité simple pour des tâches complexes',
  cloudOption2Subtitle: '',
  cloudOption2Description:
    'Nous avons créé une fonctionnalité simple qui aidera à résoudre des tâches complexes, allant de la recherche des fichiers nécessaires à la structuration de grandes quantités de données dans une interface intuitive',
  cloudOption2Param1: 'Vos fichiers dans un format visuel',
  cloudOption2Param2: 'Filtre par catégorie de fichiers',
  cloudOption2Param3: 'Recherche par hashtags',
  cloudOption2Param4: 'Créer une galerie et une bibliothèque avec accès par lien',

  cloudOption3Title: 'Ne gaspillez pas votre budget sur le stockage',
  cloudOption3Subtitle: '',
  cloudOption3Description:
    'Nous sommes les premiers à offrir la possibilité de créer des disques dans une interface unifiée pour des projets et des équipes distincts, en permettant à chaque utilisateur de choisir de manière autonome le volume, le coût et les options de sauvegarde',
  cloudOption3Param1: 'Crée un disque pour le projet',
  cloudOption3Param2: "Choisis le volume et l'emplacement de stockage",
  cloudOption3Param3: 'Connecter des équipes séparées à chaque disque',
  cloudOption3Param4: 'Choisir comment sauvegarder',

  cloudOption4Title: 'Système de billetterie pour la gestion des tâches',
  cloudOption4Subtitle:
    'Ne perdez pas de temps avec des notes, définissez des tâches en un seul endroit',
  cloudOption4Description:
    'Ne perdez pas de temps avec des appels pour garder les tâches en un seul endroit. Nous vous aiderons à rassembler tous les employés, les tâches et les outils dans un seul dossier. Créez des disques, assignez des tâches et joignez des fichiers dans une seule bibliothèque de fichiers.',
  cloudOption4Param1: 'Chats de groupe et personnels',
  cloudOption4Param2: 'Commentaires, notes, notifications',
  cloudOption4Param3: 'Attache des fichiers aux tâches',
  cloudOption4Param4: 'Signature de documents',
  landingPageSubtitle: "Optimisez les coûts en augmentant l'efficacité!",
  aboutUmi: "À propos d'UMI",
  faq: 'FAQ',
  partnership: 'Partenariat',
  forMedia: 'Pour les médias',
  products: 'Produits',
  mobileApp: 'Application mobile',
  forComputers: 'Pour les ordinateurs',
  webVersion: 'Version web',
  infrastructure: 'Infrastructure',
  api: 'API',
  translations: 'Traductions',
  instantView: 'Vue instantanée',
  development: 'Développement',
  jobs: 'Emplois',
  focusGroup: 'Groupe de discussion',
  askAQuestion: 'Poser une question',
  personalInformation: 'Informations personnelles',
  agreements: 'Accords',
  today: "Aujourd'hui",
  yesterday: 'Hier',
  paymentAmount: 'Montant du paiement',
  newDisk: 'Nouveau disque',
  myBalance: 'Mon solde',
  supportForDevelopers: 'Support pour les développeurs',
  businessSubscription: 'Abonnement professionnel',
  recentOperations: 'Opérations récentes',
  uploadsAndDownloads: 'Téléchargements et téléchargements',
  cancelAll: 'Tout annuler',
  resume: 'Reprendre',
  finished: 'Terminé',
  file: 'fichier',
  language: 'Langue',
  termsOfUse: "Conditions d'utilisation",
  noUploads: 'Pas de téléchargement ni de téléversement.',
  create: 'Créer',
  upload: 'Télécharger',
  occupancy: 'Occupation',
  diskCapacity: 'Capacité du disque',
  items: 'articles',
  item: 'article',
  digitalAssetLibrary: "Bibliothèque d'actifs numériques",
  timeOfDeletion: 'Heure de suppression',
  functions: 'Fonctions',

  highPerformance: 'Stockage haute performance pour les données utilisées régulièrement',
  requestProcessing: 'Traitement des requêtes en moins de 10 millisecondes',
  increaseAccessSpeed:
    "Augmente la vitesse d'accès par 10x, réduit les coûts de traitement des requêtes de 50% par rapport à S3 Standard",
  availabilityZone:
    "En sélectionnant la zone de disponibilité AWS, les clients peuvent regrouper les ressources de stockage et de calcul pour réduire considérablement la latence et le temps de traitement, et améliorer l'utilisation des ressources de calcul pour réduire le coût total",
  accelerateAnalytics:
    "Accélérez les charges de travail d'analytique et d'apprentissage automatique avec l'intégration des services AWS",
  scaleRequests: 'Évoluez pour gérer des millions de requêtes par minute',
  optimizeDatasets: 'Optimisez pour les grands ensembles de données avec de nombreux petits objets',
  leverageS3:
    'Exploitez les API Amazon S3 existantes avec un type différent de panier - catalogues poubelles',
  storageAvailability:
    "Le stockage est conçu pour fournir une disponibilité de 99,95% dans le cadre d'un accord de niveau de service à 99,9%",
  generalPurposeStandard:
    "Norme polyvalente pour les données fréquemment utilisées. Offre un stockage d'objets à haute fiabilité, disponibilité et performance pour stocker des données fréquemment utilisées. Fournit une faible latence et un débit élevé.",
  standard: 'Standard',

  generalPurposeStorage: 'Stockage polyvalent pour les données fréquemment utilisées',
  lowLatencyHighThroughput: 'Faible latence et débit élevé',
  storageAvailabilityAgreement:
    "Stockage conçu pour fournir une disponibilité de 99,99% conformément aux termes de l'accord de niveau de service à 99,9%",
  highPerformanceShort: 'Haute performance',
  expressOneZone:
    'Express One Zone est une classe de stockage haute performance à disponibilité unique, spécifiquement conçue pour offrir un accès constant et rapide en millisecondes aux données fréquemment utilisées et aux applications sensibles à la latence.',
  infrequentAccess: 'Accès peu fréquent',
  standardIA:
    'Standard - IA est le choix idéal pour le stockage de données auquel on accède relativement rarement mais qui doit être accessible rapidement. Haute fiabilité, haut débit et faible latence, tout en offrant un faible coût par gigaoctet. Idéal pour le stockage de données à long terme et la sauvegarde.',

  rarelyUsedData: 'Données rarement utilisées nécessitant un accès instantané',
  lowLatencyHighThroughputS3: 'Faible latence et haut débit au niveau de stockage S3 Standard',
  storageAvailabilityAgreement99:
    "Le stockage est conçu pour fournir une disponibilité de 99,9% conformément aux termes de l'accord de niveau de service de 99%",
  archiving: 'Archivage',

  glacierArchiving:
    "Glacier est conçu spécifiquement pour l'archivage des données et offre des performances maximales, une flexibilité de récupération et un coût minimal de stockage d'archives dans le cloud",
  flexibleRetrieval: 'Récupération flexible',
  retrievalSupport:
    'Prend en charge la récupération en quelques minutes pour les petits volumes et 5 à 12 heures pour les gros volumes.',

  longTermStorage:
    'Stockage à long terme de données consultées plusieurs fois par an et récupérées instantanément',
  retrieveMilliseconds:
    'Récupérez les données en quelques millisecondes avec les mêmes performances que S3 Standard.',
  storageAvailabilityAgreement992:
    "Le stockage est conçu pour une disponibilité de 99,9% conformément aux termes de l'accord de niveau de service de 99%",
  minimumObjectSize: "Taille minimale de l'objet : 128 Ko",
  s3PutApi:
    'API S3 PUT pour le téléchargement direct vers S3 Glacier Instant Retrieval et la gestion du cycle de vie S3 pour la migration automatique des objets',
  deepArchive: 'Deep Archive',
  minimalCloudCosts:
    'Offre des coûts de stockage cloud minimaux et la capacité de récupérer des données en 12 à 48 heures.',

  lowCostBackupArchiving: 'Sauvegarde et archivage à faible coût de données peu utilisées',
  storageAvailabilityAgreement9999:
    "Le stockage est conçu pour fournir une disponibilité de 99,99% conformément aux termes de l'accord de niveau de service de 99,9%",
  sslSupport: 'Prise en charge SSL pour les données en mouvement et cryptage des données sur site',
  backupDisasterRecovery:
    "Idéal pour la sauvegarde et la récupération après sinistre lorsque vous avez besoin d'une récupération ponctuelle de grands ensembles de données en quelques minutes sans frais supplémentaires.",
  customizableRetrievalTimes:
    'Temps de récupération personnalisables - de quelques minutes à plusieurs heures - et capacité de récupération par lots.',
  s3PutApiFlexibleRetrieval:
    'API S3 PUT pour le téléchargement direct vers S3 Glacier Flexible Retrieval et la gestion du cycle de vie S3 pour la migration automatique des objets',
  instantRetrieval: 'Récupération instantanée',

  minimizesLongTermStorageCost:
    'Minimise le coût du stockage à long terme pour les données qui doivent être rarement consultées et récupérées en quelques millisecondes.',

  rarelyUsedArchivalData:
    "Données d'archives exceptionnellement rarement utilisées à un coût extrêmement bas",
  repositoryAvailabilityAgreement9999:
    "Le dépôt est conçu pour une disponibilité de 99,99% conformément aux termes de l'accord de niveau de service de 99,9%",
  idealAlternativeToTape: 'Alternative idéale aux bibliothèques sur bande',
  retrievalTime12Hours: 'Temps de récupération dans les 12 heures',
  s3PutApiDeepArchive:
    'API S3 PUT pour le téléchargement direct vers S3 Glacier Deep Archive et la gestion du cycle de vie S3 pour la migration automatique des objets',
  readMore: 'Lire la suite',
  keyFeatures: 'Caractéristiques clés',
  diskParameters: 'Paramètres du disque',
  confirmParameters: 'Confirmer les paramètres',
  subscribe: 'Souscrire',
  costGigabyte: 'Coût par gigaoctet',
  amount: 'Montant',
  repeat: 'Répéter',
  failedPayment:
    "Oups ! Votre paiement n'a pas pu être traité. Cela peut être dû à des fonds insuffisants ou à une carte expirée. Veuillez réessayer ou mettre à jour vos informations de paiement.",
  paymentFailed: 'Paiement échoué',
  paymentSuccessful: 'Paiement réussi',
  successPaymentMsg:
    'Merci pour votre abonnement ! Votre paiement a été traité avec succès et votre abonnement est désormais actif. Profitez de toutes les fonctionnalités premium !',
  serverAWS: 'Serveur AWS',
  connectDisk: 'Connecter le disque',
  freedoms: 'Libertés',
  storageOffers: 'Offres de stockage',
  open: 'Ouvrir',
  completeFreedom: 'Liberté totale de choix',
  customizeStorage:
    'Personnalisez le stockage comme vous le souhaitez et gérez vos données sans restrictions',
  editDisk: 'Modifier le disque',
  noNotifications: 'Pas de notifications',
  diskInformation: 'Informations sur le disque',
  storage: 'Stockage',
  capacity: 'Capacité',
  cost: 'Coût',
  clearDiskMessage: 'Êtes-vous sûr de vouloir effacer le disque?',
  removeFromFavorite: 'Retirer des favoris',
  folderInformation: 'Informations sur le dossier',
  fileInformation: 'Informations sur le fichier',
  totalVolume: 'Volume total',
  hashtags: 'Hashtags',
  add: 'Ajouter',
  lastModifiedDate: 'Dernière modification',
  dates: 'Dates',
  noHashtags: 'Pas de hashtags',
  edit: 'Modifier',
  popularHashtags: 'Hashtags populaires',
  functionality: 'Fonctionnalité',
  marketplace: 'Place de marché',
  taskBoards: 'Tableaux de tâches',
  groupChats: 'Chats de groupe',
  unsubscribe: 'Se désabonner',
  futureFunctionality: 'Fonctionnalités futures',
  monthlySupportDevelopers: 'Support mensuel pour les développeurs',
  beAPartText: 'Faites partie de notre succès commun!',
  supportText:
    'Soutenez le développement de nouvelles fonctionnalités en vous abonnant pour seulement 1 $ par mois.',
  supportTeam: "Soutenez l'équipe",
  fileMarket: 'Marché des fichiers',
  fileCopiedSuccessfully: 'Fichier copié avec succès',
  folderCopiedSuccessfully: 'Dossier copié avec succès',
  disks: 'Disques',
  freeDisk: 'Disque gratuit',
  personalLibraryText: 'Votre bibliothèque numérique personnelle vous attend!',
  signUpAndGet10Text:
    "Inscrivez-vous et obtenez 10 Go d'espace gratuit pour vos fichiers immédiatement.",
  signUp: "S'inscrire",
  taskBoardsText:
    "Créez et suivez des tâches, contrôlez l'accès à l'information, stockez des documents en toute sécurité et communiquez avec des collègues en temps réel.",
  groupChatsText:
    "Nous créerons des discussions pour communiquer avec l'équipe, les clients, les consommateurs et les fournisseurs sans quitter le stockage, qui peut être lié aux disques et aux équipes.",
  fileSearchText:
    'Notre objectif est de créer un moteur de recherche de fichiers basé sur un stockage où chaque utilisateur peut gérer, monétiser et promouvoir ses fichiers via un moteur de recherche ouvert. Recevez des avis, des évaluations et des commandes directement dans le dépôt.',
  marketplaceText:
    'Nous développons une fonctionnalité basée sur le cloud qui aidera à monétiser tout type de fichier téléchargé dans le stockage.',
  days: 'Jours',
  Hours: 'Heures',
  Minutes: 'Minutes',
  Attention: 'Attention!!!',
  attentionText1:
    "Pour continuer à utiliser le disque, vous devez payer pour le volume d'espace disque occupé.",
  attentionText2:
    "Si vous ne renouvelez pas votre abonnement pour l'espace disque occupé, le disque et toutes ses données seront supprimés.",
  DeletionVia: 'Suppression via',
  PayForDisk: 'Payer pour le disque',
  diskWillBeDeleted: 'Le disque sera supprimé',
  day: 'Jour',
  Pay: 'Payer',
  payments: 'Paiements',
  drive_deleted: 'Le disque a été supprimé',
  drive_created: 'Le disque a été créé',
  folder_created: 'Le dossier a été créé',
  file_uploaded: 'Le fichier a été téléchargé',
  file_downloaded: 'Le fichier a été téléchargé',
  file_moved: 'Les fichiers ont été déplacés',
  file_deleted: 'Les fichiers ont été supprimés',
  item_renamed: 'L’élément a été renommé',
  drive_cleared: 'Le disque a été nettoyé',
  item_deleted: 'Les fichiers ont été supprimés',
  invoice_succeeded: 'Le paiement de l’abonnement a réussi',
  invoice_failed: 'Le paiement de la facture a échoué',
  subscription_unpaid: 'L’abonnement n’est pas payé',
  subscription_cancelled: 'L’abonnement a été annulé',
  subscription_restored: 'L’abonnement a été restauré',
  welcome_message: 'Bienvenue sur le service',
  trashed_cleared: 'La corbeille a été vidée',
  app_update_available: 'Une mise à jour de l’application est disponible',
  quantity: 'Quantité',
  readAll: 'Tout lire',
  total_transactions_message: 'Un total de {{count}} transaction a été effectué',
  total_transaction_message: 'Un total de {{count}} transactions a été effectué',
  downloaded: 'Téléchargé',
  noOperations: 'Pas d’opérations',
  volume: 'Volume',
  theOfficialUmi:
    "L'application officielle UMI est disponible pour iPhone, iPad, Android, macOS et Windows.",
  thisDevices: 'Cet appareil',
  devices: 'Appareils',
  terminateRest: 'Terminer toutes les autres sessions',
  logOutAllDevices: 'Déconnecte tous les appareils sauf celui-ci.',
  activeSessions: 'Sessions actives',
  terminateThisSession: 'Voulez-vous terminer cette session ?',
  terminate: 'Terminer',
  sureTerminateAll: 'Êtes-vous sûr de vouloir terminer toutes vos sessions sur vos appareils ?',
};

export default translation;
