import React, { FC } from 'react';
import { FileType, FolderType } from 'src/types';
import { DiskInfo, FileInfo, FolderInfo } from 'src/components/InfoComponents';
import { CloseIcon } from 'src/components/Icons';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import './styles.scss';

type Props = {
  className?: string;
  info_type: 'file' | 'folder' | 'disk';
  info?: FileType | FolderType;
  onClose: () => void;
};
export const InfoColumn: FC<Props> = ({ className, info, info_type, onClose }) => {
  const { disk } = useDiskContext();

  const sections = {
    file: <FileInfo file={info as FileType} className={className} />,
    folder: <FolderInfo folder={info as FolderType} className={className} />,
    disk: <DiskInfo disk={disk} className={className} />,
  };
  return (
    <div className="info-block">
      <CloseIcon className="info-block__close" onClick={onClose} />
      {sections[info_type]}
    </div>
  );
};
