import React, { FC, useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import './styles.scss';
import { useOutside } from 'src/hooks/useOutside';

type Props = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  onOpen?: () => void;
};

export const LeftSlide: FC<Props> = ({ open, onClose, children, onOpen }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      setIsMounted(true);
      setTimeout(() => {
        setIsVisible(true);
        if (onOpen) {
          onOpen();
        }
      }, 100);
    } else {
      setIsVisible(false);
    }
  }, [open]);

  //const { panelRef } = useOutside(onClose);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      if (
        panelRef.current &&
        !panelRef.current.contains(target) &&
        !document.querySelector('.profile-navigation__media')?.contains(target) &&
        !document.querySelector('.react-resizable')?.contains(target) &&
        !document.querySelector('.base-modal')?.contains(target) &&
        !document.querySelector('.modal')?.contains(target)
      ) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, onClose]);

  const handleAnimationEnd = () => {
    if (!open) {
      setIsMounted(false);
    }
  };

  return (
    <>
      {isMounted && (
        <div
          ref={panelRef}
          className={classNames('left-slide', {
            'left-slide-opened': isVisible,
            'left-slide-closing': !isVisible,
          })}
          onTransitionEnd={handleAnimationEnd}
        >
          {children}
        </div>
      )}
    </>
  );
};
