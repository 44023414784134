import React, { FC, useState } from 'react';
import { ReactComponent as LogoIcon } from './logo.svg';
import { useNavigate } from 'react-router-dom';
import { NotificationsSection } from 'src/components/NotificationsSection';
import { useGetDisks } from 'src/hooks/useGetDisks';
import classNames from 'classnames';
import { BackIcon } from 'src/components/Icons';
import { useTranslation } from 'react-i18next';
import { SubscriptionDisk } from 'src/pages/subscriptions/components/SubscriptionDisk';
import { getOffersList } from 'src/pages/subscriptions/offers';
import { OfferItem } from 'src/pages/subscriptions/components/OfferItem';
import { CreatingDisk } from 'src/pages/subscriptions/components/CreatingDisk';
import { DiskType } from 'src/types';
import { useDiskManage } from 'src/hooks/useDiskManage';
import { BusinessItem } from 'src/pages/subscriptions/components/BussinesItem';
import { useLoadProfile } from 'src/api/profile';
import { UserType } from 'src/types/users';
import { get } from 'lodash';
import { useSubscription } from 'src/components/Subscription/useSubscription';
import './styles.scss';

type Props = {
  isTrash?: boolean;
};

export const SubscriptionsPage: FC<Props> = () => {
  const { data } = useLoadProfile();
  const user: UserType = get(data, 'profile', {});
  const { t } = useTranslation();
  const [sidebarState, setSidebarState] = useState('closed');
  const navigate = useNavigate();
  const { createDisk, editDisk } = useDiskManage();
  const { disks } = useGetDisks();
  const { openSubscriptionArea } = useSubscription();

  const onCreatingDisk = (params: Partial<DiskType> = {}) => {
    createDisk(params);
  };

  return (
    <div className="layout-wrapper subscription">
      <div className={classNames('subscription__sidebar', { open: sidebarState === 'open' })}>
        <NotificationsSection onChange={setSidebarState} animationTime={0} />
      </div>
      <div className="subscription__content">
        <div className="subscription__header">
          <div className="subscription__header-back" onClick={() => navigate('/disk')}>
            <BackIcon />
            <LogoIcon className="subscription__logo" />
          </div>
          <div className="subscription__line"></div>
          <div className="subscription__title">{t('unifiedMediaInterface')}</div>
        </div>
        <div className="subscription__content--inner">
          <div className="subscription__disks">
            <div className="subscription__sub-title">{t('myDisks')}</div>
            <div className="subscription__blocks">
              {disks.map((disk) => {
                return (
                  <SubscriptionDisk
                    disk={disk}
                    key={disk.id}
                    title="Free disk"
                    btnText={disk.is_blocked ? t('Pay') : t('editDisk')}
                    onClick={() => {
                      if (disk.is_blocked) {
                        return;
                      }
                      editDisk(disk);
                    }}
                  />
                );
              })}
              {user.has_business_subscription && <BusinessItem onCLick={openSubscriptionArea} />}
              <CreatingDisk
                onCLick={() => {
                  onCreatingDisk();
                }}
              />
            </div>
          </div>
          <div className="subscription__offers mt-50">
            <div className="subscription__sub-title">{t('storageOffers')}</div>
            <div className="subscription__blocks full-height">
              {getOffersList(t).map((offer, i) => {
                return (
                  <div key={i}>
                    <OfferItem
                      offer={offer}
                      onClick={() => {
                        onCreatingDisk({
                          name: offer.name,
                          total_volume: offer.capacity,
                          color: offer.color,
                          drive_type: offer.drive_type,
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          {!user.has_business_subscription && (
            <div className="subscription__offers mt-50">
              <div className="subscription__sub-title">{t('functionality')}</div>
              <div className="subscription__blocks full-height">
                <BusinessItem onCLick={openSubscriptionArea} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
