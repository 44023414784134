import React, { useEffect, useMemo } from 'react';
import { ReactComponent as ArrowTop } from './assets/arrow-top.svg';
import classNames from 'classnames';
import { useBlockState } from 'src/components/Uploads/hooks/useBlockState';
import { FilesBlock } from 'src/components/Uploads/components/FilesBlock';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import { ReactComponent as EmptyIcon } from './assets/empty-uploads-icon.svg';
import './styles.scss';

export const Uploads = () => {
  const { t } = useTranslation();
  const { isOpen, visible, onHeaderClick } = useBlockState();
  const { updateUploadManager, uploadManager } = useGlobalContext();

  useEffect(() => {
    if (uploadManager.open && !isOpen) {
      onHeaderClick();
    }
  }, [uploadManager.open]);

  const onHeaderClickHandler = () => {
    onHeaderClick();
    if (uploadManager.open) {
      updateUploadManager({ open: false });
    }
  };

  const processingFiles = useMemo(
    () => uploadManager.processingFiles.filter((f) => !f.isCanceled && !f.isCompleted),
    [uploadManager.processingFiles],
  );

  const failedFiles = useMemo(
    () => uploadManager.processingFiles.filter((f) => f.isCanceled),
    [uploadManager.processingFiles],
  );

  const processedFiles = useMemo(
    () => uploadManager.processingFiles.filter((f) => f.isCompleted),
    [uploadManager.processingFiles],
  );

  return (
    <div className={classNames('uploads', { open: isOpen })}>
      <div className="uploads__header" onClick={onHeaderClickHandler}>
        <span>{t('uploadsAndDownloads')}</span>
        <ArrowTop />
      </div>
      <div className="uploads__content">
        {visible && (
          <div className="uploads__content-wrapper">
            {uploadManager.processingFiles.length === 0 && (
              <div className="uploads__empty">
                <EmptyIcon />
                <span>{t('noUploads')}</span>
              </div>
            )}
            {!!processingFiles.length && (
              <FilesBlock
                type="active"
                files={processingFiles}
                onCancel={() => {
                  updateUploadManager({
                    processingFiles: uploadManager.processingFiles.map((f) => {
                      return f.isCompleted
                        ? f
                        : {
                            ...f,
                            isCanceled: true,
                            volumeUploaded: 0,
                            isRetrying: false,
                          };
                    }),
                  });
                }}
              />
            )}
            {!!failedFiles.length && (
              <FilesBlock
                type="failed"
                files={failedFiles}
                onRetry={() => {
                  updateUploadManager({
                    processingFiles: uploadManager.processingFiles.map((f) => {
                      return f.isCompleted
                        ? f
                        : {
                            ...f,
                            isCanceled: false,
                            volumeUploaded: 0,
                            isRetrying: true,
                          };
                    }),
                  });
                }}
              />
            )}
            {!!processedFiles.length && <FilesBlock type="finished" files={processedFiles} />}
          </div>
        )}
      </div>
    </div>
  );
};
