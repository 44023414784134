import { useMutation, useQuery } from '@tanstack/react-query';
import { PAYMENT_API } from 'src/utils/API';
import { DriveTypes, PriceType, ResponseType } from 'src/types';

export type PricingPayload = {
  backup_s_coefficient: number;
  subscription_term: string;
  total_gb: number;
  drive_type: DriveTypes;
};

type DriveData = {
  cloud_provider: string;
  color: string;
  drive_id: string;
  drive_name: string;
  drive_type: DriveTypes;
};

export type SubscriptionPayload = {
  backup_s_coefficient: number;
  drive_data: DriveData;
  subscription_term: string;
  total_gb: number;
  isEdit?: boolean;
};

export const useGetPricing = (isPublic?: boolean) => {
  const sendData = async (): Promise<ResponseType<PriceType[]>> => {
    try {
      const url = isPublic ? '/subscriptions/pricing/default' : '/subscriptions/pricing';

      const resp = await PAYMENT_API.get(url, 'payments', {});
      if (resp.success) {
        return {
          success: true,
          data: resp.data,
        };
      } else {
        return resp;
      }
    } catch (error) {
      return {
        success: false,
        error: 'Something went wrong',
      };
    }
  };

  return useQuery({
    queryKey: ['get-pricing'],
    queryFn: sendData,
    staleTime: 10,
  });
};

export const useGetPrice = (isPublic?: boolean) => {
  const sendData = async (payload: PricingPayload) => {
    try {
      const url = isPublic
        ? '/subscriptions/pricing/calculate/default'
        : '/subscriptions/pricing/calculate';

      const resp = await PAYMENT_API.post(url, payload, 'payments', {});
      if (resp.success) {
        return {
          success: true,
          price: resp.data.total_price,
        };
      } else {
        return resp;
      }
    } catch (error) {
      return {
        success: false,
        error: 'Something went wrong',
      };
    }
  };

  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useCreateSubscription = () => {
  const sendData = async (payload: SubscriptionPayload) => {
    try {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const { isEdit, ...rest } = payload;
      const url = isEdit ? '/subscriptions/change' : '/subscriptions';
      const resp = await PAYMENT_API.post(
        url,
        {
          ...rest,
          success_url: `${baseUrl}/disk/?subscription=success`,
          cancel_url: `${baseUrl}/disk/?subscription=failed`,
        },
        'payments',
        {},
      );
      if (resp.success) {
        return {
          success: true,
          checkout_url: resp.data.checkout_url,
        };
      } else {
        return resp;
      }
    } catch (error) {
      return {
        success: false,
        error: 'Something went wrong',
      };
    }
  };

  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useBusinessSubscription = () => {
  const sendData = async (cancel?: boolean) => {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    try {
      const url = !cancel ? '/subscriptions/business' : '/subscriptions/business/cancel';
      const resp = await PAYMENT_API.post(
        url,
        {
          success_url: `${baseUrl}/disk/?subscription=success`,
          cancel_url: `${baseUrl}/disk/?subscription=failed`,
        },
        'payments',
        {},
      );
      if (resp.success) {
        return {
          success: true,
          checkout_url: resp.data.checkout_url,
        };
      } else {
        return resp;
      }
    } catch (error) {
      return {
        success: false,
        error: 'Something went wrong',
      };
    }
  };

  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useGetBalance = () => {
  const sendData = async () => {
    try {
      const resp = await PAYMENT_API.get('/customer/balance', 'payments', {});
      if (resp.success) {
        return {
          success: true,
          balance: resp.data.balance,
        };
      } else {
        return resp;
      }
    } catch (error) {
      return {
        success: false,
        error: 'Something went wrong',
      };
    }
  };

  return useQuery({
    queryKey: ['get-balance'],
    staleTime: 10,
    queryFn: sendData,
  });
};
