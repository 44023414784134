import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CoinIcon } from 'src/components/Icons';
import { useGetStatistics } from 'src/api/notifications';
import { get } from 'lodash';
import { emptyStatistic } from 'src/dataMocks';
import './styles.scss';

type Props = {
  diskId: string;
};

const timeRefetch = 5000; //every 5sec
export const TotalPayments: FC<Props> = ({ diskId }) => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();
  const { data, refetch } = useGetStatistics(diskId === 'all' ? undefined : diskId);
  const stats = get(data, 'data', emptyStatistic);

  useEffect(() => {
    timer.current = setInterval(async () => {
      await refetch();
    }, timeRefetch);

    return () => {
      clearInterval(timer.current as NodeJS.Timeout);
    };
  }, []);

  return (
    <div className="total-payments">
      <div className="total-payments__title">
        <CoinIcon />
        <span>{t('payments')}</span>
      </div>
      <div className="total-payments__value">{stats.total_paid}$</div>
      <div className="total-payments__totals">
        {stats.total_transactions === 1
          ? t('total_transaction_message', { count: stats.total_transactions })
          : t('total_transactions_message', { count: stats.total_transactions })}
      </div>
    </div>
  );
};
