import React from 'react';
import { useDragLayer } from 'react-dnd';
import { FileType, FolderType } from 'src/types';
import { getFileStyles } from 'src/pages/diskPage/components/DragLayer/helpers';
import { IconFile } from 'src/pages/diskPage/components/DragLayer/components/IconFile';
import { IconFolder } from 'src/pages/diskPage/components/DragLayer/components/IconFolder';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import { LineFile } from 'src/pages/diskPage/components/DragLayer/components/LineFile';
import { LineFolder } from 'src/pages/diskPage/components/DragLayer/components/LineFolder';
import './styles.scss';

const layerStyles: React.CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 10000,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

const collect = (monitor: any) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  isDragging: monitor.isDragging(),
  currentPosition: monitor.getSourceClientOffset(),
  initialMousePosition: monitor.getInitialClientOffset(),
  initialSourcePosition: monitor.getInitialSourceClientOffset(),
});

export const transparentImage =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=='; // 1x1 transparent pixel

export const DragLayer = () => {
  const { view } = useDiskContext();

  const { item, isDragging, currentPosition, initialMousePosition, initialSourcePosition } =
    useDragLayer(collect);

  if (!isDragging || !currentPosition) {
    return null;
  }

  const draggedFiles = ((item.files as FileType[]) || []).slice(-3) || [];

  const draggedFolders = ((item.folders as FolderType[]) || []).slice(-3);

  const mouseOffset = {
    x: initialMousePosition.x - initialSourcePosition.x,
    y: initialMousePosition.y - initialSourcePosition.y,
  };

  const co = {
    x: currentPosition.x + mouseOffset.x,
    y: currentPosition.y + mouseOffset.y,
  };

  const totalSelected =
    ((item.files as FileType[]) || []).length + ((item.folders as FolderType[]) || []).length;

  const filesCount = ((item.files as FileType[]) || []).length;

  let counterOffset = { x: co.x + 125, y: co.y + (filesCount === 0 ? 30 : 110) };
  if (view !== 'icons') {
    counterOffset = { x: co.x + 245, y: co.y + 30 };
  }
  return (
    <div style={layerStyles} className="dragging">
      {draggedFolders.map((item, index) => {
        return view === 'icons' ? (
          <IconFolder
            key={item.id}
            folder={item}
            position={co}
            i={index}
            foldersLength={draggedFolders.length}
            filesLength={draggedFiles.length}
          />
        ) : (
          <LineFolder
            key={item.id}
            folder={item}
            position={co}
            i={index}
            totalLength={draggedFolders.length}
          />
        );
      })}
      {draggedFiles.map((item, index) => {
        return view === 'icons' ? (
          <IconFile
            key={item.id}
            file={item}
            i={index}
            position={co}
            totalLength={draggedFiles.length}
          />
        ) : (
          <>
            <LineFile
              key={item.id}
              file={item}
              i={index}
              position={co}
              totalLength={draggedFiles.length}
            />
          </>
        );
      })}
      <div className="dragging__count" style={getFileStyles(counterOffset, 1)}>
        {totalSelected}
      </div>
    </div>
  );
};
