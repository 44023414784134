import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { AnimatedClip } from 'src/components/AnimatedClip';
import { Button } from 'src/components/Forms/Button';
import CountdownTimer from 'src/components/CountdownTimer';

export const LockedDisk = () => {
  const { t } = useTranslation();
  return (
    <div className="locked-disk">
      <div className="locked-disk__content">
        <div className="locked-disk__animation mb-40">
          <AnimatedClip type="noBreaks" className="notification-modal__clip" />
        </div>
        <div className="locked-disk__title">{t('Attention')}</div>
        <div className="locked-disk__text">
          <p>{t('attentionText1')}</p>
          <p>{t('attentionText2')}</p>
        </div>
        <div className="locked-disk__subtitle mb-10">{t('DeletionVia')}</div>

        <CountdownTimer
          finishDate="2024-12-31T23:59:59Z"
          onEnd={() => {
            console.log('end');
          }}
          className="mb-40"
        />
        <Button type="primary" content={t('PayForDisk')} fullWidth onClick={() => {}} />
      </div>
    </div>
  );
};
