import React, { FC } from 'react';
import './styles.scss';
import { SessionType } from 'src/types/general';
import { ReactComponent as PCIcon } from './assets/pc-icon.svg';
import { ReactComponent as ChromeIcon } from './assets/chrome-icon.svg';
import { ReactComponent as FireFox } from './assets/firefox-icon.svg';
import { ReactComponent as SafariFox } from './assets/safari-icon.svg';
import { ReactComponent as AndroidIcon } from './assets/android-icon.svg';
import { ReactComponent as IosIcon } from './assets/ios-icon.svg';
import { CloseIcon } from 'src/components/Icons';
import classNames from 'classnames';

type Props = {
  device: SessionType;
  onClose: (device: SessionType) => void;
  className?: string;
};

export const DeviceRow: FC<Props> = ({ device, onClose, className }) => {
  const icons: { [index: string]: React.ReactNode } = {
    chrome: <ChromeIcon />,
    firefox: <FireFox />,
    safari: <SafariFox />,
    android: <AndroidIcon />,
    ios: <IosIcon />,
  };

  const getIcon = (device: SessionType) => {
    if (icons[device.device_type]) {
      return icons[device.device_type];
    }
    const deviceName = device.device_name.toLowerCase();
    if (icons[deviceName]) {
      return icons[deviceName];
    }

    return <PCIcon />;
  };

  return (
    <div className={classNames('device', className)}>
      <div className="device__icon">{getIcon(device)}</div>
      <div className="device__info">
        <div className="device__name">{device.device_name}</div>
        <div className="device__info">{device.device_type}</div>
        {/*<div className="device__location">location</div>*/}
      </div>
      <div
        className="device__controls"
        onClick={() => {
          onClose(device);
        }}
      >
        <CloseIcon></CloseIcon>
      </div>
    </div>
  );
};
