import React, { useEffect, useState } from 'react';
import { AppContext, AppContextType, defaultAppValues } from './index';
import { Confirmation, ModalPropsType } from 'src/components/Modals/Confirmation';
import { LoadScreen } from 'src/components/LoadScreen';
import { BaseModal, BaseModalPropsType } from 'src/components/Modals/Base';
import { ModalDestination, ModalDestinationProps } from 'src/components/Modals/ModalDestination';
import { useTranslation } from 'react-i18next';
import { useLoadProfile } from 'src/api/profile';
import LocalStorage from 'src/utils/Storage';
import { RightMenuState, sortType } from 'src/context/DiskContext';
import { useUpdateUrlParams } from 'src/hooks/useUpdateUrlParams';
import { useLocation } from 'react-router-dom';
import { SlideMenu } from 'src/components/SlideMenu';

type TProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: TProps) => {
  const location = useLocation();
  const { updateUrlParams } = useUpdateUrlParams();
  const showLoader = false;
  const { data } = useLoadProfile(!LocalStorage.isAuthorised());
  const { i18n } = useTranslation();

  useEffect(() => {
    if (data && data.success && data.profile?.locale_code) {
      i18n.changeLanguage(data.profile.locale_code).then(() => {});
    }
  }, [data]);

  const [state, setState] = useState<AppContextType>(defaultAppValues);

  const openConfirmation = (values: Partial<ModalPropsType> & { open?: boolean }) => {
    setState((state) => ({
      ...state,
      confirmation: {
        ...defaultAppValues.confirmation,
        ...values,
        open: values.open ?? true,
      },
    }));
  };

  const onCancelConfirmation = () => {
    state.confirmation.onCancel();
    setState((state) => ({
      ...state,
      confirmation: {
        ...defaultAppValues.confirmation,
        open: false,
      },
    }));
  };

  const openModal = (values: Partial<BaseModalPropsType> & { open?: boolean }) => {
    setState((state) => ({
      ...state,
      modal: {
        ...defaultAppValues.modal,
        ...values,
        open: values.open ?? true,
      },
    }));
  };

  const onModalClose = () => {
    if (state.modal.onClose) {
      state.modal.onClose();
    }
    setState((state) => ({
      ...state,
      modal: {
        ...defaultAppValues.modal,
        open: false,
      },
    }));
  };

  const openDestinationModal = (values: Partial<ModalDestinationProps> & { open?: boolean }) => {
    setState((state) => ({
      ...state,
      modalDestination: {
        ...defaultAppValues.modalDestination,
        ...values,
        open: values.open ?? true,
      },
    }));
  };

  const updateState = (values: Partial<AppContextType>) => {
    setState((state) => ({
      ...state,
      ...values,
    }));
  };

  const setSort = (sort: sortType | null) => {
    setState((state) => ({
      ...state,
      sort: sort,
    }));
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type') as string;
    if (type) {
      setFileType(type !== 'all' ? type.toUpperCase() : type);
    }
  }, [location.search]);

  const setFileType = (fileType: string) => {
    setState((state) => ({
      ...state,
      filterType: fileType,
    }));

    updateUrlParams({ type: fileType.toLowerCase() });
  };

  const setRightMenuState = (values: Partial<RightMenuState>) => {
    setState((state) => ({
      ...state,
      rightMenuState: {
        ...state.rightMenuState,
        ...values,
      },
    }));
  };

  const closeRightMenu = () => {
    setRightMenuState({ open: false });
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        openConfirmation,
        openModal,
        openDestinationModal,
        updateState,
        setSort,
        setFileType,
        setRightMenuState,
      }}
    >
      {!!state.confirmation.open && (
        <Confirmation
          content={state.confirmation.content}
          onCancel={onCancelConfirmation}
          onSure={state.confirmation.onSure}
          okText={state.confirmation.okText}
          cancelText={state.confirmation.cancelText}
          secondContent={state.confirmation.secondContent}
          className={state.confirmation.className}
        />
      )}
      {!!state.modal.open && (
        <BaseModal
          onClose={onModalClose}
          content={state.modal.content}
          title={state.modal.title}
          className={state.modal.className}
          width={state.modal.width}
          hideHeader={state.modal.hideHeader}
        />
      )}
      {state.modalDestination.open && (
        <ModalDestination
          title={state.modalDestination.title}
          subTitle={state.modalDestination.subTitle}
          onCancel={() => openDestinationModal({ open: false })}
          onMove={state.modalDestination.onMove}
          btnText={state.modalDestination.btnText}
          operation={state.modalDestination.operation}
          hiddenFolders={state.modalDestination.hiddenFolders}
        />
      )}
      <LoadScreen showLoader={showLoader}>{children}</LoadScreen>
      <SlideMenu
        isOpen={state.rightMenuState.open}
        onClose={closeRightMenu}
        position="right"
        width={state.rightMenuState.width}
      >
        {state.rightMenuState.content}
      </SlideMenu>
    </AppContext.Provider>
  );
};
