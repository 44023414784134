import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { usePrevious } from 'src/hooks/usePrevious';
import FileUploader from 'src/components/Uploads/components/FileUploader';
import { FileType } from 'src/types';
import { useFileCache } from 'src/api/files';
import { DEFAULT_FILES_PAYLOAD } from 'src/config/constants';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import { UploadFileType } from 'src/context/GlobalContext';
import { useGetDisks } from 'src/hooks/useGetDisks';

export const GlobalUploader = () => {
  const timer: any = useRef(null);

  const { updateUploadManager, uploadManager, openNotification } = useGlobalContext();

  const [processingFiles, setProcessingFiles] = useState<UploadFileType[]>([]);

  const { invalidateCache: reloadDisks } = useGetDisks();

  const { addFileCache, invalidateCache } = useFileCache({
    ...DEFAULT_FILES_PAYLOAD,
    parent_id: uploadManager.folderId || uploadManager.diskId,
  });

  const prevFiles = usePrevious(uploadManager.files);
  useEffect(() => {
    if (uploadManager.files.length > 0 && !isEqual(uploadManager.files, prevFiles)) {
      const processingFiles = uploadManager.files.map((f, index) => ({
        ...f,
        progress: 0,
        volumeUploaded: 0,
        id: index.toString() + Date.now(),
      }));

      setProcessingFiles((files) => [...files, ...processingFiles]);
    }
  }, [uploadManager.files]);

  const processingFilesPartial = useMemo(() => {
    return processingFiles.map(({ isPaused, isRetrying, ...rest }) => {
      return rest;
    });
  }, [processingFiles]);

  const prevProcessingFilesPartial = usePrevious(processingFilesPartial);
  useEffect(() => {
    if (!isEqual(processingFilesPartial, prevProcessingFilesPartial)) {
      updateUploadManager({
        processingFiles: processingFiles,
      });
    }
  }, [processingFilesPartial]);

  useEffect(() => {
    uploadManager.processingFiles.forEach((file) => {
      setProcessingFiles((files) =>
        files.map((f) =>
          f.id === file.id
            ? {
                ...f,
                isPaused: file.isPaused,
                isCanceled: file.isCanceled,
                isRetrying: file.isRetrying,
              }
            : f,
        ),
      );
    });
  }, [uploadManager.processingFiles]);

  return (
    <>
      {processingFiles.map((file) => {
        return (
          <FileUploader
            diskId={uploadManager.diskId}
            folderId={uploadManager.folderId || ''}
            key={file.id}
            file={file}
            onStart={(startedFile) => {
              setProcessingFiles((files) =>
                files.map((f) => (f.id === startedFile.id ? { ...f, isStarted: true } : f)),
              );
              /*setTimeout(() => {
                reloadDisks();
              }, 1000);*/
            }}
            onUploading={(progress, bytes) => {
              setProcessingFiles((files) =>
                files.map((f) =>
                  f.id === file.id
                    ? { ...f, progress, volumeUploaded: f.volumeUploaded + bytes }
                    : f,
                ),
              );
            }}
            onFinish={(onFinishfile: FileType) => {
              addFileCache(onFinishfile);
              setProcessingFiles((files) =>
                files.map((f) =>
                  f.id === file.id ? { ...f, isCompleted: true, isStarted: false } : f,
                ),
              );
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                invalidateCache();
                reloadDisks();
              }, 100);
            }}
            onCancel={() => {
              reloadDisks();
            }}
            onRetry={() => {
              reloadDisks();
            }}
            onError={(error) => {
              openNotification({ type: 'error', notification: error });
              setProcessingFiles((files) =>
                files.map((f) =>
                  f.id === file.id
                    ? { ...f, isStarted: false, isRetrying: false, isCanceled: true }
                    : f,
                ),
              );
            }}
          />
        );
      })}
    </>
  );
};
