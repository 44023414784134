import React, { FC } from 'react';
import { Form, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Forms/Button';
import PhoneInput from 'src/components/Forms/PhoneInput';
import { DynamicInput } from 'src/components/Forms/DynamicInput';
import { ProfileDataType, UserType } from 'src/types/users';
import { useLoadProfile } from 'src/api/profile';
import { get } from 'lodash';
import { defaultUserProfile } from 'src/dataMocks';
import './styles.scss';

type FormValuesType = ProfileDataType & { confirmation: string };

export const ProfileForm: FC = () => {
  const { data } = useLoadProfile();
  const profile: UserType = get(data, 'profile', defaultUserProfile);

  const { t } = useTranslation();
  const { setFieldValue, submitForm, values, dirty, errors } = useFormikContext<FormValuesType>();

  return (
    <div>
      <Form className="profile-form">
        <div className="profile-form__content">
          <div>
            <div className="profile-form__row mb-15">
              <DynamicInput
                label={t('nameLastName')}
                value={values.full_name}
                onChange={(value) => setFieldValue('full_name', value)}
                error={errors.full_name}
                className="profile-form__input"
              />
            </div>
            {profile.phone_number?.length && (
              <div className="profile-form__row mb-20">
                <PhoneInput
                  className="profile-form__phone"
                  onChange={(value) => setFieldValue('phone_number', value)}
                  value={values.phone_number}
                  error={errors.phone_number}
                />
              </div>
            )}
            {profile.email?.length && (
              <div className="profile-form__row mb-15">
                <DynamicInput
                  label={`${t('email')}:`}
                  value={values.email}
                  onChange={(value) => setFieldValue('email', value)}
                  error={errors.email}
                  type="email"
                  className="profile-form__input"
                />
              </div>
            )}
            <div className="profile-form__row mb-15">
              <DynamicInput
                label={`${t('id')}:`}
                value={values.user_name}
                onChange={(value) => setFieldValue('user_name', value)}
                error={errors.user_name}
                className="profile-form__input"
              />
            </div>
          </div>
        </div>
      </Form>
      <div className="profile-form__row pb-20">
        <Button
          content={'OK'}
          onClick={submitForm}
          type="primary"
          isDisabled={!dirty || Object.keys(errors).length > 0}
          fullWidth
          className="profile-form__button"
        />
      </div>
    </div>
  );
};

export default ProfileForm;
