import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DiskParameters } from 'src/components/CreateDisk/DiskParameters';
import './styles.scss';
import { DriveTypes } from 'src/types';

type Props = {
  onChange: (diskName: string, drive_type: DriveTypes) => void;
};

export const StorageDisk: FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();
  const [diskName, setDiskName] = useState('');
  const [parameters, setParameters] = useState<DriveTypes>('standard');

  const handleDiskNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDiskName = event.target.value;
    setDiskName(newDiskName);
    onChange(newDiskName, parameters);
  };

  const handleChangeParameters = (value: DriveTypes) => {
    setParameters(value);
    onChange(diskName, value);
  };

  return (
    <div className="storage-disk">
      <div className="storage-disk__name-field">
        <label className="storage-disk__label">{t('creatingStorageDisk')}</label>
        <input
          type="text"
          className="storage-disk__input"
          placeholder="Disk name"
          value={diskName}
          onChange={handleDiskNameChange}
        />
      </div>
      <div className="storage-disk__reservation">
        <DiskParameters value={parameters} onChange={handleChangeParameters} />
      </div>
    </div>
  );
};
