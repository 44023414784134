import React, { FC } from 'react';
import { ButtonCheck, ButtonCross } from 'src/components/Icons';
import classNames from 'classnames';
import './styles.scss';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  disabled?: boolean;
  className?: string;
};
export const DoubleButton: FC<Props> = ({ onConfirm, disabled, onCancel, className }) => {
  const onConfirmHandler = () => {
    if (disabled) return;
    onConfirm();
  };

  const onCancelHandler = () => {
    if (disabled) return;
    onCancel();
  };
  return (
    <div className={classNames('double-button', className)}>
      <div
        className={classNames('double-button__cta', { disabled })}
        onClick={(e) => {
          e.stopPropagation();
          onCancelHandler();
        }}
      >
        <ButtonCross />
      </div>
      <div
        className={classNames('double-button__cta', { disabled })}
        onClick={(e) => {
          e.stopPropagation();
          onConfirmHandler();
        }}
      >
        <ButtonCheck />
      </div>
    </div>
  );
};
