import React, { FC } from 'react';
import { DownloadedIcon, UploadedIcon } from 'src/components/Icons';
import { useTranslation } from 'react-i18next';
import { useGetStatistics } from 'src/api/notifications';
import { get } from 'lodash';
import { emptyStatistic } from 'src/dataMocks';
import { convertBytes } from 'src/helpers';
import './styles.scss';

type Props = {
  diskId: string;
};

export const FilesStatistics: FC<Props> = ({ diskId }) => {
  const { t } = useTranslation();

  const { data } = useGetStatistics(diskId === 'all' ? undefined : diskId);
  const stats = get(data, 'data', emptyStatistic);

  const uploads = convertBytes(stats.total_uploaded_bytes);
  const downloads = convertBytes(stats.total_downloaded_bytes);

  const stringFormat = (str: string | number) => {
    return String(str).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  return (
    <div className="files-statistics">
      <div className="files-statistics__block">
        <div className="files-statistics__title">
          <UploadedIcon />
          <span>{t('uploaded')}</span>
        </div>

        <div className="files-statistics__value">
          <span>{stringFormat(stats.total_uploaded_files)}</span>
          <span className="highlight">
            {stats.total_uploaded_files === 1 ? t('file') : t('files')}
          </span>
        </div>
        <div className="files-statistics__value">
          <span>{stringFormat(uploads.size)}</span>
          <span className="highlight">{uploads.unit}</span>
        </div>
      </div>
      <div className="files-statistics__block">
        <div className="files-statistics__title">
          <DownloadedIcon />
          <span>{t('downloaded')}</span>
        </div>

        <div className="files-statistics__value">
          <span>{stringFormat(stats.total_downloaded_bytes)}</span>
          <span className="highlight">
            {stats.total_downloaded_bytes === 1 ? t('file') : t('files')}
          </span>
        </div>
        <div className="files-statistics__value">
          <span>{stringFormat(downloads.size)}</span>
          <span className="highlight">{downloads.unit}</span>
        </div>
      </div>
    </div>
  );
};
