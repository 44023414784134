import React, { FC, useState } from 'react';
import { ValuesType } from 'src/components/CreateDisk/types';
import { useCreateSubscription, useGetPricing } from 'src/api/payments';
import { useGetDisks } from 'src/hooks/useGetDisks';
import { useTranslation } from 'react-i18next';
import { DiskObject } from 'src/components/DiskObject';
import { DiskColorType, DiskDetailsType, DiskType, PriceType } from 'src/types';
import { emptyDisk } from 'src/dataMocks';
import { EmptyBlock } from 'src/components/EmptyBlock';
import { BackIcon } from 'src/components/Icons';
import { getConvertedSize } from 'src/helpers';
import { calculatorConfig } from 'src/pages/landings/Cloud/components/PriceCalculator/config';
import { Button } from 'src/components/Forms/Button';
import {
  archivingOptions,
  parametersOptions,
} from 'src/components/CreateDisk/DiskParameters/config';
import { KeyOptions } from 'src/components/CreateDisk/DiskParameters/components/KeyOptions';
import { DiskParameter } from 'src/components/CreateDisk/DiskParameters';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { useRenameObject } from 'src/api/objects';
import { Notification } from 'src/components/Notification';
import { usePrices } from 'src/components/CreateDisk/usePrices';
import './styles.scss';

type Props = {
  values: ValuesType;
  onBackClick: () => void;
  totalPrice: string;
  disk?: Partial<DiskType>;
  diskDetails?: DiskDetailsType;
};

export const DiskConfirmation: FC<Props> = ({
  values,
  onBackClick,
  totalPrice,
  disk,
  diskDetails,
}) => {
  const [errors, setErrors] = useState<string>('');
  const { openModal } = useAppContext();
  const { t } = useTranslation();
  const { getPricePerGb } = usePrices();

  const { mutateAsync: renameDisk, isPending: isPendingRename } = useRenameObject();

  const data: DiskType = {
    ...emptyDisk,
    name: 'New disk',
    total_volume: 1000000000000,
    filled_volume: 5000000000,
  };

  const { refetch } = useGetDisks();

  const { mutateAsync: createDisk, isPending } = useCreateSubscription();

  const createDiskHandler = async () => {
    const response = await createDisk({
      backup_s_coefficient: 1,
      drive_data: {
        cloud_provider: 'AWS',
        color: values.color,
        drive_id: 'NEW',
        drive_name: values.name,
        drive_type: values.drive_type,
      },
      subscription_term: values.invoice,
      total_gb: Math.ceil(values.filled_volume / 1000000000),
    });

    if (response.success) {
      document.location.href = response.checkout_url;
    } else {
      setErrors(response.error);
    }
  };

  const updateDiskHandler = async () => {
    const diskId = values.id as string;
    const renameResponse = await renameDisk({ id: diskId, name: values.name });
    if (!renameResponse.success) {
      setErrors(renameResponse.error);
      return;
    }

    if (
      disk?.total_volume !== values.filled_volume ||
      diskDetails?.subscription_term.toLowerCase() !== values.invoice.toLowerCase()
    ) {
      const response = await createDisk({
        backup_s_coefficient: 1,
        drive_data: {
          cloud_provider: 'AWS',
          color: values.color,
          drive_id: diskId,
          drive_name: values.name,
          drive_type: 'standard',
        },
        subscription_term: values.invoice,
        total_gb: Math.ceil(values.filled_volume / 1000000000),
        isEdit: true,
      });
      if (response.success) {
        document.location.href = response.checkout_url;
      } else {
        setErrors(response.error);
        return;
      }
    }
    openModal({
      open: false,
    });
    await refetch();
  };

  const handleSaveChanges = async () => {
    if (values.id) {
      await updateDiskHandler();
      return;
    } else {
      await createDiskHandler();
    }
  };

  const onBackClickHandler = () => {
    onBackClick();
  };

  const parameters = parametersOptions(t).diskParameters.find(
    (pt) => pt.value === values.drive_type,
  );

  const onFunctionsClick = (item: DiskParameter) => {
    openModal({
      content: (
        <>
          {item.value !== 'archiving' && (
            <KeyOptions title={`${t('keyFeatures')}:`} options={item.keyFeatures} />
          )}
          {item.value === 'archiving' && (
            <>
              {archivingOptions(t).map((parameterOption, index) => {
                return (
                  <KeyOptions
                    key={index}
                    title={parameterOption.label}
                    options={parameterOption.keyFeatures}
                  />
                );
              })}
            </>
          )}
        </>
      ),
      title: item.label,
      className: 'options-modal',
      width: '780px',
      onClose: () => {
        setTimeout(() => {
          openModal({
            content: (
              <DiskConfirmation
                values={values}
                onBackClick={() => {
                  openModal({ open: false });
                }}
                totalPrice={totalPrice}
              />
            ),
            title: t('uploadFiles'),
            className: 'disk-confirmation-modal',
            width: '840px',
            hideHeader: true,
          });
        }, 1);
      },
    });
  };

  const isSubscriptionCHanged = disk?.total_volume !== values.filled_volume;

  return (
    <div className="disk-confirmation">
      <div className="disk-confirmation__title">
        <div className="disk-confirmation__back" onClick={onBackClickHandler}>
          <BackIcon />
        </div>
        <span>{t('confirmParameters')}</span>
      </div>

      {!!errors.length && (
        <Notification
          notification={errors}
          onClose={() => {
            setErrors('');
          }}
        />
      )}
      <div className="disk-confirmation__columns">
        <div className="disk-confirmation__column">
          <DiskObject
            disk={{
              ...data,
              name: values.name,
              filled_volume: values.filled_volume,
              color: values.color as DiskColorType,
            }}
          />

          <div className="disk-confirmation__info mt-15">
            <div className="disk-confirmation__info--line">
              <div className="disk-confirmation__line-content">
                <span>{t('diskParameters')}</span>
                <div className="disk-confirmation__parameter-title">
                  <span>{parameters?.label}</span>
                  <div
                    className="disk-confirmation__function"
                    onClick={() => {
                      onFunctionsClick(parameters as DiskParameter);
                    }}
                  >
                    {t('functions')}
                  </div>
                </div>
                <div className="disk-confirmation__parameter-content">
                  {parameters?.description}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="disk-confirmation__column">
          <EmptyBlock className="mb-15">
            <div className="create-disk__server">AWS Storage server</div>
          </EmptyBlock>
          <div className="disk-confirmation__info">
            <div className="disk-confirmation__info--line">
              <span>{t('diskName')}:</span>
              <span>{values.name}</span>
            </div>
          </div>
          <div className="disk-confirmation__info">
            <div className="disk-confirmation__info--line">
              <span>{t('subscription')}:</span>
              <span>{values.invoice === 'ANNUAL' ? 'Annually' : values.invoice}</span>
            </div>
          </div>

          <div className="disk-confirmation__info">
            <div className="disk-confirmation__info--line">
              <span>{t('storageCapacity')}:</span>
              <span>{getConvertedSize(values.filled_volume)}</span>
            </div>
            <div className="disk-confirmation__info--line">
              <span>{t('costGigabyte')}:</span>
              <span>{getPricePerGb(values.drive_type)}$</span>
            </div>
          </div>

          <div className="disk-confirmation__info">
            <div className="disk-confirmation__info--line bottom-block">
              <span className="bottom-label">{t('amount')}:</span>
              <div className="bottom-price">
                {values.invoice === 'ANNUAL' && (
                  <div className="bottom-price__old-price">
                    {(parseFloat(totalPrice) / 0.8).toFixed(2)}$
                  </div>
                )}
                <div className="bottom-price__price">
                  {totalPrice}$
                  {values.invoice === 'ANNUAL' && (
                    <div className="bottom-price__price-discount">- 20%</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="disk-confirmation__footer mt-40">
        <Button
          type="primary"
          content={isSubscriptionCHanged ? t('subscribe') : t('saveChanges')}
          className="disk-confirmation__btn"
          onClick={handleSaveChanges}
        />
      </div>
    </div>
  );
};
