import React, { FC } from 'react';
import { ReactComponent as PlusIcon } from '../../assets/plus-icon.svg';
import { ReactComponent as CardsIcon } from '../../assets/cards-icon.svg';
import { ReactComponent as CloudIcon } from '../../assets/cloud-icon.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import './styles.scss';

type Props = {
  onNewDiskClick: () => void;
  onMyBalanceClick: () => void;
  onMySubscriptionsClick: () => void;
  isPaymentBlockVisible: boolean;
};
export const SideTabs: FC<Props> = ({
  onNewDiskClick,
  onMyBalanceClick,
  onMySubscriptionsClick,
  isPaymentBlockVisible,
}) => {
  const { hideProduction } = useFeatureFlags();
  const { t } = useTranslation();
  return (
    <div className="side-tabs">
      <div className="side-tabs__btn skip-outside" onClick={onNewDiskClick}>
        <PlusIcon />
        <span>{t('newDisk')}</span>
      </div>
      {/* {!hideProduction && (
        <div
          className={classNames('side-tabs__btn', { active: isPaymentBlockVisible })}
          onClick={onMyBalanceClick}
        >
          <CardsIcon />
          <span>{t('myBalance')}</span>
        </div>
      )}*/}
      <div className="side-tabs__btn" onClick={onMySubscriptionsClick}>
        <CloudIcon />
        <span>{t('mySubscriptions')}</span>
      </div>
    </div>
  );
};
