import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Input } from 'src/components/Forms/Inputs/Input';
import { Button } from 'src/components/Forms/Button';
import './styles.scss';
import { useCreateDisk } from 'src/api/disks';

type Props = {
  onSave: () => void;
  onClose?: () => void;
};
export const CreateDiskForm: FC<Props> = ({ onSave, onClose }) => {
  const [serverError, setServerError] = useState<string>('');
  const { mutateAsync: createDisk, isPending } = useCreateDisk();
  const { t } = useTranslation();
  const initialValues = {
    name: '',
  };

  const handleSaveChanges = async (values: { name: string }) => {
    const response = await createDisk(values);
    if (response.success) {
      onSave();
    } else {
      setServerError(response.error);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(50, ' ').required(' '),
  });

  return (
    <div className="create-disks-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSaveChanges}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ submitForm, values, setFieldValue, errors, isValid }) => (
          <div>
            <div className="mb-32">
              <Input
                className="create-disks-form__input"
                placeholder={t('diskName')}
                value={values.name}
                onChange={async (value) => {
                  await setFieldValue('name', value);
                  setServerError('');
                }}
                error={errors.name || serverError}
                maxLength={50}
              />
            </div>

            <div className="mb-6">
              <Button
                className="create-disks-form__button"
                type="primary"
                content={t('createDis')}
                onClick={submitForm}
                fullWidth
                isDisabled={!isValid || isPending}
              />
            </div>
            <div className="create-disks-form__cancel" onClick={onClose}>
              {t('cancel')}
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
