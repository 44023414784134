import { QueryClient, QueryKey } from '@tanstack/react-query';

export const updateCache = (
  queryClient: QueryClient,
  queryKey: QueryKey,
  updater: (oldData: any) => any,
) => {
  queryClient.setQueryData(queryKey, updater);
};

export const updateGlobalCache = (queryClient: QueryClient, updater: (oldData: any) => any) => {
  const queries = queryClient.getQueryCache().getAll();
  queries.forEach((query) => {
    queryClient.setQueryData(query.queryKey, updater);
  });
};
