import React, { FC } from 'react';
import { useLoadProfile } from 'src/api/profile';
import { get } from 'lodash';
import { ImgUploadIcon } from 'src/components/Icons';
import './styles.scss';

type Props = {
  size?: string;
};

export const UserAvatar: FC<Props> = ({ size }) => {
  const s = size || '50px';
  const { data: profileData } = useLoadProfile();
  const profile = get(profileData, 'profile', {});

  return (
    <div className="user-avatar-comp" style={{ width: s, height: s }}>
      <>{profile.avatarData ? <img src={profile.avatarData} alt="" /> : <ImgUploadIcon />}</>
    </div>
  );
};
