import React from 'react';
import { privacyNotice } from 'src/pages/auth/components/LoginSection/components/Terms/plicy';
import { Terms } from 'src/pages/auth/components/LoginSection/components/Terms';
import './styles.scss';

export const PolicyPage = () => {
  return (
    <div>
      <Terms terms={privacyNotice} className="terms-policy" />
    </div>
  );
};
