import React, { FC } from 'react';
import { FileType } from 'src/types';
import { FileFavoriteIcon } from 'src/components/Icons';
import { FileMedia } from 'src/components/FileMedia';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'src/config/constants';
import { getConvertedSize } from 'src/helpers';
import { useGetFolderStats } from 'src/api/folders';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import { HashtagsBlock } from 'src/components/HashtagsBlock';
import './styles.scss';

type Props = {
  file: FileType;
  className?: string;
};

export const FileInfo: FC<Props> = ({ file, className }) => {
  const { t } = useTranslation();
  const { disk } = useDiskContext();
  const { data } = useGetFolderStats(''); //TODO: add correct params
  const parentFolder = data?.stats || { name: '' };

  const fields = [
    {
      name: file.parent_id?.length ? t('parentFolderName') : t('parentDiskName'),
      value: file.parent_id?.length ? parentFolder.name : disk.name,
    },
    {
      name: t('fileExtension'),
      value: file.extension,
    },
    {
      name: t('fileTypes'),
      value: file.type,
    },
    {
      name: t('fileSize'),
      value: getConvertedSize(file.size),
    },
  ];
  return (
    <div className={classNames('fileInfo', className)}>
      <div className="info-component__section">
        <div className="info-component__section-content info-component__image-wrapper">
          <div className="fileInfo__thumbnail mb-20">
            <FileMedia fileId={file.id} extension={file.extension} className={'fileInfo__media'}>
              {file.is_favorite && <FileFavoriteIcon className="file__favorite" />}
            </FileMedia>
          </div>
          {file.name && <div className="fileInfo__title">{file.name}</div>}
        </div>
      </div>

      <HashtagsBlock itemId={file.id} />

      <div className="info-component__section">
        <div className="info-component__section-header">
          <span>{t('fileInformation')}</span>
        </div>
        <div className="info-component__section-content">
          {fields.map((item, key) => (
            <div key={key} className="info-component__section-item">
              <div className="info-component__category">{item.name}:</div>
              <div className="info-component__size">{item.value}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="info-component__section">
        <div className="info-component__section-header">
          <span>{t('dates')}</span>
        </div>
        <div className="info-component__section-content">
          <div className="info-component__section-item">
            <div className="info-component__category">{t('lastModifiedDate')}:</div>
            <div className="info-component__size">
              {moment(file.last_modified_at).format(DATE_TIME_FORMAT)}
            </div>
          </div>
          <div className="info-component__section-item">
            <div className="info-component__category">{t('creationDate')}:</div>
            <div className="info-component__size">
              {moment(file.created_at).format(DATE_TIME_FORMAT)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
