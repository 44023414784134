import React, { useState, useRef, useEffect } from 'react';
import { flags } from 'src/components/Forms/PhoneInput/config';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type Props = {
  maxWidth?: string;
  className?: string;
  onChange?: (phone: string) => void;
  error?: string;
  value?: string;
};

const PhoneInput: React.FC<Props> = ({ maxWidth, className, onChange, error, value }) => {
  const { t } = useTranslation();

  const [isActive, setIsActive] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(flags[flags.length - 1]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectedCountryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (value) {
      const parsedCountry = flags.find((flag) => value.startsWith(flag.code));
      if (parsedCountry) {
        setSelectedCountry(parsedCountry);
        setPhoneNumber(value.replace(parsedCountry.code, ''));
      } else {
        setPhoneNumber(value);
      }
    }
  }, [value]);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCountrySelect = (country: (typeof flags)[0]) => {
    setSelectedCountry(country);
    setDropdownOpen(false);
    if (onChange) {
      const updatedPhoneNumber = `${country.code}${phoneNumber}`.replace(/ /g, '');
      onChange(updatedPhoneNumber);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  const formatPhoneNumber = (value: string) => {
    const cleaned = value.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{3})(\d{0,6})$/);
    if (match) {
      return `${match[1]} ${match[2]} ${match[3]}`.trim();
    }
    return cleaned.slice(0, 11);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedNumber);
    const phoneNumber = `${selectedCountry.code}${formattedNumber}`.replace(/ /g, '');
    if (onChange) {
      onChange(phoneNumber);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (dropdownOpen && selectedCountryRef.current) {
      selectedCountryRef.current.scrollIntoView({ block: 'nearest' });
    }
  }, [dropdownOpen]);

  return (
    <div
      className={classNames('phone-input', className, { hasError: !!error?.length })}
      style={{ maxWidth: maxWidth || '100%' }}
    >
      <label className="phone-input__label" htmlFor="phone-number">
        {t('phoneNumber')}
      </label>
      <div className={classNames('phone-input__field', { active: isActive })}>
        <div className="phone-input__dropdown" ref={dropdownRef}>
          <div className="phone-input__dropdown-button" onClick={handleDropdownToggle}>
            <span className="phone-input__flag">{selectedCountry.flag}</span>
            <span className="phone-input__code">{selectedCountry.code}</span>
            <span className="phone-input__arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M10.8727 12.4228L7.79632 9.0046C7.62257 8.81154 7.75958 8.50391 8.01931 8.50391H14.1721C14.4318 8.50391 14.5688 8.81154 14.3951 9.0046L11.3187 12.4228C11.1995 12.5552 10.9919 12.5552 10.8727 12.4228Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>
          {dropdownOpen && (
            <div className="phone-input__dropdown-content">
              {flags.map((country) => (
                <div
                  key={country.code}
                  onClick={() => handleCountrySelect(country)}
                  className={`phone-input__dropdown-item ${
                    country.code === selectedCountry.code ? 'selected' : ''
                  }`}
                  ref={country.code === selectedCountry.code ? selectedCountryRef : null}
                >
                  <div className="phone-input__dropdown-item-content">
                    <span className="flag">{country.flag}</span>
                    <span className="code">{country.code}</span>
                  </div>
                  <span className="name">{t(country.name)}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        <input
          type="tel"
          id="phone-number"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder="99 000 0000"
          pattern="\d{2} \d{3} \d{6}"
          maxLength={13}
          onFocus={() => {
            setIsActive(true);
          }}
          onBlur={() => {
            setIsActive(false);
          }}
        />
      </div>
      {error && <div className="phone-input__error">{error}</div>}
    </div>
  );
};

export default PhoneInput;
