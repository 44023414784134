import React, { FC, useState } from 'react';
import { ResizableBox } from 'react-resizable';
import { ReactComponent as ResizeIcon } from './assets/resize-icon.svg';
import './diskLayout.scss';
import 'react-resizable/css/styles.css';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';

type Props = {
  children?: React.ReactNode;
  leftSidebar?: React.ReactNode;
};

export const DiskLayout: FC<Props> = ({ children, leftSidebar }) => {
  const [isResizing, setIsResizing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ['file', 'folder'],
      drop: () => {},
      canDrop: (item, monitor) => {
        return monitor.isOver({ shallow: true });
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
        canDrop: false,
      }),
    }),
    [],
  );

  return (
    <div
      className={classNames('disk-layout', {
        resizing: isResizing || isHovering,
      })}
      ref={(node) => drop(node)}
    >
      <ResizableBox
        width={380}
        height={Infinity}
        minConstraints={[380, Infinity]}
        maxConstraints={[1600, Infinity]}
        axis="x"
        resizeHandles={['e']}
        handle={
          <div
            className="disk-layout__resizer"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <ResizeIcon className="resize-icon" />
          </div>
        }
        onResizeStart={() => setIsResizing(true)}
        onResizeStop={() => setIsResizing(false)}
      >
        <div className="disk-layout__left-sidebar">{leftSidebar}</div>
      </ResizableBox>
      <div className="disk-layout__content">{children}</div>
    </div>
  );
};
