import React, { FC } from 'react';
import { LogoWhite } from 'src/components/Icons';
import classNames from 'classnames';
import './styles.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const AuthLayout: FC<Props> = ({ children, className }) => {
  return (
    <div className={classNames('auth', className)}>
      <div className="auth__left">
        <div className="auth__left--inner">
          <LogoWhite />
        </div>
      </div>
      <div className="auth__right">
        <div className="auth__mobile-logo">
          <div className="auth__mobile-logo-content">
            <LogoWhite />
          </div>
        </div>
        <div className="auth__right--inner">{children}</div>
      </div>
    </div>
  );
};
