import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import './styles.scss';
import moment from 'moment';

type Props = {
  text: string;
  time: number;
  onFinish: () => void;
};

export const SmsTimer: FC<Props> = ({ text, time, onFinish }) => {
  let interval: MutableRefObject<NodeJS.Timer | undefined> = useRef();
  const [seconds, setSeconds] = useState(time);

  useEffect(() => {
    clearInterval(interval.current);
    setSeconds(time);
  }, [time]);

  useEffect(() => {
    if (seconds === 0) {
      onFinish();
      clearInterval(interval.current);
      return;
    }
    interval.current = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval.current);
  }, [seconds, onFinish]);

  const duration = moment.duration(seconds, 'seconds');
  const formatted = moment.utc(duration.asMilliseconds()).format('mm:ss');

  return (
    <div className="sms-timer">
      <div className="sms-timer__text">{text}</div>
      <div className="sms-timer__time">{formatted}</div>
    </div>
  );
};
