import React, { FC, useMemo } from 'react';
import { DoubleButton } from 'src/pages/diskPage/components';
import { getColor } from 'src/config/fileTypes';
import { getConvertedSize } from 'src/helpers';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'src/config/constants';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { DiskType } from 'src/types';
import classNames from 'classnames';
import { ActionButton } from 'src/components/Forms/ActionButton';
import { useDiskState } from './useDiskState';
import { CloseIcon } from 'src/components/Icons';

type Props = {
  disk: DiskType;
  className?: string;
  onClose?: () => void;
};

export const DiskInfo: FC<Props> = ({ disk, className, onClose }) => {
  const { t } = useTranslation();

  const statistics = disk.statistics || [];

  const {
    editMode,
    newName,
    hasError,
    isProcessing,
    items,
    setEditMode,
    onChangeName,
    onSaveDisk,
  } = useDiskState({ disk });

  const diskSize = useMemo(() => {
    let size = 0;
    statistics.forEach((stat) => {
      size += stat.size;
    });
    return size;
  }, [statistics]);

  return (
    <div className={'info-component'}>
      <div className="right-title">
        <span>test</span>
        <CloseIcon className="right-title__close" onClick={onClose} />
      </div>
      <div className={classNames('diskInfo', className)}>
        <div className="diskInfo__thumbnail mb-30">
          <div className="diskInfo__icon-wrapper"></div>
          <div className="diskInfo__folder-name">
            {!editMode ? (
              disk.name
            ) : (
              <input
                value={newName}
                placeholder="name"
                type="text"
                className={classNames('disk__name-field', { error: hasError })}
                onChange={(e) => onChangeName(e.target.value)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            )}
          </div>
          <div className="diskInfo__folder-name-info">
            {editMode ? (
              <DoubleButton
                disabled={isProcessing}
                onCancel={() => {
                  setEditMode(false);
                }}
                onConfirm={onSaveDisk}
                className="mt-20"
              />
            ) : (
              <>
                <ActionButton items={items} className="disk__abtn" id={`info-${disk.id}`} />
              </>
            )}
          </div>
        </div>

        <div className="diskInfo__file-types">
          <div className="diskInfo__file-types-title">{t('fileTypes')}:</div>
          <div className="diskInfo__ft-items">
            {statistics
              .filter((stat) => stat.count > 0)
              .map((stat, key) => {
                return (
                  <div key={key} className="folderInfo__ft-item">
                    <div className="folderInfo__ft-item-info">
                      <div
                        className="folderInfo__ft-color"
                        style={{ backgroundColor: `${getColor(stat.type)}` }}
                      ></div>
                      <div className="folderInfo__ft-data">
                        {stat.type.toLowerCase()}: {statistics[key].count}
                      </div>
                    </div>
                    <div className="folderInfo__ft-item-size">
                      {getConvertedSize(statistics[key].size)}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="diskInfo__items">
          <div className="diskInfo__item">
            <div className="diskInfo__item-name">{t('totalDiskVolume')}:</div>
            <div className="diskInfo__item-value">{getConvertedSize(diskSize)}</div>
          </div>
          <div className="diskInfo__item">
            <div className="diskInfo__item-name">{t('creationDate')}:</div>
            <div className="diskInfo__item-value">
              {moment(disk.created_at).format(DATE_TIME_FORMAT)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
