import React from 'react';
import { BackIcon } from 'src/components/Icons';
import { useParams } from 'react-router-dom';
import { useGetHashtags } from 'src/api/hashtags';
import { get } from 'lodash';
import { HashTagType } from 'src/types';
import classNames from 'classnames';
import { EditHashtag } from 'src/pages/diskPage/components/HashtagsList/components/EditHashtag';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import { useTranslation } from 'react-i18next';
import { groupHashtagsByType } from 'src/pages/diskPage/components/HashtagsList/helpers';
import './styles.scss';

export const HashtagsList = () => {
  const { t } = useTranslation();
  const { updateState } = useDiskContext();
  const { diskId } = useParams();
  const { data, refetch } = useGetHashtags(diskId || '');
  const hashtags = get(data, 'hashtags', []);
  const [selectedHashtag, setSelectedHashtag] = React.useState<HashTagType | null>(null);

  const onHashtagClick = (hashtag: HashTagType) => {
    if (hashtag.ID === selectedHashtag?.ID) {
      setSelectedHashtag(null);
      return;
    }
    setSelectedHashtag(hashtag);
  };

  const onCloseClick = () => {
    updateState({ showHashTags: false });
  };
  return (
    <div className="hashtags-list">
      <div className="hashtags-list__content">
        <div className="hashtags-list__breadcrumbs" onClick={onCloseClick}>
          <BackIcon className="hashtags-list__breadcrumbs-btn" />{' '}
          <span>{t('popularHashtags')}</span>
        </div>
        <div className="hashtags-list__list">
          {Object.entries(groupHashtagsByType(hashtags)).map(([type, items]) => (
            <div key={type} className="hashtags-list__group">
              <div className="hashtags-list__group-title">{type.toLowerCase()}</div>
              <div className="hashtags-list__group-list">
                {items.map((item) => (
                  <div
                    key={item.ID}
                    className={classNames('hashtags-list__group-item', {
                      selected: selectedHashtag && selectedHashtag.ID === item.ID,
                    })}
                    onClick={() => onHashtagClick(item)}
                  >
                    {item.Hashtag}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedHashtag && (
        <div className="hashtags-list__sidebar">
          <EditHashtag
            hashtag={selectedHashtag}
            onEdit={async () => {
              await refetch();
            }}
            onDelete={async () => {
              setSelectedHashtag(null);
              await refetch();
            }}
          />
        </div>
      )}
    </div>
  );
};
