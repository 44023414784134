import React from 'react';
import { UserAvatar } from '../UserAvatar';
import { ReactComponent as Arrow } from './assets/arrow-green.svg';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { useGetBalance } from 'src/api/payments';
import { get } from 'lodash';
import { convertCentsToDollars } from 'src/helpers';

export const UserHeader = () => {
  const { t } = useTranslation();
  const { data } = useGetBalance();
  const balance = get(data, 'balance', 0);

  return (
    <div className="user-header">
      <UserAvatar size="36px" />
      <div className="user-header__balance">
        <span>
          {t('balance')}: ${convertCentsToDollars(balance)}
        </span>{' '}
        <Arrow />
      </div>
      <div className="user-header__right"></div>
    </div>
  );
};
