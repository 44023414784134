import { API } from 'src/utils/API';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DiskType } from 'src/types';
import LocalStorage from 'src/utils/Storage';

export const useLoadDisks2 = (payload: { disabled?: boolean }) => {
  const { disabled } = payload;
  const queryClient = useQueryClient();

  const sortDisks = (f1: DiskType, f2: DiskType) => {
    if (f1.is_favorite === f2.is_favorite) {
      return f1.name.toLowerCase().localeCompare(f2.name.toLowerCase());
    }
    return f1.is_favorite ? -1 : 1;
  };

  const fetchData = async () => {
    const resp = await API.get('/drives', 'archive');
    if (resp.success) {
      return {
        success: true,
        disks: (resp.data as DiskType[])
          .map((disk, i) => {
            return {
              ...disk,
              color: disk.color || 'blue',
              /*  is_blocked: i === 0,*/
            };
          })
          .sort(sortDisks),
      };
    } else {
      return resp;
    }
  };

  const updateDiskCache = (updatedDisk: DiskType) => {
    const id = updatedDisk.id.replace('ARCHIVE#', '');
    queryClient.setQueryData(['load-disks', payload], (oldData: any) => {
      if (oldData && oldData.success) {
        return {
          ...oldData,
          disks: oldData.disks.map((disk: DiskType) => (disk.id === id ? updatedDisk : disk)),
        };
      }
      return oldData;
    });
  };

  const queryResult = useQuery({
    queryKey: ['load-disks', payload],
    queryFn: fetchData,
    staleTime: 1000 * 60 * 5,
    enabled: !disabled && LocalStorage.isAuthorised(),
    retry: 0,
  });

  const invalidateCache = () => {
    queryResult.refetch();
  };
  return {
    ...queryResult,
    updateDiskCache,
    invalidateCache,
  };
};

export const useLoadDisk = (id: string) => {
  const fetchData = async () => {
    const resp = await API.get(`/drives/${id}`, 'archive');
    if (resp.success) {
      return {
        success: true,
        disk: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useQuery({
    queryKey: ['load-disk', id],
    queryFn: fetchData,
    staleTime: 1000 * 60 * 5,
    enabled: !!id,
    retry: 0,
  });
};

export const useCreateDisk = () => {
  const sendData = async (payload: any) => {
    const resp = await API.post('/drives', payload, 'archive');
    if (resp.success) {
      return {
        success: true,
        disk: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

/*export const useUpdateDisk = () => {
  const sendData = async ({ id, ...rest }: any) => {
    const resp = await API.put(`/drives/${id}`, rest, 'archive');
    if (resp.success) {
      return {
        success: true,
        disk: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};*/

export const useDeleteDisk = () => {
  const sendData = async (id: string) => {
    const resp = await API.delete(`/drives/${id}`, 'archive');
    if (resp.success) {
      return {
        success: true,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useUploadDiskAvatar = (id: string) => {
  const sendData = async (payload: any) => {
    const resp = await API.post(`/drives/${id}/avatar`, payload, 'archive', {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (resp.success) {
      return {
        success: true,
        avatar: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 1,
  });
};

export const useClearDisk = () => {
  const sendData = async (id: string) => {
    const resp = await API.delete(`/drives/clear/${id}`, 'archive');
    if (resp.success) {
      return {
        success: true,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 1,
  });
};
export const useGetDiskDetails = (id: string) => {
  const sendData = async () => {
    const resp = await API.get(`/drives/${id}/settings`, 'archive');
    if (resp.success) {
      return {
        success: true,
        data: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useQuery({
    queryKey: ['load-disk-details', id],
    queryFn: sendData,
    retry: 1,
    enabled: !!id,
  });
};
