import { useDeleteDisk } from 'src/api/disks';
import { useCreateFavorite, useDeleteFavorite } from 'src/api/favorities';
import { useRenameObject } from 'src/api/objects';

export const useManageDisk = () => {
  const { mutateAsync: renameDisk, isPending } = useRenameObject();
  const { mutateAsync: deleteDisk, isPending: isDeleting } = useDeleteDisk();
  const { mutateAsync: createFavorite } = useCreateFavorite();
  const { mutateAsync: deleteFavorite } = useDeleteFavorite();

  return {
    renameDisk,
    deleteDisk,
    createFavorite,
    deleteFavorite,
    isProcessing: isPending || isDeleting,
  };
};
