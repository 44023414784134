import React from 'react';
import { getConvertedSize } from 'src/helpers';
import classNames from 'classnames';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import { useTranslation } from 'react-i18next';
import './styles.scss';

export const DiskCapacity = () => {
  const { t } = useTranslation();
  const { disk } = useDiskContext();

  const isDiskAlert = disk.filled_volume / disk.total_volume > 0.8;

  return (
    <div className="disk-capacity">
      <div className="disk-capacity__labels">
        <span>{t('occupancy')}</span>
        <span>{t('diskCapacity')}</span>
      </div>
      <div className="disk-capacity__progress">
        <div
          className={classNames('disk-capacity__progress-bar', { red: isDiskAlert })}
          style={{ width: `${(disk.filled_volume / disk.total_volume) * 100}%` }}
        ></div>
      </div>
      <div className="disk-capacity__values">
        <span>{getConvertedSize(disk.filled_volume)}</span>
        <span>{getConvertedSize(disk.total_volume)}</span>
      </div>
    </div>
  );
};
