import React, { FC } from 'react';
import {
  ArrowDownIcon,
  DeleteIcon,
  GridIcons,
  ListIcon,
  RestoreIcon,
  SearchIcon,
  SideIcon,
  SortIcon,
  TotalCommander,
} from 'src/components/Icons';
import { DropDownItem } from 'src/components/Dropdown/types';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import classNames from 'classnames';
import { CreateFolderForm } from 'src/components/Header/components/CreateFolderForm';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UploadFileModal } from 'src/components/Header/components/UploadFileModal';
import { ContextDropdown } from 'src/components/Dropdown/context';
import { useSort } from 'src/hooks/useSort';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import { Breadcrumbs } from 'src/components/Breadcrumbs';
import { FilterTypes } from 'src/components/FilterTypes';
import { MultipleControls } from 'src/components/MultipleControls';
import { Search } from 'src/components/Header/components/Search';
import { Button } from 'src/components/Forms/Button';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import './styles.scss';

type Props = {
  onClickMenu: () => void;
  className?: string;
  hideBreadCrumbs?: boolean;
  isTrash?: boolean;
  onRestoreAllClick?: () => void;
  onDeleteAllClick?: () => void;
};

export const Header: FC<Props> = ({
  onClickMenu,
  className,
  hideBreadCrumbs,
  isTrash,
  onRestoreAllClick,
  onDeleteAllClick,
}) => {
  const { folderId, diskId } = useParams();
  const { t } = useTranslation();
  const { selectedIds } = useGlobalContext();
  const { openModal } = useAppContext();
  const { view, setView } = useDiskContext();
  const { sort, handleSort, getRightIcon } = useSort();

  const items: DropDownItem[] = [
    {
      value: 'name',
      label: t('byName'),
      isActive: sort?.field === 'name',
      onClick: () => handleSort('name'),
      rightIcon: getRightIcon('name'),
    },
    {
      value: 'size',
      label: t('bySize'),
      isActive: sort?.field === 'size',
      onClick: () => handleSort('size'),
      rightIcon: getRightIcon('size'),
    },
  ];

  const onCreateClick = () => {
    openModal({
      content: (
        <CreateFolderForm
          onSave={async () => {
            openModal({ open: false });
          }}
          onClose={() => {
            openModal({ open: false });
          }}
          parentId={folderId || diskId || ''}
        />
      ),
      title: t('createFolder'),
      className: 'create-folder-modal',
    });
  };

  const onUploadCLick = () => {
    openModal({
      content: (
        <UploadFileModal
          diskId={diskId as string}
          folderId={folderId}
          onFinish={async () => {
            openModal({ open: false });
          }}
        />
      ),
      title: t('uploadFiles'),
      className: 'create-folder-modal',
    });
  };

  return (
    <div className={classNames('header', className)}>
      <div className="header__content">
        <div className="header__search show-desktop">
          <Search />
        </div>

        <div className="header__right">
          <div className="header__right-group">
            <div className="header__rounded-button hide-desktop-flex">
              <SearchIcon className="header__search-icon" />
            </div>

            <ContextDropdown
              trigger={
                <div className="header__dropdown-button">
                  {t('sorting')} <ArrowDownIcon className="header__icon-sort" />
                </div>
              }
              items={items}
              id={'sort-desktop'}
              as={'div'}
              className="desktop-sorting"
              leftClick
              offset={{ x: 80, y: 40 }}
            />

            <ContextDropdown
              trigger={
                <div className={classNames('header__rounded-button')}>
                  <SortIcon />
                </div>
              }
              items={items}
              id={'sort-mobile'}
              as={'div'}
              className="mobile-sorting"
              leftClick
              offset={{ x: 80, y: 40 }}
            />
          </div>

          {!isTrash && (
            <>
              <div
                className={classNames('header__rounded-button', {
                  active: view === 'list',
                })}
                onClick={() => {
                  setView('list');
                }}
              >
                <ListIcon />
              </div>
              <div
                className={classNames('header__rounded-button', { active: view === 'icons' })}
                onClick={() => {
                  setView('icons');
                }}
              >
                <GridIcons className={'header__default-icon'} />
              </div>

              <div
                className={classNames('header__rounded-button hide-mobile-flex', {
                  active: view === 'columns',
                })}
                onClick={() => {
                  setView('columns');
                }}
              >
                <TotalCommander className="header__icon-columns" />
              </div>
            </>
          )}
          <div className="header__separator"></div>
          <div className="header__rounded-button">
            <SideIcon onClick={onClickMenu} className={'header__default-icon'} />
          </div>
        </div>
      </div>
      <div className="header__line"></div>
      <div className="header__controls">
        <div className="header__filters-wrapper">
          {!hideBreadCrumbs && <Breadcrumbs />}
          <FilterTypes isTrash={isTrash} />
        </div>
        <div className="flexbox header__cnt-btns">
          <MultipleControls
            isTrash={isTrash}
            onRestoreClick={() => {
              if (onRestoreAllClick) {
                onRestoreAllClick();
              }
            }}
            onTrashDeleteClick={() => {
              if (onDeleteAllClick) {
                onDeleteAllClick();
              }
            }}
          />
          {selectedIds.length === 0 && (
            <div className="flexbox header__btns">
              {isTrash ? (
                <>
                  <Button
                    type="text"
                    content={t('restoreAllFiles')}
                    className="header__btn restore-btn"
                    onClick={() => {
                      if (onRestoreAllClick) {
                        onRestoreAllClick();
                      }
                    }}
                    icon={<RestoreIcon />}
                  />
                  <div className="header__separator"></div>
                  <Button
                    type="text"
                    content={t('deleteAll')}
                    className="header__btn delete-all"
                    onClick={() => {
                      if (onDeleteAllClick) {
                        onDeleteAllClick();
                      }
                    }}
                    icon={<DeleteIcon />}
                  />
                </>
              ) : (
                <>
                  <Button
                    type="text"
                    content={t('create')}
                    className="header__btn"
                    onClick={onCreateClick}
                  />
                  <div className="header__separator"></div>
                  <Button
                    type="text"
                    content={t('upload')}
                    className="header__btn"
                    onClick={onUploadCLick}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
