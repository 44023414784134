import { useParams } from 'react-router-dom';
import { useGetFilteredFolders } from 'src/hooks/useGetFilteredFolders';
import { useGetFilesWithScrolls } from 'src/hooks/useGetFilesWithScrolls';
import { useEffect, useMemo } from 'react';
import { usePrevious } from 'src/hooks/usePrevious';
import { useSort } from 'src/hooks/useSort';
import { FilesPayload, useFileCache } from 'src/api/files';
import { DEFAULT_FILES_PAYLOAD } from 'src/config/constants';

export const useDiskViewState = (isDisabled?: boolean) => {
  const { sortFiles, sortFolder } = useSort();

  const { diskId, folderId } = useParams();
  const {
    data: folders,
    isPending: isPendingFolders,
    isLoading,
    addFolderCache,
    removeFolderCacheGlobal,
    invalidateCache: refetchFolders,
  } = useGetFilteredFolders({ parent_id: folderId || diskId }, isDisabled);

  const filesPayload: FilesPayload = {
    ...DEFAULT_FILES_PAYLOAD,
    parent_id: folderId || diskId || '',
  };

  const { files, containerRef, isPending, fetchNextPage } = useGetFilesWithScrolls(
    filesPayload,
    isDisabled,
  );

  const {
    addFileCache,
    removeFileCacheGlobal,
    invalidateCache: refetchFiles,
    invalidateAllCaches,
  } = useFileCache(filesPayload);

  const isEmptyDisk = useMemo(() => {
    return !folders.length && !files.length;
  }, [folders, files]);

  const prevDiskId = usePrevious(diskId);
  const prevFolderId = usePrevious(folderId);

  useEffect(() => {
    if (prevDiskId !== '' && prevDiskId !== diskId && prevFolderId !== folderId) {
      refetchFolders();
    }
  }, [diskId, folderId]);

  const sortedFolders = useMemo(() => sortFolder(folders), [folders, sortFolder]);
  const sortedFiles = useMemo(() => sortFiles(files), [files, sortFiles]);

  const invalidateCache = () => {
    refetchFiles();
    refetchFolders();
  };

  return {
    folders: sortedFolders,
    files: sortedFiles,
    isPendingFolders,
    isPendingFiles: false,
    isEmptyDisk,
    isLoading: isLoading || isPending,
    removeFileCache: removeFileCacheGlobal,
    addFileCache,
    addFolderCache,
    removeFolderCache: removeFolderCacheGlobal,
    containerRef,
    invalidateCache,
    fetchNextPage,
    invalidateAllCaches,
  };
};
