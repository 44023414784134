import React, { FC, useEffect, useState } from 'react';
import { useGetDisks } from 'src/hooks/useGetDisks';
import { useNavigate, useParams } from 'react-router-dom';
import { DiskRow } from 'src/components/NotificationsSection/components/DisksDropdown/components/diskRow';
import { ReactComponent as OpenIcon } from './assets/chevrone-down.svg';
import { ReactComponent as SelectedIcon } from './assets/selected-icon.svg';
import classNames from 'classnames';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import { DiskType } from 'src/types';
import { useOutside } from 'src/hooks/useOutside';
import './styles.scss';

type Props = {
  onSelect: (id: string) => void;
};

export const DisksDropdown: FC<Props> = ({ onSelect }) => {
  const { diskId } = useParams();

  const [selectedDisk, setSelectedDisk] = useState<string>(diskId || 'all');

  const [selectedAll, setSelectedAll] = useState(false);

  const [showDisks, setShowDisks] = useState(false);
  const { disks } = useGetDisks();
  const currentDisk = diskId ? disks.find((disk) => disk.id === selectedDisk) : disks[0];

  const { panelRef } = useOutside(() => {
    setShowDisks(false);
  });

  const onDiskClick = (disk: DiskType) => {
    /*    setDisk(disk);
    navigate(`/disk/${disk.id}`);*/
    setSelectedDisk(disk.id);
    setShowDisks(false);
    onSelect(disk.id);
  };

  const onSelectAllDisks = () => {
    onSelect('all');
    setSelectedDisk('all');
    setSelectedAll(true);
    setShowDisks(false);
  };

  useEffect(() => {
    setSelectedAll(false);
  }, [diskId]);

  return (
    <div
      className={classNames('disks-dropdown', { 'disks-dropdown__opened': showDisks })}
      ref={panelRef}
    >
      {currentDisk && !selectedAll && (
        <DiskRow
          disk={currentDisk}
          onClick={() => {
            setShowDisks(!showDisks);
          }}
          icon={<OpenIcon className={classNames({ 'disks-dropdown__opened-icon': showDisks })} />}
        />
      )}
      {selectedAll && (
        <div
          className="disks-dropdown__all-disks"
          onClick={() => {
            setShowDisks(!showDisks);
          }}
        >
          <span>All disks</span>
          <OpenIcon className={classNames({ 'disks-dropdown__opened-icon': showDisks })} />
        </div>
      )}

      <div className="disks-dropdown__list">
        {showDisks && (
          <div className="disks-dropdown__list-content">
            <div className="disks-dropdown__all-disks" onClick={onSelectAllDisks}>
              <span>All disks</span>
              {selectedAll ? <SelectedIcon /> : null}
            </div>
            {disks.map((disk) => {
              return (
                <DiskRow
                  disk={disk}
                  key={disk.id}
                  onClick={() => {
                    onDiskClick(disk);
                    setSelectedAll(false);
                  }}
                  icon={disk.id === selectedDisk && !selectedAll ? <SelectedIcon /> : null}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
