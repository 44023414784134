export const configLinks = (t: (st: string) => string) => [
  {
    title: t('aboutUmi'),
    links: [
      {
        title: t('faq'),
        url: '/faq',
      },
      {
        title: t('partnership'),
        url: '/',
      },
      {
        title: t('forMedia'),
        url: '/',
      },
    ],
  },
  {
    title: t('products'),
    links: [
      {
        title: t('mobileApp'),
        url: '/',
      },
      {
        title: t('forComputers'),
        url: '/',
      },
      {
        title: t('webVersion'),
        url: '/',
      },
    ],
  },
  {
    title: t('infrastructure'),
    links: [
      {
        title: t('api'),
        url: '/',
      },
      {
        title: t('translations'),
        url: '/',
      },
      {
        title: t('instantView'),
        url: '/',
      },
    ],
  },
  {
    title: t('development'),
    links: [
      {
        title: t('jobs'),
        url: '/',
      },
      {
        title: t('focusGroup'),
        url: '/',
      },
      {
        title: t('askAQuestion'),
        url: '/',
      },
    ],
  },
];
