import React from 'react';
import { TermsType } from 'src/pages/auth/components/LoginSection/components/Terms/types';

export const privacyNotice: TermsType = {
  title: 'PRIVACY NOTICE',
  items: [
    {
      title: '',
      content: (
        <>
          This Privacy Notice went into effect on 01/07/2023. <br />
          <br />
          This Privacy Notice (hereafter referred to as – ‘Privacy Notice’) explains Our approach to
          Personal Data collection, storage and use on the Website and on the App, as well as Your
          rights to Personal Data.
        </>
      ),
    },
    {
      title: 'I. DEFINITIONS',
      content: (
        <>
          1.1. Within the Privacy Notice we refer to the listed below notions and mean by them as
          defined: <br />
          <b>‘Website’</b> – Our website which is available at https://www.monobox.tech/. <br />
          <b>‘App’</b> – Our mobile application which is available on Google Play Market or/and
          Apple App Store under the name ‘Monobox’. <br />
          <b>‘the Services’</b> – services provided by Monobox, which are available on the Website
          or App. <br />
          <b>‘Monobox’</b> (also <b>‘we’</b>, <b>‘our’</b>, <b>‘us’</b>) – controller of the user’s
          personal data. <br />
          <b>‘User’</b> (also <b>‘you’</b>, <b>‘your’</b>) – any natural person that uses the
          Website and/or an App. <br />
          <b>‘Personal data’</b> – any information relating to the User who can be identified,
          directly or indirectly, in particular by reference to an identifier. <br />
          <b>‘processing’</b> – any act when we collect, store, record, structure, adapt, use, make
          available, erase, restrict access to your Personal data. <br />
          <b>‘content’</b> - any files, documents, data, photos, comments, messages, contacts, and
          so on you provide us with by uploading to or using our Services. <br />
          1.2. All other terms used herein are defined under the General Data Processing Regulation
          (GDPR).
        </>
      ),
    },
    {
      title: 'II. CONTROLLER',
      content: (
        <>
          2.1. The controller is the entity which determines the purposes and means of the
          processing of user’s personal data. <br />
          2.2. The controller of the user’s personal data is legal entity LLC MONOBOX, registered
          under the laws of Ukraine, registration number: 45149949, registered office address:
          Ukraine, 02002, Kyiv, Andriy Abolmasov street, house 7, apartment 446; email:
          info@monobox.tech.
        </>
      ),
    },
    {
      title: 'III. PURPOSES AND BASIS OF THE PROCESSING',
      content: (
        <>
          3.1. Personal data is any information relating to an identified or identifiable user, in
          particular by reference to an identifier such as a name, an identification number,
          location data, an online identifier etc. <br />
          3.2. Personal data processed. We collect and process user information such as <br />
          &nbsp;&nbsp;a) account information (first, middle and last name, e-mail address, telephone
          number, contact and billing address (including country and/or state and national ID
          number); <br />
          &nbsp;&nbsp;b) payment information (including credit card information, billing details);{' '}
          <br />
          &nbsp;&nbsp;c) your content (files, documents, data, photos, comments, messages, contacts,
          and so on); <br />
          &nbsp;&nbsp;d) your contacts (identifiers of other users you added to your group chats,
          you send messages or comments to, and invite to use the Services); <br />
          &nbsp;&nbsp;e) usage information (information related to how you use the Services,
          including actions you take in your account (like sharing, editing, viewing, creating and
          moving files or folders or group chats, and sending and receiving electronic signature
          requests and other transactions); <br />
          &nbsp;&nbsp;f) device information (e.g. IP-address, browser settings, operational system,
          identifiers associated with your devices and other technical information); <br />
          &nbsp;&nbsp;g) cookies (including analytical, marketing); <br />
          &nbsp;&nbsp;h) content analytics (information on how you interact with the viewed content,
          such as the date and time you view the content or used the Services, the number of times
          and length of time you view the content or used the Services, and which portions of the
          content you view or how you used the Services); <br />
          &nbsp;&nbsp;i) marketing (if you use our Services, we will send you information about
          upgrades when permissible. Users who receive these marketing materials can opt out at any
          time. If you don’t want to receive a particular type of marketing material from us, click
          the ‘unsubscribe’ link in the corresponding emails). <br />
          3.3. We may process any other personal data that is provided voluntarily by the user. The
          user is not obliged to provide personal data by law or under any agreement, however, our
          Services might not be available or might be impossible to be used without some types of
          personal data. <br />
          3.4. Purposes of the processing. The personal data received are processed for the purposes
          of: <br />
          &nbsp;&nbsp;a) provision of the Services via website and/or app in a reliable and secure
          manner; <br />
          &nbsp;&nbsp;b) user registration for use the Services; <br />
          &nbsp;&nbsp;c) to perform support regarding user’s use of the Services; <br />
          &nbsp;&nbsp;d) to provide, improve, and promote our Services, and protect our users.{' '}
          <br />
          &nbsp;&nbsp;e) researching the behavior and use of the website and/or app by the user,
          saving and adjusting the information on the website and/or app according to your
          preferences, studying the effectiveness of the website and/or app. <br />
          3.5. Legal basis for the processing. The legal basis for the processing is: <br />
          &nbsp;&nbsp;a) performance of a contract to which the user is a party or in order to take
          steps at the request of the user prior to entering into a contract; <br />
          &nbsp;&nbsp;b) legitimate interests and business needs to improve our website and/or app,
          make the website and/or app usable by enabling basic functions; <br />
          &nbsp;&nbsp;c) user’s consent to the processing for purposes of collecting statistics,
          storing preferences, tracking visitors use of the website and/or app and display relevant
          advertisements. <br />
          3.6. Retention period. We retains and processes user’s personal data as long as it is
          necessary to fulfill the purposes defined and the basis of the processing activity exists
          plus three years, if longer term is not stipulated by the law, or until a request to
          delete the personal data is received. We will also retain and use your data for as long as
          necessary to resolve disputes and/or enforce our rights and agreements. <br />
          3.7. Child privacy. We neither solicit nor collect personal data from anyone under the age
          of 16 (sixteen) or under the legal age of your country if it is different unless under the
          agreement of parents or lawful guardians. If you are a parent or a legal guardian of an
          underage child, and you know that the child has provided us with their personal
          information without your consent, please contact us on: info@monobox.tech It is forbidden
          to enter personal data of underage users without the agreement of parents or lawful
          guardians.
        </>
      ),
    },
    {
      title: 'IV. USE OF COOKIES',
      content: (
        <>
          4.1. On the website we use several types of cookies files based on the purpose they are
          used for (necessary, preference, statistic, marketing and other types of cookies). Cookies
          are small text files placed on your computer when you visit the website. We use cookie
          files for the correct display of the website’s content and for the convenience of browsing
          the website. Cookie files help the website to remember information about you, which will
          be useful in the next visit. Thanks to cookie files, the website’s browsing becomes much
          more convenient. You can learn more about these files in our Cookies Policy. You can set
          up cookies reception and blocking in your browser yourself. Inability to receive cookie
          files may limit the functionality of the website.
        </>
      ),
    },
    {
      title: 'V. PROCESSING OF PERSONAL DATA',
      content: (
        <>
          5.1. Your personal data is collected, when you consciously provide it to the website
          and/or app (e.g. when completing an online form, uploading the content) or we collect it
          by observation (e.g. using automated data capturing software). We do not collect personal
          data from publicly accessible sources. <br />
          5.2. We are processing user’s personal data by automated means. The personal data is kept
          in electronic form on the website, on app, and on computers, access to which only
          authorized our employees and contractors has. <br />
          5.3. <b>Automated decision-making.</b> Your personal data is not subject for automated
          decision-making, including profiling. However, third-party cookies are used for analyses
          of your interests (advertisement preferences). <br />
          5.4. <b>Secure.</b> We have a team dedicated to keeping your information secure and
          testing for vulnerabilities. The data we receive, shall be accessed by our authorized
          employees only. We continue to work on features to keep your information safe in addition
          to things like two-factor authentication, encryption of files. We regularly reviewing our
          data collection, storage, and processing practices, including our physical security
          measures, to prevent unauthorized access to our systems. <br />
          The data center provides customers with direct access to the main Internet traffic
          exchange points in Frankfurt - DE CIX and ECIX. It ensures secure, high-quality
          connections with ultra-low latency - between the cloud and network providers, digital
          media companies, and enterprises. The data center certifications include ISO 27001, ISO
          140001:2004, ISO 50001, ISO 9001:2008, PCI DSS, and OHSAS 18001. The data center offers a
          reliable environment for hosting equipment and adheres to the most stringent industry
          requirements as per the Uptime Institute Tier IV standard. The Building Management Systems
          (BMS) facilitates control over electronic, mechanical systems, fire extinguishing, and
          error detection systems. Physical security encompasses a contactless access card system
          and 24x7 security staff. Electronic security systems in place include card readers, RFID
          cards, PIN code + card readers, video surveillance, and motion detection sensors. <br />
          5.5. Data storage. To provide you with the Services, we may store, process, and transmit
          data in locations around the world, including those outside your country. Data may also be
          stored locally on the devices you use to access the Services. <br />
          5.6. Our website may contain links to other websites, which are not under our control. We
          are not responsible for the content and/or processing of personal data of these websites.
          We recommend you familiarize yourself with the privacy notice of every website that you
          visit, if such notice exists.
        </>
      ),
    },
    {
      title: 'VI. PERSONAL DATA TRANSFER',
      content: (
        <>
          6.1. <b>Recipients.</b> Your personal data may be transferred to such categories of
          processors (subprocessors): <br />
          &nbsp;&nbsp;a) our contractors and subcontractors (marketing manager, IT specialist,
          customer support manager, administrator etc.), those operating within the jurisdiction
          outside the European Economic Area, particularly in Ukraine; <br />
          &nbsp;&nbsp;b) hosting providers; <br />
          &nbsp;&nbsp;c) other users you interact with in your group chats, you send messages or
          comments to, you share files, folders and archives with, and invite to use the Services;{' '}
          <br />
          &nbsp;&nbsp;d) third parties that perform data collecting, independent from us, including
          Google Analytics, Google AdSense. <br />
          6.2. Those processors perform tasks on our behalf and are contractually obliged not to
          disclose or use collected information for any other purposes, other than specified in this
          Privacy Notice. <br />
          6.3. We do not pass any personal data to other organizations or services, which are not
          listed in this Privacy Notice. As an exception, the collected data may be provided on a
          lawful request of state authorities, that are authorized to request such information.{' '}
          <br />
          6.4. <b>International transfer.</b> In case, where your personal data is transferred
          outside the EEA, We enforces the appropriate legal documents that ensure the safeguard of
          personal data, particularly, the Standard Contractual Clauses.
        </>
      ),
    },
    {
      title: 'VII. USER RIGHTS',
      content: (
        <>
          7.1. User has the right: <br />
          &nbsp;&nbsp;a) to access. You are entitled to see the personal data held about you. You
          have the right to request a copy of the personal data that we hold about you. <br />
          &nbsp;&nbsp;b) to rectification. If your personal data is inaccurate, you have the right
          to have the data changed. We encourage you to contact us to let us know if any of your
          personal data is not accurate or changes, so that we can keep your personal data
          up-to-date. <br />
          &nbsp;&nbsp;c) to objection. In certain circumstances, you also have the right to object
          to processing of your personal data and to ask us to block, erase and restrict your
          personal data. <br />
          &nbsp;&nbsp;d) to data portability. You have the right to request that some of your
          personal data is provided to you, or to another data controller, in a commonly used,
          machine-readable format. <br />
          &nbsp;&nbsp;e) to erasure. You have the right to ask or require us to erase your personal
          data when the personal data is no longer necessary for the purposes for which it was
          collected, or when, among other things, your personal data have been unlawfully processed.{' '}
          <br />
          &nbsp;&nbsp;f) to restriction. You can limit the way we use your data. This is an
          alternative to requesting the erasure of your data. <br />
          &nbsp;&nbsp;g) to withdraw consent. When we rely on your consent for the processing of
          your personal data (particularly, when cookies are processed), you have the right to
          withdraw your consent at any time (e.g. by deleting or blocking cookies). However, the
          withdrawal of your consent will not affect the lawfulness of the Our processing based on
          consent acting prior to the withdrawal. <br />
          &nbsp;&nbsp;h) to file complaints. If you believe that your data protection rights may
          have been breached, you have the right to lodge a complaint with the applicable
          supervisory authority, or to seek a remedy through the courts. <br />
          7.2. You may, at any time, exercise any of the above rights, by contacting
          info@monobox.tech together with a proof of your identity.
        </>
      ),
    },
    {
      title: '8. INCIDENTS NOTIFICATION',
      content: (
        <>
          8.1. If we become aware of any breach of our security leading to the accidental or
          unlawful destruction, loss, alteration or unauthorized disclosure of, or access to
          (excluding unsuccessful attempts or activities) personal data of user on systems managed
          or otherwise controlled by us, we will notify you promptly and without undue delay and in
          compliance to the procedure prescribed under respective the GDPR and applicable laws.{' '}
          <br />
          8.2. The notification will be made to your email address. It is the sole responsibility of
          the user to provide us with the email address and ensure that this email address is valid
          and current. <br />
          8.3. None of the data incidents notification from us may not be and will not be construed
          as an acknowledgment of any fault or liability with respect to data incidents by us.
        </>
      ),
    },
    {
      title: '9. OTHER PROVISIONS',
      content: (
        <>
          9.1. This Privacy Notice may be updated in the future to comply with national and
          international laws regulating the protection of personal data and will post the most
          current version on our website. If a revision meaningfully reduces your rights, we will
          notify you. If your personal data, and more specifically, your contact information was
          entered incorrectly, we will not be able to contact you. <br />
          9.2. If you require any additional information or have any further questions concerning
          this Privacy Notice, please contact us at info@monobox.tech or by filling a contact form
          specified in a corresponding section of our website. <br />
          9.3. If you do not agree with this Privacy Notice, you cannot use the Services. In this
          case you should avoid visiting our website and app.
        </>
      ),
    },
  ],
};
