import { API } from 'src/utils/API';
import { useMutation } from '@tanstack/react-query';
import { base64ToBlob } from 'src/helpers';

export const useUploadAvatar = () => {
  const sendData = async (payload: any) => {
    const resp = await API.post('/users/avatar', base64ToBlob(payload), 'auth', {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (resp.success) {
      return {
        success: true,
        profile: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 1,
  });
};
