import React, { useState } from 'react';
import moment from 'moment';
import { ArrowTopIcon, WalletIcon } from 'src/components/Icons';
import classNames from 'classnames';
import { ReactComponent as NotificationIcon } from '../../assets/notification-icon.svg';
import { useGetNotifications, useReadNotification } from 'src/api/notifications';
import { NotificationType } from 'src/types';
import { useTranslation } from 'react-i18next';
import { get, groupBy } from 'lodash';
import 'moment/locale/ru';
import 'moment/locale/fr';
import 'moment/locale/uk';
import './styles.scss';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { NotificationsList } from 'src/components/NotificationsSection/components/Notifications/nogiticationsList';

type Props = {
  type: 'notification' | 'operation';
  diskId: string;
};

const groupNotificationsByDate = (notifications: NotificationType[]): NotificationType[] => {
  let groupedNotifications: NotificationType[] = [];
  const notificationMap = new Map<string, NotificationType[]>();

  notifications.forEach((notification) => {
    const date = moment.utc(notification.created_at).format('YYYY-MM-DD HH');
    if (!notificationMap.has(date)) {
      notificationMap.set(date, [notification]);
    } else {
      const notifications = notificationMap.get(date)!;
      notifications.push(notification);
      notificationMap.set(date, notifications);
    }
  });

  notificationMap.forEach((notifications) => {
    const groupedByType = groupBy(notifications, 'type');
    const result: NotificationType[] = [];

    Object.keys(groupedByType).forEach((type) => {
      const notifications = groupedByType[type];
      result.push({
        ...notifications[0],
        count: notifications.length,
        total_size: notifications.reduce(
          (acc, notification) => acc + notification?.metadata?.total_size || 0,
          0,
        ),
      });
    });

    groupedNotifications = [...groupedNotifications, ...result];
  });

  return groupedNotifications;
};

export const Notifications: React.FC<Props> = ({ type, diskId }) => {
  const { openModal } = useAppContext();
  const { t, i18n } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  moment.locale(i18n.language);

  const { data, refetch } = useGetNotifications({
    disabled: false,
    limit: 100,
    offset: 0,
    drive_id: diskId,
    instanceType: type,
  });

  const { mutateAsync } = useReadNotification(type);
  const notificationsItems: NotificationType[] = groupNotificationsByDate(
    get(data, 'notifications', []),
  );

  //console.log(notificationsItems);

  const onNotificationClick = (notification: NotificationType) => {
    /*openModal({
      open: true,
      title: t(notification.type),
      content: <NotificationModal type={notification.type} instanceType={type} />,
      width: '440px',
      className: 'notification-modal-wrapper',
    });*/
  };

  const onReadAll = async () => {
    const toRead = notificationsItems.reduce((acc: string[], notification: any) => {
      if (!notification.is_read) {
        acc.push(notification.id);
      }
      return acc;
    }, []);

    if (toRead.length > 0) {
      await mutateAsync(toRead);
      await refetch();
    }
  };
  return (
    <div className="notifications-list">
      <div className="notifications-list__title">
        <div className="notifications-list__title--content">
          {type !== 'notification' && <WalletIcon className="operations__title-icon" />}
          {type === 'notification' && (
            <NotificationIcon className="notifications-list__title-icon" />
          )}
          <span>{type === 'notification' ? t('notifications') : t('recentOperations')}</span>
        </div>

        <div className="notifications-list__read-all" onClick={onReadAll}>
          {t('readAll')}
        </div>

        {notificationsItems.length > 0 && (
          <div className={classNames('notifications-list__toggle', { reverted: isCollapsed })}>
            <ArrowTopIcon onClick={() => setIsCollapsed(!isCollapsed)} />
          </div>
        )}
      </div>
      <NotificationsList
        isCollapsed={isCollapsed}
        notificationsItems={notificationsItems}
        onNotificationClick={onNotificationClick}
        type={type}
      />
    </div>
  );
};
