import { useDrop } from 'react-dnd';
import { FileType, FolderType } from 'src/types';
import { useCallback } from 'react';
import { useDragState } from 'src/pages/diskPage/hooks/useDragState';
import { useParams } from 'react-router-dom';

export const useFolderDrop = (
  folderId: string,
  onDropHandler: (destinationId: string, ids: string[]) => void,
) => {
  const { diskId } = useParams();
  const parent_id = folderId || diskId || '';

  const { selectedIds, clearSelectedIds } = useDragState();

  const onDrop = useCallback(
    (data: { files: FileType[]; folders: FolderType[] }) => {
      const ids = data.files
        .filter((f) => f.parent_id !== parent_id)
        .map((f) => f.id)
        .concat(data.folders.filter((f) => f.parent_id !== parent_id).map((f) => f.id))
        .filter((id) => id !== parent_id);

      onDropHandler(folderId, ids);
    },
    [folderId, selectedIds, clearSelectedIds],
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ['file', 'folder'],
      drop: onDrop,
      canDrop: (item, monitor) => {
        return monitor.isOver({ shallow: true });
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
      }),
    }),
    [folderId, parent_id, selectedIds],
  );

  return {
    drop,
    isOver,
    canDrop,
  };
};
