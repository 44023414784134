import React from 'react';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { Subscription } from 'src/components/Subscription/index';

export const useSubscription = () => {
  const { setRightMenuState, rightMenuState } = useAppContext();
  const openSubscriptionArea = () => {
    setRightMenuState({
      ...rightMenuState,
      open: !rightMenuState.open,
      content: <Subscription />,
      width: 400,
    });
  };

  return {
    openSubscriptionArea,
  };
};
