import React, { useEffect } from 'react';

export const useBlockState = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setVisible(true);
      }, 300);
    } else {
      setVisible(false);
    }
  }, [isOpen]);

  const onHeaderClick = () => {
    setIsOpen(!isOpen);
  };

  return {
    onHeaderClick,
    visible,
    isOpen,
  };
};
