import React, { FC } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { ReactComponent as CarouselNav } from './assets/carousel-nav.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './styles.scss';

export type Slide = {
  title: string;
  subtitle: string;
  description: string;
  image: string;
};

type Props = {
  slides: Slide[];
};

export const CarouselComponent: FC<Props> = ({ slides }) => {
  return (
    <div className="carousel-comp">
      <Carousel
        showThumbs={false}
        infiniteLoop
        useKeyboardArrows
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <div className="carousel-comp__prev" onClick={onClickHandler}>
              <CarouselNav className="carousel-comp__carousel-nav" />
            </div>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <div className="carousel-comp__next" onClick={onClickHandler}>
              <CarouselNav className="carousel-comp__carousel-nav carousel-comp__carousel-nav--next" />
            </div>
          )
        }
      >
        {slides.map((slide, index) => (
          <div key={index} className="carousel-comp__slide">
            <p className="carousel-comp__slide-title">{slide.title}</p>
            <img src={slide.image} alt={slide.title} />
            <div className="carousel-comp__slider-content">
              <h3 className="carousel-comp__slider-content-title">{slide.subtitle}</h3>
              <p className="carousel-comp__slider-content-text">{slide.description}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
