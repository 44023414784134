import React, { FC } from 'react';
import './styles.scss';
import AvatarUploader from './AvatarUploader';

type Props = {
  onClose: () => void;
  url: string;
  event?: React.ChangeEvent<HTMLInputElement>;
  onChange: (data: string) => void;
};
export const UploadAvatarModal: FC<Props> = ({ onClose, url, onChange, event }) => {
  return (
    <div className="upload-avatar">
      <div className="upload-avatar__wrapper">
        <AvatarUploader e={event} onChange={onChange} onClose={onClose} />
      </div>
    </div>
  );
};
