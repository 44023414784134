import React, { FC, useEffect, useState } from 'react';
import './styles.scss';
import { BurgerIcon, CloseIcon } from 'src/components/Icons';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

type Props = {
  children?: React.ReactNode;
  leftSidebar?: React.ReactNode;
};

export const Layout: FC<Props> = ({ children, leftSidebar }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [open]);

  return (
    <div className={classNames('layout', { open })}>
      <div className={classNames('layout__left-sidebar', { open })}>
        <div className={classNames('layout__burger', { open })} onClick={() => setOpen(true)}>
          <BurgerIcon />
        </div>
        <div className="close-section show-tablet">
          <CloseIcon onClick={() => setOpen(false)} />
        </div>

        {/* <div className="close-section hide-tablet">
          <CloseIcon onClick={() => navigate('/')} />
        </div>*/}

        <div className="layout__left-sidebar--content">{leftSidebar}</div>
      </div>
      <div className={classNames('layout__content', { open })}>{children}</div>
    </div>
  );
};

export default Layout;
