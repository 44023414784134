import React, { CSSProperties, FC, useState } from 'react';
import { Button } from 'src/components/Forms/Button';
import PinInput from 'src/components/Forms/PinInput';
import { useTranslation } from 'react-i18next';
import { SmsTimer } from 'src/pages/auth/components/SmsTimer';

const MAX_RESEND_TIME = 60;
type Props = {
  onChange: (code: string) => void;
  onClick: () => void;
  isPending: boolean;
  error?: string;
  onClickResend: () => void;
  style?: CSSProperties;
  resend_time?: number;
};

export const OtpForm: FC<Props> = ({
  onChange,
  onClick,
  isPending,
  style,
  error,
  onClickResend,
  resend_time = MAX_RESEND_TIME,
}) => {
  const [timeResend, setTimeResend] = useState(resend_time);

  const { t } = useTranslation();
  const [showSendSms, setShowSendSms] = useState(false);

  const onCLickResendHandler = () => {
    onClickResend();
    setTimeResend(resend_time);
  };

  return (
    <>
      <div className="auth__row mt-27" style={style}>
        <PinInput
          length={4}
          label={t('enterCodeFromSMS')}
          onComplete={onChange}
          errorMessage={error}
        />
      </div>
      <div className="auth__row auth__row--action mb-30">
        <Button
          content={t('enter')}
          type="primary"
          className="auth__action-button"
          onClick={onClick}
          isDisabled={isPending}
          fullWidth
        />
      </div>
      <div className="auth__row">
        <SmsTimer
          text={t('smsHasBeenSent')}
          time={timeResend}
          onFinish={() => {
            setShowSendSms(true);
            setTimeResend(0);
          }}
        ></SmsTimer>
      </div>

      {showSendSms && (
        <div className="auth__row auth__row--action mb-50">
          <Button
            content={t('sendingSMSWithTheCodeAgain')}
            type="basic"
            className=""
            onClick={onCLickResendHandler}
            isDisabled={timeResend > 0}
            fullWidth
          />
        </div>
      )}
    </>
  );
};
