import React, { FC } from 'react';
import { getConvertedSize } from 'src/helpers';
import { useTranslation } from 'react-i18next';
import { DiskDetailsType, DiskType } from 'src/types';
import classNames from 'classnames';
import { CloseIcon } from 'src/components/Icons';
import { DiskObject } from 'src/components/DiskObject';
import { filterIcons } from 'src/components/FilterTypes/config';
import { Button } from 'src/components/Forms/Button';
import { ReactComponent as CleanIcon } from './assets/clean-icon.svg';
import { ReactComponent as StorageIcon } from './assets/storage-icon.svg';
import { ReactComponent as SubscriptionIcon } from './assets/subscription-icon.svg';
import { ReactComponent as PerformanceIcon } from './assets/performance-icon.svg';
import { ReactComponent as CapacityIcon } from './assets/capacity-icon.svg';
import { ReactComponent as CostIcon } from './assets/cost-icon.svg';
import { ReactComponent as TypeIcon } from './assets/type-icon.svg';
import { useDiskManage } from 'src/hooks/useDiskManage';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { useClearDisk, useGetDiskDetails, useLoadDisks2 } from 'src/api/disks';
import './styles.scss';
import '../infoComponent.scss';
import { parametersOptions } from 'src/components/CreateDisk/DiskParameters/config';

type Props = {
  disk: DiskType;
  className?: string;
  onClose?: () => void;
};

export const NewDiskInfo: FC<Props> = ({ disk, className, onClose }) => {
  const { openConfirmation } = useAppContext();
  const { editDisk } = useDiskManage();
  const { invalidateCache } = useLoadDisks2({ disabled: true });

  const { data: details } = useGetDiskDetails(disk.id);
  const diskDetails: DiskDetailsType = details?.data || {};

  const { mutateAsync: clearDisk } = useClearDisk();
  const { t } = useTranslation();
  const statistics = disk.full_statistics || [];

  const onClearDisk = () => {
    openConfirmation({
      open: true,
      content: t('clearDiskMessage'),
      onSure: async () => {
        await clearDisk(disk.id);
        invalidateCache();
        openConfirmation({
          open: false,
        });
      },
    });
  };

  const getPrice = () => {
    return +diskDetails.cost_per_gigabyte * (disk.total_volume / 1000000000);
  };

  const getType = () => {
    const type = diskDetails.storage_type || 'standard';
    return parametersOptions(t).diskParameters.find((param) => param.value === type)?.label;
  };

  return (
    <div className={'info-component'}>
      <div className="right-title">
        <span>{t('diskInformation')}</span>
        <CloseIcon className="right-title__close" onClick={onClose} />
      </div>
      <div className={classNames('diskInfo', className)}>
        <div className="diskInfo__disk-wrapper">
          <DiskObject disk={disk} />
        </div>

        <div className="info-component__section">
          <div className="info-component__section-header">
            <span>{t('diskParameters')}</span>
          </div>
          <div className="info-component__section-content">
            <div className="info-component__section-item">
              <div className="info-component__parameters">
                <div className="info-component__parameter">
                  <StorageIcon />
                  <span>{`${t('storage')}: AWS`}</span>
                </div>
                <div className="info-component__parameter">
                  <SubscriptionIcon />
                  <span>{t('subscription')}:</span>
                  <span className="highlighted">{t('annually')}</span>
                </div>
                <div className="info-component__parameter">
                  <PerformanceIcon />
                  <span>{getType()}</span>
                </div>
                <div className="info-component__parameter">
                  <CapacityIcon />
                  <span>{t('capacity')}:</span>
                  <span className="highlighted">{getConvertedSize(disk.total_volume)}</span>
                </div>
                <div className="info-component__parameter">
                  <CostIcon />
                  <span>{t('cost')}:</span>
                  <span className="highlighted">${getPrice()}</span>
                </div>
                <div className="info-component__parameter">
                  <TypeIcon />
                  <span>{t('type')}:</span>
                  <span className="highlighted">HDD</span>
                </div>
              </div>
            </div>
            <div className="info-component__section-item">
              <Button
                type="primary"
                content={disk.is_blocked ? 'Pay for disk' : 'Settings'}
                fullWidth
                onClick={() => editDisk(disk)}
              />
            </div>
          </div>
        </div>

        <div className="info-component__section">
          <div className="info-component__section-header">
            <span>{t('totalVolume')}</span>
            <span>
              {getConvertedSize(disk.filled_volume)}/{getConvertedSize(disk.total_volume)}
            </span>
          </div>
          <div className="info-component__section-content">
            {statistics
              .filter((stat) => stat.count > 0)
              .map((stat, key) => {
                return (
                  <div key={key} className="info-component__section-item">
                    <div className="info-component__category">
                      <div className="info-component__category-icon">{filterIcons[stat.type]}</div>
                      {stat.type.toLowerCase()}:
                    </div>
                    <div className="info-component__size">
                      {statistics[key].count}/{getConvertedSize(statistics[key].size)}
                    </div>
                  </div>
                );
              })}
            <div className="info-component__section-item">
              <Button
                type="primary"
                content="Disk cleanup"
                fullWidth
                icon={<CleanIcon />}
                onClick={onClearDisk}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
