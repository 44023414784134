import React from 'react';
import { ReactComponent as DriveDeletedIcon } from './assets/notifications/drive_deleted.svg';
import { ReactComponent as DriveCreatedIcon } from './assets/notifications/drive_created.svg';
import { ReactComponent as FileUploadedIcon } from './assets/notifications/file_uploaded.svg';
import { ReactComponent as FileDownloadedIcon } from './assets/notifications/file_downloaded.svg';
import { ReactComponent as FileMovedIcon } from './assets/notifications/files_moved.svg';
import { ReactComponent as DeletingFileIcon } from './assets/notifications/deleting_file.svg';
import { ReactComponent as ItemRenamedIcon } from './assets/notifications/item_renamed.svg';
import { ReactComponent as InvoiceSucceededIcon } from './assets/notifications/invoice_succeeded.svg';
import { ReactComponent as InvoiceFailedIcon } from './assets/notifications/invoice_failed.svg';
import { ReactComponent as SubscriptionUnpaidIcon } from './assets/notifications/subscription_unpaid.svg';
import { ReactComponent as SubscriptionRestoredIcon } from './assets/notifications/subscription_restored.svg';
import { ReactComponent as WelcomeIcon } from './assets/notifications/welcome_icon.svg';
import { ReactComponent as TrashedClearedIcon } from './assets/notifications/trashed_cleared.svg';
import { ReactComponent as UpdateUplicationIcon } from './assets/notifications/app_update_available.svg';

type NotificationConfigType = {
  message: string;
  icon: JSX.Element;
};
export const notificationsConfig = (
  t: (str: string) => string,
): { [index: string]: NotificationConfigType } => ({
  drive_deleted: {
    message: t('drive_deleted'),
    icon: <DriveDeletedIcon />,
  },
  drive_created: {
    message: t('drive_created'),
    icon: <DriveCreatedIcon />,
  },
  folder_created: {
    message: t('folder_created'),
    icon: <DriveCreatedIcon />,
  },
  file_uploaded: {
    message: t('file_uploaded'),
    icon: <FileUploadedIcon />,
  },
  file_downloaded: {
    message: t('file_downloaded'),
    icon: <FileDownloadedIcon />,
  },
  file_moved: {
    message: t('file_moved'),
    icon: <FileMovedIcon />,
  },
  file_deleted: {
    message: t('file_deleted'),
    icon: <DeletingFileIcon />,
  },
  item_renamed: {
    message: t('item_renamed'),
    icon: <ItemRenamedIcon />,
  },
  drive_cleared: {
    message: t('drive_cleared'),
    icon: <DriveDeletedIcon />,
  },
  item_deleted: {
    message: t('item_deleted'),
    icon: <DeletingFileIcon />,
  },
  invoice_succeeded: {
    message: t('invoice_succeeded'),
    icon: <InvoiceSucceededIcon />,
  },
  invoice_failed: {
    message: t('invoice_failed'),
    icon: <InvoiceFailedIcon />,
  },
  subscription_unpaid: {
    message: t('subscription_unpaid'),
    icon: <SubscriptionUnpaidIcon />,
  },
  subscription_cancelled: {
    message: t('subscription_cancelled'),
    icon: <InvoiceFailedIcon />,
  },
  subscription_restored: {
    message: t('subscription_restored'),
    icon: <SubscriptionRestoredIcon />,
  },
  welcome_message: {
    message: t('welcome_message'),
    icon: <WelcomeIcon />,
  },
  trashed_cleared: {
    message: t('trashed_cleared'),
    icon: <TrashedClearedIcon />,
  },
  app_update_available: {
    message: t('app_update_available'),
    icon: <UpdateUplicationIcon />,
  },
});
