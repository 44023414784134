import React, { useEffect, useState } from 'react';
import { AppContext, DiskContextType, defaultAppValues, viewType, RightMenuState } from './index';
import { useLocation } from 'react-router-dom';
import { useUpdateUrlParams } from 'src/hooks/useUpdateUrlParams';
import { DiskType } from 'src/types';

type TProps = {
  children: React.ReactNode;
};

export const DiskProvider = ({ children }: TProps) => {
  const [state, setState] = useState<DiskContextType>(defaultAppValues);
  const location = useLocation();
  const { updateUrlParams } = useUpdateUrlParams();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const viewParam = searchParams.get('view') as viewType;
    if (viewParam) {
      setView(viewParam);
    }
  }, [location.search]);

  const setView = (view: viewType) => {
    setState((state) => ({
      ...state,
      view: view,
    }));
    updateUrlParams({ view });
  };

  const updateState = (values: Partial<DiskContextType>) => {
    setState((state) => ({
      ...state,
      ...values,
    }));
  };

  const setDisk = (values: DiskType) => {
    setState((state) => ({
      ...state,
      disk: {
        ...defaultAppValues.disk,
        ...values,
      },
    }));
  };

  const setDisks = (disks: DiskType[]) => {
    setState((state) => ({
      ...state,
      disks: disks,
    }));
  };

  const getDisk = () => {
    return state.disk;
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        setView,
        updateState,
        setDisk,
        setDisks,
        getDisk,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
