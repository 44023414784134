import { HashTagType } from 'src/types';
export type GroupedHashTags = {
  [key: string]: HashTagType[];
};

export const groupHashtagsByType = (data: HashTagType[]): GroupedHashTags => {
  return data.reduce((acc: GroupedHashTags, item: HashTagType) => {
    if (!acc[item.Type]) {
      acc[item.Type] = [];
    }
    acc[item.Type].push(item);
    return acc;
  }, {});
};
