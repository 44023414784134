import React from 'react';
import { Button } from 'src/components/Forms/Button';
import { AppleIcon } from 'src/components/Icons';
import { ReactComponent as PlayMarketIcon } from './playmarket.svg';
import './styles.scss';

export const ShareLogin = () => {
  return (
    <div className="setting-login">
      <div className="setting-loginn__register-btns">
        <div className="setting-login__register-title">Продлить хранение через регистрацию</div>
        <div className="setting-login__register-btns-row">
          <Button type="basic-border" content="Google" fullWidth icon={<PlayMarketIcon />} />
          <Button type="basic-border" content="Apple" fullWidth icon={<AppleIcon />} />
        </div>
      </div>
    </div>
  );
};
