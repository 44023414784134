import React, { FC, useRef } from 'react';
import { ContextDropdownProps, DropDownItem } from 'src/components/Dropdown/types';
import 'react-contexify/dist/ReactContexify.css';
import './styles.scss';
import './context.scss';

import { Menu, useContextMenu } from 'react-contexify';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

interface ExtendedContextDropdownProps extends ContextDropdownProps {
  as?: React.ElementType;
  offset?: { x: number; y: number };
  optionsClassName?: string;
  width?: string | number;
}

export const ContextDropdown: FC<ExtendedContextDropdownProps> = ({
  trigger,
  children,
  items,
  className,
  id,
  leftClick,
  as: RootTag = 'div',
  offset,
  optionsClassName,
  width,
}) => {
  const { show, hideAll } = useContextMenu({
    id: id,
  });

  const clickedElementRef: any = useRef(null);

  const onItemClick = (item: DropDownItem) => {
    item.onClick && item.onClick(item.value || '');
  };

  function displayMenu(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    if (leftClick || e.ctrlKey) return;
    show({
      event: e,
    });
  }

  function onClick(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();

    const menuPortal = document.querySelector(`.menu-${id}`);
    if (menuPortal) {
      hideAll();
      return;
    }

    const clickedElement = clickedElementRef.current;
    const rect = clickedElement.getBoundingClientRect();

    const customPosition = {
      x: rect.left - (offset?.x || 200),
      y: rect.top + (offset?.y || 30),
    };

    show({
      event,
      props: { customPosition },
      position: customPosition,
    });
  }

  const menu = (
    <Menu
      id={id}
      className={classNames('dropdown__content skip-outside', `menu-${id}`, optionsClassName)}
    >
      {items ? (
        <>
          {items.map((item, index) => (
            <li
              className={classNames('dropdown-item', item.className, {
                'dropdown-item--disabled': item.disabled,
                'dropdown-item--first': index === 0,
              })}
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                onItemClick(item);
                hideAll();
              }}
            >
              {!!item.icon && <div className="dropdown-item__icon">{item.icon}</div>}
              <div className="dropdown-item__text" style={{ color: item.color }}>
                {item.label}
                {item.description && (
                  <div className="dropdown-item__description">{item.description}</div>
                )}
              </div>
              {!!item.rightIcon && <div className="dropdown-item__icon">{item.rightIcon}</div>}
            </li>
          ))}
        </>
      ) : (
        children
      )}
    </Menu>
  );

  return (
    <RootTag onContextMenu={displayMenu} className={classNames(className)} style={{ width }}>
      {!leftClick && trigger}
      {leftClick && (
        <div ref={clickedElementRef} onClick={onClick} className="dropdown__trigger-wrapper">
          {trigger}
        </div>
      )}
      {createPortal(menu, document.body)}
    </RootTag>
  );
};
