import React, { FC } from 'react';
import { DeleteIcon, RestoreIcon, SelectIcon } from 'src/components/Icons';

type Props = {
  onRestoreClick: () => void;
  onSelectClick: () => void;
  onDeleteClick: () => void;
};

export const Actions: FC<Props> = ({ onRestoreClick, onSelectClick, onDeleteClick }) => {
  return (
    <>
      <div className="table-btn-actions">
        <div
          onClick={(e) => {
            e.stopPropagation();
            onSelectClick();
          }}
        >
          <SelectIcon className="dropdown-svg-icon" />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            onRestoreClick();
          }}
        >
          <RestoreIcon className="dropdown-svg-icon" />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            onDeleteClick();
          }}
        >
          <DeleteIcon />
        </div>
      </div>
    </>
  );
};
