import React from 'react';
import { ReactComponent as ThreeDIcon } from './assets/icons/3d.svg';
import { ReactComponent as ApkIcon } from './assets/icons/Apk.svg';
import { ReactComponent as ArchiveIcon } from './assets/icons/Archive.svg';
import { ReactComponent as AudioIcon } from './assets/icons/Audio.svg';
import { ReactComponent as BookIcon } from './assets/icons/Book.svg';
import { ReactComponent as CodeIcon } from './assets/icons/Code.svg';
import { ReactComponent as DocumentIcon } from './assets/icons/Document.svg';
import { ReactComponent as ExcelIcon } from './assets/icons/Excel.svg';
import { ReactComponent as FolderIcon } from './assets/icons/folder.svg';
import { ReactComponent as ImageIcon } from './assets/icons/Image.svg';
import { ReactComponent as KeyIcon } from './assets/icons/key.svg';
import { ReactComponent as OthersIcon } from './assets/icons/Others.svg';
import { ReactComponent as PdfIcon } from './assets/icons/Pdf.svg';
import { ReactComponent as PresentationIcon } from './assets/icons/Presentation.svg';
import { ReactComponent as TxtIcon } from './assets/icons/Txt.svg';
import { ReactComponent as VideoIcon } from './assets/icons/Video.svg';

export const filterIcons: {
  [key: string]: React.ReactNode;
} = {
  FOLDER: <FolderIcon />,
  IMAGE: <ImageIcon />,
  VIDEO: <VideoIcon className="filter-types__video" />,
  AUDIO: <AudioIcon />,
  TEXT: <TxtIcon className="filter-types__text" />,
  DOCUMENT: <DocumentIcon />,
  PDF: <PdfIcon />,
  EXCEL: <ExcelIcon className="filter-types__excel" />,
  PRESENTATION: <PresentationIcon className="filter-types__presentation" />,
  ARCHIVE: <ArchiveIcon className="filter-types__archive" />,
  CODE: <CodeIcon />,
  '3D': <ThreeDIcon />,
  KEY: <KeyIcon className="filter-types__key" />,
  BOOK: <BookIcon className="filter-types__book" />,
  APK: <ApkIcon />,
  OTHER: <OthersIcon className="filter-types__others" />,
};
