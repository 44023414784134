import React, { FC, ReactNode } from 'react';
import './styles.scss';
import { AnimateIcon, LogoWhite } from 'src/components/Icons';

type Props = {
  children: ReactNode;
  showLoader?: boolean;
};
export const LoadScreen: FC<Props> = ({ children, showLoader = false }) => {
  return (
    <>
      {showLoader ? (
        <div className="loader-screen">
          <div className="loader-screen__content">
            <LogoWhite />
            <div className="loader-screen__wrapper">
              <AnimateIcon className="loader-screen__animation" />
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};
