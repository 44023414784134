import { API } from 'src/utils/API';
import { useMutation } from '@tanstack/react-query';

export type RenamePayload = {
  id: string;
  name: string;
};

export type MovePayload = {
  item_ids: string[];
  destination_id: string;
};

export const useRenameObject = () => {
  const sendData = async ({ id, name }: RenamePayload) => {
    const resp = await API.put(`/items/${id}/rename`, { name }, 'archive');
    if (resp.success) {
      return {
        success: true,
        folder: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useMoveObject = () => {
  const sendData = async (payload: MovePayload) => {
    const resp = await API.post('/test/items/move', payload, 'archive');
    if (resp.success) {
      return {
        success: true,
        folder: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};
