import { useGetFilteredFolders } from 'src/hooks/useGetFilteredFolders';
import { FilesPayload, useFileCache } from 'src/api/files';
import { DEFAULT_FILES_PAYLOAD } from 'src/config/constants';

export const useCache = (id?: string) => {
  const { removeFolderCacheGlobal } = useGetFilteredFolders({}, true);
  const { invalidateCache: refetchFolders } = useGetFilteredFolders(
    {
      parent_id: id || '',
    },
    true,
  );

  const filesPayload: FilesPayload = {
    ...DEFAULT_FILES_PAYLOAD,
    parent_id: id || '',
  };

  const { removeFileCacheGlobal, invalidateCache: refetchFiles } = useFileCache(filesPayload);

  const invalidateCache = () => {
    refetchFiles();
    refetchFolders();
  };
  return {
    invalidateCache,
    removeFolder: removeFolderCacheGlobal,
    removeFile: removeFileCacheGlobal,
  };
};
