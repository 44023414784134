import React, { FC, useMemo } from 'react';
import { FolderIcon } from 'src/components/Icons';
import { FolderType, Statistic } from 'src/types';
import { useGetFolderStats } from 'src/api/folders';
import { getColor } from 'src/config/fileTypes';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'src/config/constants';
import { getConvertedSize } from 'src/helpers';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './folderInfo.scss';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import { HashtagsBlock } from 'src/components/HashtagsBlock';
import { filterIcons } from 'src/components/FilterTypes/config';

type Props = {
  folder: FolderType;
  className?: string;
};

export const FolderInfo: FC<Props> = ({ folder, className }) => {
  const { t } = useTranslation();
  const { disk } = useDiskContext();
  const { data } = useGetFolderStats(folder.id);
  const { data: parentData } = useGetFolderStats(folder.parent_id);
  const parentFolder = parentData?.stats || { name: disk.name };
  const statistics: Statistic[] = data?.stats?.statistics || [];

  const folderSize = useMemo(() => {
    let size = 0;
    statistics.forEach((stat) => {
      size += stat.size;
    });
    return size;
  }, [statistics]);

  if (!folder) return <></>;
  return (
    <div className={classNames('folderInfo', className)}>
      <div className="info-component__section">
        <div className="info-component__section-header">
          <div style={{ width: '100%' }}>
            <div className="info-component__folder-wrapper">
              <FolderIcon className="folder-icon" />
            </div>
            <div className="folderInfo__title">{folder.name}</div>
          </div>
        </div>
        <div className="info-component__section-content">
          <div className="info-component__section-item">
            <div className="info-component__category">{t('lastModifiedDate')}:</div>
            <div className="info-component__size">
              {moment(folder.last_modified_at).format(DATE_TIME_FORMAT)}
            </div>
          </div>
          <div className="info-component__section-item">
            <div className="info-component__category">{t('creationDate')}:</div>
            <div className="info-component__size">
              {moment(folder.created_at).format(DATE_TIME_FORMAT)}
            </div>
          </div>
          <div className="info-component__section-item">
            <div className="info-component__category">{t('totalFolderVolume')}:</div>
            <div className="info-component__size">{getConvertedSize(folderSize)}</div>
          </div>
        </div>
      </div>

      <HashtagsBlock itemId={folder.id} />

      <div className="info-component__section">
        <div className="info-component__section-header">
          <span className="capitalize">{t('files')}</span>
        </div>
        <div className="info-component__section-content">
          {statistics
            .filter((stat) => stat.count > 0)
            .map((stat, key) => {
              return (
                <div key={key} className="info-component__section-item">
                  <div className="info-component__category">
                    <div className="info-component__category-icon">{filterIcons[stat.type]}</div>
                    {stat.type.toLowerCase()}:
                  </div>
                  <div className="info-component__size">
                    {statistics[key].count}/{getConvertedSize(statistics[key].size)}
                  </div>
                </div>
              );
            })}
          <div className="info-component__section-item">
            <div className="info-component__category">{t('creationDate')}:</div>
            <div className="info-component__size">
              {moment(folder.created_at).format(DATE_TIME_FORMAT)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
