import { useLocation, useNavigate } from 'react-router-dom';

export const useUpdateUrlParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const updateUrlParams = (params: Record<string, string>) => {
    const searchParams = new URLSearchParams(location.search);

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        searchParams.set(key, params[key]);
      } else {
        searchParams.delete(key);
      }
    });

    navigate(`?${searchParams.toString()}`);
  };

  return { updateUrlParams };
};
