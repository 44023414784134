import React, { FC } from 'react';
import { Slider } from './Slider';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { ValuesType } from 'src/components/CreateDisk/types';
import './styles.scss';
import { usePrices } from 'src/components/CreateDisk/usePrices';

type Props = {
  onChange: (capacity: number) => void;
  capacity: number;
  min: number;
  max: number;
};

export const DiskCapacity: FC<Props> = ({ onChange, capacity, min, max }) => {
  const { values } = useFormikContext<ValuesType>();
  const { getPricePerGb } = usePrices();

  const { t } = useTranslation();

  return (
    <div className="form-disk-capacity">
      <div className="form-disk-capacity__header">
        <div className="form-disk-capacity__label">{t('capacity')}:</div>
        <div className="form-disk-capacity__value">
          {t('price')} 1 GB <span>{getPricePerGb(values.drive_type)} $</span>
        </div>
      </div>
      <Slider
        onChange={(value) => {
          onChange(value);
        }}
        value={capacity}
        min={min}
        max={max}
      />
    </div>
  );
};
