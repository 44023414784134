import React, { useCallback, useState } from 'react';
import './uploaderPage.scss';
import classNames from 'classnames';
import { FileDataType } from 'src/components/FileUploader/index';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';

type Props = {
  children?: React.ReactNode;
  diskId: string;
  folderId?: string;
  className?: string;
  isEmpty?: boolean;
};

export const UploaderPage: React.FC<Props> = ({
  children,
  diskId,
  folderId,
  className,
  isEmpty = false,
}) => {
  const { updateUploadManager } = useGlobalContext();
  const [isOver, setIsOver] = useState(false);

  const onChange = useCallback(
    (data: FileDataType[]) => {
      updateUploadManager({
        open: true,
        files: data,
        diskId,
        folderId,
      });
    },
    [updateUploadManager, diskId, folderId],
  );

  const onChangeFilesProcessing = (files: FileList | null) => {
    if (files && files.length > 0) {
      const filesData: FileDataType[] = [];
      for (let i = 0; i < files.length; i++) {
        filesData.push({
          file: files[i],
          fileSize: files[i].size || 0,
          fileName: files[i].name,
          diskId: diskId || '',
          folderId: folderId || '',
        });
      }

      onChange(filesData);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    onChangeFilesProcessing(files);
  };

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      if (isEmpty) {
        event.preventDefault();
        event.stopPropagation();
      }

      const isFile = Array.from(event.dataTransfer.types).includes('Files');
      if (isFile) {
        setIsOver(true);
      }
    },
    [isEmpty],
  );

  const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsOver(false);
  }, []);

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        event.dataTransfer.clearData();
      }

      onChangeFilesProcessing(event.dataTransfer.files);
      setIsOver(false);
    },
    [updateUploadManager, diskId, folderId],
  );

  return (
    <div
      className={classNames('uploader-page', { over: isOver }, className)}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input
        id="file-input"
        type="file"
        className="uploader-page__input"
        multiple={true}
        onClick={(e) => e.preventDefault()} // Prevent the default click behavior
        onChange={handleFileChange}
        title=""
      />
      {children}
    </div>
  );
};
