import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import './styles.scss';
import { CircleCheckRed } from 'src/components/Icons';

type Props = {
  label?: string;
  subLabel?: string;
  name?: string;
  id?: string;
  className?: string;
  onChange?: (value: string) => void;
  value?: string | null;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  isRequired?: boolean;
  type?: string;
  error?: string;
  maxLength?: number;
};

export const DynamicInput: FC<Props> = ({
  label,
  name,
  id,
  className,
  onChange,
  value,
  onBlur,
  onFocus,
  placeholder,
  isRequired,
  type,
  error,
  subLabel,
  maxLength,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (value) {
      setIsFocused(true);
    }
  }, [value]);

  return (
    <div className={classNames('d-input', className, { hasError: !!error })}>
      <div className="d-input__field-wrapper">
        {label && (
          <label
            className={classNames('d-input__label', {
              'd-input__label--float': isFocused || value,
            })}
            htmlFor={id}
          >
            {isRequired && '*'}
            {label}
          </label>
        )}
        <input
          className={classNames('d-input__field', {
            'd-input__field--float': isFocused || value,
          })}
          type={type || 'text'}
          id={id}
          name={name}
          value={value || ''}
          placeholder={placeholder}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          onBlur={() => {
            setIsFocused(false);
            if (onBlur) {
              onBlur();
            }
          }}
          onFocus={() => {
            setIsFocused(true);
            if (onFocus) {
              onFocus();
            }
          }}
        />
        {maxLength && (
          <div className="d-input__counter">
            {(value?.length || 0) > maxLength && <CircleCheckRed />}
            <div className="d-input__counter-actual">{value?.length || 0}</div>/{maxLength}
          </div>
        )}
      </div>
      {!!error && <div className="d-input__error">{error}</div>}
    </div>
  );
};
