const translation = {
  welcome: 'Добро пожаловать в UMI!',
  description: 'Это многоязычное приложение.',
  loginDescription: 'больше, чем просто облачное хранилище',
  searchByFiles: 'Поиск по файлам',
  settings: 'Настройки',
  editProfile: 'Редактировать профиль',
  security: 'Безопасность',
  notifications: 'Уведомления',
  contacts: 'Контакты',
  support: 'Поддержка',
  extension: 'Расширение',
  paymentsHistory: 'История платежей',
  removedFiles: 'Удаленные файлы',
  storageSelection: 'Выбор хранилища',
  personalData: 'Личные данные',
  saveChanges: 'Сохранить изменения',
  nameLastName: 'Полное имя',
  dateOfBirth: 'Дата рождения',
  username: 'Имя пользователя',
  department: 'Отдел',
  position: 'Должность',
  belgium: 'Бельгия',
  bulgaria: 'Болгария',
  croatia: 'Хорватия',
  cyprus: 'Кипр',
  czechRepublic: 'Чехия',
  denmark: 'Дания',
  estonia: 'Эстония',
  finland: 'Финляндия',
  france: 'Франция',
  germany: 'Германия',
  greece: 'Греция',
  hungary: 'Венгрия',
  ireland: 'Ирландия',
  italy: 'Италия',
  latvia: 'Латвия',
  lithuania: 'Литва',
  luxembourg: 'Люксембург',
  malta: 'Мальта',
  netherlands: 'Нидерланды',
  poland: 'Польша',
  portugal: 'Португалия',
  romania: 'Румыния',
  slovakia: 'Словакия',
  slovenia: 'Словения',
  spain: 'Испания',
  ukraine: 'Украина',
  phoneNumber: 'Номер телефона',
  signIn: 'Войти',
  openQRCode: 'Открыть QR-код',
  continueWithApple: 'Продолжить с Apple',
  continueWithGoogle: 'Продолжить с Google',
  enter: 'Ввести',
  enterCodeFromSMS: 'Введите код из SMS',
  sendingSMSWithTheCodeAgain: 'Повторная отправка SMS с кодом',
  smsHasBeenSent: 'SMS было отправлено',
  chooseALanguage: 'Выберите язык',
  enterUniqueUsername: 'Введите уникальное имя пользователя',
  usernameLetsPeopleContactYou: 'Имя пользователя позволяет людям связаться с вами в UMI',
  yourUsername: 'Ваше имя пользователя',
  invalidPhoneNumber:
    'Введенный номер телефона имеет неверный формат. Пожалуйста, введите номер в международном формате.',
  phoneNumberIsRequired: 'Требуется номер телефона',
  invalidOtp: 'Неверный OTP',
  main: 'Главная',
  mySubscriptions: 'Мои подписки',
  loading: 'Загрузка',
  profile: 'Профиль',
  help: 'Помощь',
  privacyPolicy: 'Политика конфиденциальности',
  recycleBin: 'Корзина',
  balance: 'Баланс',
  refill: 'Пополнить',
  addYourID: 'Добавьте ваш ID',
  id: 'ID',
  email: 'Электронная почта',
  addYourFullName: 'Добавьте ваше полное имя',
  addYourEmail: 'Добавьте ваш Email',
  addYourPosition: 'Добавьте вашу должность',
  addYourDepartment: 'Добавьте ваш отдел',
  accountConnection: 'Подключение аккаунта',
  logOut: 'Выйти',
  deleteAccount: 'Удалить аккаунт',
  no: 'Нет',
  yes: 'Да',
  logOutConfirmation: 'Вы уверены, что хотите выйти из своего аккаунта?',
  profileUpdatedSuccessfully: 'Профиль успешно обновлен',
  setYourPhoto: 'Установите ваше фото',
  addYourUserName: 'Добавьте ваше имя пользователя',
  fullNameIsRequired: 'Требуется полное имя',
  usernameIsRequired: 'Требуется имя пользователя',
  departmentIsRequired: 'Требуется отдел',
  positionIsRequired: 'Требуется должность',
  validEmailError: 'должен быть действительный email',
  changeTheCover: 'Изменить обложку',
  addToFavorite: 'Добавить в избранное',
  renameDisk: 'Переименовать диск',
  downloadDisk: 'Скачать диск',
  diskName: 'Название диска',
  cancel: 'Отменить',
  createDis: 'Создать диск',
  deleteConfirmation: 'Вы уверены, что хотите удалить',
  deleteDisk: 'Удалить диск',
  download: 'Скачать',
  select: 'Выбрать',
  share: 'Поделиться',
  copy: 'Копировать',
  move: 'Переместить',
  properties: 'Свойства',
  rename: 'Переименовать',
  delete: 'Удалить',
  owner: 'Владелец',
  moveSelectTheFilesUpload:
    'Переместите или выберите файлы, которые вы хотите загрузить с вашего компьютера',
  folderName: 'Название папки',
  createFolder: 'Создать папку',
  deleteFolder: 'Удалить папку',
  renameFolder: 'Переименовать папку',
  uploadFiles: 'Загрузить файлы',
  restore: 'Восстановить',
  restoreTo: 'Восстановить в',
  deletePermanently: 'Удалить навсегда',
  sorting: 'Сортировка',
  name: 'Имя',
  uploaded: 'Загружено',
  uploading: 'Загрузка',
  canceled: 'Отменено',
  uploadYourFirstFile: 'Загрузите ваш первый файл',
  areYouSureWantDeleteAll: 'Вы уверены, что хотите удалить все файлы и папки?',
  filesWillBePermanentlyDeleted: 'Все файлы будут окончательно удалены из облачного хранилища UMI',
  willBePermanentlyDeleted: 'будет окончательно удален из облачного хранилища UMI',
  currentPosition: 'Текущая позиция',
  createNewFolder: 'Создать новую папку',
  selectLocation: 'Выберите расположение',
  moveTo: 'Переместить в',
  folderWithFiles: 'ПАПКА',
  dateOfEditing: 'Дата редактирования',
  size: 'Размер',
  type: 'Тип',
  byName: 'По имени',
  bySize: 'По размеру',
  yourFiles: 'Ваши файлы',
  createANewDisk: 'Создать новый диск',
  subscription: 'Подписка',
  save: 'Сохранить',
  accessControlTo: 'Контроль доступа к',
  trash: 'Корзина',
  fileTypes: 'Типы файлов',
  totalDiskVolume: 'Общий объем диска',
  creationDate: 'Дата создания',
  parentFolderName: 'Имя родительской папки',
  parentDiskName: 'Имя родительского диска',
  fileExtension: 'Расширение файла',
  fileSize: 'Размер файла',
  totalFolderVolume: 'Общий объем папки',
  fileSharedSuccessfully: 'Файл успешно поделился',
  editors: 'Редакторы',
  readers: 'Читатели',
  files: 'файлы',
  myDisks: 'Мои диски',
  createDisk: 'Создать диск',
  chat: 'Чат',
  search: 'Поиск',
  folders: 'Папки',
  restoreAllFiles: 'Восстановить все файлы',
  deleteAll: 'Удалить все',
  preview: 'Предпросмотр',
  uMIMoreThanCloudStorage: 'Больше чем облачное хранилище',
  landingPageDescription:
    'Мы не просто храним файлы, мы даем возможность создать единую библиотеку и подключить к ней свою команду',
  getStart: 'Начать',
  moreAboutTheService: 'Подробнее о сервисе',
  ourNetworkDC: 'Наша сеть центров обработки данных',
  dataCentersDescription:
    'Мы неустанно расширяем нашу глобальную сеть центров обработки данных, чтобы обеспечить максимально безопасное хранение ваших данных и оптимизировать расходы на хранение.',
  submitAnApplication: 'Подать заявку',
  dataStructuring: 'Структурирование данных',
  selectingCostAndStorageCapacity: 'Выбор стоимости и объема памяти',
  tagAndHashtagSystemFiles: 'Система тегов и хэштегов для файлов',
  electronicDocumentSigning: 'Электронное подписание документов',
  connectionOfYourStorage: 'Подключение собственного хранилища',
  chatForCorporateClients: 'Чаты для корпоративных клиентов',
  storageCapabilities: 'Возможности хранения',
  months: 'месяцев',
  month: 'месяц',
  storageDatacenter: 'Дата-центр хранения данных',
  dataBackup: 'Резервное копирование данных',
  numberOfGigabytes: 'Количество гигабайт',
  costPerGigabyte: 'Стоимость одного гигабайта',
  subscriptionTerm: 'Срок подписки',
  discountReceived: 'Полученна скидка',
  selectedStorageParameters: 'Выбранные параметры хранения',
  monthly: 'Ежемесячно',
  costFor: 'Стоимость за',
  storageCapacity: 'Емкость для хранения',
  price: 'Цена',
  storageTime: 'Время хранения',
  reservationOptions: 'Варианты бронирования',
  noReservation: 'Без резервирования',
  reservation: 'Бронирование',
  chooseServerStorage: 'Выберите серверное хранилище',
  AWSStorage: 'Хранилище AWS',
  downloadAPP: 'Скачать приложение',
  appDescription:
    'Управляйте своими файлами из любой точки мира. Мобильное приложение для вашего устройства доступно в маркетплейсах',
  unifiedMediaInterface: 'Библиотека цифровых активов',
  footerDescription:
    'Интегрированный и унифицированный способ управления и совместного использования мультимедийных данных в облачном хранилище',
  socialDescription:
    'UMI - это облачный сервис для мобильных устройств и компьютеров. Быстро, безопасно, функционально.',
  cloud: 'Облако',
  teamWork: 'Командная работа',
  fileSearch: 'Поиск файлов',
  basicFunctionalityStorage: 'Базовая функциональность хранилища',
  tiredOfSwitchingBetweenMultipleCloudStorages:
    'Устали переключаться между разными облачными хранилищами?',
  nowAllYourFilesAreInOnePlace:
    'Теперь все ваши файлы в одном месте! Наш сервис объединяет популярные облачные платформы, предоставляя удобный и интуитивно понятный интерфейс для управления всеми вашими данными.',
  createYourFirstDiscParameters: 'Создайте свой первый диск с оптимальными параметрами хранения',
  creatingStorageDisk: 'Создание диска для хранения данных',
  homeDescription:
    'Функциональное хранилище, файловый маркетплейс, инструменты для командных работ',
  homeTitle: 'Будущая библиотека цифровых активов',
  fileSharing: 'Файлообменник',
  annually: 'Ежегодно',
  invoice: 'Счет-фактура',
  homeSlide1Title: 'Единая библиотека цифровых активов',
  homeSlide1Subtitle: 'Мы вложили весь свой опыт в то, чтобы сделать удобное хранилище',
  homeSlide1Description:
    'Простую монетизацию, структурирование накопленных архивов, многоуровневую систему доступов, создание и настройка дисков, тикет система, подписи документов, ради которых раньше приходилось ходить по разным ресурсам, — теперь все необходимое в одной библиотеке!',
  homeSlide2Title: 'Функциональное хранение и управление',
  homeSlide2Subtitle: 'Оптимизируй затраты, повышая эффективность',
  homeSlide2Description:
    'Мы не просто храним файлы, мы даем возможность создать единую библиотеку и подключить к ней свою команду',
  homeSlide3Title: 'Управляй и монетизируй свои цифровые активы',
  homeSlide3Subtitle: 'Мы не просто храним, мы монетизируем!',
  homeSlide3Description:
    'Мы создали все инструменты для сортировки старых архивов, чтобы вы смогли увидеть потенциал старых данных и дать им новую жизнь',

  downloadApp: 'Скачать APP',
  homeAppsDescription: 'Управляйте своими файлами из любой точки мира',
  convenienceTitle: 'Удобство',
  convenienceDescription: 'Простой, интуитивно понятный интерфейс',
  goalsTitle: 'Постановка задач',
  goalsDescription: 'Контроль за выполнением работ',
  syncTitle: 'Синхронизация',
  syncDescription: 'Доступ с нескольких устройств одновременно',
  speedTitle: 'Скорость',
  speedDescription: 'Высокая скорость загрузки файлов',
  noBreaksTitle: 'Без тормозов',
  noBreaksDescription: 'Нет ограничений на размер загружаемых и скачиваемых файлов',
  opennessTitle: 'Открытость',
  opennessDescription: 'Открытый API для разработчиков',
  securityTitle: 'Безопасность',
  securityDescription: 'Двойное шифрование данных при передаче',
  communicationTitle: 'Коммуникации',
  communicationDescription: 'Групповые чаты для команд и личного пользования',
  flexTitle: 'Гибкость',
  flexDescription: 'Выбор объема и стоимости хранения',
  newsTitle: 'Свежие новости',
  reasonsTitle: 'Почему UMI?',
  cloudSlide1Title: 'Основные функции',
  cloudSlide1Subtitle: 'Больше не нужно платить за лишние гигабайты',
  cloudSlide1Description:
    'Выбирайте самостоятельно объем, стоимость и место хранения, создавая отдельные диски для различных проектов и задач в рамках одного интерфейса',
  cloudSlide2Title: 'Все необходимые инструменты в одной библиотеке',
  cloudSlide2Subtitle: 'Устали переключаться между несколькими облачными хранилищами?',
  cloudSlide2Description:
    'Функциональное отображение файлов, фильтр категорий файлов, поиск по меткам, хэштегам все это и многое другое доступно в нашем интерфейсе',
  cloudSlide3Title: 'Создание дисков для различных проектов и команд',
  cloudSlide3Subtitle: 'Оптимизируйте затраты используя функциональность хранилища',
  cloudSlide3Description:
    'Мы первые кто дает возможность создавать диски в едином интерфейсе для отдельных проектов и команд, самостоятельно выбирая объем, стоимость и варианты резервирования',
  cloudSlide4Title: 'Многоуровневая система доступов и прав',
  cloudSlide4Subtitle: 'Устали переключаться между несколькими облачными хранилищами?',
  cloudSlide4Description:
    'Вы храните все в одном месте, но каждый член команды видит файлы в рамках своей должности',
  cloudSlide5Title: 'Тикетная система постановки задач',
  cloudSlide5Subtitle: 'Устали переключаться между несколькими облачными хранилищами?',
  cloudSlide5Description:
    'Мы помогаем собрать всех сотрудников, задачи и инструменты в одном месте. Создавайте доски, назначайте задачи и прикрепляйте файлы в единой библиотеке файлов',

  cloudOption1Title: 'Основные функции',
  cloudOption1Subtitle: 'Больше не нужно платить за лишние гигабайты и функционал',
  cloudOption1Description:
    'Мы убрали все лишнее и дали право самостоятельно каждому пользователю решать каким должно быть хранилище, опираясь на личные задачи, а не на шаблоны сервисов. Ты определяешь сам:',
  cloudOption1Param1: 'Где хранить',
  cloudOption1Param2: 'Как резервировать',
  cloudOption1Param3: 'Какой объем хранилища',
  cloudOption1Param4: 'Количество дисков для хранения',

  cloudOption2Title: 'Простой функционал для сложных задач',
  cloudOption2Subtitle: '',
  cloudOption2Description:
    'Мы создали простой функционал, который поможет решать сложные задачи от поиска нужных файлов до структурирования больших массивов данных в интуитивно понятном интерфейсе',
  cloudOption2Param1: 'Ваши файлы в наглядном виде',
  cloudOption2Param2: 'Фильтр категорий файлов',
  cloudOption2Param3: 'Поиск по хештегам',
  cloudOption2Param4: 'Создай галерею и библиотеку с доступом по ссылке',

  cloudOption3Title: 'Не сливай бюджет на хранение',
  cloudOption3Subtitle: '',
  cloudOption3Description:
    'Мы первые кто дает возможность создавать диски в едином интерфейсе для отдельных проектов и команд, самостоятельно выбирая объем, стоимость и варианты резервирования',
  cloudOption3Param1: 'Создавай диск под проект',
  cloudOption3Param2: 'Выбирай объём и место хранения',
  cloudOption3Param3: 'Подключай к каждому диску отдельные команды',
  cloudOption3Param4: 'Выбирай как резервировать',

  cloudOption4Title: 'Тикетная система постановки задач',
  cloudOption4Subtitle: 'Не трать время на записки, ставь задачи в одном месте',
  cloudOption4Description:
    'Мы помогаем собрать всех сотрудников, задачи и инструменты в одном месте. Создавайте доски, назначайте задачи и прикрепляйте файлы в единой библиотеке файлов',
  cloudOption4Param1: 'Групповые и личные чаты',
  cloudOption4Param2: 'Комментарии, заметки, уведомления',
  cloudOption4Param3: 'Прикрепляй файлы к задачам',
  cloudOption4Param4: 'Подпись документов',
  landingPageSubtitle: 'Оптимизируй затраты, повышая эффективность!',
  aboutUmi: 'О UMI',
  faq: 'Часто задаваемые вопросы',
  partnership: 'Партнерство',
  forMedia: 'Для СМИ',
  products: 'Продукты',
  mobileApp: 'Мобильное приложение',
  forComputers: 'Для компьютеров',
  webVersion: 'Веб-версия',
  infrastructure: 'Инфраструктура',
  api: 'API',
  translations: 'Переводы',
  instantView: 'Мгновенный просмотр',
  development: 'Разработка',
  jobs: 'Вакансии',
  focusGroup: 'Фокус-группа',
  askAQuestion: 'Задать вопрос',
  personalInformation: 'Личная информация',
  agreements: 'Соглашения',
  today: 'Сегодня',
  yesterday: 'Вчера',
  paymentAmount: 'Сумма платежа',
  newDisk: 'Новый диск',
  myBalance: 'Мой баланс',
  supportForDevelopers: 'Поддержка для разработчиков',
  businessSubscription: 'Бизнес-подписка',
  recentOperations: 'Последние операции',
  uploadsAndDownloads: 'Загрузки и скачивания',
  cancelAll: 'Отменить все',
  resume: 'Возобновить',
  finished: 'Завершено',
  file: 'файл',
  language: 'Язык',
  termsOfUse: 'Условия использования',
  noUploads: 'Нет загрузки и скачивания',
  create: 'Создать',
  upload: 'Загрузить',
  occupancy: 'Занятость',
  diskCapacity: 'Объем диска',
  items: 'элементы',
  item: 'элемент',
  digitalAssetLibrary: 'Библиотека цифровых активов',
  timeOfDeletion: 'Время удаления',
  functions: 'Функции',

  highPerformance: 'Высокопроизводительное хранилище для часто используемых данных',
  requestProcessing: 'Обработка запросов менее чем за 10 миллисекунд',
  increaseAccessSpeed:
    'Увеличивает скорость доступа в 10 раз, снижает затраты на обработку запросов на 50% по сравнению с S3 Standard',
  availabilityZone:
    'Выбирая зону доступности AWS, клиенты могут размещать хранилища и вычислительные ресурсы рядом, значительно уменьшая задержку и время обработки, а также улучшая использование вычислительных ресурсов для сокращения общей стоимости',
  accelerateAnalytics:
    'Ускорьте аналитические задачи и задачи машинного обучения с интеграцией AWS',
  scaleRequests: 'Масштабируйте для обработки миллионов запросов в минуту',
  optimizeDatasets: 'Оптимизируйте для больших наборов данных с множеством небольших объектов',
  leverageS3:
    'Используйте существующие API Amazon S3 с другим типом корзины покупок - каталожные мусорные корзины',
  storageAvailability:
    'Хранилище предназначено для обеспечения 99,95% доступности в рамках соглашения об уровне обслуживания на 99,9%',
  generalPurposeStandard:
    'Универсальный стандарт для часто используемых данных. Предлагает высоконадежное, доступное и производительное объектное хранилище для хранения часто используемых данных. Обеспечивает низкую задержку и высокую пропускную способность.',
  standard: 'Стандарт',

  generalPurposeStorage: 'Универсальное хранилище для часто используемых данных',
  lowLatencyHighThroughput: 'Низкая задержка и высокая пропускная способность',
  storageAvailabilityAgreement:
    'Хранилище предназначено для обеспечения 99,99% доступности в соответствии с условиями соглашения об уровне обслуживания на 99,9%',

  highPerformanceShort: 'Высокопроизводительное',
  expressOneZone:
    'Express One Zone — это высокопроизводительный класс хранения с одной зоной доступности, специально разработанный для обеспечения стабильного доступа к часто используемым данным и приложениям с низкой задержкой со скоростью в миллисекунды.',
  infrequentAccess: 'Редко используемый доступ',
  standardIA:
    'Standard - IA — это идеальный выбор для хранения данных, к которым редко обращаются, но которые должны быть доступны быстро. Высокая надежность, высокая пропускная способность и низкая задержка при низкой стоимости за гигабайт. Идеально подходит для долгосрочного хранения данных и резервного копирования.',

  rarelyUsedData: 'Редко используемые данные, которые требуют мгновенного доступа',
  lowLatencyHighThroughputS3:
    'Низкая задержка и высокая пропускная способность на уровне хранилища S3 Standard',
  storageAvailabilityAgreement99:
    'Хранилище предназначено для обеспечения 99,9% доступности в соответствии с условиями соглашения об уровне обслуживания на 99%',
  archiving: 'Архивирование',
  glacierArchiving:
    'Glacier специально разработан для архивирования данных и обеспечивает максимальную производительность, гибкость восстановления и минимальную стоимость облачного архивного хранилища',
  flexibleRetrieval: 'Гибкое восстановление',
  retrievalSupport:
    'Поддерживает восстановление за считанные минуты для небольших объемов и 5-12 часов для больших пакетов.',

  longTermStorage:
    'Долгосрочное хранение данных, к которым обращаются несколько раз в год и которые извлекаются мгновенно',
  retrieveMilliseconds:
    'Извлекайте данные за несколько миллисекунд с той же производительностью, что и S3 Standard.',
  storageAvailabilityAgreement992:
    'Хранилище предназначено для обеспечения 99,9% доступности в соответствии с условиями соглашения об уровне обслуживания на 99%',
  minimumObjectSize: 'Минимальный размер объекта: 128 КБ',
  s3PutApi:
    'S3 PUT API для прямой загрузки в S3 Glacier Instant Retrieval и управления жизненным циклом S3 для автоматической миграции объектов',
  deepArchive: 'Глубокий архивирование',
  minimalCloudCosts:
    'Обеспечивает минимальные затраты на облачное хранение и возможность восстановления данных в течение 12-48 часов.',

  lowCostBackupArchiving:
    'Низкозатратное резервное копирование и архивирование редко используемых данных',
  storageAvailabilityAgreement9999:
    'Хранилище предназначено для обеспечения 99,99% доступности в соответствии с условиями соглашения об уровне обслуживания на 99,9%',
  sslSupport: 'Поддержка SSL для данных в движении и шифрование локальных данных',
  backupDisasterRecovery:
    'Отлично подходит для резервного копирования и аварийного восстановления, когда необходимо оперативное восстановление больших наборов данных за считанные минуты без дополнительных затрат.',
  customizableRetrievalTimes:
    'Настраиваемое время восстановления — от нескольких минут до нескольких часов — и возможность пакетного восстановления.',
  s3PutApiFlexibleRetrieval:
    'API S3 PUT для прямой загрузки в S3 Glacier Flexible Retrieval и управления жизненным циклом S3 для автоматической миграции объектов',
  instantRetrieval: 'Мгновенное восстановление',

  minimizesLongTermStorageCost:
    'Минимизирует стоимость долгосрочного хранения данных, к которым редко обращаются, но которые могут быть восстановлены за несколько миллисекунд.',

  rarelyUsedArchivalData: 'Исключительно редко используемые архивные данные по очень низкой цене',
  repositoryAvailabilityAgreement9999:
    'Хранилище предназначено для обеспечения 99,99% доступности в соответствии с условиями соглашения об уровне обслуживания на 99,9%',
  idealAlternativeToTape: 'Идеальная альтернатива ленточным библиотекам',
  retrievalTime12Hours: 'Время восстановления данных в пределах 12 часов',
  s3PutApiDeepArchive:
    'API S3 PUT для прямой загрузки в S3 Glacier Deep Archive и управления жизненным циклом S3 для автоматической миграции объектов',
  readMore: 'Читать далее',
  keyFeatures: 'Основные функции',
  diskParameters: 'Параметры диска',
  confirmParameters: 'Подтвердить параметры',
  subscribe: 'Подписаться',
  costGigabyte: 'Стоимость за гигабайт',
  amount: 'Количество',
  repeat: 'Повторить',
  failedPayment:
    'К сожалению, ваш платеж не удалось обработать. Это может быть связано с недостаточными средствами или истекшим сроком действия карты. Пожалуйста, попробуйте снова или обновите свои платёжные данные.',
  paymentFailed: 'Платеж не удался',
  paymentSuccessful: 'Платеж успешен',
  successPaymentMsg:
    'Спасибо за подписку! Ваш платеж успешно обработан, и ваша подписка теперь активна. Наслаждайтесь всеми премиум-функциями!',
  serverAWS: 'Сервер AWS',
  connectDisk: 'Подключить диск',
  freedoms: 'Свободы',
  storageOffers: 'Предложения по хранению',
  open: 'Открыть',
  completeFreedom: 'Полная свобода выбора',
  customizeStorage:
    'Настройте хранилище так, как вам нужно, и управляйте своими данными без ограничений',
  editDisk: 'Редактирование диска',
  noNotifications: 'Нет уведомлений',
  diskInformation: 'Информация о диске',
  storage: 'Хранилище',
  capacity: 'Объем',
  cost: 'Стоимость',
  clearDiskMessage: 'Вы уверены, что хотите очистить диск?',
  removeFromFavorite: 'Удалить из избранного',
  folderInformation: 'Информация о папке',
  fileInformation: 'Информация о файле',
  totalVolume: 'Общий объем',
  hashtags: 'Хэштеги',
  add: 'Добавить',
  lastModifiedDate: 'Последнее изменение',
  dates: 'Даты',
  noHashtags: 'Нет хэштегов',
  edit: 'Редактировать',
  popularHashtags: 'Популярные хэштеги',
  functionality: 'Функционал',
  marketplace: 'Маркетплейс',
  taskBoards: 'Доски задач',
  groupChats: 'Групповые чаты',
  unsubscribe: 'Отписаться',
  futureFunctionality: 'Будущий функционал',
  monthlySupportDevelopers: 'Месячная поддержка разработчиков',
  beAPartText: 'Станьте частью нашего общего успеха!',
  supportText: 'Поддержите разработку новых функций, подписавшись всего за $1 в месяц.',
  supportTeam: 'Поддержите команду',
  fileMarket: 'Файловый маркетплейс',
  fileCopiedSuccessfully: 'Файл успешно скопирован',
  folderCopiedSuccessfully: 'Папка успешно скопирована',
  disks: 'Диски',
  freeDisk: 'Бесплатный диск',
  personalLibraryText: 'Ваша личная цифровая библиотека ждет вас!',
  signUpAndGet10Text:
    'Зарегистрируйтесь и получите 10 ГБ бесплатного пространства для ваших файлов сразу.',
  signUp: 'Зарегистрироваться',
  taskBoardsText:
    'Создавайте и отслеживайте задачи, контролируйте доступ к информации, безопасно храните документы и общайтесь с коллегами в режиме реального времени.',
  groupChatsText:
    'Мы создадим чаты для общения с командой, клиентами, покупателями и поставщиками, не покидая хранилище, которое можно связать с дисками и командами.',
  fileSearchText:
    'Наша цель — создать файловый поиск на основе хранилища, где каждый пользователь сможет управлять, монетизировать и продвигать свои файлы через открытую поисковую систему. Получайте отзывы, оценки и заказы прямо в хранилище.',
  marketplaceText:
    'Мы создаем облачную функциональность, которая поможет монетизировать любой тип файлов, загруженных в хранилище.',
  days: 'Дней',
  Hours: 'Часов',
  Minutes: 'Минут',
  Attention: 'Внимание!!!',
  attentionText1:
    'Чтобы продолжить работу с диском, вам необходимо оплатить занятый объем дискового пространства.',
  attentionText2:
    'Если вы не продлите подписку на занятое дисковое пространство, диск и все его данные будут удалены.',
  DeletionVia: 'Удаление через',
  PayForDisk: 'Оплатить диск',
  diskWillBeDeleted: 'Диск будет удален через',
  day: 'день',
  Pay: 'Оплатить',
  payments: 'Платежи',
  drive_deleted: 'Диск был удалён',
  drive_created: 'Диск был создан',
  folder_created: 'Папка была создана',
  file_uploaded: 'Файл был загружен',
  file_downloaded: 'Файл был скачан',
  file_moved: 'Файлы были перемещены',
  file_deleted: 'Файлы были удалены',
  item_renamed: 'Элемент был переименован',
  drive_cleared: 'Диск был очищен',
  item_deleted: 'Файлы были удалены',
  invoice_succeeded: 'Оплата подписки прошла успешно',
  invoice_failed: 'Оплата счёта не удалась',
  subscription_unpaid: 'Подписка не оплачена',
  subscription_cancelled: 'Подписка была отменена',
  subscription_restored: 'Подписка была восстановлена',
  welcome_message: 'Добро пожаловать в сервис',
  trashed_cleared: 'Корзина была очищена',
  app_update_available: 'Доступно обновление приложения',
  quantity: 'Количество',
  readAll: 'Прочитать все',
  total_transactions_message: 'Всего было сделано {{count}} транзакций',
  total_transaction_message: 'Всего была сделана {{count}} транзакция',
  downloaded: 'Скачано',
  noOperations: 'Нет операций',
  volume: 'Объем',
  theOfficialUmi: 'Официальное приложение UMI доступно для iPhone, iPad, Android, macOS и Windows.',
  thisDevices: 'Это устройство',
  devices: 'Устройства',
  terminateRest: 'Завершить все другие сеансы',
  logOutAllDevices: 'Выход со всех устройств, кроме этого.',
  activeSessions: 'Активные сеансы',
  terminateThisSession: 'Вы хотите завершить этот сеанс?',
  terminate: 'Завершить',
  sureTerminateAll: 'Вы уверены, что хотите завершить все сеансы на ваших устройствах?',
};

export default translation;
