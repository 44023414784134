import React, { FC } from 'react';
import { FileType } from 'src/types';
import { CloseIcon } from 'src/components/Icons';
import { useTranslation } from 'react-i18next';
import { FileInfo } from 'src/components/InfoComponents';
import './styles.scss';
import '../infoComponent.scss';

type Props = {
  file: FileType;
  className?: string;
  onClose: () => void;
};

export const NewFileInfo: FC<Props> = ({ file, className, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className={'info-component'}>
      <div className="right-title">
        <span>{t('properties')}</span>
        <CloseIcon className="right-title__close" onClick={onClose} />
      </div>

      <div style={{ padding: '30px 30px' }}>
        <FileInfo file={file} className={className} />
      </div>
    </div>
  );
};
