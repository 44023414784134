import React, { useMemo, useState } from 'react';
import { useGetSessions, useTerminateSession } from 'src/api/sessions';
import { get } from 'lodash';
import { SessionType } from 'src/types/general';
import { getBrowserFingerprint } from 'src/helpers';
import { PlaneBlock } from 'src/components/PlaneBlock';
import { useTranslation } from 'react-i18next';
import { DeviceRow } from 'src/components/DevicesList/components/deviceRow';
import { ReactComponent as HandIcon } from './assets/tabler_hand-stop.svg';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { TerminateSession } from 'src/components/DevicesList/terminateSession';
import './styles.scss';
import LocalStorage from 'src/utils/Storage';

export const DevicesList = () => {
  const { mutateAsync: closeSession } = useTerminateSession();
  const { openConfirmation } = useAppContext();
  const { t } = useTranslation();
  const { data, refetch } = useGetSessions();
  const sessions: SessionType[] = get(data, 'data', []);

  const deviceId = getBrowserFingerprint();

  const currentDevice = useMemo(() => {
    return sessions.find((session) => session.device_id === deviceId);
  }, [sessions]);

  const [inactivePeriod, setInactivePeriod] = useState<string>('');
  const inActiveOptions = [
    { label: '1 week', value: '1' },
    { label: '1 month', value: '2' },
    { label: '3 months', value: '3' },
    { label: '6 months', value: '4' },
  ];

  const onSessionClose = (device: SessionType) => {
    openConfirmation({
      open: true,
      content: (
        <>
          <TerminateSession title={t('terminateThisSession')} />
        </>
      ),
      onSure: async () => {
        await closeSession(device.device_id);
        openConfirmation({ open: false });
        if (device.device_id === deviceId) {
          return LocalStorage.logout();
        }
        await refetch();
      },
      className: 'close-session-confirmation',
      okText: t('terminate'),
      cancelText: t('cancel'),
    });
  };

  const onTerminateAll = () => {
    openConfirmation({
      open: true,
      content: (
        <>
          <TerminateSession title={t('terminateRest')} text={t('sureTerminateAll')} />
        </>
      ),
      onSure: async () => {
        const promisses: any = [];
        sessions.forEach((session) => {
          if (session.device_id !== deviceId) {
            promisses.push(closeSession(session.device_id));
          }
        });
        await Promise.all(promisses);
        openConfirmation({ open: false });
        await refetch();
      },
      className: 'close-session-confirmation',
      okText: t('yes'),
      cancelText: t('no'),
    });
  };

  return (
    <div className="devices">
      <PlaneBlock title={t('thisDevices')}>
        {currentDevice && (
          <DeviceRow device={currentDevice} onClose={onSessionClose} className="devices__border" />
        )}
        <div className="terminate_rest" onClick={onTerminateAll}>
          <HandIcon />
          <span>{t('terminateRest')}</span>
        </div>
      </PlaneBlock>
      <div className="devices__label">{t('logOutAllDevices')}</div>
      <PlaneBlock title={t('activeSessions')}>
        {sessions
          .filter((session) => session.device_id !== deviceId)
          .map((session, index) => (
            <DeviceRow
              key={index}
              device={session}
              onClose={onSessionClose}
              className="devices__border"
            />
          ))}
      </PlaneBlock>
      <div className="devices__label">
        {t('theOfficialUmi')}
        {/* <a href="/">Learn More</a>*/}
      </div>
      {/*<PlaneBlock title="Automatically terminate old sessions">
        <InlineDropDown
          label={'If inactive for'}
          options={inActiveOptions}
          value={inactivePeriod}
          onChange={(v) => {
            setInactivePeriod(v as string);
          }}
        />
        &nbsp;
      </PlaneBlock>*/}
      &nbsp;
    </div>
  );
};
