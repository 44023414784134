///file/multi/download/files

import { API } from 'src/utils/API';
import { useMutation } from '@tanstack/react-query';

export const useDownloadFile = () => {
  const sendData = async ({ id, filename }: { id: string; filename: string }) => {
    try {
      const url = `${process.env.REACT_APP_API_PROTOCOL}://file.${process.env.REACT_APP_API_URL}/file/web?key=${id}&name=${filename}&attachment=true&octet_stream=true`;
      const link = document.createElement('a');
      link.href = url;
      /*link.target = '_blank';*/
      link.rel = 'noopener noreferrer';
      link.download = filename;
      link.click();
    } catch (error) {
      return {
        success: false,
        message: 'An error occurred while downloading the file',
      };
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const _useDownloadFiles = () => {
  const sendData = async (ids: string[]) => {
    try {
      const resp = await API.post(
        '/file/multi/download/files',
        {
          keys: ids,
        },
        'file',
      );
      console.log(resp);
      /*const url = `https://file.${process.env.REACT_APP_API_URL}/file/web?key=${id}&name=${filename}&attachment=true&octet_stream=true`;
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.download = filename;
      link.click();*/
    } catch (error) {
      return {
        success: false,
        message: 'An error occurred while downloading the file',
      };
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useDownloadFiles = () => {
  const sendData = async (ids: string[]) => {
    try {
      const resp = await API.post(
        '/file/multi/download/files',
        {
          keys: ids,
        },
        'file',
      );

      // Create a blob from the response data
      const blob = new Blob([resp.data], { type: 'application/zip' });

      // Create a link element to download the file
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = 'downloaded_files.zip'; // Optional: Set the default filename
      document.body.appendChild(link);
      link.click();

      // Cleanup: Remove the link after triggering the download
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } catch (error) {
      return {
        success: false,
        message: 'An error occurred while downloading the file',
      };
    }
  };

  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};
