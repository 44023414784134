import { FolderType } from 'src/types';
import { useParams } from 'react-router-dom';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { useTranslation } from 'react-i18next';
import { useCreateFavorite, useDeleteFavorite } from 'src/api/favorities';
import { useMoveToTrash } from 'src/api/trash';
import { useCopyFolder, useGetFolders } from 'src/api/folders';
import { DropDownItem } from 'src/components/Dropdown/types';
import {
  AddToFolderIcon,
  CopyIcon,
  DownloadIcon,
  InformationIcon,
  SelectMultipleIcon,
  RenameIcon,
} from 'src/components/Icons/context';
import { AddToFavoriteIcon, RecycleIcon } from 'src/components/Icons';
import React from 'react';
import { RenameForm } from 'src/pages/diskPage/components/RenameForm';
import { FOLDER_MOVE_SUCCESS } from 'src/config/Nnotifications';
import { useMoveObject } from 'src/api/objects';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import { useGetDisks } from 'src/hooks/useGetDisks';
import { useDiskManage } from 'src/hooks/useDiskManage';

export const useFolderState = (folder: FolderType, onDelete?: () => void) => {
  const { addSection } = useGlobalContext();
  const { openFolderInfo } = useDiskManage();
  const { invalidateCache: reloadDisks } = useGetDisks();
  const { folderId, diskId } = useParams();
  const { openModal, openDestinationModal, openConfirmation } = useAppContext();
  const { t } = useTranslation();
  const { openNotification } = useGlobalContext();

  const { mutateAsync: deleteFolder } = useMoveToTrash();
  const { mutateAsync: createFavorite } = useCreateFavorite();
  const { mutateAsync: deleteFavorite } = useDeleteFavorite();
  const { mutateAsync: moveFolder } = useMoveObject();
  const { mutateAsync: copyFolder } = useCopyFolder();

  const { invalidateCache, addFolderCache, removeFolderCache, updateFolderCacheGlobal } =
    useGetFolders(
      {
        parent_id: folderId || diskId || '',
      },
      true,
    );

  const onAddToFavorite = async () => {
    if (folder.is_favorite) {
      updateFolderCacheGlobal({ ...folder, is_favorite: false });
      const response = await deleteFavorite(folder.id);
      if (!response.success) {
        openNotification({
          type: 'error',
          notification: response.error,
        });
        updateFolderCacheGlobal({ ...folder, is_favorite: true });
      }
      return;
    }

    updateFolderCacheGlobal({ ...folder, is_favorite: true });
    const response = await createFavorite({
      id: folder.id,
    });

    if (!response.success) {
      openNotification({
        type: 'error',
        notification: response.error,
      });
      updateFolderCacheGlobal({ ...folder, is_favorite: false });
    }
  };

  const onMoveFolder = (folder: FolderType) => {
    openDestinationModal({
      open: true,
      title: `${t('move')}: ${folder.name}`,
      subTitle: `${t('currentPosition')}: ${folder.name}`,
      onMove: async (disk, destFolder) => {
        const resp = await moveFolder({
          item_ids: [folder.id],
          destination_id: destFolder?.id || disk.id,
        });
        if (resp.success) {
          invalidateCache();
          openDestinationModal({ open: false });
          openNotification({
            notification: FOLDER_MOVE_SUCCESS,
            type: 'success',
          });
          reloadDisks();
        }
      },
      hiddenFolders: [folder.id],
    });
  };

  const onCopyFolder = (f: FolderType) => {
    openDestinationModal({
      open: true,
      title: `${t('copy')}: ${folder.name}`,
      subTitle: '',
      btnText: t('copy'),
      onMove: async (disk, folder) => {
        const resp = await copyFolder({
          folder_id: f.id,
          destination_id: folder?.id || diskId || '',
        });

        if (resp.success) {
          openDestinationModal({ open: false });
          openNotification({
            notification: t('folderCopiedSuccessfully'),
            type: 'success',
          });
          invalidateCache();
          reloadDisks();
        } else {
          openNotification({
            notification: resp.error,
            type: 'error',
          });
        }
      },
    });
  };

  const onDeleteFolder = (folder: FolderType) => {
    openConfirmation({
      content: `${t('deleteConfirmation')}: ${folder.name}?`,
      onSure: async () => {
        openConfirmation({ open: false });
        removeFolderCache(folder.id);
        const resp = await deleteFolder({
          item_ids: [folder.id],
        });

        if (!resp.success) {
          addFolderCache(folder);
          openConfirmation({ open: false });
          openNotification({
            type: 'error',
            notification: resp.error,
          });
          return;
        }
        if (onDelete) {
          onDelete();
        }
        reloadDisks();
      },
    });
  };

  const items: DropDownItem[] = [
    {
      label: t('select'),
      onClick: () => {
        addSection(folder.id);
      },
      icon: <SelectMultipleIcon className="dropdown-svg-icon" />,
    },
    {
      label: t('rename'),
      onClick: () => {
        openModal({
          content: (
            <RenameForm
              type="folder"
              onSave={async (name) => {
                openModal({ open: false });
                updateFolderCacheGlobal({ ...folder, name });
              }}
              onClose={() => {
                openModal({ open: false });
              }}
              object={folder}
            />
          ),
          title: `${t('rename')}: ${folder.name}`,
          className: 'create-folder-modal',
        });
      },
      icon: <RenameIcon className="dropdown-svg-icon" />,
    },
    {
      label: t('download'),
      onClick: () => {},
      icon: <DownloadIcon className="dropdown-svg-icon" />,
    },
    {
      label: t('copy'),
      onClick: () => onCopyFolder(folder),
      icon: <CopyIcon className="dropdown-svg-icon" />,
    },
    {
      label: t('move'),
      onClick: () => onMoveFolder(folder),
      icon: <AddToFolderIcon className="dropdown-svg-icon" />,
    },
    /* {
      label: t('createFolder'),
      icon: <PlusFolderIcon className="dropdown-svg-icon" />,
      onClick: () => {
        openModal({
          content: (
            <CreateFolderForm
              onSave={async () => {
                openModal({ open: false });
              }}
              onClose={() => {
                openModal({ open: false });
              }}
              parentId={folder.id || diskId || ''}
            />
          ),
          title: t('createFolder'),
          className: 'create-folder-modal',
        });
      },
    },*/
    /*{
      label: t('uploadFiles'),
      icon: <UploadFileDarkIcon className="dropdown-svg-icon" />,
      isActive: true,
      onClick: () => {
        openModal({
          content: (
            <UploadFileModal
              diskId={''}
              folderId={folder.id}
              onFinish={async () => {
                openModal({ open: false });
              }}
            />
          ),
          title: t('uploadFiles'),
          className: 'create-folder-modal',
        });
      },
    },*/
    {
      label: folder.is_favorite ? 'Remove from favorite' : t('addToFavorite'),
      onClick: onAddToFavorite,
      icon: <AddToFavoriteIcon className="dropdown-svg-icon" />,
    },
    {
      label: t('properties'),
      onClick: () => {
        openFolderInfo(folder);
      },
      icon: <InformationIcon className="dropdown-svg-icon" />,
    },
    {
      label: t('deleteFolder'),
      onClick: () => onDeleteFolder(folder),
      icon: <RecycleIcon className={'disk__delete'} />,
      color: '#E20001',
    },
  ].filter(Boolean) as DropDownItem[];

  return {
    items,
    onDeleteFolder,
    onMoveFolder,
  };
};
