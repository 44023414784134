import React, { FC, useEffect, useRef, useState } from 'react';
import { ReactComponent as DiskIcon } from './assets/SSD.svg';
import { DiskColorType, DiskType } from 'src/types';
import { getConvertedSize } from 'src/helpers';
import { DISK_COLORS } from 'src/config/constants';
import classNames from 'classnames';
import { LogoLight } from 'src/components/Icons';
import { ReactComponent as FailedIcon } from './assets/failed.svg';
import CountdownTimer from 'src/components/CountdownTimer';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type Props = {
  disk: DiskType;
  className?: string;
  isEmpty?: boolean;
};

export const DiskObject: FC<Props> = ({ disk, className, isEmpty }) => {
  const [days, setDays] = useState<number>(0);
  const { t } = useTranslation();
  const iconRef = useRef<any>(null);
  const color: DiskColorType = disk.color || 'blue';

  useEffect(() => {
    if (iconRef.current) {
      const grStartElement = iconRef.current.querySelector('.gr-start');
      const grEndElement = iconRef.current.querySelector('.gr-end');

      if (grStartElement && grEndElement) {
        Object.assign(grStartElement.style, {
          stopColor: DISK_COLORS[color].start,
        });
        Object.assign(grEndElement.style, {
          stopColor: DISK_COLORS[color].end,
        });
      }
    }
  }, [disk]);

  return (
    <div className={classNames('disk-object', className, { locked: disk.is_blocked })}>
      <div className="disk-object__wrapper">
        <DiskIcon ref={iconRef} className="disk-object__bg" />
        <div className="disk-object__content">
          <div
            className="disk-object__gradient"
            style={{
              background: `linear-gradient(90deg, ${DISK_COLORS[color].start} 0%, ${DISK_COLORS[color].end} 100%)`,
            }}
          >
            <div>
              <div className="disk-object__server">
                {t('serverAWS')} <LogoLight className="disk-object__logo" />
              </div>
              <div className="disk-object__name">{disk.name}</div>
              <div className="disk-object__number">
                {String(disk.disk_number || '0000000000000000').replace(/(\d{4})(?=\d)/g, '$1 ')}
              </div>
            </div>
            <div className="disk-object__footer">
              <div className="disk-object__progress">
                <div
                  className="disk-object__progress-bar"
                  style={{ width: `${(disk.filled_volume / disk.total_volume) * 100}%` }}
                ></div>
              </div>
              <div className="disk-object__values">
                <div className="disk-object__values--start">
                  <span>Disk capacity</span>
                  {!isEmpty && (
                    <span className="highlight">{getConvertedSize(disk.filled_volume)}</span>
                  )}
                </div>
                <div className="disk-object__values--end">
                  <span>Max capacity</span>
                  {!isEmpty && (
                    <span className="highlight">{getConvertedSize(disk.total_volume)}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {disk.is_blocked && (
        <div className="disk-object__locked">
          <div className="disk-object__locked-wrapper">
            <div className="disk-object__locked-header">
              <FailedIcon /> <span>{t('paymentFailed')}</span>
            </div>
            <div className="disk-object__locked-content">
              <CountdownTimer
                finishDate="2024-12-31T23:59:59Z"
                onEnd={(d) => {
                  setDays(d);
                }}
                className=""
              />
            </div>
            <div className="disk-object__locked-footer">
              <span>
                {t('diskWillBeDeleted')} {days} {(days > 1 ? t('days') : t('day')).toLowerCase()}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
