import React, { FC } from 'react';
import { ReactComponent as DownloadIcon } from './assets/download-icon.svg';
import { ReactComponent as MoveIcon } from './assets/move-icon.svg';
import { ReactComponent as CloseIcon } from './assets/close-icon.svg';
import { DeleteIcon, RestoreIcon } from 'src/components/Icons';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { useTranslation } from 'react-i18next';
import { useMoveToTrash } from 'src/api/trash';
import { useFileCache } from 'src/api/files';
import { useGetFolders } from 'src/api/folders';
import { FILE_MOVE_SUCCESS } from 'src/config/Nnotifications';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import { useMoveObject } from 'src/api/objects';
import { useDownloadFiles } from 'src/api/download';
import { useGetDisks } from 'src/hooks/useGetDisks';
import './styles.scss';

type Props = {
  isTrash?: boolean;
  onRestoreClick?: () => void;
  onTrashDeleteClick?: () => void;
};

export const MultipleControls: FC<Props> = ({ isTrash, onRestoreClick, onTrashDeleteClick }) => {
  const { t } = useTranslation();
  const { selectedIds, updateState, openNotification, clearSelectedIds } = useGlobalContext();
  const { disk } = useDiskContext();
  const { openDestinationModal, openConfirmation } = useAppContext();

  const { mutateAsync: deleteFile } = useMoveToTrash();
  const { invalidateCache: reloadDisks } = useGetDisks();
  const { mutateAsync: moveFile } = useMoveObject();
  const { mutateAsync: downloadItems } = useDownloadFiles();

  const { removeFileCacheGlobal } = useFileCache({});
  const { removeFolderCacheGlobal } = useGetFolders({}, true);

  const onFinish = () => {
    clearSelectedIds();
    selectedIds.forEach((id) => {
      removeFileCacheGlobal(id);
      removeFolderCacheGlobal(id);
    });
    reloadDisks();
  };

  const onDeleteClick = () => {
    const length = selectedIds.length;
    const itemsLabel = t('items');
    const itemLabel = t('item');

    openConfirmation({
      content: `${t('deleteConfirmation')}: ${length} ${length === 1 ? itemLabel : itemsLabel}?`,
      onSure: async () => {
        openConfirmation({ open: false });
        const response = await deleteFile({
          item_ids: selectedIds,
        });

        if (!response.success) {
          openNotification({ notification: response.error, type: 'error' });
          return;
        }

        onFinish();
      },
    });
  };

  const onMoveClick = () => {
    const length = selectedIds.length;
    const itemsLabel = t('items');
    const itemLabel = t('item');

    openDestinationModal({
      open: true,
      title: `${t('move')}: ${length} ${length === 1 ? itemLabel : itemsLabel}`,
      subTitle: `${t('currentPosition')}: ${disk.name}`,
      onMove: async (disk, folder) => {
        const response = await moveFile({
          item_ids: selectedIds,
          destination_id: folder?.id || disk.id || '',
        });

        if (!response.success) {
          openNotification({ notification: response.error, type: 'error' });
          return;
        }

        openDestinationModal({ open: false });
        openNotification({
          notification: FILE_MOVE_SUCCESS,
          type: 'success',
        });
        onFinish();
      },
    });
  };

  const onDownloadClick = async () => {
    await downloadItems(selectedIds);
    clearSelectedIds();
  };

  if (selectedIds.length === 0) return <></>;
  return (
    <div className="multiple-controls">
      <div className="multiple-controls__selected">
        <div
          className="multiple-controls__button"
          onClick={() => {
            updateState({ selectedIds: [] });
          }}
        >
          <CloseIcon />
        </div>
        Selected: {selectedIds.length}
      </div>
      <div className="multiple-controls__buttons">
        {isTrash ? (
          <>
            <div
              className="multiple-controls__button"
              onClick={() => {
                onRestoreClick && onRestoreClick();
              }}
            >
              <RestoreIcon />
            </div>
            <div
              className="multiple-controls__button"
              onClick={() => {
                onTrashDeleteClick && onTrashDeleteClick();
              }}
            >
              <DeleteIcon />
            </div>
          </>
        ) : (
          <>
            <div className="multiple-controls__button" onClick={onDownloadClick}>
              <DownloadIcon />
            </div>
            <div className="multiple-controls__button" onClick={onMoveClick}>
              <MoveIcon />
            </div>
            <div className="multiple-controls__button" onClick={onDeleteClick}>
              <DeleteIcon />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
