import React, { FC } from 'react';
import './styles.scss';
import { FileType } from 'src/types';
import { FileMedia } from 'src/components/FileMedia';
import { FileFavoriteIcon } from 'src/components/Icons';

type Props = {
  file: FileType;
};

export const FileTree: FC<Props> = ({ file }) => {
  return (
    <div className="file-tree">
      <div className="file-tree__media">
        <FileMedia className={'binComp__media file'} fileId={null} extension={file.extension} />
      </div>
      <div className="file-tree__name">{file.name}</div>
    </div>
  );
};
