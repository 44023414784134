import React, { FC } from 'react';
import { Button } from 'src/components/Forms/Button';
import { useTranslation } from 'react-i18next';
import { AnimatedClip } from 'src/components/AnimatedClip';
import './styles.scss';

type Props = {
  onCLose: () => void;
};

export const SuccessModal: FC<Props> = ({ onCLose }) => {
  const { t } = useTranslation();

  return (
    <div className="disk-notification-modal success">
      <div className="disk-notification-modal__animation">
        <AnimatedClip type="blinking" className="disk-notification-modal__clip" />
      </div>
      <h3 className="disk-notification-modal__title">{t('paymentSuccessful')}!</h3>
      <p className="disk-notification-modal__text">{t('successPaymentMsg')}</p>
      <div className="disk-notification-modal__btn-wrapper">
        <Button
          type="basic"
          className="disk-notification-modal__btn"
          content={t('ok')}
          onClick={onCLose}
        />
      </div>
    </div>
  );
};
