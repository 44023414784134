import React, { FC } from 'react';
import { getLineStyles } from 'src/pages/diskPage/components/DragLayer/helpers';
import { FolderType } from 'src/types';
import classNames from 'classnames';
import { FolderIconComp } from 'src/pages/diskPage/components/ItemIcons/FolderIcon';
import './styles.scss';

type Props = {
  folder: FolderType;
  position: { x: number; y: number };
  i: number;
  totalLength: number;
};

export const LineFolder: FC<Props> = ({ folder, position, i, totalLength }) => {
  return (
    <div
      className={classNames('folder-column lineFolder')}
      style={getLineStyles(position, i, totalLength)}
    >
      <div className={classNames('folder-column__wrapper')}>
        <FolderIconComp folder={folder} />

        <div className="folder-column__details">
          <div className="folder-column__title" title={folder.name}>
            {folder.name}
          </div>
        </div>
      </div>
    </div>
  );
};
