import React, { FC, useEffect, useState, useCallback } from 'react';
import { Button } from 'src/components/Forms/Button';
import { ReactComponent as CheckIcon } from '../../assets/check-icon.svg';
import classNames from 'classnames';
import LocalStorage from 'src/utils/Storage';
import { useTranslation } from 'react-i18next';
import { CalculatorState } from 'src/pages/landings/Cloud/components/PriceCalculator/types';
import { parametersOptions } from 'src/components/CreateDisk/DiskParameters/config';
import { usePrices } from 'src/components/CreateDisk/usePrices';
import { useGetPrice } from 'src/api/payments';
import { debounce } from 'lodash';
import './styles.scss';

type ParametersType = {
  [key: string]: {
    title: string;
    value: string | number;
  };
};

type Props = {
  state: CalculatorState;
  onClick: () => void;
};

export const Parameters: FC<Props> = ({ state, onClick }) => {
  const [totalPrice, setTotalPrice] = useState<string>('0.00');
  const { mutateAsync: calculatePrice } = useGetPrice(true);
  const { t } = useTranslation();
  const { getPricePerGb } = usePrices(true);

  const getCapacity = (n: number) => {
    return n < 1000 ? `${n} GB` : `${n / 1000} TB`;
  };

  const isMonthly = state.invoice === 'monthly';

  const parametersList = parametersOptions(t).diskParameters.find(
    (pt) => pt.value === state.drive_type,
  );

  const price = getPricePerGb(state.drive_type);

  const parameters: ParametersType = {
    storage: {
      title: t('storageDatacenter'),
      value: 'AWS',
    },
    disk: {
      title: t('diskName'),
      value: state.name || '',
    },
    backup: {
      title: t('diskParameters'),
      value: parametersList?.label || '',
    },
    size: {
      title: t('numberOfGigabytes'),
      value: getCapacity(state.total_volume || 0),
    },
    cost: {
      title: t('costPerGigabyte'),
      value: `${price.toString().replace('.', ',')} $`,
    },
    period: {
      title: t('subscriptionTerm'),
      value: state.invoice === 'monthly' ? t('monthly') : t('annually'),
    },
    discount: {
      title: t('discountReceived'),
      value: isMonthly ? '' : '20%',
    },
  };

  const getTotalPrice = useCallback(
    debounce(async () => {
      const capacity = state.total_volume || 0;
      const { price } = await calculatePrice({
        backup_s_coefficient: 1,
        subscription_term: (state.invoice === 'monthly' ? 'monthly' : 'annual') as string,
        total_gb: capacity,
        drive_type: state.drive_type,
      });

      setTotalPrice((price / 100).toFixed(2));
    }, 200),
    [state, calculatePrice, isMonthly],
  );

  useEffect(() => {
    getTotalPrice();

    return () => {
      getTotalPrice.cancel();
    };
  }, [state, getTotalPrice]);

  const onSubscribe = () => {
    LocalStorage.set('storageParameters', {
      ...state,
      total_volume: state.total_volume * 1000000000,
    });
    onClick();
  };

  const subscriptionName: { [key: string]: string } = {
    monthly: t('monthly'),
    annually: t('annually'),
  };

  return (
    <div className="calculator-parameters__parameters">
      <div className="calculator-parameters__parameters-header">
        {t('selectedStorageParameters')}:
      </div>
      <div className="calculator-parameters__parameters-content">
        {Object.keys(parameters).map((key) => (
          <div key={key} className="calculator-parameters__parameters-item">
            <div className="calculator-parameters__parameters-item-title">
              <div className="calculator-parameters__parameters-item-icon">
                <CheckIcon className={classNames({ checkGray: !parameters[key].value })} />
              </div>
              {parameters[key].title}
            </div>
            <div
              className={classNames('calculator-parameters__parameters-item-value', {
                oneLine: key === 'disk',
              })}
              title={key === 'disk' ? (parameters[key].value as string) : ''}
            >
              {parameters[key].value}
            </div>
          </div>
        ))}
      </div>
      <div className="calculator-parameters__parameters-footer">
        <div className="calculator-parameters__parameters-footer-title">
          {t('costFor')} <span>{subscriptionName[state.invoice as string]}</span>
        </div>
        <div className="calculator-parameters__parameters-footer-value">{totalPrice} $</div>
        <Button
          type="basic"
          content={t('subscription')}
          fullWidth
          onClick={onSubscribe}
          className="calculator-parameters__parameters-footer-btn"
        />
        {state.invoice === 'annually' && (
          <div className="calculator-parameters__parameters-footer-discount">
            -20% {t('monthly')}
          </div>
        )}
      </div>
    </div>
  );
};
