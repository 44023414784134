import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetFolderStats } from 'src/api/folders';
import { BackIcon, HomeIcon } from 'src/components/Icons';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import './styles.scss';
import { useGetParentCount } from 'src/components/Breadcrumbs/useGetParentCount';

export const Breadcrumbs = () => {
  const isTrash = document.location.pathname.includes('trash');
  const navigate = useNavigate();
  const { folderId, diskId } = useParams();
  const { disk } = useDiskContext();

  const { data } = useGetFolderStats(folderId || '');
  const folder = data?.stats || { name: '', parent_type: '', parent_id: '' };
  const parentId = folder.parent_type !== 'DRIVE' ? folder.parent_id : undefined;
  const name = folder.name.length > 0 ? folder.name : disk.name;
  const [count, setCount] = React.useState(0);

  const { countParents } = useGetParentCount();

  useEffect(() => {
    (async () => {
      const newCount = await countParents(folderId);
      setCount(newCount);
    })();
  }, [folderId]);

  if ((!folderId || !disk.id) && !isTrash) {
    return <></>;
  }

  const onDiskClick = () => {
    navigate(`/disk/${diskId}`);
  };

  const onParentFolderClick = () => {
    if (parentId) {
      navigate(isTrash ? `/trash/${diskId}/${parentId}` : `/disk/${diskId}/folder/${parentId}`);
      return;
    }
    if (folderId && isTrash) {
      navigate(`/trash/${diskId}`);
      return;
    }
    navigate(`/disk/${diskId}`);
  };

  return (
    <div className="breadcrumbs">
      <div className="breadcrumbs__item back" onClick={onDiskClick}>
        <HomeIcon />
      </div>
      <div className="breadcrumbs__item back breadcrumbs__back-count" onClick={onParentFolderClick}>
        <div className="breadcrumbs__back-count--inner">
          <BackIcon /> {count > 0 && <span>{count}</span>}
        </div>
      </div>
      <div className="breadcrumbs__content">
        <div className="breadcrumbs__item last">{name}</div>
      </div>
    </div>
  );
};
