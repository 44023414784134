import classNames from 'classnames';
import React, { FC } from 'react';
import './styles.scss';

type Props = {
  type?:
    | 'primary'
    | 'secondary'
    | 'basic'
    | 'basic-border'
    | 'danger'
    | 'basic-danger'
    | 'text'
    | 'text-danger'
    | 'primary-dark';
  onClick?: () => void;
  isDisabled?: boolean;
  content?: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  size?: 'small' | 'regular';
};

export const Button: FC<Props> = ({
  className,
  type,
  icon,
  content,
  onClick,
  isDisabled,
  fullWidth,
  size = 'regular',
}) => {
  const onClickHandler = () => {
    if (onClick && !isDisabled) {
      onClick();
    }
  };
  return (
    <div
      className={classNames('button', `button__${type}`, `button__${size}`, className, {
        button__full: fullWidth,
        button__disabled: isDisabled,
      })}
      onClick={onClickHandler}
    >
      {!!icon && <div className="button__icon">{icon}</div>}
      <div className="button__content">{content}</div>
    </div>
  );
};
