import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FileIcon } from 'src/pages/diskPage/components/ItemIcons/FileIcon';
import { FileType, FolderType } from 'src/types';
import { FolderIconComp } from 'src/pages/diskPage/components/ItemIcons/FolderIcon';
import { TableHeaderType, TableItemType } from 'src/components/Table/types';
import { getConvertedSize } from 'src/helpers';
import moment from 'moment';
import { DATE_FORMAT } from 'src/config/constants';
import { useTranslation } from 'react-i18next';
import { Table } from 'src/components/Table';
import { Actions } from './components/Actions';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import { useTrashState } from 'src/pages/trashPage/useTrashState';
import './styles.scss';

export const Trash = () => {
  const { toggleSection } = useGlobalContext();
  const { diskId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { onRestoreClick, onDeleteClick, handleSort, tableItems, sortItems } = useTrashState();

  const fileIconSection = (item: any) => {
    return (
      <div className="table-items__cell">
        <FileIcon file={item as FileType} />
      </div>
    );
  };

  const folderIconSection = (item: any) => {
    return (
      <div className="table-items__cell">
        <FolderIconComp folder={item as FolderType} />
      </div>
    );
  };

  const renderIcon = (item: TableItemType) => {
    if (item.innerType === 'folder') {
      return folderIconSection(item);
    }
    return fileIconSection(item);
  };

  const renderSize = (item: TableItemType) => {
    let size = item?.size;
    if (item.statistics) {
      item.statistics.map((stat: any) => {
        size += stat.size;
      });
    }
    return <>{getConvertedSize(size as number)}</>;
  };

  const renderActions = (item: TableItemType) => {
    return (
      <div className="table-items__cell">
        <Actions
          onRestoreClick={() => {
            onRestoreClick(item);
          }}
          onDeleteClick={() => {
            onDeleteClick(item);
          }}
          onSelectClick={() => {
            toggleSection(item.id);
          }}
        />
      </div>
    );
  };
  const headers: TableHeaderType[] = [
    { name: t('preview'), field: '', sortable: false, width: '200px', render: renderIcon },
    { name: t('name'), field: 'name', sortable: true, width: 'auto' },
    { name: `${t('type')} (${t('extension')})`, field: 'type', sortable: true, width: '20%' },
    { name: t('size'), field: 'size', sortable: true, width: '20%', render: renderSize },
    {
      name: t('timeOfDeletion'),
      field: 'created_at',
      sortable: true,
      width: '20%',
      render: (item) => <div>{moment(item.created_at as string).format(DATE_FORMAT)}</div>,
    },
    { name: '', field: '', sortable: false, width: '300px', render: renderActions },
  ];

  const onItemClick = (item: TableItemType) => {
    if (item.innerType === 'folder') {
      navigate(`/trash/${diskId}/${item.id}`);
    }
  };

  const onHandleSort = (field: string, order: 'asc' | 'desc') => {
    handleSort(field);
  };
  return (
    <>
      <Table
        className=""
        headers={headers}
        onRowClick={onItemClick}
        items={sortItems(tableItems)}
        onSort={onHandleSort}
        disableDrag={true}
        /*
      onScrollEnd={() => {
        fetchNextPage();
      }}*/
      />
    </>
  );
};
