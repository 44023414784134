import React, { FC, ReactNode } from 'react';
import './styles.scss';
import { Navigations } from 'src/pages/fileShare/components/Navigation';

type Props = {
  children: ReactNode;
};

export const Layout: FC<Props> = ({ children }) => {
  return (
    <div className="share-layout">
      <Navigations />
      <div className="share-layout__content">{children}</div>
    </div>
  );
};
