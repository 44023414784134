import { API } from 'src/utils/API';
import { useMutation, useQuery } from '@tanstack/react-query';
import LocalStorage from 'src/utils/Storage';
import { UserType } from 'src/types/users';

interface FetchDataResponse {
  success: boolean;
  profile: UserType;
}

export const useLoadProfile = (disabled: boolean = false) => {
  const fetchData = async (): Promise<FetchDataResponse> => {
    const resp = await API.get('/users');
    if (resp.success) {
      const avatarResp = resp.data.avatar_url
        ? await API.getAbs(
            `${process.env.REACT_APP_API_PROTOCOL}://file.${process.env.REACT_APP_API_URL}/avatars?key=${resp.data.avatar_url}`,
          )
        : { data: null };

      const profileData = {
        success: true,
        profile: {
          ...resp.data,
          avatarData: avatarResp.data,
        },
      };
      LocalStorage.set('profile', profileData.profile);
      return profileData;
    } else {
      return resp;
    }
  };

  return useQuery({
    queryKey: ['profile'],
    queryFn: fetchData,
    staleTime: 1000 * 60 * 5,
    enabled: !disabled,
    retry: 0,
    initialData: () => {
      return {
        profile: LocalStorage.getCurrentUser(),
        success: true,
      };
    },
    initialDataUpdatedAt: 0,
  });
};

export const useUpdateProfile = () => {
  const sendData = async (payload: any) => {
    const resp = await API.put('/users', payload);
    if (resp.success) {
      return {
        success: true,
        profile: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useUpdatePhone = () => {
  const sendData = async (payload: any) => {
    const resp = await API.put('/users/phone_numbers', payload);
    if (resp.success) {
      return {
        success: true,
        profile: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useConfirmPhone = () => {
  const sendData = async (code: string) => {
    const resp = await API.get(`/users/phone_numbers/change/confirm/${code}`);
    if (resp.success) {
      return {
        success: true,
        profile: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useLogout = () => {
  const sendData = async () => {
    const resp = await API.post('/signout', {
      refresh_token: LocalStorage.getRefreshToken(),
    });
    if (resp.success) {
      return {
        success: true,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};
