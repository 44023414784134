import React, { FC, useState } from 'react';
import './styles.scss';

export type TabType = {
  header: string;
  content: React.ReactNode;
};

type Props = {
  tabs: TabType[];
};

export const Tabs: FC<Props> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <div className="gallery-tabs">
      <div className="gallery-tabs__header">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`gallery-tabs__header-item ${activeTab === index ? 'active' : ''}`}
            onClick={() => setActiveTab(index)}
          >
            {tab.header}
          </div>
        ))}
      </div>
      <div className="gallery-tabs__content">{tabs[activeTab]?.content}</div>
    </div>
  );
};
