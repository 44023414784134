import React from 'react';
import { CloseIcon } from 'src/components/Icons';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from './assets/white-logo.svg';
import { Accordion, AccordionItemType } from 'src/components/Accordion';
import { ReactComponent as MarketIcon } from './assets/market-icon.svg';
import { ReactComponent as TaskIcon } from './assets/task-icon.svg';
import { ReactComponent as ChatsIcon } from './assets/chats-icon.svg';
import { ReactComponent as SearchIcon } from './assets/search-icon.svg';
import { Button } from 'src/components/Forms/Button';
import { useBusinessSubscription } from 'src/api/payments';
import { useLoadProfile } from 'src/api/profile';
import { get } from 'lodash';
import { UserType } from 'src/types/users';
import { useSubscription } from 'src/components/Subscription/useSubscription';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import './styles.scss';

export const Subscription = () => {
  const { openNotification } = useGlobalContext();
  const { t } = useTranslation();
  const { mutateAsync: subscribe, isPending } = useBusinessSubscription();
  const { data, refetch } = useLoadProfile();
  const user: UserType = get(data, 'profile', {});
  const { openSubscriptionArea } = useSubscription();

  const onClose = () => {
    openSubscriptionArea();
  };

  const items: AccordionItemType[] = [
    {
      title: t('marketplace'),
      content: t('marketplaceText'),
      icon: <MarketIcon />,
      open: true,
    },
    {
      title: t('taskBoards'),
      content: t('taskBoardsText'),
      icon: <TaskIcon />,
    },
    {
      title: t('groupChats'),
      content: t('groupChatsText'),
      icon: <ChatsIcon />,
    },
    {
      title: t('fileSearch'),
      content: t('fileSearchText'),
      icon: <SearchIcon />,
    },
  ];

  const buttonContent = !user.has_business_subscription ? t('subscribe') : t('unsubscribe');

  const onBtnClick = async () => {
    const resp = await subscribe(user.has_business_subscription);
    if (resp.success) {
      if (!user.has_business_subscription) {
        document.location.href = resp.checkout_url;
        return;
      }
      await refetch();
    } else {
      openNotification({ notification: resp.error, type: 'error' });
    }
  };

  return (
    <div className="subscription-menu">
      <div className="right-title">
        <span>{t('subscription')}</span>
        <CloseIcon className="right-title__close" onClick={onClose} />
      </div>
      <div className="subscription-menu__content-wrapper">
        <div className="subscription-menu__media mb-20">
          <div className="subscription-menu__media-content">
            <div className="subscription-menu__media-title">{t('supportForDevelopers')}</div>
            <div className="subscription-menu__media-logo">
              <Logo />
            </div>
            <div className="subscription-menu__media-description">{t('businessSubscription')}</div>
          </div>
        </div>
        <div className="subscription-menu__subtitle">{t('futureFunctionality')}</div>
        <div className="subscription-menu__features mb-20">
          <Accordion items={items} />
        </div>
        <div className="subscription-menu__details mb-20">
          <div className="subscription-menu__details--left">
            <div className="subscription-menu__details-title">{t('monthly')}</div>
            <div className="subscription-menu__details-desc">{t('monthlySupportDevelopers')}</div>
          </div>
          <div className="subscription-menu__price">1$/{t('month')}</div>
        </div>

        <Button
          type="primary"
          content={buttonContent}
          className="subscription-menu__btn"
          fullWidth
          onClick={onBtnClick}
          isDisabled={isPending}
        />
      </div>
    </div>
  );
};
