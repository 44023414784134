import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Login, AuthComplete } from 'src/pages/auth';
import { PrivateRoute } from 'src/libs/routes/privateRoute';
import { DiskPage } from 'src/pages/diskPage';
import { TermsPage } from 'src/pages/auth/policy/terms';
import { PolicyPage } from 'src/pages/auth/policy/policy';
import { PublicRoute } from 'src/libs/routes/publicRoute';
import { ContactUs } from 'src/pages/contactUs';
import { TrashPage } from 'src/pages/trashPage';
import { Cloud } from 'src/pages/landings/Cloud';
import { Home } from 'src/pages/landings/Home';
import { GalleryList } from 'src/pages/fileShare/pages/gallery/list';
import { CreateGallery } from 'src/pages/fileShare/pages/createGallery';
import { SubscriptionsPage } from 'src/pages/subscriptions';

export const router = createBrowserRouter([
  {
    path: '/cloud',
    element: (
      <PublicRoute>
        <Cloud />
      </PublicRoute>
    ),
  },
  {
    path: '/auth/complete',
    element: (
      <PublicRoute>
        <AuthComplete />
      </PublicRoute>
    ),
  },
  {
    path: '/terms',
    element: (
      <PublicRoute>
        <TermsPage />
      </PublicRoute>
    ),
  },
  {
    path: '/policy',
    element: (
      <PublicRoute>
        <PolicyPage />
      </PublicRoute>
    ),
  },
  {
    path: '/',
    element: (
      <PublicRoute>
        <Home />
      </PublicRoute>
    ),
  },
  {
    path: '/disk/',
    element: (
      <PrivateRoute>
        <DiskPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/disk/:diskId',
    element: (
      <PrivateRoute>
        <DiskPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/disk/:diskId/folder/:folderId',
    element: (
      <PrivateRoute>
        <DiskPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/trash/:diskId/:folderId?',
    element: (
      <PrivateRoute>
        <TrashPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/subscriptions',
    element: (
      <PrivateRoute>
        <SubscriptionsPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/contact-us',
    element: (
      <PublicRoute>
        <ContactUs />
      </PublicRoute>
    ),
  },
  {
    path: '/shares/galleries',
    element: (
      <PublicRoute>
        <GalleryList />
      </PublicRoute>
    ),
  },
  {
    path: '/shares/gallery/create',
    element: (
      <PublicRoute>
        <CreateGallery />
      </PublicRoute>
    ),
  },

  {
    path: '*',
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
]);
