import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import devicesVideo from './assets/Video800-450.mp4';
import appleImg from './assets/appstore.png';
import googleImg from './assets/googleplay.png';
import appstoreLink from './assets/appstore_link.png';
import playmarketLink from './assets/playmarket_link.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { APPSTORE_URL, PLAYMARKET_URL } from 'src/config/urls';
import './styles.scss';

export const Apps = () => {
  const { t } = useTranslation();
  return (
    <div className="apps">
      <div className="apps__data">
        <div className="apps__title">{t('downloadAPP')}</div>
        <div className="apps__description">{t('appDescription')}</div>
        <div className="apps__links">
          <div className="app__apple">
            <Link target="_blank" to={APPSTORE_URL}>
              <img src={appleImg} alt="" className="apps__links-logo" />
            </Link>
            <img src={appstoreLink} className="apps__qr" alt="" />
          </div>
          <div className="app__google">
            <Link target="_blank" to={PLAYMARKET_URL}>
              <img src={googleImg} alt="" className="apps__links-logo" />
            </Link>

            <img src={playmarketLink} className="apps__qr" alt="" />
          </div>
        </div>
      </div>
      <div className="apps__img">
        {/*  <img src={devicesImg} alt="Devices" />*/}
        <video
          src={devicesVideo}
          autoPlay
          loop
          muted
          playsInline
          className="apps__video-background"
        />
      </div>
    </div>
  );
};
