import React, { FC } from 'react';
import { DiskType } from 'src/types';
import { DiskObject } from 'src/components/DiskObject';
import { Button } from 'src/components/Forms/Button';
import { useTranslation } from 'react-i18next';
import { emptyDisk } from 'src/dataMocks';
import './styles.scss';

type Props = {
  onCLick: () => void;
};
export const CreatingDisk: FC<Props> = ({ onCLick }) => {
  const { t } = useTranslation();

  const disk: DiskType = {
    ...emptyDisk,
    name: t('newDisk'),
    color: 'gray',
  };

  return (
    <div className="creat-disk-obj">
      <DiskObject disk={disk} className="sub-disk" isEmpty={true} />
      <div className="creat-disk-obj__title">{t('newDisk')}</div>
      <div className="creat-disk-obj__subtitle">{t('completeFreedom')}</div>
      <div className="creat-disk-obj__description">{t('customizeStorage')}</div>
      <Button
        type="primary"
        content={t('createDisk')}
        fullWidth
        className="creat-disk-obj__btn mt-20 skip-outside"
        onClick={onCLick}
      />
    </div>
  );
};
