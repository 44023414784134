import React, { FC, ReactNode, useEffect, useState } from 'react';
import { ArrowTopIcon } from 'src/components/Icons';
import classNames from 'classnames';
import './styles.scss';

export type AccordionItemType = {
  title: string;
  icon: ReactNode;
  content: ReactNode;
  open?: boolean;
};

type Props = {
  items: AccordionItemType[];
  className?: string;
};

export const Accordion: FC<Props> = ({ items, className }) => {
  const [accordionItems, setAccordionItems] = useState<AccordionItemType[]>(items);

  useEffect(() => {
    setAccordionItems(items);
  }, [items]);

  const onToggle = (index: number) => {
    const newAccordionItems = accordionItems.map((item, i) => {
      if (i === index) {
        return { ...item, open: !item.open };
      }
      return item;
    });
    setAccordionItems(newAccordionItems);
  };

  return (
    <div className={classNames('accordion', className)}>
      {accordionItems.map((item, index) => (
        <div className="accordion__item" key={index}>
          <div className="accordion__item-header" onClick={() => onToggle(index)}>
            <div className="accordion__item-header--left">
              {item.icon}
              <span>{item.title}</span>
            </div>
            <ArrowTopIcon className={classNames('accordion__close-icon', { closed: !item.open })} />
          </div>
          <div className={classNames('accordion__item-content', { opened: item.open })}>
            <div>{item.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
