export default class LocalStorage {
  static set = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  static get = (key: string) => {
    try {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : value;
    } catch (e) {
      return {};
    }
  };

  static clear = () => {
    localStorage.clear();
  };

  static getCurrentUser = () => {
    const profile = LocalStorage.get('profile');
    return profile ? profile : undefined;
  };

  static getToken = () => {
    const session = LocalStorage.get('session');
    return session ? session.access_token : '';
  };

  static getRefreshToken = () => {
    const session = LocalStorage.get('session');
    return session ? session.refresh_token : '';
  };

  static getUserId = () => {
    const session = LocalStorage.get('session');
    return session ? session.user_id : '';
  };

  static logout = () => {
    LocalStorage.clear();
    document.location.href = '/';
  };

  static remove = (key: string) => {
    localStorage.removeItem(key);
  };

  static isAuthorised = () => {
    return !!LocalStorage.get('session');
  };
}
