import React, { FC } from 'react';
import moment from 'moment/moment';
import { NotificationType } from 'src/types';
import { useTranslation } from 'react-i18next';
import { notificationsConfig } from 'src/components/NotificationsSection/components/Notifications/config';
import { getConvertedSize } from 'src/helpers';

const groupNotificationsByDate = (notifications: NotificationType[]) => {
  return notifications.reduce((groups: { [key: string]: NotificationType[] }, notification) => {
    const date = moment.utc(notification.created_at).format('YYYY-MM-DD');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(notification);
    return groups;
  }, {});
};

type Props = {
  isCollapsed: boolean;
  notificationsItems: NotificationType[];
  onNotificationClick?: (notification: NotificationType) => void;
  type?: string;
};

export const NotificationsList: FC<Props> = ({
  isCollapsed,
  notificationsItems,
  onNotificationClick,
  type,
}) => {
  const { t } = useTranslation();

  const groupedNotifications = groupNotificationsByDate(notificationsItems);

  const flatNotifications = Object.keys(groupedNotifications).reduce(
    (acc: { date: string; notifications: NotificationType[] }[], date: string) => {
      acc.push({
        date,
        notifications: groupedNotifications[date],
      });
      return acc;
    },
    [],
  );

  const getVisibleNotifications = () => {
    if (isCollapsed) {
      let count = 0;
      const visibleNotifications = flatNotifications.map((group) => {
        const visibleItems = group.notifications.slice(0, Math.max(0, 4 - count));
        count += visibleItems.length;
        return {
          ...group,
          notifications: visibleItems,
        };
      });
      return visibleNotifications.filter((group) => group.notifications.length > 0);
    }
    return flatNotifications;
  };

  return (
    <div className="notifications-list__content">
      {getVisibleNotifications().map((group) => (
        <div key={group.date}>
          <div className="notifications-list__date">
            {moment.utc(group.date).calendar(null, {
              sameDay: `[${t('today')}]`,
              lastDay: `[${t('yesterday')}]`,
              lastWeek: 'dddd',
              sameElse: 'DD MMMM YYYY',
            })}
          </div>
          {group.notifications.map((notification: NotificationType) => (
            <div
              key={notification.id}
              className="notifications-list__row"
              onClick={() => {
                if (onNotificationClick) onNotificationClick(notification);
              }}
            >
              <div className="notifications-list__item">
                <div className="notifications-list__icon">
                  {notificationsConfig(t)[notification.type].icon}
                </div>
                <div className="notifications-list__item-content">
                  <div className="notifications-list__item-title">
                    {!notification.is_read && typeof notification.is_read !== 'undefined' && (
                      <div className="notifications-list__indicator"></div>
                    )}
                    {notificationsConfig(t)[notification.type].message}
                  </div>
                  <div className="notifications-list__item-message">
                    {notification.count === 1 && notification?.metadata?.folder_name && (
                      <p>{notification?.metadata?.folder_name}</p>
                    )}
                    {notification.count === 1 && notification?.metadata?.file_name && (
                      <p>{notification?.metadata?.file_name}</p>
                    )}
                    {notification.count > 1 && (
                      <div className="notifications-list__metadata">
                        <p>
                          {t('volume')}: {getConvertedSize(notification.total_size as number)}
                        </p>
                        <p>
                          {t('quantity')}: {notification.count}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="notifications-list__item-time">
                  <div>
                    {notification?.message?.paid_amount && (
                      <div className="notifications-list__price">
                        {(+notification.message.paid_amount / 100).toFixed(2)}$
                      </div>
                    )}
                    {moment.utc(notification.created_at).format('HH:mm')}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}

      {!notificationsItems.length && (
        <div className="notifications-list__empty">
          {type === 'operation' ? t('noOperations') : t('noNotifications')}
        </div>
      )}
    </div>
  );
};
