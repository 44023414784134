import { useLoadDisks2 } from 'src/api/disks';
import { DiskType } from 'src/types';

export const useGetDisks = (disabled: boolean = false) => {
  const { data, isLoading, ...rest } = useLoadDisks2({ disabled });
  const disks: DiskType[] = data?.disks || [];

  // Retrieve saved sort order from localStorage
  const savedOrder = localStorage.getItem('sortedDiskIds');
  let sortedDisks = disks;

  if (savedOrder) {
    const savedDiskIds = JSON.parse(savedOrder) as string[];

    // Sort disks based on the saved order of IDs in localStorage
    sortedDisks = savedDiskIds
      .map((id) => disks.find((disk) => disk.id === id))
      .filter((disk): disk is DiskType => disk !== undefined);

    disks.filter((disk) => !sortedDisks.includes(disk)).forEach((disk) => sortedDisks.push(disk));

    // Sort by favorite status if necessary
    //sortedDisks.sort((a, b) => (a.is_favorite === b.is_favorite ? 0 : a.is_favorite ? -1 : 1));
  }

  return {
    disks: sortedDisks,
    isLoading,
    ...rest,
  };
};
