import { SortDirection } from 'src/components/Icons';
import React from 'react';
import { FileType, FolderType } from 'src/types';
import { sortByFavorite } from 'src/helpers/sort';
import { useAppContext } from 'src/context/AppContext/useAppcontext';

export const useSort = () => {
  const { sort, setSort } = useAppContext();
  const handleSort = (field: string) => {
    const newDirection = sort?.field === field && sort.direction === 'asc' ? 'desc' : 'asc';
    setSort({ field, direction: newDirection });
  };

  const getRightIcon = (field: string) => {
    if (sort?.field === field) {
      return sort.direction === 'asc' ? (
        <SortDirection className="direction__asc" />
      ) : (
        <SortDirection className="direction__desc" />
      );
    }
    return null;
  };

  const sortItems = <T,>(folders: T[]) => {
    if (!sort) return folders;
    return folders.sort((a, b) => {
      const field = sort.field as keyof T;
      const fieldA = a[field];
      const fieldB = b[field];

      if (typeof fieldA === 'string' && typeof fieldB === 'string') {
        if (sort.direction === 'asc') {
          return fieldA.localeCompare(fieldB);
        } else {
          return fieldB.localeCompare(fieldA);
        }
      } else if (typeof fieldA === 'number' && typeof fieldB === 'number') {
        if (sort.direction === 'asc') {
          return fieldA - fieldB;
        } else {
          return fieldB - fieldA;
        }
      }
      return 0;
    });
  };

  const sortFolder = (folders: FolderType[]) => {
    const tempFolders = [...folders];
    return sortItems(tempFolders).sort(sortByFavorite);
  };

  const sortFiles = (files: FileType[]) => {
    return [...files]
      .sort((a, b) => {
        if (sort?.field === 'name') {
          if (sort?.direction === 'asc') {
            return a.name.localeCompare(b.name);
          } else {
            return b.name.localeCompare(a.name);
          }
        } else if (sort?.field === 'size') {
          if (sort.direction === 'asc') {
            return a.size - b.size;
          } else {
            return b.size - a.size;
          }
        } else if (sort?.field === 'type') {
          if (sort.direction === 'asc') {
            return a.type.localeCompare(b.type);
          } else {
            return b.type.localeCompare(a.type);
          }
        } else if (sort?.field === 'created_at') {
          if (sort.direction === 'asc') {
            return a.created_at.localeCompare(b.created_at);
          } else {
            return b.created_at.localeCompare(a.created_at);
          }
        }
        return 0;
      })
      .sort(sortByFavorite);
  };

  return {
    sort,
    setSort,
    handleSort,
    getRightIcon,
    sortFolder,
    sortFiles,
    sortItems,
  };
};
