import React from 'react';
import { Layout } from 'src/pages/fileShare/components/Layout';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'src/pages/fileShare/pages/createGallery/components/Tabs';
import { LoadedFiles } from 'src/pages/fileShare/pages/createGallery/components/LoadedFiles';
import { GalleryDesign } from 'src/pages/fileShare/pages/createGallery/components/Design';
import { GallerySettings } from 'src/pages/fileShare/pages/createGallery/components/Settings';
import './styles.scss';

export const CreateGallery = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      header: 'Загруженные файлы',
      content: <LoadedFiles />,
    },
    {
      header: 'Оформление',
      content: <GalleryDesign />,
    },
    {
      header: 'Настройки',
      content: <GallerySettings />,
    },
  ];

  return (
    <Layout>
      <div className="create-gallery">
        <div className="create-gallery__title">Создание галереи</div>
        <Tabs tabs={tabs} />
      </div>
    </Layout>
  );
};
