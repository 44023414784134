import React, { FC } from 'react';
import { VerticalDotsIcon } from 'src/components/Icons';
import { DropDownItem } from 'src/components/Dropdown/types';
import classNames from 'classnames';
import { ContextDropdown } from 'src/components/Dropdown/context';
import './styles.scss';

type Props = {
  items: DropDownItem[];
  className?: string;
  id: string;
};
export const ActionButton: FC<Props> = ({ items, className, id }) => {
  return (
    <ContextDropdown
      className={className}
      trigger={
        <div className={classNames('action-button')}>
          <VerticalDotsIcon className="action-button__dots" />
        </div>
      }
      items={items}
      id={`act-btn-${id}`}
      leftClick
    />
  );
};
