import { API } from 'src/utils/API';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FileType, FolderType } from 'src/types';
import { objectToQueryParams } from 'src/helpers';
import { sortFiles } from 'src/helpers/sort';
import { updateGlobalCache } from 'src/api/ cacheUtils';
import { RequestPayload } from 'src/types/general';

export type MoveToTrashPayload = {
  item_ids: string[];
};

export type GetTrashObjectsPayload = RequestPayload & {
  type: 'folders' | 'files';
  extension_type?: string;
};

export type RestoreFromTrashPayload = {
  item_ids: string[];
  destination_id: string;
};

export type DeleteFromTrashPayload = {
  item_ids: string[];
};

export const useGetTrashObjects = (
  payload: GetTrashObjectsPayload,
  isDisabled: boolean = false,
) => {
  const queryKey = ['load-trash-files', payload];

  const { parent_id, type, ...rest } = payload;

  const sorObjects = (f1: FileType, f2: FileType) => {
    if (f1.is_favorite === f2.is_favorite) {
      return f1.name.toLowerCase().localeCompare(f2.name.toLowerCase());
    }
    return f1.is_favorite ? -1 : 1;
  };

  const fetchData = async () => {
    const resp = await API.get(
      `/trash/resources/${parent_id}/${type}?${objectToQueryParams(rest)}`,
      'archive',
    );
    if (resp.success) {
      return {
        success: true,
        objects: resp.data.items.sort(sorObjects),
      };
    } else {
      return resp;
    }
  };

  const { data, ...query } = useQuery({
    queryKey: queryKey,
    queryFn: fetchData,
    staleTime: 0,
    enabled: !!payload.parent_id && !isDisabled,
    retry: 0,
  });
  const invalidateCache = () => {
    query.refetch();
  };

  return { data, ...query, invalidateCache };
};

export const useMoveToTrash = () => {
  const sendData = async (payload: MoveToTrashPayload) => {
    const resp = await API.put('/trash/move', payload, 'archive');
    if (resp.success) {
      return {
        success: true,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useRestoreFromTrash = () => {
  const sendData = async (payload: RestoreFromTrashPayload) => {
    const resp = await API.put('/trash/restore', payload, 'archive');
    if (resp.success) {
      return {
        success: true,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useClearTrash = () => {
  const sendData = async () => {
    const resp = await API.put('/trash/clear', null, 'archive');
    if (resp.success) {
      return {
        success: true,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useDeleteFromTrash = () => {
  const sendData = async (payload: DeleteFromTrashPayload) => {
    const resp = await API.put('/trash/delete/items', payload, 'archive');
    if (resp.success) {
      return {
        success: true,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useTrashCache = (payload: any) => {
  const queryClient = useQueryClient();
  const queryKey = ['load-trash', payload];

  const removeFromCash = (updatedFile: FileType | FolderType) => {
    queryClient.setQueryData(queryKey, (oldData: any) => {
      if (oldData && oldData.success) {
        return {
          ...oldData,
          objects: oldData.objects.filter(
            (folder: FileType | FolderType) => folder.id !== updatedFile.id,
          ),
        };
      }
      return oldData;
    });
  };

  const addFileCache = (updatedFile: FileType) => {
    queryClient.setQueryData(queryKey, (oldData: any) => {
      if (oldData && oldData.success) {
        return {
          ...oldData,
          files: [...oldData.files, updatedFile].sort(sortFiles),
        };
      }
      return oldData;
    });
  };

  const deleteAllFromCash = () => {
    updateGlobalCache(queryClient, (oldData) => {
      if (oldData && oldData.success && oldData.objects) {
        return {
          ...oldData,
          objects: [],
        };
      }
      return oldData;
    });
  };

  const removeFromAllCaches = (updatedFile: FileType | FolderType) => {
    updateGlobalCache(queryClient, (oldData) => {
      if (oldData && oldData.success && oldData.objects) {
        return {
          ...oldData,
          objects: oldData.objects.filter(
            (folder: FileType | FolderType) => folder.id !== updatedFile.id,
          ),
        };
      }
      return oldData;
    });
  };

  return {
    removeFromCash,
    deleteAllFromCash,
    queryClient,
    addFileCache,
    removeFromAllCaches,
  };
};
