import React, { FC } from 'react';
import classNames from 'classnames';
import { DiskGradient } from 'src/components/CreateDisk/DiskDesign/components/DiskIcon/DIskGradient';
import { DISK_COLORS } from 'src/config/constants';
import './styles.scss';
import { DiskColorType } from 'src/types';

type Props = {
  selected?: boolean;
  color: DiskColorType;
  onClick: () => void;
};

export const DiskIcon: FC<Props> = ({ selected, color, onClick }) => {
  const colors = DISK_COLORS;

  return (
    <div
      className={classNames('form-disk-icon', { selected })}
      style={{ borderColor: `${selected ? colors[color].start : 'transparent'}` }}
      onClick={onClick}
    >
      <DiskGradient gradientStart={colors[color].start} gradientEnd={colors[color].end} />
    </div>
  );
};
