import React from 'react';
import { useLoadProfile } from 'src/api/profile';
import './styles.scss';
import { UserType } from 'src/types/users';
import { defaultUserProfile } from 'src/dataMocks';
import { get } from 'lodash';
import {
  EmailIcon,
  PhoneIcon,
  ProfileIcon,
  SimpleProfileIcon,
} from 'src/components/Icons/personal';
import { WarningIcon } from 'src/components/Icons';

export const ViewProfile = () => {
  const { data } = useLoadProfile();
  const profile: UserType = get(data, 'profile', defaultUserProfile);
  return (
    <div className="view-profile">
      <div className="view-profile__row">
        <ProfileIcon className="view-profile__icon" />
        <span>{profile.full_name}</span>
      </div>
      {profile.phone_number?.length && (
        <div className="view-profile__row">
          <PhoneIcon className="view-profile__icon" />
          <span>{profile.phone_number}</span>
          {!profile.phone_number?.length && <WarningIcon className="view-profile__warning" />}
        </div>
      )}
      {profile.email?.length && (
        <div className="view-profile__row">
          <EmailIcon className="view-profile__icon" />
          <span>{profile.email}</span>
          {!profile.email?.length && <WarningIcon className="view-profile__warning" />}
        </div>
      )}
      <div className="view-profile__row">
        <SimpleProfileIcon className="view-profile__icon" />
        <span>{profile.user_name}</span>
      </div>
    </div>
  );
};
