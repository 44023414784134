export const blockedHashtags = [
  '#offensive',
  '#hate',
  '#violence',
  '#drugs',
  '#illegal',
  '#nudity',
  '#porn',
  '#racism',
  '#terror',
  '#abuse',
  '#weapon',
  '#exploit',
  '#fraud',
  '#crime',
  '#extremism',
  '#inappropriate',
  '#misinformation',
  '#spam',
  '#scam',
  '#bully',
  '#hatecrime',
  '#selfharm',
  '#suicide',
  '#sexism',
  '#harassment',
  '#childabuse',
  '#assault',
  '#propaganda',
  '#terrorism',
  '#illegalcontent',
];

export const badHashtags = [
  '#test',
  '#example',
  '#placeholder',
  '#hashtag',
  '#untagged',
  '#generic',
  '#stuff',
  '#todo',
  '#random',
  '#nonspecific',
  '#misc',
  '#etc',
  '#something',
  '#nothing',
  '#idk',
  '#unrelated',
  '#irrelevant',
  '#nonsense',
];
