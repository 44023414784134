import React, { FC } from 'react';
import { AnimateIcon, ImgUploadIcon } from 'src/components/Icons';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { UploadAvatarModal } from './components/UploadAvatarModal';
import { useUploadAvatar } from './hooks/useUploadAvatar';
import { useLoadProfile } from 'src/api/profile';
import { get } from 'lodash';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';

export const UserAvatar: FC = () => {
  const [loadingAvatar, setLoadingAvatar] = React.useState<boolean>(false);
  const { openNotification } = useGlobalContext();
  const { data: profileData, refetch, isPending } = useLoadProfile();
  const profile = get(profileData, 'profile', {});

  const { t } = useTranslation();

  const { mutateAsync } = useUploadAvatar();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [imageEvent, setImageEvent] = React.useState<
    React.ChangeEvent<HTMLInputElement> | undefined
  >();

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageEvent(e);
    setShowModal(true);
  };

  const onChangeHandler = async (data: string) => {
    setLoadingAvatar(true);
    setShowModal(false);
    setImageEvent(undefined);

    const resp = await mutateAsync(data);
    if (resp.success) {
      await refetch();
      openNotification({
        type: 'success',
        open: true,
        notification: t('profileUpdatedSuccessfully'),
        autoHideDuration: 3,
      });
    } else {
      openNotification({
        open: true,
        type: 'error',
        notification: resp.error,
        autoHideDuration: 3,
      });
    }
    setLoadingAvatar(false);
  };

  return (
    <div className="user-avatar">
      <div className="user-avatar__wrapper">
        <div className="user-avatar__avatar">
          {(loadingAvatar || isPending) && (
            <div className="user-avatar__animation-wrapper">
              <AnimateIcon className="user-avatar__animation" />
            </div>
          )}
          {profile?.avatarData ? (
            <img src={profile.avatarData || ''} alt="avatar" />
          ) : (
            <ImgUploadIcon />
          )}
        </div>
        <div className="user-avatar__text">
          {!imageEvent && (
            <input
              className="user-avatar__input-file"
              type="file"
              accept="image/*"
              onChange={onImageChange}
              title={''}
            />
          )}
          {t('setYourPhoto')}
        </div>
      </div>
      {showModal && (
        <UploadAvatarModal
          onClose={() => {
            setShowModal(false);
            setImageEvent(undefined);
          }}
          url={''}
          event={imageEvent}
          onChange={onChangeHandler}
        />
      )}
    </div>
  );
};

export default UserAvatar;
