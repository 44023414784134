import React, { FC, useState } from 'react';
import { DiskType, FileType } from 'src/types';
import { useGetFilteredFolders } from 'src/hooks/useGetFilteredFolders';
import { ReactComponent as BackIcon } from 'src/components/Modals/ModalDestination/assets/back.svg';
import { FolderTree } from 'src/components/Modals/ModalDestination/components/FoldersTree/components/folderTree';
import { FolderIcon } from 'src/components/Icons';
import { WizardFolder } from 'src/components/Modals/ModalDestination';
import { FilesPayload, useGetFiles } from 'src/api/files';
import { FileTree } from 'src/components/Modals/ModalDestination/components/FoldersTree/components/fileTree';
import { useTranslation } from 'react-i18next';
import { SearchInput } from 'src/components/Forms/Inputs/SearchInput';
import './styles.scss';

type Props = {
  disk: DiskType;
  folder: WizardFolder | null;
  onBackCLick: () => void;
  onOpen: (folder: WizardFolder) => void;
  onAddFolder: () => void;
  onClick: (folder: WizardFolder | null) => void;
  onMove: (folder: WizardFolder) => void;
  btnText?: string;
  hiddenFolders?: string[];
};

export const FoldersTree: FC<Props> = ({
  disk,
  onBackCLick,
  folder,
  onOpen,
  onAddFolder,
  onClick,
  onMove,
  btnText,
  hiddenFolders,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const filesPayload: FilesPayload = {
    parent_id: folder?.id || disk.id,
    offset: 0,
    limit: 1000,
  };
  const { data: filesData } = useGetFiles(filesPayload);
  const files: FileType[] = filesData || [];

  const { data: folders } = useGetFilteredFolders({
    parent_id: folder?.id || disk.id,
  });

  const [selectedFolder, setSelectedFolder] = useState<WizardFolder | null>(null);

  return (
    <div className="folders-tree">
      <div className="folders-tree__back">
        {folder?.parent_id && (
          <div className="folders-tree__back-control" onClick={onBackCLick}>
            <BackIcon />
          </div>
        )}

        {folder ? (
          <>
            <FolderIcon className="folder-tree__icons-folder" />
            <div className="folders-tree__back-title">{folder.name}</div>
          </>
        ) : (
          <>
            <div className="folders-tree__back-title">{disk.name}</div>
          </>
        )}
      </div>
      <div className="folders-tree__search-wrapper">
        <SearchInput onChange={setSearch} className="folders-tree__search-input" value={search} />
      </div>
      <div className="folders-tree__items">
        {folders
          .filter((f) => !hiddenFolders?.includes(f.id))
          .filter((f) => f.name.toLowerCase().includes(search.toLowerCase()))
          .map((f) => {
            const folder: WizardFolder = {
              ...f,
              parentFolder: null,
            };
            return (
              <FolderTree
                key={folder.id}
                folder={folder}
                selected={folder.id === selectedFolder?.id}
                onCLick={() => {
                  const clickedFolder = selectedFolder?.id === folder.id ? null : folder;
                  setSelectedFolder(clickedFolder);
                  onClick(clickedFolder);
                }}
                onMove={onMove}
                onOpen={onOpen}
                btnText={btnText}
              />
            );
          })}
        {files.map((file) => {
          return <FileTree key={file.id} file={file} />;
        })}
      </div>
      <div className="folders-tree__cta" onClick={onAddFolder}>
        {t('createNewFolder')}
      </div>
    </div>
  );
};
