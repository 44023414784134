const translation = {
  welcome: 'Ласкаво просимо до UMI!',
  description: 'Це багатомовний додаток.',
  loginDescription: 'більше ніж просто хмарне сховище',
  searchByFiles: 'Пошук за файлами',
  settings: 'Налаштування',
  editProfile: 'Редагувати профіль',
  security: 'Безпека',
  notifications: 'Сповіщення',
  contacts: 'Контакти',
  support: 'Підтримка',
  extension: 'Розширення',
  paymentsHistory: 'Історія платежів',
  removedFiles: 'Видалені файли',
  storageSelection: 'Вибір сховища',
  personalData: 'Особисті дані',
  saveChanges: 'Зберегти зміни',
  nameLastName: 'Повне ім’я',
  dateOfBirth: 'Дата народження',
  username: "Ім'я користувача",
  department: 'Відділ',
  position: 'Посада',
  belgium: 'Бельгія',
  bulgaria: 'Болгарія',
  croatia: 'Хорватія',
  cyprus: 'Кіпр',
  czechRepublic: 'Чеська Республіка',
  denmark: 'Данія',
  estonia: 'Естонія',
  finland: 'Фінляндія',
  france: 'Франція',
  germany: 'Німеччина',
  greece: 'Греція',
  hungary: 'Угорщина',
  ireland: 'Ірландія',
  italy: 'Італія',
  latvia: 'Латвія',
  lithuania: 'Литва',
  luxembourg: 'Люксембург',
  malta: 'Мальта',
  netherlands: 'Нідерланди',
  poland: 'Польща',
  portugal: 'Португалія',
  romania: 'Румунія',
  slovakia: 'Словаччина',
  slovenia: 'Словенія',
  spain: 'Іспанія',
  ukraine: 'Україна',
  phoneNumber: 'Номер телефону',
  signIn: 'Увійти',
  openQRCode: 'Відкрити QR-код',
  continueWithApple: 'Продовжити з Apple',
  continueWithGoogle: 'Продовжити з Google',
  enter: 'Ввести',
  enterCodeFromSMS: 'Введіть код з SMS',
  sendingSMSWithTheCodeAgain: 'Надсилання SMS з кодом знову',
  smsHasBeenSent: 'SMS було відправлено',
  chooseALanguage: 'Виберіть мову',
  enterUniqueUsername: 'Введіть унікальне ім’я користувача',
  usernameLetsPeopleContactYou: 'Ім’я користувача дозволяє людям зв’язатися з вами на UMI',
  yourUsername: 'Ваше ім’я користувача',
  invalidPhoneNumber:
    'Введений номер телефону має неправильний формат. Будь ласка, введіть номер у міжнародному форматі.',
  phoneNumberIsRequired: 'Номер телефону є обов’язковим',
  invalidOtp: 'Неправильний OTP',
  main: 'Головна',
  mySubscriptions: 'Мої підписки',
  loading: 'Завантаження',
  profile: 'Профіль',
  help: 'Допомога',
  privacyPolicy: 'Політика конфіденційності',
  recycleBin: 'Корзина',
  balance: 'Баланс',
  refill: 'Поповнити',
  addYourID: 'Додайте свій ID',
  id: 'ID',
  email: 'Електронна пошта',
  addYourFullName: 'Додайте своє повне ім’я',
  addYourEmail: 'Додайте свою електронну пошту',
  addYourPosition: 'Додайте свою посаду',
  addYourDepartment: 'Додайте свій відділ',
  accountConnection: 'Зв’язок з обліковим записом',
  logOut: 'Вийти',
  deleteAccount: 'Видалити обліковий запис',
  no: 'Ні',
  yes: 'Так',
  logOutConfirmation: 'Ви впевнені, що хочете вийти з облікового запису?',
  profileUpdatedSuccessfully: 'Профіль успішно оновлено',
  setYourPhoto: 'Встановіть своє фото',
  addYourUserName: 'Додайте своє ім’я користувача',
  fullNameIsRequired: 'Повне ім’я є обов’язковим',
  usernameIsRequired: 'Ім’я користувача є обов’язковим',
  departmentIsRequired: 'Відділ є обов’язковим',
  positionIsRequired: 'Посада є обов’язковою',
  validEmailError: 'має бути дійсною електронною поштою',
  changeTheCover: 'Змінити обкладинку',
  addToFavorite: 'Додати до улюблених',
  renameDisk: 'Перейменувати диск',
  downloadDisk: 'Завантажити диск',
  diskName: 'Назва диска',
  cancel: 'Скасувати',
  createDis: 'Створити диск',
  deleteConfirmation: 'Ви впевнені, що хочете видалити',
  deleteDisk: 'Видалити диск',
  download: 'Завантажити',
  select: 'Вибрати',
  share: 'Поділитися',
  copy: 'Копіювати',
  move: 'Перемістити',
  properties: 'Властивості',
  rename: 'Перейменувати',
  delete: 'Видалити',
  owner: 'Власник',
  moveSelectTheFilesUpload:
    'Перемістіть або виберіть файли, які ви хочете завантажити зі свого комп’ютера',
  folderName: 'Назва папки',
  createFolder: 'Створити папку',
  deleteFolder: 'Видалити папку',
  renameFolder: 'Перейменувати папку',
  uploadFiles: 'Завантажити файли',
  restore: 'Відновити',
  restoreTo: 'Відновити до',
  deletePermanently: 'Видалити назавжди',
  sorting: 'Сортування',
  name: 'Назва',
  uploaded: 'Завантажено',
  uploading: 'Завантаження',
  canceled: 'Скасовано',
  uploadYourFirstFile: 'Завантажте свій перший файл',
  areYouSureWantDeleteAll: 'Ви впевнені, що хочете видалити всі файли та папки?',
  filesWillBePermanentlyDeleted: 'Усі файли будуть назавжди видалені зі сховища UMI Cloud',
  willBePermanentlyDeleted: 'буде назавжди видалено зі сховища UMI Cloud',
  currentPosition: 'Поточне положення',
  createNewFolder: 'Створити нову папку',
  selectLocation: 'Виберіть місце розташування',
  moveTo: 'Перемістити до',
  folderWithFiles: 'ПАПКА',
  dateOfEditing: 'Дата редагування',
  size: 'Розмір',
  type: 'Тип',
  byName: 'За назвою',
  bySize: 'За розміром',
  yourFiles: 'Ваші файли',
  createANewDisk: 'Створити новий диск',
  subscription: 'Підписка',
  save: 'Зберегти',
  accessControlTo: 'Контроль доступу до',
  trash: 'Кошик',
  fileTypes: 'Типи файлів',
  totalDiskVolume: 'Загальний обсяг диска',
  creationDate: 'Дата створення',
  parentFolderName: 'Назва батьківської папки',
  parentDiskName: 'Назва батьківського диска',
  fileExtension: 'Розширення файлу',
  fileSize: 'Розмір файлу',
  totalFolderVolume: 'Загальний обсяг папки',
  fileSharedSuccessfully: 'Файл успішно надіслано',
  editors: 'Редактори',
  readers: 'Читачі',
  files: 'файли',
  myDisks: 'Мої диски',
  createDisk: 'Створити диск',
  chat: 'Чат',
  search: 'Пошук',
  folders: 'Папки',
  restoreAllFiles: 'Відновити всі файли',
  deleteAll: 'Видалити всі',
  preview: 'Попередній перегляд',
  uMIMoreThanCloudStorage: 'Більше ніж хмарне сховище',
  landingPageDescription:
    'Ми не просто зберігаємо файли, ми надаємо можливість створити єдину бібліотеку та підключити до неї вашу команду',
  getStart: 'Розпочати',
  moreAboutTheService: 'Більше про сервіс',
  ourNetworkDC: 'Наша мережа центрів обробки даних',
  dataCentersDescription:
    'Ми невпинно розширюємо нашу глобальну мережу центрів обробки даних, щоб забезпечити максимально безпечне зберігання ваших даних і оптимізувати витрати на зберігання.',
  submitAnApplication: 'Подати заявку',
  dataStructuring: 'Структурування даних',
  selectingCostAndStorageCapacity: "Вибір вартості та обсягу пам'яті",
  tagAndHashtagSystemFiles: 'Система тегів і хештегів для файлів',
  electronicDocumentSigning: 'Електронне підписання документів',
  connectionOfYourStorage: 'Підключення власного сховища',
  chatForCorporateClients: 'Чати для корпоративних клієнтів',
  storageCapabilities: 'Можливості сховища',
  months: 'місяці',
  month: 'місяць',
  storageDatacenter: 'Центр зберігання даних',
  dataBackup: 'Резервне копіювання даних',
  numberOfGigabytes: 'Кількість гігабайтів',
  costPerGigabyte: 'Вартість одного гігабайта',
  subscriptionTerm: 'Термін підписки',
  discountReceived: 'Отримана знижка',
  selectedStorageParameters: 'Обрані параметри сховища',
  monthly: 'Щомісячно',
  costFor: 'Вартість за',
  storageCapacity: 'Ємність для зберігання',
  price: 'Ціна',
  storageTime: 'Час зберігання',
  reservationOptions: 'Варіанти бронювання',
  noReservation: 'Без бронювання',
  reservation: 'Бронювання',
  chooseServerStorage: 'Виберіть серверне сховище',
  AWSStorage: 'Сховище AWS',
  downloadAPP: 'Завантажити додаток',
  appDescription:
    'Керуйте своїми файлами з будь-якої точки світу. Мобільний додаток для вашого пристрою доступний на маркетплейсах',
  unifiedMediaInterface: 'Універсальний медіа-інтерфейс',
  footerDescription:
    'Інтегрований та уніфікований спосіб керування і спільного використання мультимедійних даних у хмарному сховищі.',
  socialDescription:
    'UMI - це хмарний сервіс для мобільних пристроїв і комп’ютерів. Швидкий, безпечний, функціональний.',
  cloud: 'Хмара',
  teamWork: 'Командна робота',
  fileSearch: 'Пошук файлів',
  basicFunctionalityStorage: 'Основна функціональність сховища',
  tiredOfSwitchingBetweenMultipleCloudStorages:
    'Втомилися перемикатися між кількома хмарними сховищами?',
  nowAllYourFilesAreInOnePlace:
    'Тепер усі ваші файли в одному місці! Наш сервіс об’єднує популярні хмарні платформи, забезпечуючи зручний та інтуїтивний інтерфейс для керування всіма вашими даними.',
  createYourFirstDiscParameters: 'Створіть свій перший диск з оптимальними параметрами зберігання',
  creatingStorageDisk: 'Створення диска для зберігання даних',
  annually: 'Щорічно',
  invoice: 'Рахунок-фактура',
  homeDescription: 'Функціональне сховище, файловий маркетплейс, інструменти для командної роботи',
  homeTitle: 'Майбутня бібліотека цифрових активів',
  fileSharing: 'Файлообмінник',
  homeSlide1Title: 'Єдина бібліотека цифрових активів',
  homeSlide1Subtitle: 'Ми вклали весь свій досвід у створення зручного сховища',
  homeSlide1Description:
    'Проста монетизація, структуризація накопичених архівів, багаторівнева система доступів, створення і налаштування дисків, система заявок, підписання документів — для яких раніше потрібно було звертатися до різних ресурсів, тепер все необхідне в одній бібліотеці!',
  homeSlide2Title: 'Функціональне зберігання та управління',
  homeSlide2Subtitle: 'Оптимізуйте витрати, підвищуючи ефективність',
  homeSlide2Description:
    'Ми не просто зберігаємо файли, ми надаємо можливість створити єдину бібліотеку і підключити до неї вашу команду',
  homeSlide3Title: 'Керуйте та монетизуйте свої цифрові активи',
  homeSlide3Subtitle: 'Ми не просто зберігаємо, ми монетизуємо!',
  homeSlide3Description:
    'Ми створили всі інструменти для сортування старих архівів, щоб ви змогли побачити потенціал старих даних і дати їм нове життя',
  downloadApp: 'Завантажити APP',
  homeAppsDescription: 'Керуйте своїми файлами з будь-якої точки світу.',
  convenienceTitle: 'Зручність',
  convenienceDescription: 'Простий, інтуїтивно зрозумілий інтерфейс',
  goalsTitle: 'Постановка задач',
  goalsDescription: 'Контроль за виконанням робіт',
  syncTitle: 'Синхронізація',
  syncDescription: 'Доступ з кількох пристроїв одночасно',
  speedTitle: 'Швидкість',
  speedDescription: 'Висока швидкість завантаження файлів',
  noBreaksTitle: 'Без перешкод',
  noBreaksDescription: 'Без обмежень на розмір завантажуваних файлів',
  opennessTitle: 'Відкритість',
  opennessDescription: 'Відкритий API для розробників',
  securityTitle: 'Безпека',
  securityDescription: 'Подвійне шифрування даних під час передачі',
  communicationTitle: 'Комунікації',
  communicationDescription: 'Групові чати для команд і особистого користування',
  flexTitle: 'Гнучкість',
  flexDescription: 'Вибір обсягу і вартості зберігання',
  newsTitle: 'Новини',
  reasonsTitle: 'Чому UMI?',
  cloudSlide1Title: 'Основні Функції',
  cloudSlide1Subtitle: 'Більше не потрібно платити за зайві гігабайти',
  cloudSlide1Description:
    'Вибирайте обсяг, вартість і місце зберігання самостійно, створюючи окремі диски для різних проєктів і завдань в рамках одного інтерфейсу',
  cloudSlide2Title: 'Усі Необхідні Інструменти в Одній Бібліотеці',
  cloudSlide2Subtitle: 'Втомилися перемикатися між кількома хмарними сховищами?',
  cloudSlide2Description:
    'Функціональне відображення файлів, фільтр категорій файлів, пошук за тегами, хештегами—все це та багато іншого доступно в нашому інтерфейсі',
  cloudSlide3Title: 'Створення Дисків для Різних Проєктів та Команд',
  cloudSlide3Subtitle: 'Оптимізуйте витрати, використовуючи функціональність сховища',
  cloudSlide3Description:
    'Ми перші, хто надає можливість створювати диски в єдиному інтерфейсі для окремих проєктів і команд, вибираючи обсяг, вартість і варіанти резервування самостійно',
  cloudSlide4Title: 'Багаторівнева Система Доступів та Прав',
  cloudSlide4Subtitle: 'Втомилися перемикатися між кількома хмарними сховищами?',
  cloudSlide4Description:
    'Ви зберігаєте все в одному місці, але кожен член команди бачить файли в рамках своєї посади',
  cloudSlide5Title: 'Система Тікетів для Постановки Завдань',
  cloudSlide5Subtitle: 'Втомилися перемикатися між кількома хмарними сховищами?',
  cloudSlide5Description:
    'Ми допомагаємо зібрати всіх співробітників, завдання та інструменти в одному місці. Створюйте дошки, призначайте завдання та прикріплюйте файли в єдиній бібліотеці файлів',
  cloudOption1Title: 'Основні функції',
  cloudOption1Subtitle: 'Більше не потрібно платити за зайві гігабайти та функціонал',
  cloudOption1Description:
    'Ми прибрали все зайве та надали кожному користувачу право самостійно вирішувати, яким має бути сховище, виходячи з особистих потреб, а не шаблонів сервісів. Ти визначаєш сам:',
  cloudOption1Param1: 'Де зберігати',
  cloudOption1Param2: 'Як резервувати',
  cloudOption1Param3: 'Який обсяг сховища',
  cloudOption1Param4: 'Кількість дисків для зберігання',

  cloudOption2Title: 'Простий функціонал для складних завдань',
  cloudOption2Subtitle: '',
  cloudOption2Description:
    'Ми створили простий функціонал, який допоможе вирішувати складні завдання — від пошуку потрібних файлів до структурування великих масивів даних в інтуїтивно зрозумілому інтерфейсі',
  cloudOption2Param1: 'Ваші файли у наочному вигляді',
  cloudOption2Param2: 'Фільтр категорій файлів',
  cloudOption2Param3: 'Пошук за хештегами',
  cloudOption2Param4: 'Створіть галерею та бібліотеку з доступом за посиланням',

  cloudOption3Title: 'Не витрачай бюджет на зберігання',
  cloudOption3Subtitle: '',
  cloudOption3Description:
    'Ми перші, хто надає можливість створювати диски в єдиному інтерфейсі для окремих проєктів і команд, самостійно вибираючи обсяг, вартість та варіанти резервування',
  cloudOption3Param1: 'Створюй диск для проєкту',
  cloudOption3Param2: 'Обирай обсяг і місце зберігання',
  cloudOption3Param3: 'Підключайте до кожного диска окремі команди',
  cloudOption3Param4: 'Обирайте, як резервувати',

  cloudOption4Title: 'Тікетна система постановки завдань',
  cloudOption4Subtitle: 'Не витрачайте час на нотатки, ставте завдання в одному місці',
  cloudOption4Description:
    'Не витрачайте час на дзвінки, щоб зберігати завдання в одному місці. Ми допоможемо зібрати всіх співробітників, завдання та інструменти в одній папці. Створюйте диски, призначайте завдання та прив’язуйте файли в одній бібліотеці файлів.',
  cloudOption4Param1: 'Групові та особисті чати',
  cloudOption4Param2: 'Коментарі, нотатки, сповіщення',
  cloudOption4Param3: 'Прикріплюй файли до завдань',
  cloudOption4Param4: 'Підписання документів',
  landingPageSubtitle: 'Оптимізуй витрати, підвищуючи ефективність!',
  aboutUmi: 'Про UMI',
  faq: 'Часті запитання',
  partnership: 'Партнерство',
  forMedia: 'Для ЗМІ',
  products: 'Продукти',
  mobileApp: 'Мобільний додаток',
  forComputers: "Для комп'ютерів",
  webVersion: 'Веб-версія',
  infrastructure: 'Інфраструктура',
  api: 'API',
  translations: 'Переклади',
  instantView: 'Миттєвий перегляд',
  development: 'Розробка',
  jobs: 'Вакансії',
  focusGroup: 'Фокус-група',
  askAQuestion: 'Задати питання',
  personalInformation: 'Особиста інформація',
  agreements: 'Угоди',
  today: 'Сьогодні',
  yesterday: 'Вчора',
  paymentAmount: 'Сума платежу',
  newDisk: 'Новий диск',
  myBalance: 'Мій баланс',
  supportForDevelopers: 'Підтримка для розробників',
  businessSubscription: 'Бізнес-підписка',
  recentOperations: 'Останні операції',
  uploadsAndDownloads: 'Завантаження та скачування',
  cancelAll: 'Скасувати все',
  resume: 'Продовжити',
  finished: 'Завершено',
  file: 'файл',
  language: 'Мова',
  termsOfUse: 'Умови використання',
  noUploads: 'Немає завантажень та скачувань',
  create: 'Створити',
  upload: 'Завантажити',
  occupancy: 'Зайнятість',
  diskCapacity: 'Обсяг диска',
  items: 'предмети',
  item: 'предмет',
  digitalAssetLibrary: 'Бібліотека цифрових активів',
  timeOfDeletion: 'Час видалення',
  functions: 'Функції',

  highPerformance: 'Високопродуктивне зберігання для регулярно використовуваних даних',
  requestProcessing: 'Обробка запитів менше ніж за 10 мілісекунд',
  increaseAccessSpeed:
    'Збільшує швидкість доступу в 10 разів, знижує витрати на обробку запитів на 50% порівняно з S3 Standard',
  availabilityZone:
    'Вибираючи зону доступності AWS, клієнти можуть розташувати зберігання та обчислювальні ресурси поруч, щоб значно знизити затримку, час обробки та підвищити використання обчислювальних ресурсів для зменшення загальної вартості TCO',
  accelerateAnalytics:
    'Прискорюйте аналітику та робочі навантаження машинного навчання з інтеграцією AWS',
  scaleRequests: 'Масштабування для обробки мільйонів запитів на хвилину',
  optimizeDatasets: "Оптимізовано для великих наборів даних із багатьма невеликими об'єктами",
  leverageS3:
    'Використовуйте наявні API Amazon S3 з іншим типом кошика для покупок - каталогом сміттєвих кошиків',
  storageAvailability:
    'Зберігання призначене для забезпечення 99,95% доступності відповідно до угоди про рівень обслуговування на 99,9%',
  generalPurposeStandard:
    "Універсальний стандарт для часто використовуваних даних. Пропонує високу надійність, доступність та продуктивність об'єктного сховища для зберігання часто використовуваних даних. Забезпечує низьку затримку та високу пропускну здатність.",
  standard: 'Стандарт',

  generalPurposeStorage: 'Універсальне сховище для часто використовуваних даних',
  lowLatencyHighThroughput: 'Низька затримка та висока пропускна здатність',
  storageAvailabilityAgreement:
    'Сховище призначене для забезпечення 99,99% доступності відповідно до умов угоди про рівень обслуговування на 99,9%',
  highPerformanceShort: 'Високопродуктивне',
  expressOneZone:
    'Express One Zone — це високопродуктивний клас зберігання з однією зоною доступності, спеціально розроблений для забезпечення стабільного доступу до регулярно використовуваних даних і додатків, чутливих до затримки, зі швидкістю в мілісекунди.',
  infrequentAccess: 'Рідкісний доступ',
  standardIA:
    'Standard - IA — це ідеальний вибір для зберігання даних, до яких рідко звертаються, але які необхідно отримати швидко. Висока надійність, висока пропускна здатність і низька затримка, при цьому пропонуються низькі витрати на гігабайт. Ідеально підходить для довготривалого зберігання даних і резервного копіювання.',

  rarelyUsedData: 'Рідко використовувані дані, які потребують миттєвого доступу',
  lowLatencyHighThroughputS3:
    'Низька затримка та висока пропускна здатність на рівні сховища S3 Standard',
  storageAvailabilityAgreement99:
    'Сховище призначене для забезпечення 99,9% доступності відповідно до умов угоди про рівень обслуговування на 99%',
  archiving: 'Архівування',

  glacierArchiving:
    'Glacier спеціально розроблений для архівування даних та пропонує максимальну продуктивність, гнучкість відновлення та мінімальну вартість архівного хмарного зберігання',
  flexibleRetrieval: 'Гнучке відновлення',
  retrievalSupport:
    'Підтримує відновлення за лічені хвилини для невеликих обсягів і 5-12 годин для великих пакетів.',

  longTermStorage:
    'Довгострокове зберігання даних, до яких звертаються кілька разів на рік і отримують миттєво',
  retrieveMilliseconds:
    'Отримуйте дані за кілька мілісекунд із такою ж продуктивністю, як S3 Standard.',
  storageAvailabilityAgreement992:
    'Сховище призначене для забезпечення 99,9% доступності відповідно до умов угоди про рівень обслуговування на 99%',
  minimumObjectSize: "Мінімальний розмір об'єкта: 128 КБ",
  s3PutApi:
    "API S3 PUT для прямого завантаження в S3 Glacier Instant Retrieval і управління життєвим циклом S3 для автоматичної міграції об'єктів",
  deepArchive: 'Глибоке архівування',
  minimalCloudCosts:
    'Який забезпечує мінімальні витрати на хмарне зберігання та можливість відновлення даних протягом 12-48 годин.',

  lowCostBackupArchiving:
    'Недороге резервне копіювання та архівування рідко використовуваних даних',
  storageAvailabilityAgreement9999:
    'Сховище призначене для забезпечення 99,99% доступності відповідно до умов угоди про рівень обслуговування на 99,9%',
  sslSupport: 'Підтримка SSL для даних у русі та шифрування локальних даних',
  backupDisasterRecovery:
    'Чудово підходить для резервного копіювання та аварійного відновлення, коли потрібно отримати великі набори даних за кілька хвилин без додаткових витрат.',
  customizableRetrievalTimes:
    'Налаштовувані терміни отримання — від кількох хвилин до кількох годин — і можливість пакетного отримання.',
  s3PutApiFlexibleRetrieval:
    "API S3 PUT для прямого завантаження до S3 Glacier Flexible Retrieval і управління життєвим циклом S3 для автоматичної міграції об'єктів",
  instantRetrieval: 'Миттєве відновлення',

  minimizesLongTermStorageCost:
    'Мінімізує вартість довгострокового зберігання даних, до яких потрібно звертатися рідко, але які можна отримати за кілька мілісекунд.',

  rarelyUsedArchivalData: 'Надзвичайно рідко використовувані архівні дані за дуже низькою ціною',
  repositoryAvailabilityAgreement9999:
    'Сховище призначене для забезпечення 99,99% доступності відповідно до умов угоди про рівень обслуговування на 99,9%',
  idealAlternativeToTape: 'Ідеальна альтернатива стрічковим бібліотекам',
  retrievalTime12Hours: 'Час відновлення даних до 12 годин',
  s3PutApiDeepArchive:
    "API S3 PUT для прямого завантаження в S3 Glacier Deep Archive і управління життєвим циклом S3 для автоматичної міграції об'єктів",
  readMore: 'Читати далі',
  keyFeatures: 'Ключові функції',
  diskParameters: 'Параметри диска',
  confirmParameters: 'Підтвердити параметри',
  subscribe: 'Підписатися',
  costGigabyte: 'Вартість за гігабайт',
  amount: 'Кількість',
  repeat: 'Повторити',
  failedPayment:
    "На жаль, вашу оплату не вдалося здійснити. Це може бути пов'язано з недостатньою кількістю коштів або закінченням терміну дії картки. Будь ласка, спробуйте ще раз або оновіть свої платіжні дані.",
  paymentFailed: 'Помилка оплати',
  paymentSuccessful: 'Оплата успішна',
  successPaymentMsg:
    'Дякуємо за підписку! Ваш платіж успішно оброблено, і ваша підписка тепер активна. Насолоджуйтеся всіма преміум-функціями!',
  serverAWS: 'Сервер AWS',
  connectDisk: 'Підключити диск',
  freedoms: 'Свободи',
  storageOffers: 'Пропозиції зберігання',
  open: 'Відкрити',
  completeFreedom: 'Повна свобода вибору',
  customizeStorage: 'Налаштуйте сховище так, як вам потрібно, і керуйте своїми даними без обмежень',
  editDisk: 'Редагування диску',
  noNotifications: 'Немає сповіщень',
  diskInformation: 'Інформація про диск',
  storage: 'Сховище',
  capacity: 'Ємність',
  cost: 'Вартість',
  clearDiskMessage: 'Ви впевнені, що хочете очистити диск?',
  removeFromFavorite: 'Видалити з обраного',
  folderInformation: 'Інформація про папку',
  fileInformation: 'Інформація про файл',
  totalVolume: 'Загальний обсяг',
  hashtags: 'Хештеги',
  add: 'Додати',
  lastModifiedDate: 'Останній раз змінено',
  dates: 'Дати',
  noHashtags: 'Немає хештегів',
  edit: 'Редагувати',
  popularHashtags: 'Популярні хештеги',
  functionality: 'Функціонал',
  marketplace: 'Маркетплейс',
  taskBoards: 'Дошки завдань',
  groupChats: 'Групові чати',
  unsubscribe: 'Відписатися',
  futureFunctionality: 'Майбутній функціонал',
  monthlySupportDevelopers: 'Щомісячна підтримка розробників',
  beAPartText: 'Станьте частиною нашого спільного успіху!',
  supportText: 'Підтримайте розробку нових функцій, підписавшись всього за $1 на місяць.',
  supportTeam: 'Підтримайте команду',
  fileMarket: 'Файловий маркетплейс',
  fileCopiedSuccessfully: 'Файл успішно скопійовано',
  folderCopiedSuccessfully: 'Папку успішно скопійовано',
  disks: 'Диски',
  freeDisk: 'Безкоштовний диск',
  personalLibraryText: 'Ваша особиста цифрова бібліотека чекає на вас!',
  signUpAndGet10Text:
    'Зареєструйтеся та отримайте 10 ГБ безкоштовного простору для ваших файлів відразу.',
  signUp: 'Зареєструватися',
  taskBoardsText:
    'Створюйте та відстежуйте завдання, контролюйте доступ до інформації, безпечно зберігайте документи та спілкуйтеся з колегами в режимі реального часу.',
  groupChatsText:
    'Ми створимо чати для спілкування з командою, клієнтами, покупцями та постачальниками, не залишаючи сховище, яке можна пов’язати з дисками та командами.',
  fileSearchText:
    'Наша мета — створити пошукову систему файлів на основі сховища, де кожен користувач зможе керувати, монетизувати та просувати свої файли через відкриту пошукову систему. Отримуйте відгуки, оцінки та замовлення безпосередньо в сховище.',
  marketplaceText:
    'Ми розробляємо хмарну функціональність, яка допоможе монетизувати будь-який тип файлів, завантажених у сховище.',
  days: 'Днів',
  Hours: 'Годин',
  Minutes: 'Хвилин',
  Attention: 'Увага!!!',
  attentionText1:
    'Щоб продовжити роботу з диском, ви повинні оплатити зайнятий обсяг дискового простору.',
  attentionText2:
    'Якщо ви не поновите підписку на зайнятий обсяг дискового простору, диск та всі його дані будуть видалені.',
  DeletionVia: 'Видалення через',
  PayForDisk: 'Оплатити диск',
  diskWillBeDeleted: 'Диск буде видалено через',
  day: 'day',
  Pay: 'Оплатити',
  payments: 'Платежі',
  drive_deleted: 'Диск було видалено',
  drive_created: 'Диск було створено',
  folder_created: 'Папку було створено',
  file_uploaded: 'Файл було завантажено',
  file_downloaded: 'Файл було завантажено',
  file_moved: 'Файли було переміщено',
  file_deleted: 'Файли було видалено',
  item_renamed: 'Елемент було перейменовано',
  drive_cleared: 'Диск було очищено',
  item_deleted: 'Файли було видалено',
  invoice_succeeded: 'Платіж за підписку успішний',
  invoice_failed: 'Оплата рахунку не вдалася',
  subscription_unpaid: 'Підписка не оплачена',
  subscription_cancelled: 'Підписку було скасовано',
  subscription_restored: 'Підписку було відновлено',
  welcome_message: 'Ласкаво просимо до сервісу',
  trashed_cleared: 'Кошик було очищено',
  app_update_available: 'Доступне оновлення додатку',
  quantity: 'Кількість',
  readAll: 'Прочитати все',
  total_transactions_message: 'Всього було здійснено {{count}} транзакцій',
  total_transaction_message: 'Всього було здійснено {{count}} транзакцію',
  downloaded: 'Завантажено',
  noOperations: 'Операцій немає',
  volume: "Об'єм",
  theOfficialUmi: 'Офіційний додаток UMI доступний для iPhone, iPad, Android, macOS та Windows.',
  thisDevices: 'Цей пристрій',
  devices: 'Пристрої',
  terminateRest: 'Завершити всі інші сеанси',
  logOutAllDevices: 'Вихід з усіх пристроїв, окрім цього.',
  activeSessions: 'Активні сеанси',
  terminateThisSession: 'Ви хочете завершити цей сеанс?',
  terminate: 'Завершити',
  sureTerminateAll: 'Ви впевнені, що хочете завершити всі ваші сеанси на пристроях?',
};

export default translation;
