import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { useUploadDiskAvatar } from 'src/api/disks';
import React, { useState } from 'react';
import { DiskType } from 'src/types';
import { useTranslation } from 'react-i18next';
import { DropDownItem } from 'src/components/Dropdown/types';
import { ImagePlusIcon, RenameIcon, UploadFileDarkIcon } from 'src/components/Icons';
import { base64ToBlob } from 'src/helpers';
import { UploadFileModal } from 'src/components/Header/components/UploadFileModal';
import { useManageDisk } from 'src/pages/diskPage/components/Disk/hooks/useManageDisk';
import { ChangeCover } from 'src/pages/diskPage/components/Disk/changeCover';
import { useGetDisks } from 'src/hooks/useGetDisks';

type Props = {
  disk: DiskType;
};

export const useDiskState = ({ disk }: Props) => {
  const { mutateAsync: uploadAvatar } = useUploadDiskAvatar(disk.id);
  const { openModal } = useAppContext();
  const { updateDiskCache } = useGetDisks();
  const { renameDisk, isProcessing } = useManageDisk();

  const [hasError, setHasError] = useState(false);
  const [newName, setNewName] = useState(disk.name);

  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();

  const onChangeCover = () => {
    openModal({
      title: 'Change the cover',
      content: (
        <>
          <ChangeCover
            onCancel={() => {
              openModal({ open: false });
            }}
            onCrop={async (img) => {
              openModal({ open: false });
              const resp = await uploadAvatar(base64ToBlob(img));
              if (resp.success) {
                updateDiskCache({
                  ...disk,
                  avatar_key: resp.avatar.avatar_key,
                });
              }
            }}
          />
        </>
      ),
      width: '413px',
    });
  };

  const onRenameDisk = () => {
    setEditMode(true);
    setNewName(disk.name);
  };

  const items: DropDownItem[] = [
    {
      label: t('uploadFiles'),
      icon: <UploadFileDarkIcon className="dropdown-svg-icon" />,
      isActive: true,
      onClick: () => {
        openModal({
          content: (
            <UploadFileModal
              diskId={disk.id as string}
              folderId={''}
              onFinish={async () => {
                openModal({ open: false });
              }}
            />
          ),
          title: t('uploadFiles'),
          className: 'create-folder-modal',
        });
      },
    },
    {
      label: t('changeTheCover'),
      onClick: onChangeCover,
      icon: <ImagePlusIcon className="change-cover-icon" />,
    },
    /*{
      label: disk.favorite ? 'Remove from favorite' : t('addToFavorite'),
      onClick: onAddToFavorite,
      icon: <AddToFavoriteIcon className="favorite-icon" />,
    },*/
    {
      label: t('renameDisk'),
      onClick: onRenameDisk,
      icon: <RenameIcon className="rename-icon" />,
      id: 'rename-disk',
    },
  ];

  const onChangeName = (value: string) => {
    setNewName(value);
  };

  const onSaveDisk = async () => {
    const response = await renameDisk({ id: disk.id, name: newName });
    if (response.success) {
      setEditMode(false);
      updateDiskCache({
        ...disk,
        ...response.disk,
      });
    } else {
      setHasError(true);
    }
  };

  return {
    editMode,
    disk,
    newName,
    hasError,
    isProcessing,
    items,
    setEditMode,
    onChangeName,
    onSaveDisk,
  };
};
