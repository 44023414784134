import * as Yup from 'yup';

export const validationSchema = (t: (v: string) => string) =>
  Yup.object().shape({
    full_name: Yup.string().required(t('fullNameIsRequired')),
    user_name: Yup.string().nullable().required(t('usernameIsRequired')),
    locale_code: Yup.string().nullable(),
    phone_number: Yup.string().nullable(),
    google: Yup.mixed().nullable(),
    apple: Yup.mixed().nullable(),
    avatar_url: Yup.mixed().nullable(),
    email: Yup.string().email(t('validEmailError')).nullable(),
  });
