import React, { FC } from 'react';
import { DiskOffer } from 'src/pages/subscriptions/components/DiskOffer';
import { DiskType } from 'src/types';

type Props = {
  disk: DiskType;
  onClick: () => void;
  icon: React.ReactNode;
};

export const DiskRow: FC<Props> = ({ disk, icon, onClick }) => {
  return (
    <div className="disks-dropdown__disk" onClick={onClick}>
      <div className="disks-dropdown__disk-content">
        <div className="disks-dropdown__preview">
          {disk && (
            <DiskOffer
              disk={{
                id: disk.id,
                name: disk.name,
                storage: 'Server UMI',
                capacity: 2000000000000,
                price: '2$',
                color: disk.color,
                description: '1',
                drive_type: 'standard',
              }}
              className="current-disk-preview"
            />
          )}
        </div>
        <div className="disks-dropdown__disk-info">
          <div className="disks-dropdown__disk-name">{disk?.name}</div>
          <div className="disks-dropdown__disk-number">
            {String(disk?.disk_number).replace(/(\d{4})(?=\d)/g, '$1 ')}
          </div>
        </div>
      </div>
      <div className="disks-dropdown__disk-control">{icon}</div>
    </div>
  );
};
