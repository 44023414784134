export const PLAYMARKET_URL =
  'https://play.google.com/store/apps/details?id=com.umi.cloud&hl=uk&pli=1';
export const APPSTORE_URL = 'https://apps.apple.com/ua/app/umi-cloud/id6475421434';
export const LINKEDIN_URL =
  'https://www.linkedin.com/company/umi-product-company/posts/?feedView=all&viewAsMember=true';
export const INSTAGRAM_URL =
  'https://www.instagram.com/umi_cloud_storage?igsh=dnNhN2t3OWhmMDB1&utm_source=qr';
export const TELEGRAM_URL = 'https://t.me/umicloud';
export const FACEBOOK_URL = 'https://www.facebook.com/umicloudstorage';
export const WATSAPP_URL = 'https://chat.whatsapp.com/EecZWlBhZE8Lv8BHULcMjW';
export const VIBER_URL = 'https://invite.viber.com/?g2=AQBv3%2F%2F%2F%2F%2F%2Fw';
