import React, { FC } from 'react';
import classNames from 'classnames';
import './input.scss';
import { CircleCheckRed } from 'src/components/Icons';

type Props = {
  label?: string;
  subLabel?: string;
  name?: string;
  id?: string;
  className?: string;
  onChange?: (value: string) => void;
  value?: string | null;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  isRequired?: boolean;
  type?: string;
  error?: string;
  maxLength?: number;
};
export const Input: FC<Props> = ({
  label,
  name,
  id,
  className,
  onChange,
  value,
  onBlur,
  onFocus,
  placeholder,
  isRequired,
  type,
  error,
  subLabel,
  maxLength,
}) => {
  return (
    <div
      className={classNames('input-container', className, {
        hasError: !!error,
        widthMaxLength: maxLength,
      })}
    >
      {label && (
        <label className="input-container__label" htmlFor={id}>
          {isRequired && '*'}
          {label}
        </label>
      )}
      {subLabel && <div className="input-container__sub-label">{subLabel}</div>}
      <div className="input-container__field-wrapper">
        <input
          className="input-container__field"
          type={type || 'text'}
          id={id}
          name={name}
          value={value || ''}
          placeholder={placeholder}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          onBlur={() => {
            if (onBlur) {
              onBlur();
            }
          }}
          onFocus={() => {
            if (onFocus) {
              onFocus();
            }
          }}
        />
        {maxLength && (
          <div className="input-container__counter">
            {(value?.length || 0) > maxLength && <CircleCheckRed />}
            <div className="input-container__counter-actual">{value?.length || 0}</div>/{maxLength}
          </div>
        )}
      </div>
      {!!error && <div className="input-container__error">{error}</div>}
    </div>
  );
};
