import React, { FC } from 'react';
import { ReactComponent as ItemIcon } from './item-icon.svg';
import './styles.scss';

type Props = {
  title: string;
  options: string[];
};
export const KeyOptions: FC<Props> = ({ title, options }) => {
  return (
    <div className="key-options">
      <div className="key-options__title">{title}</div>
      <div className="key-options__items">
        {options.map((option) => (
          <div key={option} className="key-options__item">
            <ItemIcon className="key-options__icon" />
            <div>{option}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
