// AvatarUploader.tsx
import React, { useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './AvatarUploader.scss';
import classNames from 'classnames';

type Props = {
  e?: React.ChangeEvent<HTMLInputElement>;
  onChange: (data: string) => void;
  onClose: () => void;
};

const CROP_WIDTH = 800;
const CROP_HEIGHT = 800;

const AvatarUploader: React.FC<Props> = ({ e, onChange, onClose }) => {
  const [image, setImage] = useState<string | null>(null);
  const cropperRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (e) {
      const files = e.target.files;
      if (files && files.length > 0) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result as string);
        };
        reader.readAsDataURL(files[0]);
      }
    }
  }, [e]);

  const getCropData = () => {
    if (cropperRef.current) {
      const cropper = (cropperRef.current as any).cropper;
      const cropBoxData = cropper.getCropBoxData();
      const cropWidth = cropBoxData.width;
      const cropHeight = cropBoxData.height;
      const targetWidth = Math.min(CROP_WIDTH, cropWidth);
      const targetHeight = Math.min(CROP_HEIGHT, cropHeight);
      const canvas = cropper.getCroppedCanvas({
        width: cropWidth <= CROP_WIDTH && cropHeight <= CROP_HEIGHT ? cropWidth : targetWidth,
        height: cropWidth <= CROP_WIDTH && cropHeight <= CROP_HEIGHT ? cropHeight : targetHeight,
      });
      const croppedImage = canvas.toDataURL();
      onChange(croppedImage);
    }
  };

  return (
    <div className="avatar-uploader">
      {image && (
        <div className="cropper-container">
          <Cropper
            src={image}
            style={{ height: '680px', width: '100%' }}
            initialAspectRatio={1}
            guides={false}
            viewMode={1}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={true}
            ref={cropperRef}
            aspectRatio={1}
          />
          <div className="cropper-overlay" />
        </div>
      )}
      <div className="upload-avatar__footer">
        <div className={classNames('upload-avatar__button')} onClick={onClose}>
          Cancel
        </div>
        <div className={classNames('upload-avatar__button')} onClick={getCropData}>
          Set photo
        </div>
      </div>
    </div>
  );
};

export default AvatarUploader;
