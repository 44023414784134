import React, { FC } from 'react';
import { ReactComponent as DiskIcon } from './assets/SSD.svg';
import { DiskColorType } from 'src/types';
import { getConvertedSize } from 'src/helpers';
import { DISK_COLORS } from 'src/config/constants';
import classNames from 'classnames';
import { OfferType } from 'src/pages/subscriptions/types';
import './styles.scss';
import { LogoLight } from 'src/components/Icons';

type Props = {
  disk: OfferType;
  className?: string;
};

export const DiskOffer: FC<Props> = ({ disk, className }) => {
  const color: DiskColorType = disk.color || 'blue';

  const gradientId = disk.id;

  return (
    <div className={classNames('offer-object', className)}>
      <div className="offer-object__wrapper">
        <DiskIcon id={gradientId} className="offer-object__bg" />
        <div className="offer-object__content">
          <div
            className="offer-object__gradient"
            style={{
              background: `linear-gradient(90deg, ${DISK_COLORS[color].start} 0%, ${DISK_COLORS[color].end} 100%)`,
            }}
          >
            <div>
              <div className="offer-object__server">
                {disk.storage}
                <LogoLight className="offer-object__logo" />
              </div>
              <div className="offer-object__name">{disk.name}</div>
            </div>
            <div className="offer-object__footer">
              <div className="offer-object__progress"></div>
              <div className="offer-object__values">
                <div className="offer-object__values--start">
                  <span className="highlight">{getConvertedSize(disk.capacity)}</span>
                </div>
                <div className="offer-object__values--end">
                  <span className="highlight">{disk.price}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
