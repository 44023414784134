import React from 'react';
import { Button } from 'src/components/Forms/Button';
import { ReactComponent as ChainIcon } from './assets/chain-icon.svg';
import './styles.scss';

export const Navigations = () => {
  return (
    <div className="share-navigation">
      <ul>
        <li>
          <a href="/" className="active">
            <ChainIcon /> Мои галареи
          </a>
        </li>
        <li>
          <a href="/">
            <ChainIcon /> Мои библиотеки
          </a>
        </li>
        <li>
          <a href="/">
            <ChainIcon /> Настройки
          </a>
        </li>
        <li>
          <a href="/">
            <ChainIcon /> Статистика
          </a>
        </li>
        <li>
          <Button type="primary-dark" content="Перейти в хранилище" />
        </li>
      </ul>
    </div>
  );
};
