import React, { useCallback, useEffect, useState } from 'react';
import { useGetFilteredFolders } from 'src/hooks/useGetFilteredFolders';
import { useGetFilesWithScrolls } from 'src/hooks/useGetFilesWithScrolls';
import { useParams } from 'react-router-dom';
import { FolderColumn } from './components/FolderColumn';
import { FileColumn } from './components/FileColumn';
import { useTranslation } from 'react-i18next';
import { useSort } from 'src/hooks/useSort';
import { UploaderPage } from 'src/components/FileUploader/UploaderPage';
import { useFolderDrop } from 'src/pages/diskPage/hooks/useFolderDrop';
import classNames from 'classnames';
import { CloseIcon, DiskIcon } from 'src/components/Icons';
import { ActionButton } from 'src/components/Forms/ActionButton';
import { FileType, FolderType } from 'src/types';
import { InfoColumn } from 'src/pages/diskPage/Views/ColumnsView/components/InfoColumn';
import { FolderIconComp } from 'src/pages/diskPage/components/ItemIcons/FolderIcon';
import { FilesPayload } from 'src/api/files';
import { DEFAULT_FILES_PAYLOAD } from 'src/config/constants';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import { List, ListRowRenderer, AutoSizer } from 'react-virtualized';
import { usePrevious } from 'src/hooks/usePrevious';
import { isEqual } from 'lodash';
import './styles.scss';
import { useDropState } from 'src/pages/diskPage/hooks/useDropState';

type PathType = {
  id: string;
  name: string;
  folder?: FolderType;
};

type ColumnProps = {
  diskId: string;
  folderId: string;
  folderName: string;
  level: number;
  onFolderClick: (folderId: string, folderName: string, level: number) => void;
  currentPath: PathType[];
  onFolderDelete: (folderId: string) => void;
  onItemClick: (folder?: FolderType, file?: FileType) => void;
  selectedId?: string;
};

type SelectedItemType = {
  info_type: 'file' | 'folder' | 'disk';
  info?: FileType | FolderType;
};
export const ColumnsView: React.FC = () => {
  const { disk } = useDiskContext();
  const { diskId } = useParams();
  const [currentPath, setCurrentPath] = useState<PathType[]>([{ id: '', name: disk.name }]);
  const [deletedFolders, setDeletedFolders] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<SelectedItemType>({
    info_type: 'disk',
  });

  useEffect(() => {
    setCurrentPath([{ id: '', name: disk.name }]);
  }, [disk.id]);

  const handleFolderClick = (folderId: string, folderName: string, level: number) => {
    if (deletedFolders.includes(folderId)) {
      return;
    }

    const existingIndex = currentPath.findIndex((path) => path.id === folderId);

    if (existingIndex !== -1) {
      setCurrentPath(currentPath.slice(0, level));
    } else {
      const newPath = [...currentPath.slice(0, level), { id: folderId, name: folderName }];
      setCurrentPath(newPath);
    }
  };

  const handleFolderDelete = (folderId: string) => {
    setDeletedFolders([...deletedFolders, folderId]);
    setCurrentPath(currentPath.filter((path) => path.id !== folderId));
  };

  return (
    <div className="folder-explorer">
      <div className="folder-explorer__content">
        <div className="folder-explorer__columns">
          {currentPath.map((path, index) => (
            <Column
              key={path.id}
              diskId={diskId || ''}
              folderId={path.id}
              folderName={path.name}
              level={index}
              onFolderClick={handleFolderClick}
              currentPath={currentPath}
              onFolderDelete={handleFolderDelete}
              selectedId={selectedItem.info?.id}
              onItemClick={(folder, file) => {
                if (folder) {
                  setSelectedItem({
                    info_type: 'folder',
                    info: {
                      ...folder,
                      parent_id: folder.parent_id || '',
                    },
                  });
                  return;
                }
                if (file) {
                  setSelectedItem({
                    info_type: 'file',
                    info: file,
                  });
                  return;
                }
              }}
            />
          ))}
          {!!selectedItem.info && (
            <div className="folder-explorer__info">
              <InfoColumn
                className="folder-explorer__info--object"
                info_type={selectedItem.info_type}
                info={selectedItem.info}
                onClose={() =>
                  setSelectedItem({
                    info_type: 'disk',
                  })
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Column: React.FC<ColumnProps> = ({
  diskId,
  folderId,
  folderName,
  level,
  onFolderClick,
  currentPath,
  onFolderDelete,
  onItemClick,
  selectedId,
}) => {
  const { sortFiles, sortFolder } = useSort();
  const { t } = useTranslation();

  const {
    data: folders,
    isPending: isPendingFolders,
    getFolderFromCacheGlobal,
  } = useGetFilteredFolders({
    parent_id: folderId || diskId,
  });

  const filesPayload: FilesPayload = {
    ...DEFAULT_FILES_PAYLOAD,
    parent_id: folderId || diskId,
  };

  const { files, fetchNextPage } = useGetFilesWithScrolls(filesPayload);

  const sortedFolders = sortFolder(folders);
  const sortedFiles = sortFiles(files);

  const [items, setItems] = useState<any[]>([]);

  const prevItems = usePrevious([
    ...sortedFolders.map((item) => ({ ...item, type: 'folder' })),
    ...sortedFiles.map((item) => ({ ...item, type: 'file' })),
  ]);
  useEffect(() => {
    if (
      isEqual(prevItems, [
        ...sortedFolders.map((item) => ({ ...item, type: 'folder' })),
        ...sortedFiles.map((item) => ({ ...item, type: 'file' })),
      ])
    )
      return;

    setItems(() => [
      ...sortedFolders.map((item) => ({ ...item, type: 'folder' })),
      ...sortedFiles.map((item) => ({ ...item, type: 'file' })),
    ]);
  }, [sortedFolders, sortedFiles]);

  const onFileClick = (file: FileType) => {
    onItemClick(undefined, file);
  };

  const onFolderClickHandler = (folder: FolderType) => {
    onFolderClick(folder.id, folder.name, level + 1);
    onItemClick(folder, undefined);
  };

  const getFolderName = () => {
    const f = getFolderFromCacheGlobal(folderId);
    return f?.name || folderName;
  };

  const rootFolder = getFolderFromCacheGlobal(folderId);

  const { onDropHandler } = useDropState(folderId || diskId);
  const { drop, isOver } = useFolderDrop(folderId || diskId, onDropHandler);

  const rowRenderer: ListRowRenderer = ({ index, key, style }) => {
    const item = items[index];
    if (item.type === 'folder') {
      return (
        <div key={key} style={style}>
          <FolderColumn
            key={item.id}
            folder={item}
            onClick={() => onFolderClickHandler(item)}
            selected={currentPath[level + 1]?.id === item.id}
            onDragStart={() => {
              // handle drag start
            }}
            onDelete={() => {
              onFolderDelete(item.id);
            }}
            onDrop={onDropHandler}
          />
        </div>
      );
    } else {
      return (
        <div key={key} style={style}>
          <FileColumn
            key={item.id}
            file={item}
            onClick={() => onFileClick(item)}
            selected={item.id === selectedId}
            parentId={folderId || diskId}
          />
        </div>
      );
    }
  };

  const handleScroll = useCallback(
    ({ scrollTop, scrollHeight, clientHeight }: any) => {
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        fetchNextPage();
      }
    },
    [fetchNextPage],
  );

  return (
    <div className="column" ref={(node) => drop(node)}>
      <div className="column-header">
        {rootFolder && <FolderIconComp folder={rootFolder as FolderType} />}
        {!rootFolder && (
          <div className="column-header__disk-icon">
            <DiskIcon />
          </div>
        )}
        <div className="column-header__text" title={getFolderName()}>
          {getFolderName()}
        </div>
        <div className="column-header__actions">
          {!!rootFolder && (
            <>
              <ActionButton items={[]} className="folder-column__actions-btn" id={folderId} />
              <CloseIcon
                className="column-header__close"
                onClick={() => {
                  onFolderClick(rootFolder.id, rootFolder.name, level);
                }}
              />
            </>
          )}
        </div>
      </div>
      {!isPendingFolders && (
        <div className={classNames('column__content', { hover: isOver })}>
          <UploaderPage
            diskId={diskId || ''}
            folderId={folderId}
            className="column__content--upload"
          >
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowCount={items.length}
                  rowHeight={44}
                  rowRenderer={rowRenderer}
                  onScroll={handleScroll}
                  overscanRowCount={5}
                />
              )}
            </AutoSizer>
          </UploaderPage>
        </div>
      )}
      {isPendingFolders && <div className="column__loading">{t('loading')}...</div>}
    </div>
  );
};
