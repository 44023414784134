import React from 'react';
import android from './assets/android_app.png';
import apple from './assets/apple_app.png';
import mac from './assets/mac_app.png';
import windows from './assets/window_app.png';
import { APPSTORE_URL, PLAYMARKET_URL } from 'src/config/urls';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AppleIcon } from './assets/ic_baseline-apple.svg';
import { ReactComponent as AndroidIcon } from './assets/uil_android.svg';
import { ReactComponent as WindowIcon } from './assets/mingcute_windows-fill.svg';
import './styles.scss';

export const Apps = () => {
  const { t } = useTranslation();

  return (
    <div className="home-apps">
      <div className="landing-container">
        <h2 className="home-apps__title">{t('downloadApp')}</h2>
        <div className="home-apps__description">{t('homeAppsDescription')}</div>
        <div className="home-apps__rows">
          <div className="home-apps__row">
            <a className="home-apps__item" href={PLAYMARKET_URL} target="__blank">
              <img src={android} alt="App" />
              <div className="home-apps__item-title">
                <AndroidIcon />
                UMI for Android
              </div>
            </a>
            <a className="home-apps__item" href={APPSTORE_URL} target="__blank">
              <img src={apple} alt="App" />
              <div className="home-apps__item-title">
                <AppleIcon /> UMI for iPhone / iPad
              </div>
            </a>
          </div>
          <div className="home-apps__row">
            <a className="home-apps__item" href={APPSTORE_URL} target="__blank">
              <img src={mac} alt="App" />
              <div className="home-apps__item-title">
                <AppleIcon /> UMI for MacOs
              </div>
            </a>
            <a className="home-apps__item" href={PLAYMARKET_URL} target="__blank">
              <img src={windows} alt="App" />
              <div className="home-apps__item-title">
                <WindowIcon />
                UMI for Windows
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
