import { API } from 'src/utils/API';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export type CreateFavoriteType = {
  id: string;
};

export const useCreateFavorite = () => {
  const sendData = async (payload: CreateFavoriteType) => {
    const resp = await API.post('/favorites', payload, 'archive');
    if (resp.success) {
      return {
        success: true,
        favorite: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useDeleteFavorite = () => {
  const sendData = async (id: string) => {
    const resp = await API.delete(`/favorites/${id}`, 'archive');
    if (resp.success) {
      return {
        success: true,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};
