import React, { FC, useEffect, useState } from 'react';
import { Formik } from 'formik';
import ProfileForm from '../profileForm';
import { useTranslation } from 'react-i18next';
import { useLoadProfile, useUpdatePhone, useUpdateProfile } from 'src/api/profile';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { ConfirmationForm } from 'src/components/ConfirmationForm';
import { validationSchema } from './validations';
import { FormValuesType } from 'src/types/users';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';

type Props = {
  onOk: () => void;
};

export const EditProfile: FC<Props> = ({ onOk }) => {
  const { openModal } = useAppContext();
  const { t } = useTranslation();
  const { openNotification } = useGlobalContext();
  const { mutateAsync: saveData } = useUpdateProfile();
  const { mutateAsync: savePhone } = useUpdatePhone();
  const { data, refetch } = useLoadProfile();

  const defaultValues: FormValuesType = {
    id: '',
    user_name: '',
    full_name: '',
    department: '',
    position: '',
    locale_code: '',
    phone_number: '',
    google: null,
    apple: null,
    avatar_url: null,
    email: '',
    confirmation: '',
  };

  const [initialValues, setInitialValues] = useState<FormValuesType>(defaultValues);

  useEffect(() => {
    if (data?.success) {
      setInitialValues(data.profile);
    }
  }, [data]);

  const getChangedValues = (initialValues: FormValuesType, currentValues: FormValuesType) => {
    return Object.keys(currentValues).reduce((acc, key) => {
      // @ts-expect-error Missing type checking for dynamic key access.
      if (initialValues[key] !== currentValues[key]) {
        // @ts-expect-error Missing type checking for dynamic key access.
        acc[key] = currentValues[key];
      }
      return acc;
    }, {} as FormValuesType);
  };

  const sendPhone = async (phone: string) => {
    const resp = await savePhone({ phone_number: phone });
    if (resp.success) {
      openModal({
        content: (
          <>
            <ConfirmationForm
              onConfirm={() => {
                openModal({ open: false });
              }}
              phone={phone}
            />
          </>
        ),
        onClose: () => {
          openModal({ open: false });
        },
        width: '320px',
      });

      return;
    }
    openNotification({
      open: true,
      notification: resp.error,
      autoHideDuration: 3,
    });
  };

  const handleSaveChanges = async (values: FormValuesType) => {
    const data = getChangedValues(initialValues, values);
    if (Object.keys(data).length === 0) {
      onOk();
      return;
    }
    const { phone_number, ...changedValues } = data;

    if (phone_number) {
      await sendPhone(phone_number);
    }

    if (Object.values(changedValues).length === 0) return;

    const resp = await saveData(changedValues);
    if (resp.success) {
      setInitialValues(resp.profile);
      openNotification({
        type: 'success',
        open: true,
        notification: t('profileUpdatedSuccessfully'),
        autoHideDuration: 3,
      });
      await refetch();
      onOk();
    } else {
      openNotification({
        open: true,
        notification: resp.error,
        autoHideDuration: 3,
      });
    }
  };

  return (
    <div className="edit-profile">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSaveChanges}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        <ProfileForm />
      </Formik>
    </div>
  );
};
