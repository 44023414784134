import React, { FC } from 'react';
import './styles.scss';

type Props = {
  children: React.ReactNode;
};
export const LandingSection: FC<Props> = ({ children }) => {
  return (
    <div className="landing-section">
      <div className="landing-section__wrapper">{children}</div>
    </div>
  );
};
