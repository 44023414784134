import { createContext, ReactNode } from 'react';
import { DiskType, FileType, FolderType } from 'src/types';
import { emptyDisk } from 'src/dataMocks';

export type viewType = 'list' | 'icons' | 'columns';
export type sortType = { field: string; direction: 'asc' | 'desc' };

export type RightMenuState = {
  open: boolean;
  content: ReactNode;
  width?: number;
};

export type DiskContextType = {
  view: viewType;
  setView: (view: viewType) => void;
  updateState: (values: Partial<DiskContextType>) => void;
  disk: DiskType;
  disks: DiskType[];
  setDisk: (disk: DiskType) => void;
  setDisks: (disks: DiskType[]) => void;
  getDisk: () => DiskType;
  showHashTags: boolean;
};

export const defaultAppValues: DiskContextType = {
  view: 'icons',
  disk: emptyDisk,
  showHashTags: false,
  disks: [],
  setDisk: () => {},
  getDisk: () => emptyDisk,
  setDisks: () => {},
  setView: () => {},
  updateState: () => {},
};

export const AppContext = createContext<DiskContextType>(defaultAppValues);
