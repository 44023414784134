import { DiskColorType } from 'src/types';

export const DATE_TIME_FORMAT = 'D MMMM YYYY, HH:mm';
export const DATE_FORMAT = 'MM.D.YYYY';

export const DEFAULT_FILES_PAYLOAD = {
  limit: 50,
  offset: 0,
};

export const DISK_COLORS: Record<DiskColorType, { start: string; end: string; middle?: string }> = {
  blue: {
    start: '#1AA1E2',
    end: '#1AA1E2',
  },
  purpleGradient: {
    start: '#9400D3',
    end: '#4B0082',
  },
  greenGradient: {
    start: '#02AAB0',
    end: '#00CDAC',
  },
  grayGradient: {
    start: '#536976',
    end: '#292E49',
  },
  yellowGradient: {
    start: '#FFE259',
    end: '#FFA751',
  },
  lightBlueGradient: {
    start: '#0052D4',
    middle: '#4364F7',
    end: '#6FB1FC',
  },
  redGradient: {
    start: '#EC008C',
    end: '#FC6767',
  },
  gray: {
    start: '#777A7C',
    end: '#777A7C',
  },
};
