import React, { FC, useEffect, useState } from 'react';
import {
  archivingOptions,
  parametersOptions,
} from 'src/components/CreateDisk/DiskParameters/config';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { KeyOptions } from 'src/components/CreateDisk/DiskParameters/components/KeyOptions';
import { DriveTypes } from 'src/types';
import './styles.scss';

export type DiskParameter = {
  label: string;
  value: string;
  description: string;
  functionsLink: string;
  readMoreLink: string;
  keyFeatures: string[];
};

type Props = {
  value: DriveTypes;
  archiving?: string;
  onChange: (parameter: DriveTypes, subParameter?: string) => void;
  isDisabled?: boolean;
};

export const DiskParameters: FC<Props> = ({ value, onChange, archiving, isDisabled }) => {
  const { openModal } = useAppContext();
  const { t } = useTranslation();
  const [parameter, setParameter] = useState<DriveTypes>(value);
  const [archivingOpt, setArchivingOpt] = useState<string>(archiving || 'flexible_retrieval');

  useEffect(() => {
    setParameter(value);
  }, [value]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) return;
    const newReservation = event.target.value as DriveTypes;
    setParameter(newReservation);
    onChange(newReservation, archivingOpt);
  };

  const onChangeArchiving = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newReservation = event.target.value;
    setArchivingOpt(newReservation);
    onChange(parameter, newReservation);
  };

  const onFunctionsClick = (item: DiskParameter) => {
    openModal({
      content: (
        <>
          {item.value !== 'archiving' && (
            <KeyOptions title={`${t('keyFeatures')}:`} options={item.keyFeatures} />
          )}
          {item.value === 'archiving' && (
            <>
              {archivingOptions(t).map((parameterOption, index) => {
                return (
                  <KeyOptions
                    key={index}
                    title={parameterOption.label}
                    options={parameterOption.keyFeatures}
                  />
                );
              })}
            </>
          )}
        </>
      ),
      title: item.label,
      className: 'options-modal',
      width: '780px',
    });
  };
  const onReadMoreClick = (item: DiskParameter) => {
    openModal({
      content: (
        <>
          <KeyOptions title={`${t('keyFeatures')}:`} options={item.keyFeatures} />
        </>
      ),
      title: item.label,
      className: 'options-modal',
      width: '780px',
    });
  };

  return (
    <div className="disk-parameters">
      <div className="disk-parameters__label">{t('diskParameters')}:</div>
      {parametersOptions(t).diskParameters.map((parameterOption, index) => {
        return (
          <div
            className={classNames('custom-radio', {
              disabled: isDisabled && parameter !== parameterOption.value,
            })}
            key={index}
          >
            <input
              id={parameterOption.value}
              type="radio"
              name="parameters"
              value={parameterOption.value}
              checked={parameter === parameterOption.value}
              onChange={onChangeHandler}
            />
            <label htmlFor={parameterOption.value} className="">
              {parameterOption.label} {/*{parameter === parameterOption.value && <WarningIcon />}*/}
              <div
                className={classNames('disk-parameters__functions', {
                  active: parameter === parameterOption.value,
                })}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onFunctionsClick(parameterOption);
                }}
              >
                {t('functions')}
              </div>
            </label>
            {parameter === parameterOption.value && (
              <div className="disk-parameters__warning">
                {parameterOption.description}

                {parameterOption.value === 'archiving' && (
                  <div className="disk-parameters__archiving">
                    {archivingOptions(t).map((parameterOption, index) => {
                      return (
                        <div
                          className={classNames('custom-radio', {
                            disabled: isDisabled && archivingOpt !== parameterOption.value,
                          })}
                          key={index}
                        >
                          <input
                            id={parameterOption.value}
                            type="radio"
                            name="archivingOpt"
                            value={parameterOption.value}
                            checked={archivingOpt === parameterOption.value}
                            onChange={onChangeArchiving}
                            disabled={isDisabled}
                          />
                          <label htmlFor={parameterOption.value} className="">
                            {parameterOption.label}
                          </label>
                          <div className="disk-parameters__warning">
                            {parameterOption.description}{' '}
                            <span
                              className="disk-parameters__more"
                              onClick={() => {
                                onReadMoreClick(parameterOption);
                              }}
                            >
                              {t('readMore')}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
