import React, { FC, useState, useRef, useEffect } from 'react';
import './styles.scss';
import { Button } from 'src/components/Forms/Button';
import { termsData } from 'src/pages/auth/components/LoginSection/components/Terms/terms';
import { privacyNotice } from 'src/pages/auth/components/LoginSection/components/Terms/plicy';

type Props = {
  onSave: () => void;
};

export const TermsBlock: FC<Props> = ({ onSave }) => {
  const [showPolicy, setShowPolicy] = useState(true);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const title = showPolicy ? 'Privacy Policy' : 'Terms of Use';

  const onAgree = () => {
    if (showPolicy) {
      // Switch to Terms of Use, scroll to top, and disable the button again
      setShowPolicy(false);
      setIsScrolledToBottom(false);
      if (contentRef.current) {
        contentRef.current.scrollTop = 0; // Scroll to the top
      }
      return;
    }
    onSave();
  };

  const handleScroll = () => {
    const contentElement = contentRef.current;
    if (contentElement) {
      const { scrollTop, scrollHeight, clientHeight } = contentElement;
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsScrolledToBottom(true);
      }
    }
  };

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener('scroll', handleScroll);
      return () => contentElement.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div className="terms-block">
      <div className="terms-block__title">{title}</div>
      <div className="terms-block__content" ref={contentRef}>
        {!showPolicy &&
          termsData.items.map((item, index) => {
            return (
              <div className="terms-block__section" key={index}>
                {!!item.title && <h2 className="terms-block__subtitle">{item.title}</h2>}
                <p>{item.content}</p>
              </div>
            );
          })}

        {showPolicy &&
          privacyNotice.items.map((item, index) => {
            return (
              <div className="terms-block__section" key={index}>
                {!!item.title && <h2 className="terms-block__subtitle">{item.title}</h2>}
                <p>{item.content}</p>
              </div>
            );
          })}
      </div>
      <div className="terms-block__footer">
        <Button
          content="Agree"
          type="primary"
          fullWidth
          className="auth__action-button"
          onClick={onAgree}
          isDisabled={!isScrolledToBottom}
        />
      </div>
    </div>
  );
};
