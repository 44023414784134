import React, { FC } from 'react';
import PhoneInput from 'src/components/Forms/PhoneInput';
import { Button } from 'src/components/Forms/Button';
import { useTranslation } from 'react-i18next';

type Props = {
  handlePhoneChange: (phone_number: string) => void;
  onLoginClick: () => void;
  isPending: boolean;
  error?: string;
};

export const LoginForm: FC<Props> = ({ handlePhoneChange, onLoginClick, isPending, error }) => {
  const { t } = useTranslation();
  /*const [policy, setPolicy] = useState(false);
  const [terms, setTerms] = useState(false);*/

  /*  const onChangeTermsHandler = () => {
    onTermsChange(!terms);
    setTerms(!terms);
  };

  const onChangePolicyHandler = () => {
    onPolicyChange(!policy);
    setPolicy(!policy);
  };*/

  return (
    <>
      <div className="auth__row">
        <PhoneInput className="login-phone-input" onChange={handlePhoneChange} error={error} />
      </div>
      <div className="auth__row auth__row--action mt-20">
        <Button
          content={t('signIn')}
          type="primary"
          className="auth__action-button"
          onClick={onLoginClick}
          isDisabled={isPending}
          fullWidth
        />
      </div>
    </>
  );
};
