import { API } from 'src/utils/API';
import { useMutation, useQuery } from '@tanstack/react-query';
import { SettingsType } from 'src/types/general';
import { getBrowserFingerprint } from 'src/helpers';

type UpdateSettingsPayload = SettingsType;

export const useGetSettings = (disabled: boolean = false) => {
  const DEVICE_ID = getBrowserFingerprint();
  const fetchData = async () => {
    const resp = await API.get(`/device/${DEVICE_ID}/settings`);
    if (resp.success) {
      return {
        success: true,
        settings: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useQuery({
    queryKey: ['get-settings'],
    queryFn: fetchData,
    staleTime: 1000 * 60 * 5,
    enabled: !disabled,
    retry: 0,
    initialDataUpdatedAt: 0,
  });
};

export const useUpdateSettings = () => {
  const sendData = async (payload: UpdateSettingsPayload) => {
    const DEVICE_ID = getBrowserFingerprint();
    const resp = await API.put(`/device/${DEVICE_ID}/settings`, payload);
    if (resp.success) {
      return {
        success: true,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};
