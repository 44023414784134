import React, { FC } from 'react';
import { LandingNavigation } from 'src/pages/landings/components/LandingNavigation';
import { Button } from 'src/components/Forms/Button';
import { AppleIcon } from 'src/components/Icons';
import { ReactComponent as PlayMarketIcon } from '../../assets/playmarket.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/arrow-down.svg';
import { useTranslation } from 'react-i18next';
import { APPSTORE_URL, PLAYMARKET_URL } from 'src/config/urls';
import './styles.scss';
import classNames from 'classnames';

type Props = {
  onClick: (isShare?: boolean) => void;
  isDark?: boolean;
  title: string;
  description: string;
  subtitle?: string;
};

export const LandingHeader: FC<Props> = ({ onClick, isDark, subtitle, title, description }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('landing-header', { 'header-dark': isDark })}>
      <div className="landing-header__top-section--wrapper">
        <div className="landing-header__container">
          <LandingNavigation onClick={onClick} />
          <div className="landing-header__middle">
            <div className="landing-header__middle-content">
              <h1 className="landing-header__title">{title}</h1>
              <div className="landing-header__description">{description}</div>
              {subtitle && <h2 className="landing-header__subtitle">{subtitle}</h2>}
              <div className="landing-header__top-section-button">
                <Button
                  type="primary"
                  className="landing__button"
                  content={t('getStart')}
                  onClick={onClick}
                />
              </div>
              <div className="landing-header__top-section-buttons">
                <Button
                  content={t('continueWithApple')}
                  type="basic-border"
                  className="account-connections__apple-button landing__top-button"
                  onClick={() => {
                    window.open(APPSTORE_URL, '_blank');
                  }}
                  isDisabled={false}
                  icon={<AppleIcon />}
                />
                <Button
                  content={t('continueWithGoogle')}
                  type="basic-border"
                  onClick={() => {
                    window.open(PLAYMARKET_URL, '_blank');
                  }}
                  isDisabled={false}
                  icon={<PlayMarketIcon />}
                  className="account-connections__apple-button landing__top-button"
                />
              </div>
            </div>
          </div>
          <div
            className="landing-header__more-control"
            onClick={() => {
              const element = document.getElementById('landing-carousel');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            <div className="landing-header__more-control-wrapper">
              <span>{t('moreAboutTheService')}</span> <br />
              <ArrowDownIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
