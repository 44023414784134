import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GlobalProvider } from 'src/context/GlobalContext/GlobalProvider';
import App from './App';
import './i18n';
import './styles/index.scss';

const appRoot = document.getElementById('root');
appRoot?.setAttribute('notranslate', 'true');

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(appRoot as HTMLElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <GlobalProvider>
      <App />
    </GlobalProvider>
  </QueryClientProvider>,
);

function setVh() {
  const vh = window.innerHeight;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

// Initial call
setVh();
// Re-calculate on window resize
window.addEventListener('resize', setVh);
