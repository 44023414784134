import React from 'react';
import './styles.scss';

export const Covers = () => {
  const [cover, setCover] = React.useState(0);

  const handleCoverChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCover = parseInt(event.target.value, 10);
    setCover(newCover);
  };

  return (
    <div className="covers">
      <h3 className="covers__title">Выбор фона для обложки</h3>
      <div className="covers__content">
        {new Array(12).fill(null).map((_, index) => (
          <div className="covers__cover" key={index}>
            <input
              id={`cover${index}`}
              type="radio"
              name="cover"
              value={index}
              checked={cover === index}
              onChange={handleCoverChange}
            />
            <label htmlFor={`cover${index}`}></label>
          </div>
        ))}
      </div>
    </div>
  );
};
