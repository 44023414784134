import { useMutation, useQuery } from '@tanstack/react-query';
import { API } from 'src/utils/API';
import { SessionType } from 'src/types/general';

type ResponseType<T> = {
  success: boolean;
  data: T;
};

export const useGetSessions = () => {
  const fetchData = async (): Promise<ResponseType<SessionType[]>> => {
    try {
      const response = await API.get('/users/sessions', 'auth');
      return {
        success: true,
        data: response.data,
      };
    } catch (e) {
      return {
        success: false,
        data: [],
      };
    }
  };

  return useQuery({
    queryKey: ['sessions'],
    queryFn: fetchData,
    staleTime: 0,
    retry: 0,
  });
};

export const useTerminateSession = () => {
  const fetchData = async (deviceId: string) => {
    try {
      await API.post(`/users/sessions/${deviceId}/logout`, {}, 'auth');
      return {
        success: true,
      };
    } catch (e) {
      return {
        success: false,
      };
    }
  };
  return useMutation({
    mutationFn: fetchData,
    retry: 0,
  });
};
