import {
  Belgium,
  Bulgaria,
  Croatia,
  Cyprus,
  CzechRepublic,
  Denmark,
  Estonia,
  Finland,
  France,
  Germany,
  Greece,
  Hungary,
  Ireland,
  Italy,
  Latvia,
  Lithuania,
  Luxembourg,
  Malta,
  Netherlands,
  Poland,
  Portugal,
  Romania,
  Slovakia,
  Slovenia,
  Spain,
  Ukraine,
} from 'src/components/Icons/flags';

export const flags = [
  { name: 'belgium', code: '+32', flag: <Belgium /> },
  { name: 'bulgaria', code: '+359', flag: <Bulgaria /> },
  { name: 'croatia', code: '+385', flag: <Croatia /> },
  { name: 'cyprus', code: '+357', flag: <Cyprus /> },
  { name: 'czechRepublic', code: '+420', flag: <CzechRepublic /> },
  { name: 'denmark', code: '+45', flag: <Denmark /> },
  { name: 'estonia', code: '+372', flag: <Estonia /> },
  { name: 'finland', code: '+358', flag: <Finland /> },
  { name: 'france', code: '+33', flag: <France /> },
  { name: 'germany', code: '+49', flag: <Germany /> },
  { name: 'greece', code: '+30', flag: <Greece /> },
  { name: 'hungary', code: '+36', flag: <Hungary /> },
  { name: 'ireland', code: '+353', flag: <Ireland /> },
  { name: 'italy', code: '+39', flag: <Italy /> },
  { name: 'latvia', code: '+371', flag: <Latvia /> },
  { name: 'lithuania', code: '+370', flag: <Lithuania /> },
  { name: 'luxembourg', code: '+352', flag: <Luxembourg /> },
  { name: 'malta', code: '+356', flag: <Malta /> },
  { name: 'netherlands', code: '+31', flag: <Netherlands /> },
  { name: 'poland', code: '+48', flag: <Poland /> },
  { name: 'portugal', code: '+351', flag: <Portugal /> },
  { name: 'romania', code: '+40', flag: <Romania /> },
  { name: 'slovakia', code: '+421', flag: <Slovakia /> },
  { name: 'slovenia', code: '+386', flag: <Slovenia /> },
  { name: 'spain', code: '+34', flag: <Spain /> },
  { name: 'ukraine', code: '+380', flag: <Ukraine /> },
];
