import React, { useEffect, useState } from 'react';
import { AuthLayout } from 'src/pages/auth/components/AuthLayout';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption } from 'src/components/Forms/Dropdown';
import { Button } from 'src/components/Forms/Button';
import { Input } from 'src/components/Forms/Inputs/Input';
import { useUpdateProfile } from 'src/api/profile';
import { useNavigate } from 'react-router-dom';
import LocalStorage from 'src/utils/Storage';
import './styles.scss';
import { getBrowserFingerprint } from 'src/helpers';

const defaultState = {
  locale_code: 'en',
  user_name: '',
};

const options = [
  { text: 'English', value: 'en' },
  { text: 'French', value: 'fr' },
  { text: 'Spanish', value: 'es' },
];

export const AuthComplete = () => {
  const profile = LocalStorage.get('profile');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [profileState, setProfileState] = useState(defaultState);
  const [showLocale, setShowLocale] = useState(true);

  const { mutateAsync: saveData, isPending } = useUpdateProfile();
  const [error, setError] = useState('');

  useEffect(() => {
    const session = localStorage.getItem('session');
    if (!session) {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    setProfileState({
      user_name: profile?.user_name ?? '',
      locale_code: profile?.locale_code || 'en',
    });
    if (!!profile?.locale_code ?? '') {
      navigate('/');
    }
  }, [navigate]);

  const isDisabled = () => {
    if (!!error.length || isPending) return true;

    if (showLocale) {
      return profileState.locale_code === '';
    } else {
      return profileState.user_name === '';
    }
  };

  const onChangeLanguage = ({ value }: DropdownOption) => {
    setProfileState((state) => ({
      ...state,
      locale_code: value,
    }));
  };

  const onUserNameChange = (value: string) => {
    setProfileState((state) => ({
      ...state,
      user_name: value,
    }));
    setError('');
  };

  const onCtaClick = async () => {
    if (showLocale) {
      setShowLocale(false);
      return;
    }
    const device_id = getBrowserFingerprint();
    const response = await saveData({ ...profileState, device_id });
    if (!response.success) {
      setError(response.error);
      return;
    }

    LocalStorage.set('profile', response.profile);
    navigate('/');
  };

  return (
    <AuthLayout>
      <div className="auth__row mb-30">
        <h3 className="auth__title">{t('welcome')}</h3>
      </div>

      {showLocale && (
        <div className="auth__row mb-20">
          <Dropdown
            label={t('chooseALanguage')}
            placeholder={t('chooseALanguage')}
            options={options}
            onSelect={onChangeLanguage}
            selectedValue={profileState.locale_code}
          />
        </div>
      )}

      {!showLocale && (
        <div className="auth__row mb-20">
          <Input
            label={`${t('enterUniqueUsername')}:`}
            subLabel={t('usernameLetsPeopleContactYou')}
            placeholder={t('yourUsername')}
            name="username"
            onChange={onUserNameChange}
            value={profileState.user_name}
            error={error}
          />
        </div>
      )}

      <div className="auth__row mb-30">
        <Button
          content={t('enter')}
          type="primary"
          className="auth__action-button"
          onClick={onCtaClick}
          isDisabled={isDisabled()}
          fullWidth
        />
      </div>
    </AuthLayout>
  );
};
