import React from 'react';
import { Uploader } from 'src/pages/landings/components/FileSharing/components/Uploader';
import { Button } from 'src/components/Forms/Button';
import { ReactComponent as PlusIcon } from './assets/plus-icon.svg';
import { ShareLogin } from 'src/pages/landings/components/FileSharing/components/ShareLogin';
import './styles.scss';

export const FileSharing = () => {
  return (
    <div className="file-sharing">
      <h2 className="file-sharing__title">Файлообменик UMI</h2>
      <div className="file-sharing__content">
        <Uploader />
        <div className="file-sharing__row">
          <Button type="primary-dark" content="Создать галерею" fullWidth icon={<PlusIcon />} />
        </div>
        <ShareLogin />
      </div>
    </div>
  );
};
