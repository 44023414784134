import React, { FC } from 'react';
import { SearchRegular } from 'src/components/Icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './searchInput.scss';

type Props = {
  label?: string;
  subLabel?: string;
  name?: string;
  id?: string;
  className?: string;
  onChange?: (value: string) => void;
  value?: string | null;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  isRequired?: boolean;
  type?: string;
};
export const SearchInput: FC<Props> = ({
  label,
  name,
  id,
  className,
  onChange,
  value,
  onBlur,
  onFocus,
  placeholder,
  isRequired,
  type,
  subLabel,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('search-control', className)}>
      {label && (
        <label className="input-container__label" htmlFor={id}>
          {isRequired && '*'}
          {label}
        </label>
      )}
      {subLabel && <div className="input-container__sub-label">{subLabel}</div>}
      <div className="search-control__icon">
        <SearchRegular />
      </div>
      <input
        className="search-control__input"
        placeholder={placeholder || t('search')}
        type={type || 'text'}
        id={id}
        name={name}
        value={value || ''}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
        }}
        onFocus={() => {
          if (onFocus) {
            onFocus();
          }
        }}
      />
    </div>
  );
};
