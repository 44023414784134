import React from 'react';
import { Input } from 'src/components/Forms/Inputs/Input';
import { Textarea } from 'src/components/Forms/Textarea';
import { LoadingArea } from 'src/pages/fileShare/pages/createGallery/components/LoadingArea';
import { SectionFooter } from 'src/pages/fileShare/pages/createGallery/components/SectionFooter';
import './styles.scss';
import { Covers } from 'src/pages/fileShare/pages/createGallery/components/Covers';

export const GalleryDesign = () => {
  return (
    <div className="gallery-design">
      <div className="gallery-design__row">
        <Input label="Название галереи*" placeholder="Введите название" />
      </div>
      <div className="gallery-design__row">
        <Textarea label="Описание" placeholder="Введите описание галереи" />
      </div>
      <div className="gallery-design__row">
        <LoadingArea title="Выбор обложки" />
      </div>
      <div className="gallery-design__row">
        <Covers />
      </div>
      <div className="gallery-design__row">
        <SectionFooter />
      </div>
    </div>
  );
};
