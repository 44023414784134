import React, { FC, ReactNode } from 'react';
import { AppProvider } from 'src/context/AppContext/AppProvider';
import { DiskProvider } from 'src/context/DiskContext/DiskProvider';

type Props = {
  children: ReactNode;
};

export const PublicRoute: FC<Props> = ({ children }) => {
  return (
    <>
      <AppProvider>
        <DiskProvider>{children}</DiskProvider>
      </AppProvider>
    </>
  );
};
