import { useGetPricing } from 'src/api/payments';
import { get } from 'lodash';
import { DriveTypes, PriceType } from 'src/types';

export const usePrices = (isPublic?: boolean) => {
  const { data } = useGetPricing(isPublic);
  const prices = get(data, 'data', []) || [];

  const getPricePerGb = (type: DriveTypes) => {
    const price: PriceType | { price_per_gb: string } = prices.find(
      (item) => item.drive_type === type,
    ) || { price_per_gb: '0.00' };
    return price.price_per_gb;
  };

  return {
    getPricePerGb,
  };
};
