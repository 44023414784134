import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';

export const Storages = () => {
  const { t } = useTranslation();
  return (
    <div className="storages">
      <div>
        <div className="storages__label">{t('chooseServerStorage')}*</div>
        <div className="storages__value">{t('AWSStorage')}</div>
      </div>
    </div>
  );
};
