import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as ChevronIcon } from './assets/chevron-down.svg';
import { ReactComponent as CheckIcon } from './assets/check-icon.svg';

import './inline.scss';
import classNames from 'classnames';

interface Option {
  label: string;
  value: string | number;
}

interface InlineDropDownProps {
  options: Option[];
  value?: string | number;
  onChange: (value: string | number) => void;
  icon?: React.ReactNode;
  label?: string;
}

export const InlineDropDown: React.FC<InlineDropDownProps> = ({
  options,
  value,
  onChange,
  icon,
  label,
}) => {
  const [opened, setOpened] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpened(!opened);
  };

  const handleSelect = (optionValue: string | number) => {
    onChange(optionValue);
    setOpened(false);
  };

  const selectedLabel = options.find((option) => option.value === value)?.label || 'Select';

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setOpened(false);
    }
  };

  useEffect(() => {
    if (opened) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [opened]);

  return (
    <div className={classNames('inline-dropdown', { opened })} ref={dropdownRef}>
      <div className="inline-dropdown__header" onClick={handleToggle}>
        <div className="inline-dropdown__icon-value">
          {icon && <div className="inline-dropdown__icon">{icon}</div>}
          <div className="inline-dropdown__value">
            <div className="inline-dropdown__label">
              {label}
              {selectedLabel.length && ':'}
            </div>
            <span>{selectedLabel}</span>
          </div>
        </div>
        <ChevronIcon className="inline-dropdown__chevron" />
      </div>
      {opened && (
        <div className="inline-dropdown__content">
          {options.map((option) => (
            <div
              key={option.value}
              className={classNames('inline-dropdown__item', { selected: option.value === value })}
              onClick={() => handleSelect(option.value)}
            >
              <CheckIcon
                className={classNames({ 'inline-dropdown__transparent': option.value !== value })}
              />{' '}
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
