import { FilesPayload, useGetFilesInfinity } from 'src/api/files';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { FileType } from 'src/types';
import { useScrollContent } from 'src/hooks/useScrollContent';
import { getFileExtensionsByType } from 'src/config/fileTypes';

export const useGetFilesWithScrolls = (filesPayload: FilesPayload, isDisabled?: boolean) => {
  const { filterType } = useAppContext();

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetching: isLoadingData,
    isPending,
  } = useGetFilesInfinity(
    {
      ...filesPayload,
      type: filterType,
    },
    isDisabled,
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const files: FileType[] = data?.files || [];

  const { containerRef } = useScrollContent(fetchNextPage, hasNextPage && !isLoadingData);

  return {
    files: files.filter(
      (file: FileType) =>
        filterType === 'all' || getFileExtensionsByType(filterType).includes(file.extension),
    ),
    hasNextPage,
    fetchNextPage,
    isLoadingData,
    containerRef,
    isPending,
  };
};
