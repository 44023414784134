import React, { FC, useEffect } from 'react';
import { HashTagType } from 'src/types';
import { Input } from 'src/components/Forms/Inputs/Input';
import { Button } from 'src/components/Forms/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDeleteHashtagFormFile, useEditHashtag } from 'src/api/hashtags';
import { useParams } from 'react-router-dom';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { blockedHashtags } from 'src/components/HashtagsBlock/config';

type Props = {
  hashtag: HashTagType;
  onEdit: () => void;
  onDelete: () => void;
};
export const EditHashtag: FC<Props> = ({ hashtag, onEdit, onDelete }) => {
  const { t } = useTranslation();
  //TODO: move to single context
  const { openConfirmation } = useAppContext();
  const { openNotification } = useGlobalContext();

  const { diskId } = useParams();
  const { mutateAsync: saveHashtag, isPending } = useEditHashtag(diskId || '');
  const { mutateAsync: deleteHashtag, isPending: isDeleting } = useDeleteHashtagFormFile();
  const [initialValues, setInitialValues] = React.useState<{ name: string }>({
    name: hashtag.Hashtag,
  });

  useEffect(() => {
    setInitialValues({ name: hashtag.Hashtag });
  }, [hashtag]);

  const handleSaveChanges = async (values: { name: string }) => {
    await saveHashtag({ hashtag_id: hashtag.ID, hashtag: values.name });
    setInitialValues({ name: values.name });
    onEdit();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(50, ' ').required(' '),
  });

  const onDeleteClick = () => {
    openConfirmation({
      content: 'By deleting a hashtag',
      secondContent:
        'The files to which it has been assigned will no longer show up in searches for that hashtag',
      onSure: async () => {
        openConfirmation({ open: false });

        const response = await deleteHashtag({ item_id: hashtag.ItemID, hashtag_id: hashtag.ID });

        if (!response.success) {
          openNotification({ notification: response.error, type: 'error' });
          return;
        }
        onDelete();
      },
      className: 'edit-hashtags__confirmation',
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSaveChanges}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ submitForm, values, setFieldValue, errors, isValid, dirty }) => (
        <div className="edit-hashtags">
          <div className="edit-hashtags__title">Edit hashtag</div>
          <div className="edit-hashtags__form">
            <div className="edit-hashtags__form-title">Hashtag</div>
            <Input
              className="edit-hashtags__input mb-15"
              placeholder="#hashtag"
              value={values.name}
              onChange={async (value) => {
                await setFieldValue('name', value);
              }}
              error={errors.name || blockedHashtags.includes(values.name) ? ' ' : ''}
            />
            <Button
              type="primary"
              onClick={submitForm}
              fullWidth
              content="Save"
              className="mb-1"
              isDisabled={
                !isValid ||
                isPending ||
                !dirty ||
                isDeleting ||
                blockedHashtags.includes(values.name)
              }
            />
            <Button type="text-danger" onClick={onDeleteClick} fullWidth content="Delete" />
          </div>
        </div>
      )}
    </Formik>
  );
};
