import React from 'react';
import './styles.scss';
import { Dropdown } from 'src/components/Forms/Dropdown';
import { ShareLogin } from 'src/pages/fileShare/pages/createGallery/components/Settings/components/ShareLogin';
import { Input } from 'src/components/Forms/Inputs/Input';
import { Checkbox } from 'src/components/Forms/Checkbox';
import { SectionFooter } from 'src/pages/fileShare/pages/createGallery/components/SectionFooter';

export const GallerySettings = () => {
  const options = [
    {
      text: '1 день',
      value: '1',
    },
    {
      text: '2 дня',
      value: '2',
    },
    {
      text: '3 дня',
      value: '3',
    },
    {
      text: '4 дня',
      value: '4',
    },
  ];
  return (
    <div className="gallery-settings">
      <div className="gallery-settings__row">
        <div className="gallery-settings__cell-data">
          <Dropdown
            options={options}
            onSelect={() => {}}
            label="Срок хранения до:"
            placeholder="Период хранения"
            className={'gallery-settings__dropdown'}
          />
          <div className="gallery-settings__period-description">
            Бесплатный срок хранения до 7 дней
          </div>
        </div>
        <div className="gallery-settings__cell-data">
          <ShareLogin />
        </div>
      </div>

      <div className="gallery-settings__row">
        <div className="gallery-settings__cell-data">
          <Input label="Ссылка для галереи*" placeholder="Ссылка" />
        </div>
        <div className="gallery-settings__cell">
          <div className="gallery-settings__generate">Сгенерировать</div>
        </div>
      </div>

      <div className="gallery-settings__row">
        <div className="gallery-settings__cell-data">
          <Input label="Ключ доступа*" placeholder="Введите ключ доступа" />
        </div>
        <div className="gallery-settings__cell">
          <div className="gallery-settings__generate">Сгенерировать</div>
        </div>
      </div>

      <div className="gallery-settings__row">
        <div className="gallery-settings__cell-data">
          <div className="gallery-settings__subtitle">Права доступа*</div>
          <Checkbox label="Только для просмотра" id="viewOnly" />
          <Checkbox label="Доступно скачивание всей галереи" id="multiple" />
          <Checkbox label="Доступно скачивание отдельных файлов" id="signle" />
        </div>
        <div className="gallery-settings__cell"></div>
      </div>

      <div className="gallery-settings__row">
        <div className="gallery-settings__cell-data">
          <Checkbox label="Отправить приглашение по почте" id="byEmail" />
          <Input placeholder="Введите емейл" />
        </div>
        <div className="gallery-settings__cell">
          <div className="gallery-settings__invetation">+ Добавить еще емейл</div>
        </div>
      </div>
      <div>
        <SectionFooter />
      </div>
    </div>
  );
};
