import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-web';
import classNames from 'classnames';
import './styles.scss';

type AnimationTypes =
  | 'convenience'
  | 'goals'
  | 'sync'
  | 'speed'
  | 'noBreaks'
  | 'openness'
  | 'security'
  | 'communication'
  | 'flex'
  | 'cool'
  | 'idn'
  | 'blinking'
  | 'curious';

type Props = {
  type: AnimationTypes;
  className?: string;
};

export const AnimatedClip: React.FC<Props> = ({ type, className }) => {
  const animationContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (animationContainerRef.current) {
      Lottie.loadAnimation({
        container: animationContainerRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: `/animations/${type}.json`,
      });
    }
  }, [type]);

  return (
    <div className={classNames('animated-clip', className)}>
      <div ref={animationContainerRef} className={classNames('animation-container', type)}></div>
    </div>
  );
};
