import { useMutation, useQuery } from '@tanstack/react-query';
import { API } from 'src/utils/API';
import { NotificationType, OperationType } from 'src/types';
import { objectToQueryParams } from 'src/helpers';
import { RequestResponse } from 'src/api/types';
import { StatisticType } from 'src/types/general';

type NotificationsResponse = {
  success: boolean;
  notifications: NotificationType[];
};

type GetNotificationsPayloadType = {
  disabled?: boolean;
  limit: number;
  offset: number;
  drive_id?: string;
  type?: string;
  instanceType: 'notification' | 'operation';
};

export const useGetNotifications = (payload: GetNotificationsPayloadType) => {
  const { disabled, instanceType, drive_id, ...rest } = payload;

  const url =
    instanceType === 'notification' ? '/notifications/web/history' : '/operation/web/history';

  const fetchData = async (): Promise<NotificationsResponse> => {
    let params: { [index: string]: any } = rest;
    if (drive_id && drive_id !== 'all') {
      params = {
        ...params,
        drive_id,
      };
    }
    const resp = await API.get(`${url}?${objectToQueryParams(params)}`, 'archive');
    if (resp.success) {
      const data = resp.data.sort(
        (a: NotificationType, b: NotificationType) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );

      return {
        success: true,
        notifications: data,
      };
    } else {
      return { success: false, notifications: [] };
    }
  };

  const queryResult = useQuery({
    queryKey: ['get-notifications-operations', payload],
    queryFn: fetchData,
    staleTime: 0,
    enabled: !disabled,
    retry: 0,
  });

  const invalidateCache = () => {
    queryResult.refetch();
  };
  return {
    ...queryResult,
    invalidateCache,
  };
};

export const useReadNotification = (type?: 'notification' | 'operation') => {
  const mutateFunction = async (message_ids: string[]) => {
    try {
      const url = type === 'notification' ? '/notifications/read' : '/operation/read';
      await API.post(url, { message_ids }, 'archive');
      return {
        success: true,
      };
    } catch (e) {
      console.log(e);
    }
  };

  return useMutation({
    mutationFn: mutateFunction,
  });
};

export const useGetStatistics = (disk_id?: string) => {
  const fetData = async (): Promise<RequestResponse<StatisticType>> => {
    const url = disk_id ? `/user/statistics/drives/${disk_id}` : '/user/statistics';
    const resp = await API.get(url, 'archive');
    if (resp.success) {
      return {
        success: true,
        data: resp.data,
      };
    } else {
      return { success: false };
    }
  };

  return useQuery({
    queryKey: ['get-statistics', disk_id],
    queryFn: fetData,
    staleTime: 0,
    retry: 0,
  });
};
