import React from 'react';
import { ReactComponent as DataStructuringIcon } from './assets/data-structuring.svg';
import { ReactComponent as SelectingCost } from './assets/selecting-cost.svg';
import { ReactComponent as TagSystem } from './assets/tag-system.svg';
import { ReactComponent as ElectronicIcon } from './assets/electronic-signin.svg';
import { ReactComponent as OwnStorage } from './assets/own-storage.svg';
import { ReactComponent as ChatsIcon } from './assets/chats.svg';
import './styles.scss';
import { useTranslation } from 'react-i18next';

export const Capabilities = () => {
  const { t } = useTranslation();

  const capabilities = [
    {
      icon: <DataStructuringIcon />,
      title: t('dataStructuring'),
    },
    {
      icon: <SelectingCost />,
      title: t('selectingCostAndStorageCapacity'),
    },
    {
      icon: <TagSystem />,
      title: t('tagAndHashtagSystemFiles'),
    },
    {
      icon: <ElectronicIcon />,
      title: t('electronicDocumentSigning'),
    },
    {
      icon: <OwnStorage />,
      title: t('connectionOfYourStorage'),
    },
    {
      icon: <ChatsIcon />,
      title: t('chatForCorporateClients'),
    },
  ];

  return (
    <div className="capabilities">
      <h2 className="capabilities__title">{t('storageCapabilities')}</h2>
      <div className="capabilities__content">
        {capabilities.map((item, index) => (
          <div className="capabilities__item" key={index}>
            {item.icon}
            <span>{item.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
