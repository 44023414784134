import React, { FC } from 'react';
import classNames from 'classnames';
import { ArrowRightIcon, FolderIcon, StarSmallIcon } from 'src/components/Icons';
import { ReactComponent as CheckIcon } from '../../assets/check-icon.svg';
import { ReactComponent as SelectedFolder } from 'src/components/Modals/ModalDestination/assets/folder-selected.svg';
import './styles.scss';
import { WizardFolder } from 'src/components/Modals/ModalDestination';

type Props = {
  folder: WizardFolder;
  onCLick: () => void;
  selected?: boolean;
  onMove: (folder: WizardFolder) => void;
  onOpen: (folder: WizardFolder) => void;
  btnText?: string;
};
export const FolderTree: FC<Props> = ({ folder, onCLick, selected, onMove, onOpen, btnText }) => {
  return (
    <div className={classNames('folder-tree')} onClick={onCLick}>
      <div className={classNames('folder-tree__wrapper', { selected })}>
        <div className="folder-tree__icons">
          {folder.is_favorite && (
            <div className="disk-tree__favorite">
              <StarSmallIcon />
            </div>
          )}
          {!selected ? (
            <FolderIcon className="folder-tree__icons-folder" />
          ) : (
            <SelectedFolder className="folder-tree__icons-folder" />
          )}
        </div>

        <div className="folder-tree__details">
          <div className="folder-tree__title">{folder.name}</div>
        </div>

        <div className="folder-tree__abtns">
          <div className="folder-tree__abtns-wrapper">
            <div
              className="folder-tree__abtn-move"
              onClick={(e) => {
                e.stopPropagation();
                onMove(folder);
              }}
            >
              {btnText}
            </div>
          </div>
          <div className="folder-tree__abtn ">
            {!selected ? (
              <div
                className="folder-tree__abtn-drill"
                onClick={(e) => {
                  e.stopPropagation();
                  onOpen(folder);
                }}
              >
                <ArrowRightIcon />
              </div>
            ) : (
              <CheckIcon />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
