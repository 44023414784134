import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Slider } from 'src/pages/landings/Cloud/components/PriceCalculator/components/StorageCapacity/Slider';
import { usePrices } from 'src/components/CreateDisk/usePrices';
import { CalculatorState } from 'src/pages/landings/Cloud/components/PriceCalculator/types';
import './styles.scss';

type Props = {
  onChange: (capacity: number, invoice: string) => void;
  state: CalculatorState;
};

export const StorageCapacity: FC<Props> = ({ onChange, state }) => {
  const { getPricePerGb } = usePrices(true);
  const { t } = useTranslation();
  const [capacity, setCapacity] = useState<number>(25);
  const [invoice, setInvoice] = useState<string>('monthly');

  useEffect(() => {
    onChange(capacity, invoice);
  }, [capacity, invoice]);

  const onChangeInvoice = () => {
    setInvoice((prev) => (prev === 'monthly' ? 'annually' : 'monthly'));
  };

  return (
    <div className="storage-capacity">
      <div className="storage-capacity__container">
        <div className="storage-capacity__invoice">
          <div className="storage-capacity__header">
            <div className="storage-capacity__label">{t('invoice')}:</div>
          </div>
          <div className="radio">
            <div className="custom-radio">
              <input
                id="invoice"
                type="radio"
                name="invoice"
                value={'monthly'}
                checked={invoice === 'monthly'}
                onChange={onChangeInvoice}
              />
              <label htmlFor="invoice">{t('monthly')}</label>
            </div>
            <div className="custom-radio">
              <input
                id="annual"
                type="radio"
                name="invoice"
                value={'annually'}
                checked={invoice === 'annually'}
                onChange={onChangeInvoice}
              />
              <label htmlFor="annual">{t('annually')}</label>
            </div>
            <div className="storage-capacity__discount">-20% {t('monthly')}</div>
          </div>
        </div>

        <div className="storage-capacity__header">
          <div className="storage-capacity__label">{t('storageCapacity')}</div>
          <div className="storage-capacity__value">
            {t('price')} 1 GB <span>{getPricePerGb(state.drive_type)} $</span>
          </div>
        </div>
        <Slider
          onChange={(value) => {
            setCapacity(value);
          }}
        />
      </div>
    </div>
  );
};
