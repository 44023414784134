import React from 'react';

export const getFileStyles = (
  currentOffset: { x: number; y: number } | null,
  index: number,
  total: number = 1,
  offset: number = 0,
  skipRotation: boolean = false,
): React.CSSProperties => {
  if (!currentOffset) {
    return { display: 'none' };
  }
  const { x, y } = currentOffset;
  const offsetX = x;
  const offsetY = y;
  let transform = '';

  switch (index) {
    case 0:
      transform = 'rotate(15deg)';
      break;
    case 1:
      transform = 'rotate(5deg)';
      break;
    case 2:
      transform = 'rotate(-10deg)';
      break;
  }

  return {
    transform,
    WebkitTransform: total > 1 && !skipRotation ? transform : '',
    top: offsetY + offset,
    left: offsetX,
    position: 'absolute',
  };
};

export const getFolderStyles = (
  currentOffset: { x: number; y: number } | null,
  index: number,
  total: number = 1,
  isTop: boolean = false,
): React.CSSProperties => {
  if (!currentOffset) {
    return { display: 'none' };
  }
  const { x, y } = currentOffset;
  const offsetX = x - 20;
  const offsetY = y + (isTop ? 0 : 40);
  let transform = '';

  switch (index) {
    case 0:
      transform = 'rotate(5deg)';
      break;
    case 1:
      transform = 'rotate(2deg)';
      break;
    case 2:
      transform = 'rotate(0deg)';
      break;
  }
  return {
    transform,
    WebkitTransform: total > 1 ? transform : '',
    top: offsetY,
    left: offsetX,
    position: 'absolute',
  };
};

export const getLineStyles = (
  currentOffset: { x: number; y: number } | null,
  index: number,
  total: number = 1,
  offset: number = 0,
  skipRotation: boolean = false,
): React.CSSProperties => {
  if (!currentOffset) {
    return { display: 'none' };
  }
  const { x, y } = currentOffset;
  const offsetX = x;
  const offsetY = y;
  let transform = '';

  switch (index) {
    case 0:
      transform = 'rotate(5deg)';
      break;
    case 1:
      transform = 'rotate(2deg)';
      break;
    case 2:
      transform = 'rotate(0deg)';
      break;
  }

  return {
    transform,
    WebkitTransform: total > 1 && !skipRotation ? transform : '',
    top: offsetY + offset,
    left: offsetX,
    position: 'absolute',
  };
};
