import React from 'react';
import background from 'src/assets/img/background.svg';
import { UploadFileModal } from 'src/components/Header/components/UploadFileModal';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const EmptyDisk = () => {
  const { diskId, folderId } = useParams();
  const { t } = useTranslation();

  return (
    <div className="disk-view__empty" style={{ backgroundImage: `url(${background})` }}>
      <div className="disk-view__empty-cta">
        <div className="disk-view__empty-title">{t('uploadYourFirstFile')}</div>
        <UploadFileModal
          id="empty-upload"
          diskId={diskId as string}
          folderId={folderId}
          onFinish={() => {}}
        />
      </div>
    </div>
  );
};
