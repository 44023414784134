import React, { useState } from 'react';
import { ReactComponent as PencilIcon } from './assets/pancil.svg';
import { PlaneBlock } from 'src/components/PlaneBlock';
import { EditProfile } from 'src/components/ProfileBlock/components/EditProfile';
import { ViewProfile } from 'src/components/ProfileBlock/components/ViewProfile';
import { CloseIcon } from 'src/components/Icons';
import {
  PrivacyIcon,
  TelegramIcon,
  WatsupIcon,
  LanguageIcon,
  ThemeIcon,
} from 'src/components/Icons/settings';
import { InlineDropDown } from 'src/components/Dropdown/inline';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Forms/Button';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import LocalStorage from 'src/utils/Storage';
import { ReactComponent as LogoutIcon } from './assets/logout-icon.svg';
import { useLogout } from 'src/api/profile';
import { useGetSettings, useUpdateSettings } from 'src/api/settings';
import { get } from 'lodash';
import { TELEGRAM_URL, WATSAPP_URL } from 'src/config/urls';
import { Link } from 'react-router-dom';
import { privacyNotice } from 'src/pages/auth/components/LoginSection/components/Terms/plicy';
import { Terms } from 'src/pages/auth/components/LoginSection/components/Terms';
import { termsData } from 'src/pages/auth/components/LoginSection/components/Terms/terms';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { ReactComponent as DeviceIcon } from './assets/devices-icon.svg';
import './styles.scss';
import { DevicesList } from 'src/components/DevicesList';

export const ProfileBlock = () => {
  const { hideProduction } = useFeatureFlags();
  const { mutateAsync } = useLogout();
  const { mutateAsync: saveSettings } = useUpdateSettings();
  const { openConfirmation, openModal } = useAppContext();
  const [showForm, setShowForm] = useState(false);
  const { i18n, t } = useTranslation();

  const { data } = useGetSettings();
  const language = get(data, 'settings.language', 'en');

  const languages: any = [
    { label: 'English', value: 'en' },
    { label: 'French', value: 'fr' },
    { label: 'Ukrainian', value: 'ua' },
    { label: 'Russian', value: 'ru' },
  ];

  const options: any = [
    { label: 'Item 1', value: 1 },
    { label: 'Item 2', value: 2 },
    { label: 'Item 3', value: 3 },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(language || languages[0].value);

  const onLanguageChange = async (value: string) => {
    setSelectedLanguage(value);
    await i18n.changeLanguage(value);
    await saveSettings({
      language: value,
    });
  };

  const onLogoutCLick = () => {
    openConfirmation({
      open: true,
      content: t('logOutConfirmation'),
      onSure: async () => {
        await mutateAsync();
        LocalStorage.logout();
      },
    });
  };

  const onPolicyClick = () => {
    openModal({
      open: true,
      content: (
        <>
          <Terms terms={privacyNotice} className="profile-policy" />
        </>
      ),
      width: '1200px',
    });
  };

  const onTermsClick = () => {
    openModal({
      open: true,
      content: (
        <>
          <Terms terms={termsData} className="profile-policy" />
        </>
      ),
      width: '1200px',
    });
  };

  const onDevicesCLick = () => {
    openModal({
      open: true,
      content: (
        <>
          <DevicesList />
        </>
      ),
      width: '400px',
      title: t('devices'),
      className: 'devices-modal',
    });
  };

  return (
    <div className="profile-form">
      <PlaneBlock
        title={t('personalInformation')}
        button={
          <div className="profile-form__btn" onClick={() => setShowForm(!showForm)}>
            {!showForm ? <PencilIcon /> : <CloseIcon />}
          </div>
        }
      >
        {showForm ? (
          <EditProfile
            onOk={() => {
              setShowForm(false);
            }}
          />
        ) : (
          <ViewProfile />
        )}
      </PlaneBlock>

      <PlaneBlock title={t('settings')}>
        <div className="plane-block__row pointer no-padding">
          <InlineDropDown
            label={t('language')}
            options={languages}
            value={selectedLanguage}
            onChange={(v) => onLanguageChange(v as string)}
            icon={<LanguageIcon className="plane-block__icon" />}
          />
        </div>
        {!hideProduction && (
          <div className="plane-block__row pointer no-padding">
            <InlineDropDown
              label="Theme"
              options={options}
              value={selectedLanguage}
              onChange={(newValue) => {
                setSelectedLanguage(newValue);
              }}
              icon={<ThemeIcon className="plane-block__icon" />}
            />
          </div>
        )}
      </PlaneBlock>

      <PlaneBlock title={t('security')}>
        <div className="plane-block__row pointer">
          <div className="security_icon" onClick={onDevicesCLick}>
            <DeviceIcon />
            <span>{t('devices')}</span>
          </div>
        </div>
      </PlaneBlock>

      <PlaneBlock title={t('support')}>
        <Link className="plane-block__row pointer" target="_blank" to={TELEGRAM_URL}>
          <TelegramIcon className="plane-block__icon" />
          Telegram
        </Link>
        <Link className="plane-block__row pointer" target="_=blank" to={WATSAPP_URL}>
          <WatsupIcon className="plane-block__icon" />
          WatsUp
        </Link>
      </PlaneBlock>

      <PlaneBlock title={t('agreements')}>
        <div className="plane-block__row pointer" onClick={onPolicyClick}>
          <PrivacyIcon className="plane-block__icon" />
          {t('privacyPolicy')}
        </div>
        <div className="plane-block__row pointer" onClick={onTermsClick}>
          <PrivacyIcon className="plane-block__icon" />
          {t('termsOfUse')}
        </div>
      </PlaneBlock>
      <Button
        type="basic"
        content={t('logOut')}
        fullWidth
        onClick={onLogoutCLick}
        className="profile-form__logout"
        icon={<LogoutIcon />}
      />
    </div>
  );
};
