import React, { FC } from 'react';
import { FileIcon } from 'src/components/FileIcon';
import './styles.scss';

type Props = {
  searchTerm: string;
};

export const GeneralSearch: FC<Props> = ({ searchTerm }) => {
  return (
    <div className="general-search">
      <div className="search-comp__result-item">
        <div className="search-comp__item-title">
          <FileIcon extension={'jpg'} />
          <span>Yesterday</span>
        </div>
        <div className="search-comp__item-info">
          <span>.odp</span>
          <span>380 MB</span>
        </div>
      </div>

      <div className="search-comp__result-item">
        <div className="search-comp__item-title">
          <FileIcon extension={'jpg'} />
          <span>Yesterday</span>
        </div>
        <div className="search-comp__item-info">
          <span>.odp</span>
          <span>380 MB</span>
        </div>
      </div>

      <div className="search-comp__result-item">
        <div className="search-comp__item-title">
          <FileIcon extension={'jpg'} />
          <span>Yesterday</span>
        </div>
        <div className="search-comp__item-info">
          <span>.odp</span>
          <span>380 MB</span>
        </div>
      </div>
    </div>
  );
};
