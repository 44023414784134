import React, { useEffect } from 'react';
import { router } from './libs/routes';
import { RouterProvider } from 'react-router-dom';
import { useGetSettings } from 'src/api/settings';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import LocalStorage from 'src/utils/Storage';

function App() {
  const { data, isLoading } = useGetSettings(!LocalStorage.isAuthorised());
  const language = get(data, 'settings.language', 'en');
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return <div className="App">{!isLoading && <RouterProvider router={router} />}</div>;
}

export default App;
