import React, { useState } from 'react';
import './style.scss';

interface PinInputProps {
  length: number;
  label: string;
  errorMessage?: string;
  onComplete: (pin: string) => void;
}

const PinInput: React.FC<PinInputProps> = ({ length, label, errorMessage, onComplete }) => {
  const [values, setValues] = useState(Array(length).fill(''));

  const handleChange = (value: string, index: number) => {
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);

    if (value && index < length - 1) {
      const nextInput = document.getElementById(`pin-input-${index + 1}`);
      if (nextInput) nextInput.focus();
    }

    if (newValues.every((v) => v !== '') && newValues.length === length) {
      onComplete(newValues.join(''));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !values[index] && index > 0) {
      const prevInput = document.getElementById(`pin-input-${index - 1}`);
      if (prevInput) prevInput.focus();
    }
  };

  return (
    <div className="pin-input">
      <label className={`pin-input__label ${errorMessage ? 'pin-input__label--error' : ''}`}>
        {label}
      </label>
      <div className="pin-input__container">
        {values.map((value, index) => (
          <input
            key={index}
            id={`pin-input-${index}`}
            type="text"
            maxLength={1}
            value={value}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            className={`pin-input__field ${errorMessage ? 'pin-input__field--error' : ''}`}
          />
        ))}
      </div>
      {errorMessage && <div className="pin-input__error-message">{errorMessage}</div>}
    </div>
  );
};

export default PinInput;
