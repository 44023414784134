import React, { useEffect, useState } from 'react';
import { LandingSection } from 'src/pages/landings/components/LandingSection';
import { LandingFooter } from 'src/pages/landings/components/LandingFooter';
import { LandingHeader } from 'src/pages/landings/components/LandingHeader';
import classNames from 'classnames';
import { LoginSection } from 'src/pages/auth/components/LoginSection';
import { CloseIcon } from 'src/components/Icons';
import { SlideMenu } from 'src/components/SlideMenu';
import { useTranslation } from 'react-i18next';
import { Datacenters } from 'src/pages/landings/Cloud/components/Datacenters';
import { PriceCalculator } from 'src/pages/landings/Cloud/components/PriceCalculator';
import { Capabilities } from 'src/pages/landings/Cloud/components/Capabilities';
import { Apps } from 'src/pages/landings/Cloud/components/Apps';
import { FileSharing } from 'src/pages/landings/components/FileSharing';
import { InfoLine } from 'src/pages/landings/Cloud/components/InfoLine';
import './styles.scss';

export const Cloud = () => {
  const { t } = useTranslation();
  const [showSide, setShowSide] = useState(false);
  const [showShare, setShowShare] = useState(false);

  useEffect(() => {
    document.documentElement.style.overflow = showSide ? 'hidden' : '';
    return () => {
      document.documentElement.style.overflow = '';
    };
  }, [showSide]);

  return (
    <div className={classNames('landing-page' /*{ withSide: showSide }*/)}>
      <div className="landing-page__content">
        <LandingHeader
          onClick={(isShare) => {
            setShowShare(!!isShare);
            setShowSide(!showSide);
          }}
          title={t('uMIMoreThanCloudStorage')}
          description={t('landingPageDescription')}
        />
        <div className="landing-page__section" id="landing-carousel">
          <InfoLine />
        </div>
        <div className="landing-page__section">
          <LandingSection>
            <Datacenters />
          </LandingSection>
        </div>
        <div className="landing-page__section landing-page__calculator">
          <PriceCalculator onClick={() => setShowSide(!showSide)} />
        </div>
        <div className="landing-page__section">
          <LandingSection>
            <div className="landing-container">
              <Capabilities />
            </div>
          </LandingSection>
        </div>
        <div className="landing-page__section">
          <div className="landing-container">
            <Apps />
          </div>
        </div>

        <div className="landing-page__section">
          <LandingFooter onClick={() => setShowSide(!showSide)} />
        </div>
      </div>

      <SlideMenu
        isOpen={showSide}
        onClose={() => {
          setShowSide(!showSide);
        }}
        position="right"
      >
        <div className={classNames('landing-page__side', { share: showShare })}>
          <div className="landing-page__side-content">
            <div className="landing-page__side-close" onClick={() => setShowSide(!showSide)}>
              <CloseIcon />
            </div>
            <div className="landing-page__side-content-wrapper">
              {showShare ? <FileSharing /> : <LoginSection defaultSize />}
            </div>
          </div>
        </div>
      </SlideMenu>
    </div>
  );
};
