import React, { FC } from 'react';
import classNames from 'classnames';
import './styles.scss';

type Props = {
  isFavorite: boolean;
  className?: string;
  color: string;
  id?: string;
};

export const DiskIcon: FC<Props> = ({ isFavorite, className, color, id }) => {
  const inner_id = 'disk-icon-' + id;
  return (
    <div className={classNames('disk-icon', className, color)}>
      {isFavorite ? (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.5" width="24" height="24" rx="5" fill={`url(#${inner_id}_fav)`} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 21C16.6944 21 20.5 17.1944 20.5 12.5C20.5 7.80558 16.6944 4 12 4C7.30558 4 3.5 7.80558 3.5 12.5C3.5 17.1944 7.30558 21 12 21ZM10.5775 9.2022L10.5765 9.20401L10.4125 9.49801L10.4125 9.49802C10.2325 9.82101 10.1425 9.98251 10.0025 10.089C9.86205 10.1955 9.68755 10.235 9.33806 10.314L9.02007 10.386C7.7901 10.664 7.17511 10.8035 7.02862 11.274C6.88262 11.7445 7.30161 12.235 8.14009 13.215L8.35709 13.469L8.35762 13.4696C8.59526 13.7472 8.71462 13.8866 8.76758 14.059C8.82157 14.231 8.80358 14.417 8.76758 14.789L8.73458 15.127L8.73432 15.1296C8.608 16.4364 8.54484 17.0897 8.92807 17.3805C9.31056 17.6705 9.88655 17.4055 11.038 16.8755L11.3355 16.7385C11.663 16.5875 11.8265 16.5125 12 16.5125C12.1735 16.5125 12.337 16.5875 12.664 16.7385L12.9625 16.8755L12.9645 16.8764C14.1141 17.4058 14.6897 17.6709 15.0724 17.38C15.4552 17.0897 15.392 16.4364 15.2657 15.1296L15.2654 15.127L15.2324 14.7885C15.1964 14.417 15.1784 14.231 15.2319 14.0585C15.2853 13.887 15.4042 13.7479 15.6408 13.471L15.6429 13.4685L15.8599 13.215C16.6984 12.2345 17.1174 11.744 16.9714 11.274C16.8249 10.8037 16.2108 10.6646 14.9816 10.3863L14.9804 10.386L14.6624 10.314C14.3124 10.235 14.1374 10.1955 13.9975 10.089C13.8575 9.98251 13.7675 9.82101 13.5875 9.49801L13.4235 9.20401L13.4225 9.2022C12.7896 8.0674 12.4732 7.5 12 7.5C11.5268 7.5 11.2104 8.0674 10.5775 9.2022Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id={`${inner_id}_fav`}
              x1="0"
              y1="12.5"
              x2="24"
              y2="12.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00F5A0" className="grad-start" />
              <stop offset="1" stopColor="#00D9F5" className="grad-end" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          width="40"
          height="41"
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="-0.00390625"
            y="0.648438"
            width="39.9961"
            height="39.9961"
            rx="8.33252"
            fill={`url(#${inner_id})`}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.9954 34.811C27.8186 34.811 34.1606 28.469 34.1606 20.6458C34.1606 12.8225 27.8186 6.48047 19.9954 6.48047C12.1721 6.48047 5.83008 12.8225 5.83008 20.6458C5.83008 28.469 12.1721 34.811 19.9954 34.811ZM19.9954 23.9792C21.8364 23.9792 23.3288 22.4868 23.3288 20.6458C23.3288 18.8049 21.8364 17.3125 19.9954 17.3125C18.1545 17.3125 16.6621 18.8049 16.6621 20.6458C16.6621 22.4868 18.1545 23.9792 19.9954 23.9792Z"
            fill="#F5F9FC"
          />
          <defs>
            <linearGradient
              id={`${inner_id}`}
              x1="-0.00390625"
              y1="20.6465"
              x2="39.9922"
              y2="20.6465"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9400D3" className="grad-start" />
              <stop offset="1" stopColor="#4B0082" className="grad-end" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </div>
  );
};
