import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CloudUpload, PlusSmallWhite } from 'src/components/Icons';
import './FileUpload.scss';
import { useParams } from 'react-router-dom';

export type FileDataType = {
  fileSize: number;
  file: File;
  fileName: string;
  isUploaded?: boolean;
  diskId: string;
  folderId?: string;
};

type Props = {
  onChange?: (data: string) => void;
  multiple?: boolean;
  onChangeFiles?: (data: FileDataType[]) => void;
  accept?: string;
  id?: string;
};

const FileUpload: React.FC<Props> = ({ onChange, onChangeFiles, multiple, accept, id }) => {
  const { diskId, folderId } = useParams();

  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const onChangeFilesProcessing = (files: FileList | null) => {
    if (onChangeFiles && files && files.length > 0) {
      const filesData: FileDataType[] = [];
      for (let i = 0; i < files.length; i++) {
        filesData.push({
          file: files[i],
          fileSize: files[i].size || 0,
          fileName: files[i].name,
          diskId: diskId || '',
          folderId: folderId || '',
        });
      }

      onChangeFiles(filesData);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (onChange && files && files.length > 0) {
      setSelectedFile(files[0]);
      const reader = new FileReader();
      reader.onload = () => {
        onChange(reader.result as string);
      };
      reader.readAsDataURL(files[0]);
    }

    onChangeFilesProcessing(files);
  };

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      setSelectedFile(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
    }

    onChangeFilesProcessing(event.dataTransfer.files);
  }, []);

  return (
    <div
      className={`file-upload ${isDragging ? 'file-upload--dragging' : ''}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <label htmlFor="file-input" className="file-upload__label">
        <div className="file-upload__content">
          <CloudUpload className="file-upload__icon" />
          <span className="file-upload__text">
            {selectedFile ? selectedFile.name : t('moveSelectTheFilesUpload')}
          </span>
          <div className="file-upload__cta">
            <PlusSmallWhite />
          </div>
        </div>
      </label>
      <input
        id={id || 'file-input'}
        type="file"
        className="file-upload__input"
        multiple={multiple}
        onChange={handleFileChange}
        accept={accept}
      />
    </div>
  );
};

export default FileUpload;
