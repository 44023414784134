import React, { FC } from 'react';
//import { Skeleton } from '@mui/material';
import './styles.scss';
import classNames from 'classnames';

type Props = {
  type?: 'trash' | 'disks' | 'disk' | 'table';
  className?: string;
};
export const Skeleton: FC<Props> = ({ className, type = 'trash' }) => {
  const skeletons = {
    trash: (
      <div className="skeleton">
        {Array(10)
          .fill('')
          .map((_, index) => (
            <div key={index} className="skeleton-item" />
          ))}
      </div>
    ),
    disk: (
      <>
        <div className="grid-skeleton">
          {Array(10)
            .fill('')
            .map((_, index) => (
              <div key={index} className="grid-skeleton-item folder"></div>
            ))}
        </div>
        <div className="grid-skeleton-line">
          <div className="grid-skeleton-item line"></div>
        </div>
        <div className="grid-skeleton">
          {Array(40)
            .fill('')
            .map((_, index) => (
              <div key={index} className="grid-skeleton-item"></div>
            ))}
        </div>
      </>
    ),
    disks: <></>,
    table: (
      <>
        <div className="grid-skeleton-item table-placeholder"></div>
      </>
    ),
  };
  return <div className={classNames('skeleton-wrapper', className)}>{skeletons[type]}</div>;
};
