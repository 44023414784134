import React, { FC } from 'react';
import { CloseIcon } from 'src/components/Icons';
import './styles.scss';
import { TermsType } from 'src/pages/auth/components/LoginSection/components/Terms/types';
import classNames from 'classnames';

type Props = {
  onClose?: () => void;
  terms: TermsType;
  className?: string;
};
export const Terms: FC<Props> = ({ onClose, terms, className }) => {
  return (
    <div className={classNames('terms', className)}>
      <div className="terms__content">
        {!!onClose && (
          <div className="terms__close" onClick={onClose}>
            <CloseIcon />
          </div>
        )}
        <div className="terms__sections">
          <h1 className="terms__title">{terms.title}</h1>
          <div className="terms__content-wrapper">
            {terms.items.map((item, index) => {
              return (
                <div className="terms__section" key={index}>
                  {!!item.title && <h2 className="terms__subtitle">{item.title}</h2>}
                  <p>{item.content}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
