import { GetFoldersPayload, useGetFolders } from 'src/api/folders';
import { FolderType } from 'src/types';
import { sortFolders } from 'src/helpers/sort';
import { useAppContext } from 'src/context/AppContext/useAppcontext';

export const useGetFilteredFolders = (payload: GetFoldersPayload, isDisabled = false) => {
  const { filterType } = useAppContext();

  const { data: foldersData, ...rest } = useGetFolders(
    {
      ...payload,
      parent_id: payload.parent_id || '',
    },
    isDisabled,
  );
  const folders = foldersData?.folders || [];

  /* useEffect(() => {
    const count = folders.filter(() => filterType === 'all' || filterType === 'FOLDER').length;
    console.log(folders, count);
    if (countFolders !== count) {
      updateState({ countFolders: count });
    }
  }, [folders, filterType, favorites]);*/

  return {
    data: folders
      .filter(() => filterType === 'all' || filterType === 'FOLDER')
      .map((folder: FolderType) => {
        return {
          ...folder,
        };
      })
      .sort(sortFolders) as FolderType[],
    ...rest,
  };
};
