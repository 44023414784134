import React, { FC } from 'react';
import { RoundPlus } from 'src/components/Icons';
import './styles.scss';

type Props = {
  title?: string;
};

export const LoadingArea: FC<Props> = ({ title }) => {
  return (
    <div className="loading-area">
      {title && <div className="loading-area__label">{title}</div>}
      <div className="loading-area__wrapper">
        <div className="loading-area__content">
          <div className="loading-area__icon">
            <RoundPlus />
          </div>
          <div className="loading-area__title">Добавить файлы</div>
          <div className="loading-area__text">
            Нажмите + для выбора файловна устройстве или перетащите выбранные файлы в это окно
          </div>
        </div>
      </div>
      <div className="loading-area__description">Формат файла .png или .jpg</div>
    </div>
  );
};
