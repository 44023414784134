import { FileType, FolderType } from 'src/types';
import { TableItemType } from 'src/pages/diskPage/Views/ListView/types';

export const sortFiles = (f1: FileType, f2: FileType) => {
  if (f1.is_favorite === f2.is_favorite) {
    return f1.name.toLowerCase().localeCompare(f2.name.toLowerCase());
  }
  return f1.is_favorite ? -1 : 1;
};

export const sortFolders = (f1: FolderType, f2: FolderType) => {
  if (f1.is_favorite === f2.is_favorite) {
    return f1.name.toLowerCase().localeCompare(f2.name.toLowerCase());
  }
  return f1.is_favorite ? -1 : 1;
};

export const sortByFavorite = (f1: FolderType | FileType | TableItemType | any) => {
  return f1.is_favorite ? -1 : 1;
};
