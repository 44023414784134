import React, { FC, useEffect, useRef, useState } from 'react';
import './styles.scss';
import { ArrowBottom } from 'src/components/Icons';
import classNames from 'classnames';

export type DropdownOption = {
  text: string;
  value: string;
};

type CustomDropdownProps = {
  options: DropdownOption[];
  onSelect: (option: DropdownOption) => void;
  selectedValue?: string;
  label?: string;
  error?: string;
  className?: string;
  placeholder?: string;
};

export const Dropdown: FC<CustomDropdownProps> = ({
  options,
  onSelect,
  selectedValue,
  label,
  error,
  className,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
    options.find((opt) => opt.value === selectedValue) || null,
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: DropdownOption) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedOption(options.find((opt) => opt.value === selectedValue) || null);
  }, [selectedValue, options]);

  return (
    <div
      className={classNames('custom-dropdown', className, { hasError: !!error })}
      ref={dropdownRef}
    >
      {label && <div className="custom-dropdown__label">{label}</div>}
      <div
        className={classNames('custom-dropdown__element', { placeholder: !selectedOption })}
        onClick={handleToggle}
      >
        <span>{selectedOption ? selectedOption.text : placeholder}</span>
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>
          <ArrowBottom />
        </span>
      </div>
      <ul className={`custom-dropdown__list ${isOpen ? 'open' : ''}`}>
        {options.map((option) => (
          <li
            key={option.value}
            className={classNames('custom-dropdown__list-item', {
              'custom-dropdown__list-item--selected': selectedOption?.value === option.value,
            })}
            onClick={() => handleOptionClick(option)}
          >
            {option.text}
          </li>
        ))}
      </ul>
      {!!error && <div className="custom-dropdown__error">{error}</div>}
    </div>
  );
};
