import React, { FC, useRef, useState } from 'react';
import FileUpload from 'src/components/FileUploader/index';
import { Button } from 'src/components/Forms/Button';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-cropper';
import './changeCover.scss';
import 'cropperjs/dist/cropper.css';

type Props = {
  onCancel: () => void;
  onCrop: (data: string) => void;
};

const CROP_WIDTH = 800;
const CROP_HEIGHT = 800;

export const ChangeCover: FC<Props> = ({ onCancel, onCrop }) => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const { t } = useTranslation();
  const [img, setImg] = useState('');
  const [showCrop, setShowCrop] = useState(false);

  const getCropData = () => {
    if (cropperRef.current) {
      const cropper = (cropperRef.current as any).cropper;
      const cropBoxData = cropper.getCropBoxData();
      const cropWidth = cropBoxData.width;
      const cropHeight = cropBoxData.height;
      const targetWidth = Math.min(CROP_WIDTH, cropWidth);
      const targetHeight = Math.min(CROP_HEIGHT, cropHeight);
      const canvas = cropper.getCroppedCanvas({
        width: cropWidth <= CROP_WIDTH && cropHeight <= CROP_HEIGHT ? cropWidth : targetWidth,
        height: cropWidth <= CROP_WIDTH && cropHeight <= CROP_HEIGHT ? cropHeight : targetHeight,
      });
      const croppedImage = canvas.toDataURL();
      onCrop(croppedImage);
    }
  };

  return (
    <div className="change-cover">
      {!showCrop && (
        <div>
          {!!img.length && <img className="change-cover__preview" src={img} alt="" />}
          <FileUpload onChange={setImg} accept="image/*" />
          <p className="change-cover__text">
            Ensure the file types are restricted to images (e.g., JPEG, PNG).
          </p>
          <Button
            content={'Crop Avatar'}
            type="basic"
            className="change-cover__cta mt-10"
            onClick={() => setShowCrop(true)}
            isDisabled={!img.length}
            fullWidth
          />
          <div className="change-cover__cancel mt-10" onClick={onCancel}>
            {t('cancel')}
          </div>
        </div>
      )}

      {showCrop && (
        <div className="change-cover__crop">
          <Cropper
            src={img}
            style={{ height: '500px', width: '100%' }}
            initialAspectRatio={1}
            guides={false}
            viewMode={1}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={true}
            ref={cropperRef}
            aspectRatio={1}
          />
          <div className="change-cover__crop-cta" onClick={getCropData}>
            Crop
          </div>
        </div>
      )}
    </div>
  );
};
