import React, { useEffect, useState } from 'react';
import Tab from './tab';
import { DiskType } from 'src/types';
import { ReactComponent as PlusIcon } from './assets/plus-icon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'src/components/Forms/Button';
import { TrashIcon } from 'src/components/Icons';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import './styles.scss';

interface TabsProps {
  disks: DiskType[];
  onTabClick?: (disk: DiskType) => void;
  onAddClick?: () => void;
  children?: React.ReactNode;
}

interface SortableTabProps {
  disk: DiskType;
  onClick: () => void;
}

const SortableTab: any = SortableElement(({ disk, onClick }: SortableTabProps) => (
  <Tab label={disk.name} disk={disk} onClick={onClick} />
)) as React.ComponentType<SortableTabProps & { index: number }>;

interface SortableTabListProps {
  disks: DiskType[];
  activeIndex: number;
  handleTabClick: (disk: DiskType, index: number) => void;
}

const SortableTabList: any = SortableContainer(({ disks, handleTabClick }: any) => (
  <div className="disk-tabs__list-content">
    {disks.map((disk: DiskType, index: number) => (
      <SortableTab
        key={`disk-${disk.id}`}
        index={index}
        disk={disk}
        onClick={() => handleTabClick(disk, index)}
        disabled={disk.is_favorite}
      />
    ))}
  </div>
)) as React.ComponentType<SortableTabListProps>;

export const DiskTabs: React.FC<TabsProps> = ({ disks, onTabClick, onAddClick, children }) => {
  const navigate = useNavigate();
  const { diskId } = useParams();

  const [activeIndex, setActiveIndex] = useState(0);
  const [sortableDisks, setSortableDisks] = useState<DiskType[]>([]);

  useEffect(() => {
    const savedOrder = localStorage.getItem('sortedDiskIds');
    if (savedOrder) {
      const savedIds = JSON.parse(savedOrder) as string[];
      const orderedDisks = savedIds
        .map((id) => disks.find((disk) => disk.id === id))
        .filter((disk): disk is DiskType => disk !== undefined);

      setSortableDisks(orderedDisks);
    } else {
      sortDisks(disks);
    }
  }, [disks]);

  const sortDisks = (disksToSort: DiskType[]) => {
    const favoriteDisks = disksToSort.filter((disk) => disk.is_favorite);
    const nonFavoriteDisks = disksToSort.filter((disk) => !disk.is_favorite);
    setSortableDisks([...favoriteDisks, ...nonFavoriteDisks]);
  };

  const handleTabClick = (disk: DiskType, index: number) => {
    setActiveIndex(index);
    if (onTabClick) {
      onTabClick(disk);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const favoriteDisks = sortableDisks.filter((disk) => disk.is_favorite);
    const nonFavoriteDisks = sortableDisks.filter((disk) => !disk.is_favorite);

    // Adjust indices for non-favorite disks only
    const updatedNonFavoriteDisks = arrayMove(
      nonFavoriteDisks,
      oldIndex - favoriteDisks.length,
      newIndex - favoriteDisks.length,
    );

    // Merge favorite and sorted non-favorite disks
    const newDisksOrder = [...favoriteDisks, ...updatedNonFavoriteDisks];
    setSortableDisks(newDisksOrder);

    const sortedIds = newDisksOrder.map((disk) => disk.id);
    localStorage.setItem('sortedDiskIds', JSON.stringify(sortedIds));
  };

  const onAddClickHandler = () => {
    if (onAddClick) {
      onAddClick();
    }
  };

  const onTrashClick = () => {
    navigate(`/trash/${diskId}`);
  };

  useEffect(() => {
    if (diskId) {
      const diskIndex = disks.findIndex((disk) => disk.id === diskId);
      if (diskIndex !== -1) {
        setActiveIndex(diskIndex);
      }
    }
  }, [diskId, disks]);

  useEffect(() => {
    // Resort disks whenever `disks` or `is_favorite` status changes
    sortDisks(disks);
  }, [disks]);

  return (
    <div className="disk-tabs">
      <div className="disk-tabs__list">
        <SortableTabList
          disks={sortableDisks}
          activeIndex={activeIndex}
          handleTabClick={handleTabClick}
          onSortEnd={onSortEnd}
          axis="x"
          lockAxis="x"
          lockToContainerEdges
        />
        <div className="disk-tabs__add skip-outside" onClick={onAddClickHandler}>
          <PlusIcon className="skip-outside" />
        </div>
        <div className="disk-tabs__btn-wrapper">
          <Button
            type="basic"
            content="Trash"
            icon={<TrashIcon />}
            className="disk-tabs__trash-btn"
            onClick={onTrashClick}
          />
        </div>
      </div>
    </div>
  );
};
