import React from 'react';
import { Layout } from 'src/pages/fileShare/components/Layout';
import { GalleryTile, GalleryTileType } from 'src/pages/fileShare/components/GalleryTile';
import { RoundPlus } from 'src/components/Icons';
import './list.scss';

export const GalleryList = () => {
  const galleries: GalleryTileType[] = [
    {
      title: 'Sunset Reflection Sunset Reflection ',
      date: '21 August 2022',
      files: '12 files',
      size: '12GB',
      image: 'image_url_1',
    },
    {
      title: 'Floral Elegance',
      date: '15 July 2022',
      files: '10 files',
      size: '8GB',
      image: 'image_url_2',
    },
    {
      title: 'Mystic Warrior',
      date: '5 June 2022',
      files: '15 files',
      size: '14GB',
      image: 'image_url_3',
    },
    {
      title: 'Fiery Passion',
      date: '12 May 2022',
      files: '8 files',
      size: '9GB',
      image: 'image_url_4',
    },
    {
      title: 'Blue Rose',
      date: '29 April 2022',
      files: '20 files',
      size: '18GB',
      image: 'image_url_5',
    },
    {
      title: 'Golden Fields',
      date: '18 March 2022',
      files: '14 files',
      size: '11GB',
      image: 'image_url_6',
    },
  ];

  return (
    <Layout>
      <div className="gallery-list">
        <div className="gallery-list__create gallery-list__item">
          <div className="gallery-list__create-content">
            <RoundPlus />
            <div className="gallery-list__create-title">Создать галерею</div>
          </div>
        </div>

        {galleries.map((gallery, index) => (
          <GalleryTile key={index} tile={gallery} className="gallery-list__item" />
        ))}
      </div>
    </Layout>
  );
};
