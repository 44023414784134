import React, { FC, ReactNode, useEffect, useRef } from 'react';
import './AnimatedWrapper.scss';

interface AnimatedWrapperProps {
  children: ReactNode;
  speed?: number; // Speed in seconds, optional
}

const AnimatedWrapper: FC<AnimatedWrapperProps> = ({ children, speed = 10 }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      wrapper.style.animationDuration = `${speed}s`;
    }
  }, [speed]);

  return (
    <div className="animated-wrapper">
      <div className="content-wrapper" ref={wrapperRef}>
        {children}
        {children}
      </div>
    </div>
  );
};

export default AnimatedWrapper;
