import { useEffect, useRef } from 'react';

export const useOutside = (closeFunc: () => void) => {
  const panelRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Node;

    // Check if the click happened inside any element with .skip-outside
    const skipOutsideElements = document.querySelectorAll('.skip-outside');
    const isClickInsideSkipOutside = Array.from(skipOutsideElements).some((el) =>
      el.contains(target),
    );

    if (
      panelRef.current &&
      !panelRef.current.contains(target) &&
      !document.querySelector('.react-resizable')?.contains(target) &&
      !isClickInsideSkipOutside
    ) {
      closeFunc();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return {
    panelRef,
  };
};
