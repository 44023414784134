import { FileType } from 'src/types';
import { useParams } from 'react-router-dom';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { FilesPayload, useCopyFile, useFileCache } from 'src/api/files';
import { useTranslation } from 'react-i18next';
import { useCreateFavorite, useDeleteFavorite } from 'src/api/favorities';
import { useMoveToTrash } from 'src/api/trash';
import { DropDownItem } from 'src/components/Dropdown/types';
import {
  AddToFolderIcon,
  CopyIcon,
  DeleteIcon,
  DownloadIcon,
  InformationIcon,
  RenameIcon,
  SelectMultipleIcon,
} from 'src/components/Icons/context';
import { AddToFavoriteIcon } from 'src/components/Icons';
import { convertBytes } from 'src/helpers';
import React from 'react';
import { RenameForm } from 'src/pages/diskPage/components/RenameForm';
import { FILE_MOVE_SUCCESS } from 'src/config/Nnotifications';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import { useMoveObject } from 'src/api/objects';
import { DEFAULT_FILES_PAYLOAD } from 'src/config/constants';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import { useDownloadFile } from 'src/api/download';
import { useGetDisks } from 'src/hooks/useGetDisks';
import { useDiskManage } from 'src/hooks/useDiskManage';

export const useFileState = (file: FileType) => {
  const { addSection } = useGlobalContext();
  const name = file?.name || '';

  const { invalidateCache: reloadDisks } = useGetDisks();
  const { openFileInfo } = useDiskManage();
  const { diskId, folderId } = useParams();
  const { openModal, openDestinationModal, openConfirmation } = useAppContext();
  const { disk } = useDiskContext();

  const {
    removeFileCacheGlobal,
    updateFileCacheGlobal,
    addFileCache,
    addFileByCacheKey,
    invalidateCache,
  } = useFileCache({
    ...DEFAULT_FILES_PAYLOAD,
    parent_id: folderId || diskId,
  } as FilesPayload);

  const { openNotification } = useGlobalContext();
  const { t } = useTranslation();

  const { mutateAsync: deleteFile } = useMoveToTrash();
  const { mutateAsync: createFavorite } = useCreateFavorite();
  const { mutateAsync: deleteFavorite } = useDeleteFavorite();
  const { mutateAsync: moveFile } = useMoveObject();
  const { mutateAsync: copyFile } = useCopyFile();

  const onAddToFavorite = async () => {
    if (file.is_favorite) {
      updateFileCacheGlobal({ ...file, is_favorite: false });

      const response = await deleteFavorite(file.id);
      if (!response.success) {
        openNotification({
          type: 'error',
          notification: response.error,
        });
        updateFileCacheGlobal({ ...file, is_favorite: true });
      }
      return;
    }

    updateFileCacheGlobal({ ...file, is_favorite: true });
    const response = await createFavorite({
      id: file.id,
    });

    if (!response.success) {
      openNotification({
        type: 'error',
        notification: response.error,
      });
      updateFileCacheGlobal({ ...file, is_favorite: false });
    }
  };

  const onMoveFile = (file: FileType) => {
    openDestinationModal({
      open: true,
      title: `${t('move')}: ${name}`,
      subTitle: `${t('currentPosition')}: ${disk.name}`,
      onMove: async (disk, folder) => {
        removeFileCacheGlobal(file.id);
        const resp = await moveFile({
          item_ids: [file.id],
          destination_id: folder?.id || diskId || '',
        });

        if (resp.success) {
          addFileByCacheKey(
            [
              'load-files',
              {
                ...DEFAULT_FILES_PAYLOAD,
                parent_id: folder?.id || disk.id,
              },
            ],
            file,
          );
          openDestinationModal({ open: false });

          openNotification({
            notification: FILE_MOVE_SUCCESS,
            type: 'success',
          });
          reloadDisks();
        }
      },
    });
  };
  const onCopyFile = (file: FileType) => {
    openDestinationModal({
      open: true,
      title: `${t('copy')}: ${name}`,
      subTitle: '',
      btnText: t('copy'),
      onMove: async (disk, folder) => {
        const resp = await copyFile({
          file_id: file.id,
          name: file.name,
          destination_id: folder?.id || diskId || '',
        });

        if (resp.success) {
          openDestinationModal({ open: false });
          openNotification({
            notification: t('fileCopiedSuccessfully'),
            type: 'success',
          });
          invalidateCache();
          reloadDisks();
        } else {
          openNotification({
            notification: resp.error,
            type: 'error',
          });
        }
      },
    });
  };

  const { mutateAsync: downloadFile } = useDownloadFile();

  const onDeleteClick = (file: FileType) => {
    openConfirmation({
      content: `${t('deleteConfirmation')}: ${name}?`,
      onSure: async () => {
        openConfirmation({ open: false });
        removeFileCacheGlobal(file.id);
        const response = await deleteFile({
          item_ids: [file.id],
        });

        if (!response.success) {
          openNotification({ notification: response.error, type: 'error' });
          addFileCache(file);
          return;
        }
        reloadDisks();
      },
    });
  };

  const items: DropDownItem[] = [
    {
      label: t('select'),
      onClick: () => {
        addSection(file.id);
      },
      icon: <SelectMultipleIcon className="dropdown-svg-icon" />,
    },
    {
      label: t('rename'),
      onClick: () => {
        openModal({
          content: (
            <RenameForm
              type="file"
              onSave={async (name) => {
                openModal({ open: false });

                updateFileCacheGlobal({ ...file, name });
              }}
              onClose={() => {
                openModal({ open: false });
              }}
              object={file}
            />
          ),
          title: `${t('rename')}: ${name}`,
          className: 'create-folder-modal',
        });
      },
      icon: <RenameIcon className="dropdown-svg-icon" />,
    },
    {
      label: t('download'),
      onClick: async () => {
        await downloadFile({ id: file.id, filename: file.name });
      },
      icon: <DownloadIcon className="dropdown-svg-icon" />,
    },
    {
      label: t('copy'),
      onClick: () => onCopyFile(file),
      icon: <CopyIcon className="dropdown-svg-icon" />,
    },
    {
      label: t('move'),
      onClick: () => onMoveFile(file),
      icon: <AddToFolderIcon className="dropdown-svg-icon" />,
    },

    {
      label: file.is_favorite ? t('removeFromFavorite') : t('addToFavorite'),
      onClick: onAddToFavorite,
      icon: <AddToFavoriteIcon className="dropdown-svg-icon" />,
    },
    {
      label: t('properties'),
      onClick: () => {
        openFileInfo(file);
      },
      icon: <InformationIcon className="dropdown-svg-icon" />,
    },
    {
      label: t('delete'),
      onClick: () => onDeleteClick(file),
      icon: <DeleteIcon className={'dropdown-svg-icon'} />,
      color: '#E20001',
    },
  ].filter(Boolean) as DropDownItem[];

  const size = convertBytes(file?.size || 0);
  return { items, size, onMoveFile, onDeleteClick };
};
