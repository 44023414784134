import React, { FC, useEffect } from 'react';
import { useGetFolders } from 'src/api/folders';
import { FolderType } from 'src/types';
import { FolderTree } from 'src/components/FoldersTree/folderTree';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { usePrevious } from 'src/hooks/usePrevious';

type Props = {
  diskId: string;
  folderId?: string;
  level?: number;
  onInit?: (hasFolders: boolean) => void;
};

export const FoldersTree: FC<Props> = ({ diskId, folderId, onInit, level = 1 }) => {
  const { t } = useTranslation();

  const parentId = folderId || diskId;
  const prevParentId = usePrevious(parentId);

  const { data: foldersData } = useGetFolders(
    {
      parent_id: parentId,
    },
    prevParentId === parentId,
  );

  const folders: FolderType[] = foldersData?.folders || [];

  useEffect(() => {
    if (onInit) {
      onInit(folders.length > 0);
    }
  }, [onInit, folders]);

  if (folders.length === 0) return <></>;

  return (
    <div className={classNames('disk-folders-tree')}>
      {level === 1 && <div className="disk-folders-tree__title">{t('folders')}</div>}
      {folders.map((folder) => {
        return (
          <FolderTree folder={folder} key={folder.id} level={level}>
            <FoldersTree folderId={folder.id} diskId={diskId} level={level + 1} />
          </FolderTree>
        );
      })}
    </div>
  );
};
