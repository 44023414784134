import React, { FC } from 'react';
import './styles.scss';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const EmptyBlock: FC<Props> = ({ children, className }) => {
  return <div className={classNames('empty-block', className)}>{children}</div>;
};
