import React from 'react';
import { Input } from 'src/components/Forms/Inputs/Input';
import { Button } from 'src/components/Forms/Button';
import { AppleIcon } from 'src/components/Icons';
import { ReactComponent as PlayMarketIcon } from '../../../../assets/playmarket.svg';
import './styles.scss';

export const ShareLogin = () => {
  return (
    <div className="share-login">
      <div className="share-login__form">
        <div className="share-login__row">
          <Input
            label={'Логин или ссылка'}
            placeholder="Введете логин или ссылку"
            className={'share-login__input'}
          />
        </div>
        <div className="share-login__row">
          <Input
            label={'Введите ключ доступа'}
            placeholder="Введите ключ"
            className={'share-login__input'}
          />
        </div>
        <div className="share-login__row">
          <Button type="primary-dark" content="Войти" fullWidth />
        </div>
        <div className="share-login__row">
          <div className="share-login__faq">Что такое ключ доступа?</div>
        </div>
      </div>
      <div className="share-login__register-btns">
        <div className="share-login__register-title">Зарегистрироваться</div>
        <div className="share-login__register-btns-row">
          <Button type="basic-border" content="Google" fullWidth icon={<PlayMarketIcon />} />
          <Button type="basic-border" content="Apple" fullWidth icon={<AppleIcon />} />
        </div>
      </div>
    </div>
  );
};
