import React from 'react';
import { DiskType } from 'src/types';
import { ContextDropdown } from 'src/components/Dropdown/context';
import { useDiskState } from 'src/pages/diskPage/components/Disk/hooks/useDiskState';
import { DiskIcon } from 'src/components/DiskIcon';
import { LockIcon } from 'src/components/Icons';
import { useParams } from 'react-router-dom';

interface TabProps {
  label: string;
  onClick: () => void;
  disk: DiskType;
}

const Tab: React.FC<TabProps> = ({ label, disk, onClick }) => {
  const { diskId } = useParams();
  const { items } = useDiskState({ disk, minimized: false });
  const active = disk.id === diskId;
  return (
    <ContextDropdown
      trigger={
        <div className={`tab ${active ? 'active' : ''}`} onClick={onClick}>
          <div className="tab__content">
            {!disk.is_blocked && (
              <DiskIcon isFavorite={disk.is_favorite} color={disk.color} id={disk.id} />
            )}
            {disk.is_blocked && <LockIcon style={{ marginRight: '10px' }} />}
            <div className="tab__name" title={label}>
              {label}
            </div>
          </div>
        </div>
      }
      items={items}
      className="custom-dropdown z-index-100"
      id={disk.id}
    />
  );
};

export default Tab;
