import React, { FC, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { CrossIcon } from 'src/components/Icons';
import './styles.scss';

export type BaseModalPropsType = {
  title: string;
  onClose?: () => void;
  content: string | React.ReactNode;
  className?: string;
  width?: string;
  hideHeader?: boolean;
};

export const BaseModal: FC<BaseModalPropsType> = ({
  content,
  title,
  width,
  onClose,
  className,
  hideHeader,
}) => {
  const modalRoot = document.createElement('div');
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.appendChild(modalRoot);

    return () => {
      document.body.removeChild(modalRoot);
    };
  }, [modalRoot]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        modalRef.current &&
        !modalRef.current.contains(target) &&
        !target.closest('.skip-outside')
      ) {
        onClose && onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  // The modal content itself
  const modalContent = (
    <div className={classNames('base-modal', 'skip-outside', className)}>
      <div ref={modalRef} className="base-modal__body" style={{ maxWidth: width || '575px' }}>
        <div className="base-modal__content">
          {!hideHeader && (
            <div className="base-modal__header">
              {title}
              <CrossIcon onClick={onClose} className="base-modal__close" />
            </div>
          )}
          <div className="base-modal__content-wrapper">{content}</div>
        </div>
      </div>
    </div>
  );

  // Render the modal using a portal
  return ReactDOM.createPortal(modalContent, modalRoot);
};
