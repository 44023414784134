import React, { FC } from 'react';
import { FolderIcon, StarSmallIcon } from 'src/components/Icons';
import { FolderType } from 'src/types';
import './styles.scss';

type Props = {
  folder: FolderType;
};

export const FolderIconComp: FC<Props> = ({ folder }) => {
  return (
    <div className="folder-icon-comp">
      <div className="folder-icon-comp__item-media">
        {folder.is_favorite && (
          <div className="folder-icon-comp__favorite">
            <StarSmallIcon />
          </div>
        )}
        <div className="folder-icon-comp__media-wrapper">
          <FolderIcon className="folder-icon-comp__folder-icon" />
        </div>
      </div>
    </div>
  );
};
