import { createContext } from 'react';
import { ModalPropsType } from 'src/components/Modals/Confirmation';
import { BaseModalPropsType } from 'src/components/Modals/Base';
import { ModalDestinationProps } from 'src/components/Modals/ModalDestination';
import { GetFoldersPayload } from 'src/api/folders';
import { FilesPayload } from 'src/api/files';
import { RightMenuState } from 'src/context/DiskContext';

export type CacheKeysType = {
  folders: GetFoldersPayload | null;
  files: FilesPayload | null;
};

export type viewType = 'list' | 'icons' | 'columns';
export type sortType = { field: string; direction: 'asc' | 'desc' };

export type AppContextType = {
  confirmation: ModalPropsType & { open?: boolean; className?: string };
  openConfirmation: (values: Partial<ModalPropsType> & { open?: boolean }) => void;
  openModal: (values: Partial<BaseModalPropsType> & { open?: boolean; width?: string }) => void;
  modal: BaseModalPropsType & { open?: boolean; width?: string };
  modalDestination: ModalDestinationProps & { open?: boolean };
  openDestinationModal: (values: Partial<ModalDestinationProps> & { open?: boolean }) => void;
  showChat: boolean;
  updateState: (values: Partial<AppContextType>) => void;
  sort: sortType | null;
  setSort: (sort: sortType | null) => void;
  filterType: string;
  setFileType: (filter: string) => void;
  countFolders: number;
  countFiles: number;
  rightMenuState: RightMenuState;
  setRightMenuState: (value: Partial<RightMenuState>) => void;
};

export const defaultAppValues: AppContextType = {
  confirmation: {
    open: false,
    onSure: () => {},
    onCancel: () => {},
    content: '',
    okText: '',
    cancelText: '',
  },
  modal: {
    title: '',
    content: '',
    open: false,
    onClose: () => {},
  },

  modalDestination: {
    open: false,
    title: '',
    subTitle: '',
    onMove: () => {},
    onCancel: () => {},
  },
  showChat: false,
  sort: { field: 'name', direction: 'asc' },
  filterType: 'all',
  countFolders: 0,
  countFiles: 0,
  rightMenuState: {
    open: false,
    content: null,
  },
  setRightMenuState: () => {},
  setSort: () => {},
  openConfirmation: () => {},
  openModal: () => {},
  openDestinationModal: () => {},
  updateState: () => {},
  setFileType: () => {},
};

export const AppContext = createContext<AppContextType>(defaultAppValues);
