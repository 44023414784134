import React, { ReactNode } from 'react';
import slide1 from './assets/slide1.png';
import slide2 from './assets/slide2.png';
import slide3 from './assets/slide3.png';
import slide4 from './assets/slide4.png';
import infoIcon3 from './assets/icons/info-icon3.png';
import { ReactComponent as InfoIcon1 } from './assets/icons/info-icon1.svg';
import { ReactComponent as InfoIcon2 } from './assets/icons/info-icon2.svg';
import { ReactComponent as InfoIcon3 } from './assets/icons/info-icon3.svg';
import { ReactComponent as InfoIcon4 } from './assets/icons/info-icon4.svg';
import { ReactComponent as InfoIcon5 } from './assets/icons/info-icon5.svg';
import { ReactComponent as InfoIcon6 } from './assets/icons/info-icon6.svg';
import { ReactComponent as InfoIcon7 } from './assets/icons/info-icon7.svg';
import { ReactComponent as InfoIcon8 } from './assets/icons/info-icon8.svg';
import { ReactComponent as InfoIcon9 } from './assets/icons/info-icon9.svg';
import { ReactComponent as InfoIcon10 } from './assets/icons/info-icon10.svg';
import { ReactComponent as InfoIcon11 } from './assets/icons/info-icon11.svg';
import { ReactComponent as InfoIcon12 } from './assets/icons/info-icon12.svg';
import { ReactComponent as InfoIcon13 } from './assets/icons/info-icon13.svg';
import { ReactComponent as InfoIcon14 } from './assets/icons/info-icon14.svg';
import { ReactComponent as InfoIcon15 } from './assets/icons/info-icon15.svg';
import { ReactComponent as InfoIcon16 } from './assets/icons/info-icon16.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type InfoLineProps = {
  title: string;
  image: string;
  subtitle: string;
  description: string;
  options: { icon: ReactNode; text: string }[];
};

export const InfoLine = () => {
  const { t } = useTranslation();
  const options: InfoLineProps[] = [
    {
      title: t('cloudOption1Title'),
      image: slide1,
      subtitle: t('cloudOption1Subtitle'),
      description: t('cloudOption1Description'),
      options: [
        { icon: <InfoIcon1 />, text: t('cloudOption1Param1') },
        { icon: <InfoIcon2 />, text: t('cloudOption1Param2') },
        { icon: <img src={infoIcon3} alt="" />, text: t('cloudOption1Param3') },
        { icon: <InfoIcon4 />, text: t('cloudOption1Param4') },
      ],
    },
    {
      title: t('cloudOption2Title'),
      image: slide2,
      subtitle: t('cloudOption2Subtitle'),
      description: t('cloudOption2Description'),
      options: [
        { icon: <InfoIcon5 />, text: t('cloudOption2Param1') },
        { icon: <InfoIcon6 />, text: t('cloudOption2Param2') },
        { icon: <InfoIcon7 />, text: t('cloudOption2Param3') },
        { icon: <InfoIcon8 />, text: t('cloudOption2Param4') },
      ],
    },
    {
      title: t('cloudOption3Title'),
      image: slide3,
      subtitle: t('cloudOption3Subtitle'),
      description: t('cloudOption3Description'),
      options: [
        { icon: <InfoIcon9 />, text: t('cloudOption3Param1') },
        { icon: <InfoIcon10 />, text: t('cloudOption3Param2') },
        { icon: <InfoIcon11 />, text: t('cloudOption3Param3') },
        { icon: <InfoIcon12 />, text: t('cloudOption3Param4') },
      ],
    },
    {
      title: t('cloudOption4Title'),
      image: slide4,
      subtitle: t('cloudOption4Subtitle'),
      description: t('cloudOption4Description'),
      options: [
        { icon: <InfoIcon13 />, text: t('cloudOption4Param1') },
        { icon: <InfoIcon14 />, text: t('cloudOption4Param2') },
        { icon: <InfoIcon15 />, text: t('cloudOption4Param3') },
        { icon: <InfoIcon16 />, text: t('cloudOption4Param4') },
      ],
    },
  ];

  return (
    <div className="info-line">
      <div className="info-line__container">
        {options.map((option, index) => (
          <div
            key={index}
            className={classNames('info-line__section', { inverted: index % 2 !== 0 })}
          >
            <div className="info-line__media">
              <img src={option.image} alt="" />
            </div>
            <div className="info-line__content">
              <div className="info-line__title">{option.title}</div>
              {!!option.subtitle && <div className="info-line__subtitle">{option.subtitle}</div>}
              <div className="info-line__description">{option.description}</div>
              <div className="info-line__options">
                {option.options.map((item, index) => (
                  <div key={index} className="info-line__option">
                    <div className="info-line__option-icon">{item.icon}</div>
                    <span className="info-line__option-text">{item.text}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
