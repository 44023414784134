import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { CloseRoundedIcon, SuccessIcon } from 'src/components/Icons';
import './styles.scss';

export type NotificationPropsType = {
  title?: string;
  notification: string;
  onClose: () => void;
  type?: 'error' | 'success';
  autoHideDuration?: number;
};

export const Notification: FC<NotificationPropsType> = ({
  title,
  notification,
  onClose,
  autoHideDuration = 5,
  type = 'error',
}) => {
  useEffect(() => {
    if (autoHideDuration !== undefined) {
      const timer = setTimeout(() => {
        onClose();
      }, autoHideDuration * 1000);

      return () => clearTimeout(timer);
    }
  }, [autoHideDuration, onClose]);

  return (
    <div className={classNames('notification', type)}>
      <div className="notification__close" onClick={onClose}>
        {type === 'error' ? <CloseRoundedIcon /> : <SuccessIcon />}
      </div>
      <div className="notification__content">
        {!!title && <div className="notification__title">{title}</div>}
        {!!notification && <div className="notification__text">{notification}</div>}
      </div>
    </div>
  );
};
