import React, { FC } from 'react';
import { getLineStyles } from 'src/pages/diskPage/components/DragLayer/helpers';
import { FileType } from 'src/types';
import classNames from 'classnames';
import { FileIcon } from 'src/pages/diskPage/components/ItemIcons/FileIcon';
import './styles.scss';

type Props = {
  file: FileType;
  position: { x: number; y: number };
  i: number;
  totalLength: number;
};

export const LineFile: FC<Props> = ({ file, position, i, totalLength }) => {
  return (
    <div
      className={classNames('file-column lineFile', {})}
      style={getLineStyles(position, i, totalLength)}
    >
      <FileIcon file={file} />
      <div className="file-column__name" title={file.name}>
        {file.name}
      </div>
      {/* <div className="file-column__info">
        {!!file.extension && <div className="file-column__info--extension">.{file.extension}</div>}
        <div className="file-column__info--size">{getConvertedSize(file.size)}</div>
      </div>*/}
    </div>
  );
};
