import React, { useEffect, useState, forwardRef, useCallback } from 'react';
import { TableHeaderType, TableItemType } from './types';
import classNames from 'classnames';
import { Row } from './row';
import { AutoSizer, Column, Table as VirtualTable } from 'react-virtualized';
import './styles.scss';

type Props = {
  headers: TableHeaderType[];
  items: TableItemType[];
  className?: string;
  onRowClick?: (item: TableItemType) => void;
  onRowDrop?: (item: TableItemType, target: TableItemType) => void;
  actions?: React.ReactNode;
  onSort?: (field: string, order: 'asc' | 'desc') => void;
  isTrashTable?: boolean;
  isLoading?: boolean;
  onScrollEnd?: () => void;
  disableDrag?: boolean;
};

// eslint-disable-next-line react/display-name
export const Table = forwardRef<HTMLDivElement, Props>(
  ({ headers, items, className, onRowClick, onSort, onScrollEnd, disableDrag }, ref) => {
    const [sortedItems, setSortedItems] = useState(items);
    const [sortField, setSortField] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

    const handleSort = (field: string) => {
      let order: 'asc' | 'desc' = 'asc';
      if (sortField === field && sortOrder === 'asc') {
        order = 'desc';
      }
      setSortField(field);
      setSortOrder(order);
      onSort && onSort(field, order);
    };

    useEffect(() => {
      setSortedItems(items); // Reset sorted items when items change
    }, [items]);

    const handleScroll = useCallback(
      ({ scrollTop, scrollHeight, clientHeight }: any) => {
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          onScrollEnd && onScrollEnd();
        }
      },
      [onScrollEnd],
    );

    const customCellRenderer = ({ cellData, columnIndex, rowData }: any) => {
      const render = headers[columnIndex]?.render;
      return <div className="table__cell">{render ? render(rowData) : cellData}</div>;
    };

    const customHeaderRenderer = ({ label, dataKey }: any) => {
      return (
        <div
          className={classNames('table__header', {
            sorted: sortField === dataKey && !!dataKey.length,
          })}
          onClick={() => handleSort(dataKey)}
        >
          {label}
          {sortField === dataKey && !!dataKey.length && (
            <span className={`sort-indicator ${sortOrder}`}>{sortOrder === 'asc' ? '↑' : '↓'}</span>
          )}
        </div>
      );
    };

    const customRowRenderer = ({ className, columns, index, key, style }: any) => {
      return (
        <Row
          columns={columns}
          item={sortedItems[index]} // Use sortedItems here
          index={index}
          style={style}
          key={key}
          className={className}
          onRowClick={onRowClick}
          disableDrag={disableDrag}
        />
      );
    };

    return (
      <div className={classNames('table-wrapper table', className)} ref={ref}>
        <AutoSizer>
          {({ height, width }) => (
            <VirtualTable
              width={width}
              height={height}
              headerHeight={40}
              rowHeight={50}
              rowCount={sortedItems.length} // Use sortedItems here
              rowGetter={({ index }: any) => sortedItems[index]} // Use sortedItems here
              onScroll={handleScroll}
              rowRenderer={customRowRenderer}
            >
              {headers.map((header) => {
                let columnWidth;

                if (header.width === 'auto') {
                  const fixedWidth = headers
                    .filter((h) => h.width && h.width !== 'auto' && !h.width.includes('%'))
                    .reduce((sum, h) => sum + parseInt(h.width || '0', 10), 0);
                  columnWidth =
                    (width - fixedWidth) / headers.filter((h) => h.width === 'auto').length;
                } else if (header.width && header.width.endsWith('px')) {
                  columnWidth = parseInt(header.width, 10);
                } else if (header.width && header.width.endsWith('%')) {
                  columnWidth = (parseInt(header.width, 10) / 100) * width;
                } else {
                  columnWidth = 100;
                }
                return (
                  <Column
                    key={header.field}
                    label={header.name}
                    dataKey={header.field}
                    width={columnWidth}
                    headerRenderer={customHeaderRenderer}
                    cellRenderer={customCellRenderer}
                  />
                );
              })}
            </VirtualTable>
          )}
        </AutoSizer>
      </div>
    );
  },
);
