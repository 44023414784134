import React, { FC, useEffect } from 'react';
import { Button } from 'src/components/Forms/Button';
import { useTranslation } from 'react-i18next';
import { FoldersTree } from 'src/components/Modals/ModalDestination/components/FoldersTree';
import { DiskType, FolderType } from 'src/types';
import { CreateDiskForm } from 'src/pages/diskPage/components/';
import { useGetFilteredFolders } from 'src/hooks/useGetFilteredFolders';
import './styles.scss';
import { useParams } from 'react-router-dom';
import { CreateFolderForm } from 'src/components/Header/components/CreateFolderForm';
import { useGetDisks } from 'src/hooks/useGetDisks';

export type WizardFolder = FolderType & { parentFolder: WizardFolder | null };

type WizardState = {
  showFolders: boolean;
  currentFolder: WizardFolder | null;
  destination: {
    disk: DiskType | null;
    folder: WizardFolder | null;
  };
};

export type ModalDestinationProps = {
  onCancel: () => void;
  title?: string;
  subTitle?: string;
  onMove: (disk: DiskType, folder?: FolderType) => void;
  btnText?: string;
  hiddenFolders?: string[];
  operation?: string;
};

type WizardView = 'tree' | 'newFolder' | 'newDisk';
export const ModalDestination: FC<ModalDestinationProps> = ({
  onCancel,
  title,
  subTitle,
  onMove,
  btnText,
  hiddenFolders,
  operation,
}) => {
  const { t } = useTranslation();
  const { diskId } = useParams();

  const [view, setView] = React.useState<WizardView>('tree');
  const { disks } = useGetDisks();
  const currentDisk = disks.find((disk: DiskType) => disk.id === diskId);

  const [wizardState, setWizardState] = React.useState<WizardState>({
    showFolders: false,
    currentFolder: null,
    destination: {
      disk: null,
      folder: null,
    },
  });

  const { invalidateCache: invalidateDisks } = useGetDisks();
  const { invalidateCache } = useGetFilteredFolders({
    parent_id: wizardState.currentFolder?.id || wizardState.destination.disk?.id,
  });

  const setDisk = (disk: DiskType | null) => {
    setWizardState({
      ...wizardState,
      destination: {
        ...wizardState.destination,
        disk,
      },
    });
  };

  const onOpenDisk = (disk: DiskType) => {
    setWizardState({
      ...wizardState,
      destination: {
        ...wizardState.destination,
        disk,
      },
      showFolders: true,
    });
  };

  useEffect(() => {
    if (currentDisk) {
      setDisk(currentDisk);
      onOpenDisk(currentDisk as DiskType);
    }
  }, [currentDisk]);

  const onOpenFolder = (folder: WizardFolder) => {
    setWizardState({
      ...wizardState,
      currentFolder: {
        ...folder,
        parentFolder: wizardState.destination.folder,
      },
      destination: {
        ...wizardState.destination,
        folder: {
          ...folder,
          parentFolder: wizardState.destination.folder,
        },
      },
    });
  };

  const setFolder = (folder: WizardFolder | null) => {
    setWizardState({
      ...wizardState,
      destination: {
        ...wizardState.destination,
        folder: folder,
      },
    });
  };

  const onGoBack = () => {
    if (!wizardState.currentFolder) {
      setWizardState({
        ...wizardState,
        currentFolder: null,
        showFolders: false,
        destination: {
          ...wizardState.destination,
          folder: null,
          disk: null,
        },
      });
      return;
    }
    setWizardState({
      ...wizardState,
      currentFolder: wizardState.currentFolder?.parentFolder || null,
      destination: {
        ...wizardState.destination,
        folder: wizardState.destination.folder?.parentFolder || null,
      },
    });
  };

  const onMoveHandler = () => {
    if (onMove) {
      onMove(
        wizardState.destination.disk as DiskType,
        wizardState.destination.folder as FolderType,
      );
    }
  };

  const renderWizard = () => {
    return (
      <>
        <div className="modal-destination__content skip-outside">
          <div className="modal-destination__header">{title}</div>
          {!!subTitle?.length && <div className="modal-destination__info mb-25">{subTitle}</div>}
          <div className="modal-destination__content-wrapper mb-15">
            {wizardState.showFolders && wizardState.destination.disk && (
              <FoldersTree
                disk={wizardState.destination.disk}
                onOpen={onOpenFolder}
                onBackCLick={onGoBack}
                folder={wizardState.currentFolder}
                onAddFolder={() => {
                  setView('newFolder');
                }}
                onClick={setFolder}
                onMove={(folder) => {
                  onMove(wizardState.destination.disk as DiskType, folder);
                }}
                btnText={btnText || t('move')}
                hiddenFolders={hiddenFolders}
              />
            )}
          </div>
          {!wizardState.destination.disk && (
            <div className="modal-destination__info">{t('selectLocation')}</div>
          )}
        </div>
        <div className="modal__footer">
          <Button
            content={t('cancel')}
            type="text"
            className=""
            onClick={onCancel}
            isDisabled={false}
            fullWidth
          />
          <Button
            content={btnText || t('move')}
            type="primary"
            className=""
            onClick={onMoveHandler}
            isDisabled={!wizardState.destination.disk}
            fullWidth
          />
        </div>
      </>
    );
  };

  const views = {
    tree: renderWizard(),
    newFolder: (
      <div className="modal-destination__form">
        <div className="modal-destination__header">{t('createNewFolder')}</div>
        <CreateFolderForm
          onSave={async () => {
            invalidateCache();
            setView('tree');
          }}
          onClose={() => {
            setView('tree');
          }}
          parentId={wizardState.currentFolder?.id || diskId || ''}
        />
      </div>
    ),
    newDisk: (
      <div className="modal-destination__form">
        <div className="modal-destination__header">{t('createANewDisk')}</div>
        <CreateDiskForm
          onSave={async () => {
            invalidateDisks();
            setView('tree');
          }}
          onClose={() => {
            setView('tree');
          }}
        />
      </div>
    ),
  };

  return (
    <div className="modal-destination skip-outside">
      <div className="modal-destination__body">{views[view]}</div>
    </div>
  );
};
