import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import { FolderComponent } from './components/FolderComponent';
import { FileComponent } from './components/FileComponent';
import { EmptyDisk } from 'src/pages/diskPage/components/EmptyDisk';
import { useDiskViewState } from 'src/pages/diskPage/hooks/useDiskViewState';
import { UploaderPage } from 'src/components/FileUploader/UploaderPage';
import { useNavigate, useParams } from 'react-router-dom';
import { Skeleton } from 'src/components/Skeletons';
import { useTranslation } from 'react-i18next';
import { FolderType } from 'src/types';
import { Grid, GridCellProps, CellMeasurer, CellMeasurerCache, AutoSizer } from 'react-virtualized';
import { FileInfo } from 'src/components/InfoComponents';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import './styles.scss';
import { useDropState } from 'src/pages/diskPage/hooks/useDropState';

export const IconsView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openModal } = useAppContext();

  const { diskId, folderId } = useParams<{ diskId: string; folderId: string }>();
  const { folders, files, isEmptyDisk, isLoading, fetchNextPage } = useDiskViewState();
  const { onDropHandler } = useDropState();

  const fileContainerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const MIN_CELL_WIDTH = 230;
  const FILE_GAP = 20;

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    }),
  );

  const onFolderClick = (folder: FolderType) => {
    navigate(`/disk/${diskId}/folder/${folder.id}`);
  };

  useEffect(() => {
    const calculateDimensions = () => {
      const container = document.querySelector('.disk-view__elements2');
      if (container) {
        setContainerWidth(container.clientWidth || 0);
      }
    };

    calculateDimensions();
    const timeoutId = setTimeout(calculateDimensions, 500);

    window.addEventListener('resize', calculateDimensions);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', calculateDimensions);
    };
  }, [diskId, folderId, isEmptyDisk, isEmptyDisk, files]);

  const ITEMS_PER_ROW = useMemo(() => {
    if (containerWidth === 0) return 1;
    const availableWidth = containerWidth - FILE_GAP; // Adjust for padding/gap
    const estimatedItemsPerRow = Math.floor(availableWidth / (MIN_CELL_WIDTH + FILE_GAP));
    return estimatedItemsPerRow || 1; // Ensure at least 1 item per row
  }, [containerWidth, diskId, folderId]);

  const actualCellWidth = useMemo(() => {
    if (ITEMS_PER_ROW === 0) return MIN_CELL_WIDTH;
    return (containerWidth - FILE_GAP * (ITEMS_PER_ROW - 1)) / ITEMS_PER_ROW;
  }, [ITEMS_PER_ROW, containerWidth]);

  const totalRows = useMemo(() => {
    return Math.ceil(files.length / ITEMS_PER_ROW);
  }, [files.length, ITEMS_PER_ROW, diskId, folderId]);

  const renderFileCell = ({ columnIndex, rowIndex, key, parent, style }: GridCellProps) => {
    const index = rowIndex * ITEMS_PER_ROW + columnIndex;
    if (index < files.length) {
      const file = files[index];
      return (
        <CellMeasurer
          key={key}
          cache={cache.current}
          parent={parent}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
        >
          {({ measure }) => (
            <div key={key} style={{ ...style, padding: FILE_GAP / 2 }} onLoad={measure}>
              <FileComponent
                file={file}
                onClick={(file) => {
                  openModal({
                    content: (
                      <>
                        <FileInfo file={file} className="file-info-block" />
                      </>
                    ),
                    title: '',
                    className: 'create-folder-modal file-info-modal',
                  });
                }}
              />
            </div>
          )}
        </CellMeasurer>
      );
    }
    return null;
  };

  const handleScroll = useCallback(
    ({ scrollTop, scrollHeight, clientHeight }: any) => {
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        fetchNextPage();
      }
    },
    [fetchNextPage],
  );

  const renderData = () => {
    return (
      <div className="icons-root" style={{ height: '100%' }}>
        {!!folders.length && (
          <div className="icons-root__folders">
            <div className="disk-view__title">{t('folders')}</div>
            <div className="disk-view__elements disk-view__elements--folders">
              {folders.map((folder) => (
                <FolderComponent
                  folder={folder}
                  key={folder.id}
                  onClick={onFolderClick}
                  onDrop={(id, ids) => {
                    onDropHandler(id, ids);
                  }}
                />
              ))}
            </div>
          </div>
        )}
        {!!files.length && (
          <div className="icons-root__files">
            <div className="disk-view__title">{t('files')}</div>
            <div className="disk-view__elements2" ref={fileContainerRef}>
              <AutoSizer>
                {({ width, height }) => (
                  <Grid
                    columnCount={ITEMS_PER_ROW}
                    columnWidth={actualCellWidth}
                    height={height}
                    rowCount={totalRows}
                    rowHeight={cache.current.rowHeight}
                    deferredMeasurementCache={cache.current}
                    width={width}
                    cellRenderer={renderFileCell}
                    overscanRowCount={2}
                    overscanColumnCount={1}
                    onScroll={handleScroll}
                  />
                )}
              </AutoSizer>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="disk-view icons-view" style={{ height: '100%' }}>
      {isLoading ? (
        <Skeleton type="disk" />
      ) : (
        <>
          <div className="icons-view__elements" style={{ height: '100%' }}>
            <UploaderPage
              diskId={diskId || ''}
              folderId={folderId}
              className={'dashed'}
              isEmpty={isEmptyDisk}
            >
              {isEmptyDisk && !isLoading && <EmptyDisk />}
              {!isEmptyDisk && renderData()}
            </UploaderPage>
          </div>
        </>
      )}
    </div>
  );
};
