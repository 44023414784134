import { createContext } from 'react';
import { FileType, FolderType } from 'src/types';
import { FileDataType } from 'src/components/FileUploader';
import { NotificationPropsType } from 'src/components/Notification';

export type UploadFileType = FileDataType & {
  progress: number;
  volumeUploaded: number;
  isStarted?: boolean;
  isPaused?: boolean;
  isCanceled?: boolean;
  isCompleted?: boolean;
  isRetrying?: boolean;
  id: string;
};

export type UploadManagerPayload = {
  open: boolean;
  diskId: string;
  folderId?: string;
  files: FileDataType[];
  processingFiles: UploadFileType[];
};

export type GlobalContextType = {
  selectedItems: Array<FileType | FolderType>;
  isMultipleEnabled: boolean;
  uploadManager: UploadManagerPayload;
  updateUploadManager: (data: Partial<UploadManagerPayload>) => void;
  notification: NotificationPropsType & { open?: boolean };
  openNotification: (values: Partial<NotificationPropsType> & { open?: boolean }) => void;
  selectedIds: string[];
  toggleSection: (id: string) => void;
  addSection: (id: string) => void;
  clearSelectedIds: () => void;
  isDragging?: boolean;
  updateState: (values: Partial<GlobalContextType>) => void;
  isSelected: (ids: string[], id: string) => boolean;
};

export const defaultGlobalValues: GlobalContextType = {
  selectedItems: [],
  isMultipleEnabled: false,
  uploadManager: {
    open: false,
    files: [],
    processingFiles: [],
    diskId: '',
  },
  notification: {
    open: false,
    title: '',
    notification: '',
    onClose: () => {},
    type: 'error',
    autoHideDuration: 5,
  },
  selectedIds: [],
  toggleSection: () => {},
  addSection: () => {},
  clearSelectedIds: () => {},
  updateUploadManager: () => {},
  openNotification: () => {},
  updateState: () => {},
  isSelected: () => false,
};

export const GlobalContext = createContext<GlobalContextType>(defaultGlobalValues);
