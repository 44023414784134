import React from 'react';
import { ProfileBlock } from 'src/components/ProfileBlock';
import UserAvatar from 'src/components/UserAvatar';
import './styles.scss';

export const Settings = () => {
  return (
    <div className="profile-block">
      <div className="profile-block__avatar">
        <UserAvatar />
      </div>
      <ProfileBlock />
    </div>
  );
};
