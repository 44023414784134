import React, { FC } from 'react';
import './styles.scss';
import classNames from 'classnames';

type Props = {
  placeholder?: string;
  label?: string;
  className?: string;
};
export const Textarea: FC<Props> = ({ placeholder, label, className }) => {
  return (
    <div className={classNames('textarea', className)}>
      {label && <label className="textarea__label">{label}</label>}
      <textarea className="textarea" placeholder={placeholder} />
    </div>
  );
};
