import React, { FC } from 'react';
import classNames from 'classnames';
import { FolderType } from 'src/types';
import { useFolderState } from 'src/pages/diskPage/hooks/useFolderState';
import { ContextDropdown } from 'src/components/Dropdown/context';
import { ActionButton } from 'src/components/Forms/ActionButton';
import { DragPreviewImage, useDrag } from 'react-dnd';
import { useFolderDrop } from 'src/pages/diskPage/hooks/useFolderDrop';
import { FolderIconComp } from 'src/pages/diskPage/components/ItemIcons/FolderIcon';
import { useDragState } from 'src/pages/diskPage/hooks/useDragState';
import { transparentImage } from 'src/pages/diskPage/components/DragLayer/DragLayer';
import './styles.scss';

type Props = {
  folder: FolderType;
  onClick: () => void;
  selected?: boolean;
  btnText?: string;
  onDragStart: () => void;
  onDelete: () => void;
  onDrop: (destinationId: string, ids: string[]) => void;
};
export const FolderColumn: FC<Props> = ({
  folder,
  onClick,
  selected,
  onDragStart,
  onDelete,
  onDrop,
}) => {
  const {
    updateState,
    isSelected,
    selectedIdsRef,
    filesRef,
    foldersRef,
    selectedIds,
    toggleSection,
    isGroupDragging,
  } = useDragState();

  const { items } = useFolderState(folder, onDelete);

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'folder',
    item: () => {
      updateState({ isDragging: true });
      return {
        folders: isSelected(selectedIdsRef.current, folder.id)
          ? foldersRef.current
          : [...foldersRef.current, folder],
        files: filesRef.current,
      };
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: () => updateState({ isDragging: false }),
  }));

  const dragRef = selected ? null : drag;

  const { drop, isOver } = useFolderDrop(folder.id, onDrop);

  const onContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (selected) return;
    if (event.ctrlKey || event.metaKey) {
      toggleSection(folder.id);
    }
  };

  const onClickFolder = () => {
    if (selectedIds.length > 0) {
      toggleSection(folder.id);
      return;
    }
    onClick();
  };

  return (
    <ContextDropdown
      trigger={
        <>
          <DragPreviewImage connect={preview} src={transparentImage} />
          <div
            ref={(node) => (dragRef ? dragRef(drop(node)) : drop(node))}
            className={classNames('folder-column select-item')}
            onClick={onClickFolder}
            onContextMenu={onContextMenu}
            id={folder.id}
          >
            <div
              className={classNames('folder-column__wrapper', {
                selected: selectedIds.includes(folder.id) || selected,
                isOver,
                dragging: (isGroupDragging && selectedIds.includes(folder.id)) || isDragging,
              })}
            >
              <FolderIconComp folder={folder} />

              <div className="folder-column__details">
                <div className="folder-column__title" title={folder.name}>
                  {folder.name}
                </div>
              </div>

              <div className="folder-column__abtns">
                <ActionButton items={items} className="folder-column__actions-btn" id={folder.id} />
              </div>
            </div>
          </div>
        </>
      }
      items={items}
      className="custom-dropdown"
      id={folder.id}
    />
  );
};
