import { API } from 'src/utils/API';

export const useGetParentCount = () => {
  const countParents = async (folderId?: string): Promise<number> => {
    if (!folderId) return 0;
    try {
      const resp = await API.get(`/resources/folder/${folderId}/statistics`, 'archive');
      const folder = resp.data || { parent_type: '', parent_id: '' };
      if (folder.parent_type === 'DRIVE') {
        return 1;
      }
      return 1 + (await countParents(folder.parent_id));
    } catch (error) {
      return 0;
    }
  };

  return {
    countParents,
  };
};
