import React, { FC, useState } from 'react';
import { Storages } from './components/Storages';
import { Parameters } from './components/Parameters';
import { StorageDisk } from './components/StorageDisk';
import { StorageCapacity } from './components/StorageCapacity';
import { CalculatorState } from './types';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type Props = {
  onClick: () => void;
};
export const PriceCalculator: FC<Props> = ({ onClick }) => {
  const [state, setState] = useState<CalculatorState>({
    name: '',
    total_volume: 25000000,
    invoice: 'monthly',
    drive_type: 'standard',
  });
  const { t } = useTranslation();

  return (
    <div className="price-calculator">
      <h3 className="price-calculator__title">{t('createYourFirstDiscParameters')}</h3>
      <div className="price-calculator__content">
        <Parameters state={state} onClick={onClick} />
        <div className="price-calculator__controls">
          <Storages />
          <StorageDisk
            onChange={(name, drive_type) => {
              setState({ ...state, name, drive_type });
            }}
          />
          <StorageCapacity
            state={state}
            onChange={(total_volume, invoice) => {
              setState({ ...state, total_volume, invoice });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const convertGbToTb = (gb: number): number => {
  if (gb < 1000) return gb;
  const tb = gb / 1000;
  return parseFloat(tb.toFixed(2));
};
