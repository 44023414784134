import React from 'react';
import './styles.scss';
import { LoadingArea } from 'src/pages/fileShare/pages/createGallery/components/LoadingArea';

export const LoadedFiles = () => {
  return (
    <div className="loaded-files">
      <h3 className="loaded-files__title">Загрузить файлы</h3>
      <LoadingArea />
    </div>
  );
};
