import { CreateDisk } from 'src/components/CreateDisk';
import React from 'react';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { DiskType, FileType, FolderType } from 'src/types';
import { NewDiskInfo } from 'src/components/InfoComponents/diskInfo/newDiskInfo';
import { NewFileInfo } from 'src/components/InfoComponents/fileInfo/newFileInfo';
import { NewFolderInfo } from 'src/components/InfoComponents/folderInfo/newFolderInfo';

export const useDiskManage = () => {
  const { setRightMenuState, rightMenuState } = useAppContext();
  const createDisk = (params?: Partial<DiskType>) => {
    setRightMenuState({
      ...rightMenuState,
      open: true,
      content: (
        <CreateDisk
          onCLose={() => {
            setRightMenuState({ open: false });
          }}
          parameters={params}
        />
      ),
      width: 400,
    });
  };

  const editDisk = (disk: DiskType) => {
    setRightMenuState({
      ...rightMenuState,
      open: true,
      content: (
        <CreateDisk
          onCLose={() => {
            setRightMenuState({ open: false });
          }}
          parameters={disk}
        />
      ),
      width: 400,
    });
  };

  const openDiskInfo = (disk: DiskType) => {
    setRightMenuState({
      ...rightMenuState,
      open: true,
      content: (
        <>
          <NewDiskInfo
            disk={disk}
            onClose={() => {
              setRightMenuState({ open: false });
            }}
          />
        </>
      ),
      width: 400,
    });
  };

  const openFileInfo = (file: FileType) => {
    setRightMenuState({
      ...rightMenuState,
      open: true,
      content: (
        <>
          <NewFileInfo
            file={file}
            onClose={() => {
              setRightMenuState({ open: false });
            }}
          />
        </>
      ),
      width: 400,
    });
  };

  const openFolderInfo = (folder: FolderType) => {
    setRightMenuState({
      ...rightMenuState,
      open: true,
      content: (
        <>
          <NewFolderInfo
            folder={folder}
            onClose={() => {
              setRightMenuState({ open: false });
            }}
          />
        </>
      ),
      width: 400,
    });
  };

  return {
    createDisk,
    editDisk,
    openDiskInfo,
    openFileInfo,
    openFolderInfo,
  };
};
