import React, { FC } from 'react';
import { Button } from 'src/components/Forms/Button';
import { OfferType } from 'src/pages/subscriptions/types';
import { DiskOffer } from '../DiskOffer';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type Props = {
  offer: OfferType;
  onClick: () => void;
};

export const OfferItem: FC<Props> = ({ offer, onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="offer-item">
      <div className="offer-item__wrapper">
        <DiskOffer disk={offer} className="sub-disk" />
        <div className="offer-item__title">{offer.name}</div>
        <div className="offer-item__description">{offer.description}</div>
      </div>
      <Button
        type="primary"
        content={t('connectDisk')}
        fullWidth
        className="offer-item__btn skip-outside"
        onClick={onClick}
      />
    </div>
  );
};
