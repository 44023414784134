import React, { useEffect, useState } from 'react';
import { AnimatedClip } from 'src/components/AnimatedClip';
import { ReactComponent as HeaderLogo } from 'src/assets/icons/logo.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { useSubscription } from 'src/components/Subscription/useSubscription';

export const SupportDevelopers = () => {
  const { openSubscriptionArea } = useSubscription();
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsActive(true);
    }, 10);

    return () => {
      setIsActive(false);
    };
  }, []);

  return (
    <div
      className={classNames('support-developers', { active: isActive })}
      onClick={openSubscriptionArea}
    >
      <HeaderLogo className="support-developers__logo" />
      <div className="support-developers__content">
        <div className="support-developers__title">{t('supportForDevelopers')}</div>
        <div className="support-developers__description">
          {t('businessSubscription')} <span>1$</span>
        </div>
      </div>
      <AnimatedClip type="cool" />
    </div>
  );
};
