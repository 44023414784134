import React, { FC } from 'react';
import FileUpload, { FileDataType } from 'src/components/FileUploader';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import './styles.scss';

type Props = {
  onFinish: () => void;
  diskId: string;
  folderId?: string;
  id?: string;
};

export const UploadFileModal: FC<Props> = ({ onFinish, diskId, folderId, id }) => {
  const { updateUploadManager } = useGlobalContext();
  const onChange = (data: FileDataType[]) => {
    updateUploadManager({
      open: true,
      files: data,
      diskId,
      folderId,
    });
    onFinish();
  };

  return (
    <div>
      <FileUpload id={id} onChangeFiles={onChange} multiple></FileUpload>
    </div>
  );
};
