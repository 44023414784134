import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetPrice } from 'src/api/payments';
import { debounce } from 'lodash';
import { ValuesType } from 'src/components/CreateDisk/types';
import './styles.scss';

type Props = {
  onChange: (invoice: string) => void;
  values: ValuesType;
  onPriceChange: (price: string) => void;
};

export const DiskInvoice: FC<Props> = ({ onChange, values, onPriceChange }) => {
  const [totalPrice, setTotalPrice] = useState<string>('0.00');
  const { mutateAsync: calculatePrice } = useGetPrice();

  const { t } = useTranslation();
  const [invoice, setInvoice] = useState<string>(values.invoice);

  const onChangeInvoice = () => {
    setInvoice((prev) => (prev === 'monthly' ? 'ANNUAL' : 'monthly'));
    onChange(invoice === 'monthly' ? 'ANNUAL' : 'monthly');
  };

  useEffect(() => {
    const isMonthly = values.invoice === 'monthly';
    const debouncedCalculate = debounce(async () => {
      const capacity = Math.ceil(values.filled_volume / 1000000000);
      const response = await calculatePrice({
        backup_s_coefficient: 1,
        subscription_term: isMonthly ? 'monthly' : 'ANNUAL',
        total_gb: capacity,
        drive_type: values.drive_type,
      });

      if (response.success) {
        setTotalPrice((response.price / 100).toFixed(2));
      }
    }, 100);
    debouncedCalculate();

    return () => {
      debouncedCalculate.cancel();
    };
  }, [values.filled_volume, values.invoice, values.drive_type]);

  useEffect(() => {
    onPriceChange(totalPrice);
  }, [totalPrice]);

  return (
    <div className="disk-invoice">
      <div className="disk-invoice__header">
        <div className="disk-invoice__label">{t('invoice')}:</div>
      </div>
      <div className="radio">
        <div className="custom-radio">
          <input
            id="invoice"
            type="radio"
            name="invoice"
            value={'monthly'}
            checked={invoice === 'monthly'}
            onChange={onChangeInvoice}
          />
          <label htmlFor="invoice">{t('monthly')}</label>
        </div>
        <div className="custom-radio relative">
          <input
            id="annual"
            type="radio"
            name="invoice"
            value={'ANNUAL'}
            checked={invoice === 'ANNUAL'}
            onChange={onChangeInvoice}
          />
          <label htmlFor="annual">{t('annually')}</label>
          <div className="disk-invoice__discount">-20% {t('monthly')}</div>
        </div>
      </div>
      <div className="disk-invoice__separator"></div>
      <div className="disk-invoice__total">
        <span>Amount: </span>
        <span className="highlight">{totalPrice} $</span>
      </div>
    </div>
  );
};
