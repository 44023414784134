import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import { useParams } from 'react-router-dom';
import { useGetHashtags } from 'src/api/hashtags';
import { get } from 'lodash';
import { groupHashtagsByType } from 'src/pages/diskPage/components/HashtagsList/helpers';
import classNames from 'classnames';
import { HashTagType } from 'src/types';
import './styles.scss';

type Props = {
  searchTerm: string;
  onEdit: () => void;
};
export const TagsSearch: FC<Props> = ({ searchTerm, onEdit }) => {
  const { updateState } = useDiskContext();
  const { t } = useTranslation();

  const { diskId } = useParams();
  const { data, refetch } = useGetHashtags(diskId || '', searchTerm);
  const hashtags = get(data, 'hashtags', []);
  const [selectedHashtag, setSelectedHashtag] = React.useState<any>(null);

  const onEditClick = () => {
    updateState({ showHashTags: true });
    onEdit();
  };

  const onHashtagClick = (tag: HashTagType) => {
    if (tag.ID === selectedHashtag?.ID) {
      setSelectedHashtag(null);
      return;
    }
    setSelectedHashtag(tag);
  };

  return (
    <div className="tags-search">
      <div className="tags-search__title">
        <span>{t('popularHashtags')}</span>
        <div className="tags-search__edit-btn" onClick={onEditClick}>
          {t('edit')}
        </div>
      </div>
      <div className="tags-search__list">
        {Object.entries(groupHashtagsByType(hashtags)).map(([type, items]) => (
          <div key={type} className="hashtags-list__group">
            <div className="hashtags-list__group-title">{type.toLowerCase()}</div>
            <div className="hashtags-list__group-list">
              {items.map((item) => (
                <div
                  key={item.ID}
                  className={classNames('hashtags-list__group-item', {
                    selected: selectedHashtag && selectedHashtag.ID === item.ID,
                  })}
                  onClick={() => onHashtagClick(item)}
                >
                  {item.Hashtag}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
