import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Forms/Button';
import { Input } from 'src/components/Forms/Inputs/Input';
import { ReactComponent as CloseIcon } from './close-icon.svg';
import { useCreateHashtag, useDeleteHashtagFormFile, useGetHashtags } from 'src/api/hashtags';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { HashTagType } from 'src/types';
import './styles.scss';
import { blockedHashtags } from 'src/components/HashtagsBlock/config';

type Props = {
  itemId: string;
};

export const HashtagsBlock: FC<Props> = ({ itemId }) => {
  const { t } = useTranslation();
  const { diskId } = useParams();
  const { data, refetch } = useGetHashtags(diskId || '');
  const hashtags = get(data, 'hashtags', []).filter((item: HashTagType) => item.ItemID === itemId);
  const { mutateAsync: deleteHashtag, isPending: isDeleting } = useDeleteHashtagFormFile();

  const { mutateAsync: addTag, isPending } = useCreateHashtag();

  const [isEditing, setIsEditing] = React.useState(false);
  const [newTag, setNewTag] = React.useState('#');

  const onSaveHashTag = async () => {
    if (newTag === '#') {
      setIsEditing(false);
      return;
    }
    await addTag({ item_id: itemId, hashtag: newTag, drive_id: diskId || '' });
    await refetch();
    setNewTag('#');
    setIsEditing(false);
  };

  const onRemoveHashtag = async (hashtag: HashTagType) => {
    await deleteHashtag({ item_id: hashtag.ItemID, hashtag_id: hashtag.ID });
    await refetch();
  };

  const [error, setError] = useState('');
  const isBlocked = () => {
    return blockedHashtags.includes(newTag);
  };

  return (
    <div className="hashtags-block__section">
      <div className="hashtags-block__section-header">
        <div className="hashtags-block__title">
          {t('hashtags')}
          {!isEditing && (
            <Button
              type="primary"
              content={t('add')}
              className="hashtags-block__btn"
              onClick={() => setIsEditing(true)}
            />
          )}
        </div>
        {isEditing && (
          <div className="hashtags-block__edit mt-15">
            <Input
              className="hashtags-block__input"
              placeholder="#hashtag"
              value={newTag}
              onChange={async (value) => {
                if (value.charAt(0) !== '#') {
                  value = '#' + value;
                }
                setNewTag(value);
                if (blockedHashtags.includes(value)) {
                  setError(' ');
                } else {
                  setError('');
                }
              }}
              error={error}
            />
            <Button
              type="primary"
              content={t('add')}
              className="hashtags-block__btn edit"
              onClick={onSaveHashTag}
              isDisabled={isBlocked()}
            />
          </div>
        )}
      </div>
      <div className="hashtags-block__section-content">
        <div className="hashtags-block__tags">
          {hashtags.map((item: HashTagType) => (
            <div key={item.ID} className="hashtags-block__tag">
              <span>{item.Hashtag}</span> <CloseIcon onClick={() => onRemoveHashtag(item)} />
            </div>
          ))}
          {hashtags.length === 0 && <div className="hashtags-block__empty">{t('noHashtags')}</div>}
        </div>
      </div>
    </div>
  );
};
