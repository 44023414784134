import { API_Fetch, RequestConfig } from 'src/utils/apis/fetch';

export class API extends API_Fetch {}

export class PAYMENT_API extends API_Fetch {
  static config: RequestConfig = {
    ...API_Fetch.config,
    baseURL: process.env.REACT_APP_PAYMENT_API_URL || 'http://localhost:4000',
  };
}
