import React from 'react';
import { AnimatedClip } from 'src/components/AnimatedClip';
import { useTranslation } from 'react-i18next';
import './styles.scss';

export const Reasons = () => {
  const { t } = useTranslation();
  const reasons = [
    {
      media: (
        <div className="reasons__media">
          <AnimatedClip type="convenience" />
        </div>
      ),
      title: t('convenienceTitle'),
      description: t('convenienceDescription'),
    },
    {
      media: (
        <div className="reasons__media">
          <AnimatedClip type="goals" />
        </div>
      ),
      title: t('goalsTitle'),
      description: t('goalsDescription'),
    },
    {
      media: (
        <div className="reasons__media">
          <AnimatedClip type="sync" />
        </div>
      ),
      title: t('syncTitle'),
      description: t('syncDescription'),
    },
    {
      media: (
        <div className="reasons__media">
          <AnimatedClip type="speed" />
        </div>
      ),
      title: t('speedTitle'),
      description: t('speedDescription'),
    },
    {
      media: (
        <div className="reasons__media">
          <AnimatedClip type="noBreaks" />
        </div>
      ),
      title: t('noBreaksTitle'),
      description: t('noBreaksDescription'),
    },
    {
      media: (
        <div className="reasons__media">
          <AnimatedClip type="openness" />
        </div>
      ),
      title: t('opennessTitle'),
      description: t('opennessDescription'),
    },
    {
      media: (
        <div className="reasons__media">
          <AnimatedClip type="security" />
        </div>
      ),
      title: t('securityTitle'),
      description: t('securityDescription'),
    },
    {
      media: (
        <div className="reasons__media">
          <AnimatedClip type="communication" />
        </div>
      ),
      title: t('communicationTitle'),
      description: t('communicationDescription'),
    },
    {
      media: (
        <div className="reasons__media">
          <AnimatedClip type="flex" />
        </div>
      ),
      title: t('flexTitle'),
      description: t('flexDescription'),
    },
  ];

  return (
    <div className="reasons">
      <div className="landing-container">
        <h2 className="reasons__title">{t('reasonsTitle')}</h2>
        <div className="reasons__list">
          {reasons.map((reason, index) => (
            <div key={index} className="reasons__item">
              <div className="reasons__item-content">
                <div className="reasons__item-media">{reason.media}</div>
                <div className="reasons__item-title">{reason.title}</div>
                <div className="reasons__item-description">{reason.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
