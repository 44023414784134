import React from 'react';
import {
  FileVideoIcon,
  FileImageIcon,
  FileAudioIcon,
  FileDocumentIcon,
  FileArchiveIcon,
  FileTextIcon,
  FilePdfIcon,
  BokIcon,
  ExcelIcon,
  KeyIcon,
  FilePresentationIcon,
} from 'src/components/Icons/files';
import { ReactComponent as OthersIcon } from 'src/components/FilterTypes/assets/icons/Others.svg';

export const FileIcons: {
  [key: string]: {
    color: string;
    icon: JSX.Element;
  };
} = {
  IMAGE: {
    color: '#0B5EAB',
    icon: <FileImageIcon />,
  },
  VIDEO: {
    color: '#0080DD',
    icon: <FileVideoIcon />,
  },
  AUDIO: {
    color: '#1678A7',
    icon: <FileAudioIcon />,
  },
  DOCUMENT: {
    color: '#064536',
    icon: <FileDocumentIcon />,
  },
  PDF: {
    color: '#A00001',
    icon: <FilePdfIcon />,
  },
  ARCHIVE: {
    color: '#61646C',
    icon: <FileArchiveIcon />,
  },
  OTHERS: {
    color: '#43287E',
    icon: <FileDocumentIcon />,
  },
  CODE: {
    color: '#43287E',
    icon: <FileTextIcon />,
  },
  EXECUTABLE: {
    color: '#43287E',
    icon: <OthersIcon className="file-icon__others" />,
  },
  BOOKS: {
    color: '#7E4C28',
    icon: <BokIcon />,
  },
  TABLE: {
    color: '#057258',
    icon: <ExcelIcon />,
  },
  KEY: {
    color: '#5B0949',
    icon: <KeyIcon />,
  },
  PRESENTATION: {
    color: '#A00001',
    icon: <FilePresentationIcon />,
  },
  MODEL: {
    color: '#A00001',
    icon: <OthersIcon className="file-icon__others" />,
  },
  default: {
    color: '#43287E',
    icon: <FileDocumentIcon />,
  },
};

const FileTypes: { [index: string]: string[] } = {
  IMAGE: [
    'jpeg',
    'jpg',
    'png',
    'gif',
    'bmp',
    'webp',
    'heic',
    'svg',
    'psd',
    'tiff',
    'ai',
    'cr2',
    'nef',
    'raw',
    'dng',
    'dwg',
    'svgz',
    'eps',
    'tga',
    '3fr',
    'arw',
    'crw',
    'dcr',
    'kdc',
    'mef',
    'mos',
    'mrw',
    'nrw',
    'orf',
    'pef',
    'r3d',
    'raf',
    'rw2',
    'rwl',
    'sr2',
    'x3f',
  ],
  PRESENTATION: ['ppt', 'pptx', 'odp', 'pps', 'ppsx', 'kth'],
  VIDEO: [
    'mp4',
    'mov',
    'avi',
    'mkv',
    'webm',
    'wmv',
    'mxf',
    'ogv',
    '3gp',
    '3gpp2',
    'asf',
    'dv',
    'm2t',
    'm4v',
    'mts',
    'rm',
    'vob',
  ],
  AUDIO: ['mp3', 'wav', 'aac', 'm4a', 'ogg', 'oga', 'flac'],
  TEXT: ['txt', 'md', 'properties'],
  DOCUMENT: ['doc', 'docx', 'odt', 'rtf', 'sketch', 'xd', 'pages'],

  BOOKS: ['epub', 'mobi', 'azw3', 'fb2', 'djvu', 'lit', 'tcr', 'ibooks', 'pdb'],
  PDF: ['pdf'],
  TABLE: ['xls', 'xlsx', 'csv', 'ods', 'numbers'],

  ARCHIVE: [
    'zip',
    'rar',
    '7z',
    'tar',
    'tgz',
    'lzh',
    'xz',
    'iso',
    'cpio',
    'taz',
    'tlz',
    'txz',
    'Z',
    'bz2',
    'lha',
    'tbz2',
    'tz',
    'xar',
  ],
  CODE: [
    'html',
    'css',
    'js',
    'jsx',
    'php',
    'asp',
    'aspx',
    'jsp',
    'json',
    'xml',
    'xhtml',
    'py',
    'java',
    'c',
    'cpp',
    'cs',
    'm',
    'swift',
    'go',
    'rb',
    'pl',
    'yaml',
    'yml',
    'ini',
    'toml',
    'cfg',
    'conf',
    'sh',
    'bat',
    'ps1',
    'ts',
    'tsx',
    'dart',
  ],
  MODEL: ['obj', 'fbx', 'dae', 'blend', 'stl', 'ply'],
  EXECUTABLE: ['exe', 'bin', 'app', 'dmg', 'ipa', 'jar', 'apk'],
  KEY: ['key', 'pem', 'der', 'pfx', 'p12', 'keychain'],
  undefined: ['lic', 'reg', 'ini'],
};

export const getFileIcon = (extension: string) => {
  for (const [type, extensions] of Object.entries(FileTypes)) {
    if (extensions.includes(extension)) {
      return FileIcons[type] || FileIcons['default'];
    }
  }
  return FileIcons['default'];
};

export const getColor = (key: string) => {
  if (FileIcons[key]) {
    return FileIcons[key].color;
  }
  return FileIcons['default'].color;
};

export const getFileExtensionsByType = (type: string) => {
  if (type === 'EXCEL') {
    return [...FileTypes['TABLE']];
  }
  if (type === 'OTHERS') {
    return [...FileTypes['EXECUTABLE'], ...FileTypes['MODEL']];
  }
  return FileTypes[type] || [];
};
