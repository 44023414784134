import React, { useState, useCallback } from 'react';
import { ReactComponent as SearchIcon } from './assets/search-icon.svg';
import { ReactComponent as ArrowBottom } from './assets/arrow-bottom.svg';
import classNames from 'classnames';
import { useOutside } from 'src/hooks/useOutside';
import { GeneralSearch } from 'src/components/Header/components/Search/components/GeneralSearch';
import { TagsSearch } from 'src/components/Header/components/Search/components/TagsSearch';
import { debounce } from 'lodash';
import './styles.scss';

export const Search = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const [openHashtags, setOpenHashtags] = useState(false);

  const closePanel = () => {
    setIsOpened(false);
    setOpenHashtags(false);
  };

  const { panelRef } = useOutside(closePanel);

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      setSearchQuery(value);
    }, 300),
    [],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchChange(e.target.value);
  };

  return (
    <div ref={panelRef} className={classNames('search-comp', { isOpened: isOpened })}>
      <div className="search-comp__control">
        <SearchIcon />
        <input
          type="text"
          placeholder="Search"
          onFocus={() => setIsOpened(true)}
          onChange={handleInputChange}
        />
        <div
          className={classNames('search-comp__tags', { active: openHashtags })}
          onClick={() => {
            setOpenHashtags(!openHashtags);
            setIsOpened(true);
          }}
        >
          #Hashtags <ArrowBottom />
        </div>
      </div>
      {isOpened && (
        <div className="search-comp__results">
          {!openHashtags && <GeneralSearch searchTerm={searchQuery} />}
          {openHashtags && <TagsSearch searchTerm={searchQuery} onEdit={closePanel} />}
        </div>
      )}
    </div>
  );
};
