import React from 'react';
import { TermsType } from 'src/pages/auth/components/LoginSection/components/Terms/types';

export const termsData: TermsType = {
  title: 'TERMS AND CONDITIONS',
  items: [
    {
      title: '',
      content: <>Effective as of 01/07/ 2023</>,
    },
    {
      title: '',
      content: (
        <>
          These Terms and Conditions (hereafter referred to as – ‘Terms and Conditions’) outline who
          We are and what services We provide as well as other essential provisions of this binding
          agreement between You and Us on Our Website and App use.
          <br />
          Within the Terms and Conditions we refer to the listed below notions and mean by them as
          defined: <b>‘Website’</b> – Our website which is available at https://www.monobox.tech/.{' '}
          <br />
          <b>‘App’</b> – Our mobile application which is available on Google Play Market or/and
          Apple App Store under the name ‘Monobox’. <br />
          <b>‘the Services’</b> – services provided by Monobox, which are available on the Website
          or App. <b>‘Monobox’</b> (also <b>‘We’</b>, <b>‘Our’</b>, <b>‘Us’</b>) – LLC MONOBOX
          registered under the laws of Ukraine, registration number: 45149949, address: Ukraine,
          02002, Kyiv, Andriy Abolmasov street, house 7, apartment 446. <b>‘User’</b> (also{' '}
          <b>‘You’</b>,<b>‘Your’</b>) – any natural person that uses the Website and/or an App.
          <b>‘Your Content’</b> (also <b>‘the Content’</b>) - any files, documents, data, photos,
          comments, messages, contacts, and so on You provide Us with by uploading to or using Our
          Services.
        </>
      ),
    },
    {
      title: 'I. Where do these Terms and Conditions apply?',
      content: (
        <>
          These Terms and Conditions applicable to Your use of Our Website and/or App. By using the
          Services You confirm that:
          <ul>
            <li>
              You are aged not less than 13 years or for this matter any legal age of Your country
              and
            </li>
            <li>You have read and accepted the provisions of these Terms and Conditions and</li>
            <li>agreed to be bound by them.</li>
          </ul>
        </>
      ),
    },
    {
      title: 'II. What services do We provide?\n',
      content: (
        <>
          We provide You with the opportunity to use cloud storage which is designed to make it
          easier for teams and individuals to handle large numbers of the Content. You may upload,
          submit, store, send, receive, share, comment, search, sort and work on Your Content and
          folders with others on Your computer or mobile device using Our Services. To get more
          information on the Services please visit Our About page. <br />
          We exclusively reserve the right to change the scope and conditions of the Services
          provided to You without any prior notice or Your special permission. All such changes, if
          present, will be outlined in these Terms and Conditions and/or on Our Website and/or App.
        </>
      ),
    },
    {
      title: 'III. Description of the Services',
      content: (
        <>
          {/* To get access to the Services on the Website or App You need to obtain a paid
          subscription. <br />*/}
          You may need to register for an account to access the Services, and We may create an
          account for You so that You can interact with the Services. <br />
          We do not guarantee that the Services on the Website or App will be always available or
          working uninterrupted and be free from errors or omissions, or will be available in all
          countries and/or all geographic locations. <br />
          Please be informed, an official App can be downloaded on Google Play Market or/and Apple
          App Store. We shall not be liable for any infringement caused by the App downloaded from
          third-party sources. The Website and/or App can be updated or changed without any prior
          notice.
        </>
      ),
    },
    /*   {
      title: 'IV. Subscription',
      content: (
        <>
          You can purchase a subscription on Our App. <br />
          The cost of the subscription depends on the storage time, ranging from 1 month to 36
          months, and storage capacity, ranging from 120 GB to 2 000 GB. <br /> This paid
          subscription includes such features:
          <ul>
            <li>minimum storage capacity is 120 GB and maximum storage capacity is 2 000 GB;</li>
            <li>minimum storage time is 1 month and maximum storage time is 36 months;</li>
            <li>uploading Your Content without restrictions;</li>
            <li>no advertisement within the App;</li>
            <li>ability to create group chats and to share Your Content via chat;</li>
            <li>offline mode feature with accessing Your Content without internet;</li>
            <li>files storage from internet searches;</li>
            <li>ability to choose Your country and storage location Yourself;</li>
            <li>ability to connect Your server to Us;</li>
            <li>cloud storage security settings;</li>
            <li>live support.</li>
          </ul>
          The subscription begins after the payment and terminates on the last day of the paid
          period, which is indicated at the time of the purchase. <br />
          We may discontinue the Services based on exceptional unforeseen circumstances and events
          beyond Our control, or to comply with a legal requirement. In such case, We will give You
          reasonable prior 30 days’ notice so that You can export Your Content from Our systems. If
          We discontinue the Services in this way before the end of any fixed or minimum term You
          have paid Us for, We will refund the portion of the fees You have pre-paid but have not
          received Services for. <br />
          We shall not be liable for the inability to use the features of the paid subscription due
          to Your hardware and/or software limitations. <br />
          We reserve an exclusive right to change subscription price, introduce promotion prices
          change or other conditions for the Services available on App. <br />
          In addition to these Terms and Conditions, terms of marketplaces regarding the order,
          payment and refund shall be applicable to You. <br />
        </>
      ),
    },
    {
      title: 'V. Price and payments',
      content: (
        <>
          The price of the subscription is based on Your storage capacity, storage time, location of
          data center You chosed, currency and may vary from region to region. In any event the
          precise price of the chosen subscription is displayed at the time of the purchase. Please
          be informed that Our monthly or annualy paid subscription will be automatically renewed
          and charged every month or year. Additionally, we might introduce a free time-based trial
          period for some subscriptions, which will be indicated in any in-App promotions or splash
          screens. <br />
          To the extent permitted by the applicable law, payments are non-refundable and We do not
          provide refunds for any unused features of the paid subscription. If You cancel a
          purchased subscription, You will not receive a refund for the current billing period, but
          will continue to receive Your subscription Content for the remainder of the current
          billing period, regardless of the cancellation date. Your cancellation goes into effect
          after the current billing period has passed. <br />
          We may change the fees of Our subscription in effect on renewal of Your subscription, to
          reflect some factors such as changes in economic conditions, or in Our product offerings,
          or changes to Our business etc. We will give You no less than 30 days’ advance notice of
          these changes via a message to the email address associated with Your account and You will
          have the opportunity to cancel Your subscription before the new fee comes into effect.
        </>
      ),
    },*/
    {
      title: 'VI. Intellectual property rights',
      content: (
        <>
          All of the content present on the Website, which could be subject to copyright or in this
          matter any other intellectual property right (e.g. trademark, design etc.), belongs to Us
          and/or used by Us under permission of Our licensors and/or used under a free public
          license, which allows commercial use. So long as You comply with these Terms and
          Conditions We grant You a personal, limited, non-exclusive, non-sublicensable, revocable,
          non-transferable, royalty-free, worldwide license to access and use Website and/or access
          and/or use any copyrighted content that may be available on the Website. <br />
          All copyright or in this matter any other intellectual property right to Our App belongs
          to Us. So long as You comply with these Terms and Conditions We grant You a personal,
          limited, non-exclusive, non-sublicensable, revocable, non-transferable, royalty-free,
          worldwide license to access and use App and/or access and/or use any copyrighted content
          that may be available on the App. <br />
          You must not modify, copy, distribute, transmit, perform, display, prepare derivative
          works from, transfer, sell, exploit or otherwise use any content present on the Website
          and/or App without prior written permission of Us or Our licensors. <br />
          Our services are designed to let You upload, submit, store, send, receive, share or work
          with Your Content. You have no obligation to provide any content to Our services and You
          are free to choose the content that You want to provide. If You choose to upload, share or
          store Your Content, please make sure You have the necessary rights to do so and that the
          content is lawful. <br />
          We do not claim ownership in any of Your Content that you upload, share, or store using
          Our Services. <br />
          We will not change or use Your Content. You grant Us with a limited license to use Your
          Content only to enable Us to provide and improve the Services, such as providing the
          functionality You want if You choose to share Your Content with someone or open it on a
          different device. <br />
          We will keep Your Content only as long as You ask Us to keep it. <br />
          You can delete Your Content if You choose to stop using Our Services. <br />
        </>
      ),
    },
    {
      title: 'VII. Sharing of Your Content',
      content: (
        <>
          With Our Services, You can work on Your Content together with other users. You can create
          archives with Your Content, as well as group chats, share Your Content in chats, discuss
          and edit Your Content and archives with Your Content. <br />
          You control who can access Your Content using Our Services. Sharing settings in Our
          Services allow You to manage permissions for other users to interact with Your Content in
          a chat and configure file privacy. <br />
          We will not share Your Content with others except as described in our Privacy Policy.{' '}
          <br />
        </>
      ),
    },
    {
      title: 'VIII. Termination',
      content: (
        <>
          You are free to stop using our Services at any time. We reserve the right to suspend or
          terminate Your access to the Services with notice to you if We reasonably believes that
          You are in breach of these Terms and Conditions or applicable laws. <br />
          We will provide You with reasonable advance notice via the email address associated with
          Your account to remedy the activity that prompted Us to contact You and give You the
          opportunity to export Your Content from Our Services. If after such notice You fail to
          take the steps We ask of You, we will terminate or suspend Your access to the Services.{' '}
          <br />
          Once we suspend or terminate Your access to the Services, You will not be able to access
          or export Your Content. We do not provide refunds if We suspend or terminate Your access
          to the Services, unless required by law. <br />
        </>
      ),
    },
    {
      title: 'IX. Limitation of liabilities and indemnification',
      content: (
        <>
          We shall not be liable for any incidental, indirect, punitive and consequential damages,
          lost profits, or damages resulting from infringement caused by Your use of the Services.{' '}
          <br />
          You agree to defend, indemnify, and hold harmless Us from and against any claims, actions,
          demands, liabilities, losses, damages, costs and expenses including, without limitation,
          reasonable legal and accounting fees, arising from or relating to your breach of these
          Terms and Conditions or your access to, use, or misuse of the Website and/or App.
        </>
      ),
    },
    {
      title: 'X. Governing law and dispute resolution',
      content: (
        <>
          These Terms and Conditions, where applicable, and all claims or causes of action (whether
          in contract, tort or statute) that may be based upon, arise out of or relate to these
          Terms and Conditions are exclusively governed by and enforced in accordance with the
          legislation of the United States of America and the State of Delaware. <br />
          Any dispute regarding the Services rendering and use of the Website and/or App under these
          Terms and Conditions will be subject to the exclusive jurisdiction of the respective
          courts of Ukraine. <br />
          All disputes and disagreements that might arise out of or in connection with these Terms
          and Conditions shall be resolved by means of negotiations. You agree that for the purposes
          of the settlement of disputes between You and Us, email for correspondence:
          info@monobox.tech shall be an effective and binding method of communication. <br />
        </>
      ),
    },
    {
      title: 'XI. Contact Us',
      content: (
        <>
          You shall have the right to contact Us on any issue regarding the Services, Our Website
          and App via the email: info@monobox.tech. We will reply to any such communication without
          undue delay and always within 30 days.
        </>
      ),
    },
    {
      title: 'XII. Changes to these Terms and Conditions',
      content: (
        <>
          These Terms and Conditions went into effect on 01/07/2023, however, are subject to change
          and occasional updates.
        </>
      ),
    },
  ],
};
