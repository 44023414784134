import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Input } from 'src/components/Forms/Inputs/Input';
import { Button } from 'src/components/Forms/Button';
import { CreateFolderPayload, useCreateFolder, useGetFolders } from 'src/api/folders';
import './styles.scss';

type Props = {
  onSave: () => void;
  onClose?: () => void;
  parentId: string;
};

export const CreateFolderForm: FC<Props> = ({ onSave, onClose, parentId }) => {
  const { addFolderCache } = useGetFolders(
    {
      parent_id: parentId,
    },
    true,
  );

  const [serverError, setServerError] = useState<string>('');
  const { mutateAsync: createFolder, isPending } = useCreateFolder();
  const { t } = useTranslation();

  const initialValues: CreateFolderPayload = {
    folder_name: '',
    parent_id: parentId,
  };

  const handleSaveChanges = async (values: CreateFolderPayload) => {
    const response = await createFolder(values);
    if (response.success) {
      addFolderCache(response.folder);
      onSave();
    } else {
      setServerError(response.error);
    }
  };

  const validationSchema = Yup.object().shape({
    folder_name: Yup.string().max(50, ' ').required(' '),
  });

  return (
    <div className="create-folder-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSaveChanges}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ submitForm, values, setFieldValue, errors, isValid }) => (
          <div>
            <div className="mb-32">
              <Input
                className="create-folder-form__input"
                placeholder={t('folderName')}
                value={values.folder_name}
                onChange={async (value) => {
                  await setFieldValue('folder_name', value);
                  setServerError('');
                }}
                error={errors.folder_name || serverError}
                maxLength={50}
              />
            </div>

            <div className="mb-6">
              <Button
                className="create-folder-form__button"
                type="primary"
                content={t('createFolder')}
                onClick={submitForm}
                fullWidth
                isDisabled={!isValid || isPending}
              />
            </div>
            <div className="create-folder-form__cancel" onClick={onClose}>
              {t('cancel')}
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
