import { useMoveObject } from 'src/api/objects';
import { useDragState } from 'src/pages/diskPage/hooks/useDragState';
import { useDiskViewState } from 'src/pages/diskPage/hooks/useDiskViewState';
import { FilesPayload, useFileCache } from 'src/api/files';
import { DEFAULT_FILES_PAYLOAD } from 'src/config/constants';
import { useGetFilteredFolders } from 'src/hooks/useGetFilteredFolders';
import { useParams } from 'react-router-dom';

export const useDropState = (parentFolderId?: string) => {
  const { diskId, folderId } = useParams();
  const parent_id = parentFolderId || folderId || diskId || '';

  const { clearSelectedIds } = useDragState();
  const { invalidateCache: refetchFolders } = useGetFilteredFolders({ parent_id }, true);

  const filesPayload: FilesPayload = {
    ...DEFAULT_FILES_PAYLOAD,
    parent_id,
  };
  const { invalidateCache: refetchFiles } = useFileCache(filesPayload);

  const invalidateCache = () => {
    refetchFiles();
    refetchFolders();
  };

  const { removeFileCache, removeFolderCache } = useDiskViewState();
  const { mutateAsync: moveFile } = useMoveObject();

  const onDropHandler = async (destinationId: string, ids: string[]) => {
    // console.log(destinationId, ids);

    if (ids.length === 0) return;
    const response = await moveFile({
      destination_id: destinationId,
      item_ids: ids,
    });
    if (response.success) {
      ids.forEach((id) => {
        removeFileCache(id);
        removeFolderCache(id);
      });
      clearSelectedIds();
    }
    invalidateCache();
  };

  return {
    onDropHandler,
  };
};
