import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDiskViewState } from 'src/pages/diskPage/hooks/useDiskViewState';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { FileType, FolderType } from 'src/types';
import { useSort } from 'src/hooks/useSort';
import { usePrevious } from 'src/hooks/usePrevious';
import { isEqual } from 'lodash';
import { UploaderPage } from 'src/components/FileUploader/UploaderPage';
import { Table } from 'src/components/Table';
import { TableHeaderType, TableItemType } from 'src/components/Table/types';
import moment from 'moment/moment';
import { DATE_FORMAT } from 'src/config/constants';
import { getConvertedSize } from 'src/helpers';
import { FileInfo } from 'src/components/InfoComponents/fileInfo/fileInfo';
import { FileIcon } from 'src/pages/diskPage/components/ItemIcons/FileIcon';
import { FolderIconComp } from 'src/pages/diskPage/components/ItemIcons/FolderIcon';
import { useDragState } from 'src/pages/diskPage/hooks/useDragState';
import { FolderActions } from 'src/pages/diskPage/Views/ListView/components/folderActions';
import './styles.scss';

export const ListView: FC = () => {
  const { selectedIds, toggleSection } = useDragState();

  const navigate = useNavigate();
  const { handleSort } = useSort();

  const { diskId, folderId } = useParams();
  const { folders, files, fetchNextPage } = useDiskViewState();

  const { openModal } = useAppContext();
  const { t } = useTranslation();
  const [tableItems, setTableItems] = useState<TableItemType[]>([]);

  const prevFiles = usePrevious(files);
  const prevFolders = usePrevious(folders);

  useEffect(() => {
    if (isEqual(prevFiles, files) && isEqual(prevFolders, folders)) return;
    setTableItems(() => {
      return [
        ...folders.map((folder: FolderType) => ({
          size: 0,
          type: t('folderWithFiles'),
          innerType: 'folder',
          favorite: folder.is_favorite,
          actions: [],
          ...folder,
        })),
        ...files.map((file: FileType) => ({
          favorite: file.is_favorite,
          actions: [],
          ...file,
        })),
      ] as any[];
    });
  }, [folders, files, t]);

  const fileIconSection = (item: any) => {
    return (
      <div className="table-items__cell">
        <FileIcon file={item as FileType} />
      </div>
    );
  };

  const folderIconSection = (item: any) => {
    return (
      <div className="table-items__cell">
        <FolderIconComp folder={item as FolderType} />
      </div>
    );
  };

  const renderIcon = (item: TableItemType) => {
    if (item.innerType === 'folder') {
      return folderIconSection(item);
    }
    return fileIconSection(item);
  };

  const renderSize = (item: TableItemType) => <>{getConvertedSize(item?.size as number)}</>;

  const renderActions = (item: TableItemType) => {
    return (
      <div className="table-items__cell">
        <FolderActions item={item} />
      </div>
    );
  };
  const headers: TableHeaderType[] = [
    { name: t('preview'), field: '', sortable: false, width: '200px', render: renderIcon },
    { name: t('name'), field: 'name', sortable: true, width: 'auto' },
    {
      name: t('dateOfEditing'),
      field: 'created_at',
      sortable: true,
      width: '20%',
      render: (item) => <div>{moment(item.created_at as string).format(DATE_FORMAT)}</div>,
    },
    { name: t('size'), field: 'size', sortable: true, width: '20%', render: renderSize },
    { name: t('type'), field: 'type', sortable: true, width: '20%' },
    { name: '', field: '', sortable: false, width: '300px', render: renderActions },
  ];

  const onRowClick = (item: TableItemType) => {
    if (selectedIds.length > 0) {
      toggleSection(item.id);
      return;
    }
    if (item.innerType === 'folder') {
      navigate(`/disk/${diskId}/folder/${item.id}`);
    } else {
      openModal({
        content: (
          <>
            <FileInfo file={item as FileType} className="file-info-block" />
          </>
        ),
        title: '',
        className: 'create-folder-modal',
      });
    }
  };

  const onHandleSort = (field: string) => {
    handleSort(field);
  };

  return (
    <div className="disk-view list-view">
      <UploaderPage diskId={diskId || ''} folderId={folderId} isEmpty={!tableItems.length}>
        <Table
          className=""
          headers={headers}
          onRowClick={onRowClick}
          items={tableItems}
          onSort={onHandleSort}
          onScrollEnd={() => {
            fetchNextPage();
          }}
        />
      </UploaderPage>
    </div>
  );
};
