import React, { FC } from 'react';
import './styles.scss';
import classNames from 'classnames';
import { ActionButton } from 'src/components/Forms/ActionButton';
import { ForwardIcon } from 'src/components/Icons/context';

export type GalleryTileType = {
  title: string;
  date: string;
  files: string;
  size: string;
  image: string;
};

type Props = {
  tile: GalleryTileType;
  className?: string;
};

export const GalleryTile: FC<Props> = ({ tile, className }) => {
  const actions: any[] = [
    {
      label: 'Test',
      onClick: async () => {},
      icon: <ForwardIcon className="dropdown-svg-icon" />,
    },
  ];

  return (
    <div className={classNames('gallery-tile', className)}>
      <div className="gallery-tile__image"></div>
      <div className="gallery-tile__content">
        <div className="gallery-tile__title">
          <div className="gallery-tile__title--content">{tile.title}</div>
          <ActionButton items={actions} className="file-column__abtn" id={tile.title} />
        </div>
        <div className="gallery-tile__footer">
          <div className="gallery-tile__info">{tile.date}</div>
          <div className="gallery-tile__info">{tile.files}</div>
          {/* <div className="gallery-tile__info">{tile.size}</div>*/}
        </div>
      </div>
    </div>
  );
};
