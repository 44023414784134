import React, { FC } from 'react';
import { ReactComponent as UploadingIcon } from './assets/uploading-icon.svg';
import { ReactComponent as UploadedIcon } from './assets/uploaded-icon.svg';
import { ReactComponent as FailedIcon } from './assets/failed-icon.svg';
import { UploadingFile } from 'src/components/Uploads/components/UploadingFile';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { UploadFileType } from 'src/context/GlobalContext';

type Props = {
  type: 'active' | 'finished' | 'failed';
  files: UploadFileType[];
  onCancel?: () => void;
  onRetry?: () => void;
};

export const FilesBlock: FC<Props> = ({ type, files, onRetry, onCancel }) => {
  const { t } = useTranslation();

  const onCancelAllClick = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const onRetryAllClick = () => {
    if (onRetry) {
      onRetry();
    }
  };

  const getFilesText = (count: number) => {
    return count === 1 ? t('file') : t('files');
  };

  const settings = {
    active: {
      icon: <UploadingIcon className="files-block__head-icon" />,
      title: `${t('uploading')} - ${files.length} ${getFilesText(files.length)}`,
      actions: (
        <div
          className="files-block__header-action files-block__header-action--cancel"
          onClick={onCancelAllClick}
        >
          {t('cancelAll')}
        </div>
      ),
      files: files,
    },
    failed: {
      icon: <FailedIcon className="files-block__head-icon" />,
      title: `Failed - ${files.length} ${getFilesText(files.length)}`,
      actions: (
        <div className="files-block__resume files-block__header-action" onClick={onRetryAllClick}>
          {t('resume')}
        </div>
      ),
      files: files,
    },
    finished: {
      icon: <UploadedIcon className="files-block__head-icon" />,
      title: `${t('finished')} - ${files.length} ${getFilesText(files.length)}`,
      actions: <></>,
      files: files,
    },
  };

  return (
    <div className="files-block">
      <div className="files-block__header">
        {settings[type].icon}
        <span>{settings[type].title}</span>
        {settings[type].actions}
      </div>
      <div className="files-block__content">
        <div className="files-block__content-wrapper">
          {settings[type].files.map((file, index) => {
            return <UploadingFile file={file} key={index} type={type} />;
          })}
        </div>
      </div>
    </div>
  );
};
