import { API } from 'src/utils/API';
import { useMutation } from '@tanstack/react-query';
import { objectToQueryParams } from 'src/helpers';

export type CreateFilePayload = {
  name: string;
  size: number;
  parent_id: string;
};

export type UploadPartPayload = {
  key: string;
  uploadId: string;
  partNumber: number;
  data: any;
};

export type Etag = {
  PartNumber: number;
  ETag: string;
};

export type FinishUploadingPayload = {
  key: string;
  uploadId: string;
  archive_id: string;
  data: Etag[];
};

export const useCreateFile = () => {
  const sendData = async (payload: CreateFilePayload) => {
    const { parent_id, ...reset } = payload;

    const resp = await API.post(`/resources/${parent_id}/files`, reset, 'archive');
    if (resp.success) {
      return {
        success: true,
        file: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useCreateMultiPart = () => {
  const sendData = async (key: string) => {
    const resp = await API.post(`/file/multipart/create?key=${key}`, null, 'file');
    if (resp.success) {
      return {
        success: true,
        uploadId: resp.data.UploadId,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useUploadPart = () => {
  const sendData = async (payload: UploadPartPayload, controller: AbortController) => {
    const { data, ...rest } = payload;

    const formData = new FormData();
    formData.append('file', data, 'test.jpeg');

    const resp = await API.post(
      `/file/multipart/upload?${objectToQueryParams(rest)}`,
      data,
      'file',
      { signal: controller.signal, headers: { 'Content-Type': 'multipart/form-data' } },
    );
    if (resp.success) {
      return {
        success: true,
        data: resp.data,
      };
    } else {
      return resp;
    }
  };

  return useMutation({
    mutationFn: (variables: { payload: UploadPartPayload; controller: AbortController }) =>
      sendData(variables.payload, variables.controller),
    retry: 0,
  });
};

export const useCompleteMultiPart = () => {
  const sendData = async (payload: FinishUploadingPayload) => {
    const { data, ...rest } = payload;
    const resp = await API.post(
      `/file/multipart/complete?${objectToQueryParams(rest)}`,
      data,
      'file',
    );
    if (resp.success) {
      return {
        success: true,
        data: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};
