import React, { FC, useEffect } from 'react';
import { DiskIcon } from './components/DiskIcon';
import './styles.scss';
import { DiskColorType } from 'src/types';

type Props = {
  color: string;
  onChange: (color: string) => void;
};

export const DiskDesign: FC<Props> = ({ color, onChange }) => {
  const colors: DiskColorType[] = [
    'blue',
    'lightBlueGradient',
    'grayGradient',
    'greenGradient',
    'purpleGradient',
    'redGradient',
    'yellowGradient',
  ];
  const [selected, setSelected] = React.useState<string>(color);
  useEffect(() => {
    setSelected(color);
  }, [color]);
  return (
    <div className="disk-design">
      <div className="disk-design__title">Disk design:</div>
      <div className="disk-design__items">
        {colors.map((color) => (
          <DiskIcon
            selected={selected === color}
            key={color}
            color={color}
            onClick={() => {
              setSelected(color);
              onChange(color);
            }}
          />
        ))}
      </div>
    </div>
  );
};
