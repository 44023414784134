import React, { FC, useEffect, useState } from 'react';
import { useLogin, useOtpCode, useSendIdentity } from 'src/pages/auth/hooks/useAuth';
import { LoginState } from 'src/pages/auth/login/types';
import { LoginForm } from './components/LoginForm';
import { OtpForm } from './components/OtpForm';
import { useNavigate } from 'react-router-dom';
import { Notification } from 'src/components/Notification';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Forms/Button';
import { AppleIcon, GoogleIcon } from 'src/components/Icons';
import LocalStorage from 'src/utils/Storage';
import { get } from 'lodash';
import { useUpdateProfile } from 'src/api/profile';
import { getBrowserFingerprint } from 'src/helpers';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, googleProvider, appleProvider } from './firebaseConfig';
import { TermsBlock } from 'src/pages/auth/components/LoginSection/components/TermsBlock';
import './styles.scss';

const emptyNotification = {
  open: false,
  notification: '',
};

type Props = {
  defaultSize?: boolean;
};

export const LoginSection: FC<Props> = () => {
  const { t } = useTranslation();
  const { mutateAsync: saveProfile, isPending: isUpdating } = useUpdateProfile();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [notification, setNotification] = useState(emptyNotification);
  const { mutateAsync: loginRequest, isPending } = useLogin();
  const { mutateAsync: otpRequest, isPending: isProcessing } = useOtpCode();
  const { mutateAsync: sendId } = useSendIdentity();

  const [showTerms, setShowTerms] = useState(false);

  const [loginState, setLoginState] = useState<LoginState>({
    phone_number: '',
  });

  useEffect(() => {
    const session = localStorage.getItem('session');
    if (session) {
      navigate('/');
    }
  }, [navigate]);

  const handlePhoneChange = (phone_number: string) => {
    setLoginState((prevState) => ({
      ...prevState,
      phone_number,
    }));
    setError('');
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    return /^\+(?:[0-9] ?){6,14}[0-9]$/.test(phoneNumber);
  };

  const onLoginClick = async () => {
    if (!validatePhoneNumber(loginState.phone_number)) {
      setError(t('invalidPhoneNumber'));
      return;
    }

    const resp = await loginRequest(loginState);
    if (resp.success) {
      setLoginState((prevState) => ({
        ...prevState,
        session: resp.session,
      }));
    } else {
      setError(resp.error);
    }
  };

  const updateProfile = async (payload: any) => {
    return await saveProfile(payload);
  };

  const sendOptData = async (data: any) => {
    const device_id = getBrowserFingerprint();
    const resp = await otpRequest({
      ...data,
      device_id: device_id,
    });

    if (!resp.success) {
      setError(resp.error);
      setLoginState((prevState) => ({
        ...prevState,
        session: resp.session ?? prevState.session,
      }));
      return;
    }

    const profile = await updateProfile({});
    if (!profile.success) {
      setError(profile.error);
      return;
    }

    LocalStorage.set('profile', profile.profile);
    const accepted_tos = get(profile, 'profile.accepted_tos', false);
    if (!accepted_tos) {
      setShowTerms(true);
      return;
    }
    navigate('/disk');
  };

  const onSendOtp = async () => {
    if (loginState.answer?.length !== 4) {
      setError(t('invalidOtp'));
      return;
    }
    await sendOptData(loginState);
  };

  const handleOtpChange = async (code: string) => {
    setLoginState((prevState) => ({
      ...prevState,
      answer: code,
    }));
    setError('');
  };

  const onResendSms = async () => {
    const resp = await loginRequest({
      phone_number: loginState.phone_number,
    });
    if (resp.success) {
      setLoginState((prevState) => ({
        ...prevState,
        session: resp.session,
      }));
    } else {
      setError(resp.error);
    }
  };

  const sendIdToken = async (id: string) => {
    const resp = await sendId(id);
    if (resp.success) {
      const profile = await updateProfile({ accepted_privacy_policy: true, accepted_tos: true });
      if (!profile.success) {
        setError(profile.error);
        return;
      }
      LocalStorage.set('profile', profile.profile);
      document.location = '/disk';
    }
  };
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential?.idToken) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        await sendIdToken(result.user.accessToken);
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };
  const signInWithApple = async () => {
    try {
      const result = await signInWithPopup(auth, appleProvider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential?.idToken) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        await sendIdToken(result.user.accessToken);
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <>
      {showTerms && (
        <TermsBlock
          onSave={async () => {
            const profile = await updateProfile({
              accepted_privacy_policy: true,
              accepted_tos: true,
            });
            if (!profile.success) {
              setError(profile.error);
              return;
            }
            navigate('/disk');
          }}
        />
      )}
      {!showTerms && (
        <div className="login-section">
          <>
            <div className="auth__row">
              <h3 className="auth__title">{t('welcome')}</h3>
            </div>
            <div>
              <div>
                {!loginState.session ? (
                  <LoginForm
                    onLoginClick={onLoginClick}
                    handlePhoneChange={handlePhoneChange}
                    isPending={isPending}
                    error={error}
                  />
                ) : (
                  <OtpForm
                    onClick={onSendOtp}
                    onChange={handleOtpChange}
                    isPending={isProcessing || isUpdating}
                    error={error}
                    onClickResend={onResendSms}
                  />
                )}

                <>
                  <div className="auth__row mb-50">
                    <div className="auth__separator"></div>
                  </div>
                  <div className="auth__row mb-15">
                    <Button
                      content={t('continueWithApple')}
                      type="basic-border"
                      className="account-connections__apple-button"
                      onClick={signInWithApple}
                      isDisabled={isPending}
                      fullWidth
                      icon={<AppleIcon />}
                    />
                  </div>
                  <div className="auth__row">
                    <Button
                      content={t('continueWithGoogle')}
                      type="basic-border"
                      onClick={signInWithGoogle}
                      isDisabled={isPending}
                      fullWidth
                      icon={<GoogleIcon />}
                    />
                  </div>
                </>

                {notification.open && (
                  <Notification
                    notification={notification.notification}
                    onClose={() => setNotification(emptyNotification)}
                  />
                )}
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};
