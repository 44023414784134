import React, { FC } from 'react';
import './styles.scss';

type Props = {
  title: string;
  children: React.ReactNode;
  button?: React.ReactNode;
};

export const PlaneBlock: FC<Props> = ({ title, children, button }) => {
  return (
    <div className="plane-block">
      <div className="plane-block__title">
        <span>{title}</span>
        {button}
      </div>
      <div className="plane-block__content">{children}</div>
    </div>
  );
};
