import { OtpForm } from 'src/pages/auth/components/LoginSection/components/OtpForm';
import React, { FC, useState } from 'react';
import { useConfirmPhone, useUpdatePhone } from 'src/api/profile';

type Props = {
  onConfirm: () => void;
  phone: string;
};
export const ConfirmationForm: FC<Props> = ({ onConfirm, phone }) => {
  const { mutateAsync: confirmPhone, isPending } = useConfirmPhone();
  const { mutateAsync: savePhone } = useUpdatePhone();
  const [error, setError] = useState('');
  const [confirmation, setConfirmation] = useState('');

  const sendPhoneConfirmation = async () => {
    const resp = await confirmPhone(confirmation);
    if (resp.success) {
      onConfirm();
      return;
    }
    setError(resp.error);
  };

  const onChange = (pin: string) => {
    setConfirmation(pin);
    setError('');
  };

  const onResend = async () => {
    const resp = await savePhone({ phone_number: phone });
    if (!resp.success) {
      setError(resp.error);
    }
  };

  return (
    <OtpForm
      onClick={sendPhoneConfirmation}
      onChange={onChange}
      isPending={isPending || confirmation.length < 4}
      error={error}
      onClickResend={onResend}
      style={{ textAlign: 'center' }}
      resend_time={300}
    />
  );
};
