import React, { useEffect, useState } from 'react';
import { LandingSection } from 'src/pages/landings/components/LandingSection';
import { LandingFooter } from 'src/pages/landings/components/LandingFooter';
import { LandingHeader } from 'src/pages/landings/components/LandingHeader';
import classNames from 'classnames';
import { LoginSection } from 'src/pages/auth/components/LoginSection';
import { CloseIcon } from 'src/components/Icons';
import { SlideMenu } from 'src/components/SlideMenu';
import { useTranslation } from 'react-i18next';
import { CarouselComponent, Slide } from 'src/pages/landings/components/Carousel';
import { Apps } from './components/apps';
import { Reasons } from 'src/pages/landings/Home/components/reasons';
import { News } from 'src/pages/landings/Home/components/news';
import { useNavigate } from 'react-router-dom';
import LocalStorage from 'src/utils/Storage';
import slide1 from './assets/slide1.jpg';
import slide2 from './assets/slide2.jpg';
import slide3 from './assets/slide3.jpg';
import { FileSharing } from 'src/pages/landings/components/FileSharing';

export const Home = () => {
  const isAuthenticated = LocalStorage.isAuthorised();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [showSide, setShowSide] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const slides: Slide[] = [
    {
      title: t('homeSlide1Title'),
      subtitle: t('homeSlide1Subtitle'),
      description: t('homeSlide1Description'),
      image: slide1,
    },
    {
      title: t('homeSlide2Title'),
      subtitle: t('homeSlide2Subtitle'),
      description: t('homeSlide2Description'),
      image: slide2,
    },
    {
      title: t('homeSlide3Title'),
      subtitle: t('homeSlide3Subtitle'),
      description: t('homeSlide3Description'),
      image: slide3,
    },
  ];

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/disk');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    document.documentElement.style.overflow = showSide ? 'hidden' : '';
    return () => {
      document.documentElement.style.overflow = '';
    };
  }, [showSide]);

  return (
    <div className={classNames('landing-page' /*{ withSide: showSide }*/)}>
      <div className="landing-page__content">
        <LandingHeader
          onClick={(isShare) => {
            setShowShare(!!isShare);
            setShowSide(!showSide);
          }}
          title={t('homeTitle')}
          description={t('homeDescription')}
          isDark
        />
        <div className="landing-page__section landing-page__carousel-wrapper" id="landing-carousel">
          <CarouselComponent slides={slides} />
        </div>
        <div className="landing-page__section">
          <LandingSection>
            <Apps />
          </LandingSection>
        </div>

        <div className="landing-page__section  pb-50 mb-50">
          <Reasons />
        </div>
        {/*
        <div className="landing-page__section pb-50 mb-50">
          <LandingSection>
            <News />
          </LandingSection>
        </div>*/}

        <div className="landing-page__section">
          <LandingFooter onClick={() => setShowSide(!showSide)} />
        </div>
      </div>

      <SlideMenu
        isOpen={showSide}
        onClose={() => {
          setShowSide(!showSide);
        }}
        position="right"
      >
        <div className={classNames('landing-page__side', { share: showShare })}>
          <div className="landing-page__side-content">
            <div className="landing-page__side-close" onClick={() => setShowSide(!showSide)}>
              <CloseIcon />
            </div>
            <div className="landing-page__side-content-wrapper">
              {showShare ? <FileSharing /> : <LoginSection defaultSize />}
            </div>
          </div>
        </div>
      </SlideMenu>
    </div>
  );
};
