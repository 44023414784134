import React from 'react';
import { Layout } from 'src/components/Layout';
import { ReactComponent as SideLogo } from './side-logo.svg';
import { Input } from 'src/components/Forms/Inputs/Input';
import { Textarea } from 'src/components/Forms/Textarea';
import { Button } from 'src/components/Forms/Button';
import './styles.scss';
import { Link } from 'react-router-dom';

export const ContactUs = () => {
  return (
    <Layout
      leftSidebar={
        <>
          <div className="contact-us__logo">
            <SideLogo />
          </div>
        </>
      }
    >
      <div className="page">
        <div className="contact-us">
          <Link to="/disk">test</Link>
          <div className="contact-us__title pb-30">Contact Us</div>
          <div className="contact-us__form">
            <Input placeholder="Your name" label="Name" className="mb-30" />
            <Input placeholder="Your email" label="Email" className="mb-30" />
            <Textarea placeholder="Your message" label="Message" className="mb-30"></Textarea>
            <Button type="primary" content="Send message" fullWidth />
          </div>
        </div>
      </div>
    </Layout>
  );
};
