import React from 'react';
import { Terms } from 'src/pages/auth/components/LoginSection/components/Terms';
import { termsData } from 'src/pages/auth/components/LoginSection/components/Terms/terms';
import './styles.scss';

export const TermsPage = () => {
  return (
    <div>
      <Terms terms={termsData} className="terms-policy" />
    </div>
  );
};
