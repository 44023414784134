import React, { FC, useEffect } from 'react';
import './styles.scss';
import classNames from 'classnames';
import { AnimateIcon } from 'src/components/Icons';
import { useFileImg } from 'src/api/files';
import { getFileIcon } from 'src/config/fileTypes';

type Props = {
  fileId: string | null;
  extension: string;
  children?: React.ReactNode;
  className?: string;
};

export const FileMedia: FC<Props> = ({ fileId, extension, children, className }) => {
  const { data, isPending, refetch } = useFileImg(fileId);
  const image = data?.image || '';

  const getFileConfig = () => {
    return getFileIcon(extension);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (fileId && !image) {
      timer = setTimeout(() => {
        refetch();
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [fileId, image, refetch]);

  return (
    <div
      className={classNames('file__media-icon', className)}
      style={{
        backgroundColor: `${getFileConfig().color || '#A00001'}`,
        backgroundImage: `url(${image})`,
      }}
    >
      {fileId && image ? (
        <>{isPending ? <AnimateIcon className="user-avatar__animation" /> : <div></div>}</>
      ) : (
        getFileConfig().icon
      )}
      {children}
    </div>
  );
};
