import React, { FC } from 'react';
import { AnimatedClip } from 'src/components/AnimatedClip';
import './terminate.scss';

type Props = {
  title?: string;
  text?: string;
};

export const TerminateSession: FC<Props> = ({ title, text }) => {
  return (
    <div className="terminate-modal success">
      <div className="terminate-modal__animation">
        <AnimatedClip type="curious" className="terminate-modal__clip" />
      </div>
      <h3 className="terminate-modal__title">{title}</h3>
      {text && <p className="terminate-modal__text">{text}</p>}
      {/*<p className="terminate-modal__text">eeee</p>
      <div className="terminate-modal__btn-wrapper">dddd</div>*/}
    </div>
  );
};
