import React from 'react';
import { AuthLayout } from 'src/pages/auth/components/AuthLayout';
import { LoginSection } from 'src/pages/auth/components/LoginSection';

export const Login = () => {
  return (
    <AuthLayout>
      <LoginSection />
    </AuthLayout>
  );
};
