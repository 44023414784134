import React from 'react';

type DiskProps = {
  gradientStart: string;
  gradientEnd: string;
};

export const DiskGradient: React.FC<DiskProps> = ({ gradientStart, gradientEnd }) => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="-0.00390625"
      y="0.648438"
      width="39.9961"
      height="39.9961"
      rx="8.33252"
      fill={`url(#${gradientStart})`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9954 34.811C27.8186 34.811 34.1606 28.469 34.1606 20.6458C34.1606 12.8225 27.8186 6.48047 19.9954 6.48047C12.1721 6.48047 5.83008 12.8225 5.83008 20.6458C5.83008 28.469 12.1721 34.811 19.9954 34.811ZM19.9954 23.9792C21.8364 23.9792 23.3288 22.4868 23.3288 20.6458C23.3288 18.8049 21.8364 17.3125 19.9954 17.3125C18.1545 17.3125 16.6621 18.8049 16.6621 20.6458C16.6621 22.4868 18.1545 23.9792 19.9954 23.9792Z"
      fill="#F5F9FC"
    />
    <defs>
      <linearGradient
        id={gradientStart}
        x1="-0.00390625"
        y1="20.6465"
        x2="39.9922"
        y2="20.6465"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={gradientStart} />
        <stop offset="1" stopColor={gradientEnd} />
      </linearGradient>
    </defs>
  </svg>
);
