import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import './styles.scss';
import { useTranslation } from 'react-i18next';

interface CountdownTimerProps {
  finishDate: string;
  onEnd: (remainingDays: number) => void; // Додаємо параметр для передачі кількості днів
  className?: string;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ finishDate, onEnd, className }) => {
  const { t } = useTranslation();

  const calculateTimeLeft = () => {
    const now = moment();
    const targetDate = moment(finishDate);
    const duration = moment.duration(targetDate.diff(now));

    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      if (
        newTimeLeft.days <= 0 &&
        newTimeLeft.hours <= 0 &&
        newTimeLeft.minutes <= 0 &&
        newTimeLeft.seconds <= 0
      ) {
        clearInterval(timer);
        onEnd(0); // Якщо таймер завершився, передаємо 0 днів
      } else {
        onEnd(newTimeLeft.days); // Передаємо кількість днів, що залишились
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [finishDate, onEnd]);

  return (
    <div className={classNames('countdown', className)}>
      <div className="countdown__box">
        <div className="countdown__value">{String(timeLeft.days).padStart(2, '0')}</div>
        <div className="countdown__label">{t('days')}</div>
      </div>
      <div className="countdown__box">
        <div className="countdown__value">{String(timeLeft.hours).padStart(2, '0')}</div>
        <div className="countdown__label">{t('Hours')}</div>
      </div>
      <div className="countdown__box">
        <div className="countdown__value">{String(timeLeft.minutes).padStart(2, '0')}</div>
        <div className="countdown__label">{t('Minutes')}</div>
      </div>
    </div>
  );
};

export default CountdownTimer;
