import React, { FC, useState } from 'react';
import ReactSlider from 'react-slider';
import { ReactComponent as TooltipTop } from './assets/tooltip-top.svg';
import { convertGbToTb } from 'src/pages/landings/Cloud/components/PriceCalculator';
import classNames from 'classnames';
import './styles.scss';

type Props = {
  onChange: (value: number) => void;
};

export const Slider: FC<Props> = ({ onChange }) => {
  const marks = [25, 1000]; // Start with 25 GB, end with 10 TB (10000 GB)
  const minValue = marks[0];
  const maxValue = marks[marks.length - 1];
  const [currentValue, setCurrentValue] = useState<number | string>(minValue);

  const handleChange = (value: number) => {
    const newValue = Math.min(maxValue, value);
    setCurrentValue(newValue);
    onChange(newValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === '') {
      setCurrentValue('');
    } else {
      const numericValue = parseInt(value, 10);
      if (!isNaN(numericValue)) {
        setCurrentValue(numericValue);
      }
    }
  };

  const handleInputBlur = () => {
    if (currentValue === '') {
      setCurrentValue(minValue);
      onChange(minValue);
    } else if (typeof currentValue === 'number') {
      let value = currentValue;
      if (value < minValue) {
        value = minValue;
      } else if (value > maxValue) {
        value = maxValue;
      }
      setCurrentValue(value);
      onChange(value);
    }
  };

  // Calculate the number of steps and generate an array for markers
  const step = minValue * 4;
  const numSteps = Math.floor((maxValue - minValue) / step);
  const marksPoints = Array.from({ length: numSteps + 1 }, (_, index) => minValue + index * step);

  return (
    <div className="storage-slider">
      <div className="storage-slider__value">
        <input
          value={currentValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          min={minValue}
          max={maxValue}
        />
        <span>GB</span>
      </div>
      <div className="slider-container">
        <div className="slider-labels-top">
          <span className="label-top" style={{ left: '0%' }}>
            25 GB
          </span>
          <span className="label-top" style={{ left: '100%' }}>
            1 TB
          </span>
        </div>

        <ReactSlider
          className="custom-slider"
          min={minValue}
          max={maxValue}
          step={step}
          thumbClassName="custom-thumb"
          trackClassName="custom-track"
          value={typeof currentValue === 'number' ? currentValue : minValue}
          onChange={handleChange}
          renderThumb={(props, state) => (
            <div {...props}>
              <div className="tooltip">
                <TooltipTop className="tooltip-marker" />
                {state.valueNow >= 1000
                  ? `${convertGbToTb(Math.min(state.valueNow, 1))} TB`
                  : `${state.valueNow} GB`}
              </div>
            </div>
          )}
        />

        <div className="slider-marks-landing">
          {marksPoints.map((mark, index) => {
            const position = (mark * 100) / maxValue;
            return (
              <span
                key={index}
                className={classNames('slider-mark', {
                  'slider-mark--active': mark <= currentValue,
                })}
                style={{ left: `calc(${position}%)` }}
              ></span>
            );
          })}
        </div>
      </div>
    </div>
  );
};
