import React, { FC, ReactNode } from 'react';
import { DiskType } from 'src/types';
import { DiskObject } from 'src/components/DiskObject';
import { Button } from 'src/components/Forms/Button';
import { useTranslation } from 'react-i18next';
import { useDiskManage } from 'src/hooks/useDiskManage';
import './styles.scss';

type Props = {
  disk: DiskType;
  title: string;
  onClick?: () => void;
  content?: ReactNode;
  btnText?: string;
};
export const SubscriptionDisk: FC<Props> = ({ disk, onClick, content, btnText }) => {
  const { t } = useTranslation();
  const { editDisk } = useDiskManage();

  const onDiskCLick = () => {
    if (onClick) {
      onClick();
      return;
    }
    editDisk(disk);
  };
  return (
    <div className="subscription-disk">
      <DiskObject disk={disk} className="sub-disk" />
      {content && <div className="subscription-disk__content">{content}</div>}
      <Button
        type="primary"
        content={btnText || t('editDisk')}
        fullWidth
        className="subscription-disk__btn mt-20 skip-outside"
        onClick={onDiskCLick}
      />
    </div>
  );
};
