import React from 'react';
import './styles.scss';
import { Button } from 'src/components/Forms/Button';

export const SectionFooter = () => {
  return (
    <div className="section-footer">
      <Button
        content="Сохранить"
        type="text"
        className="section-footer__btn section-footer__btn--save"
      />
      <Button content="Удалить галерею" type="text-danger" className="section-footer__btn" />
    </div>
  );
};
