import { useEffect, useRef } from 'react';

export const useScrollContent = (onScrollCallback: () => void, canScroll: boolean) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        const buffer = 150;

        if (scrollTop + clientHeight >= scrollHeight - buffer && canScroll) {
          onScrollCallback();
        }
      }
    };
    const container = containerRef?.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [canScroll]);

  return {
    containerRef,
  };
};
