import React from 'react';
import { ReactComponent as PauseIcon } from '../../assets/pause-icon.svg';
import { ReactComponent as PlayIcon } from '../../assets/play-icon.svg';
import './styles.scss';
import classNames from 'classnames';

interface ProgressIconProps {
  progress: number;
  isPaused?: boolean;
  onClick: () => void;
}

export const ProgressIcon: React.FC<ProgressIconProps> = ({ progress, isPaused, onClick }) => {
  const radius = 439.6 - (progress / 100) * 439.6;

  return (
    <div className="progress-icon" onClick={onClick}>
      <svg width="160" height="160" viewBox="0 0 160 160">
        <g transform="rotate(-90 80 80)">
          <circle
            r="70"
            cx="80"
            cy="80"
            fill="transparent"
            stroke="rgba(255,255,255, .3)"
            strokeWidth="18"
          ></circle>
          <circle
            r="70"
            cx="80"
            cy="80"
            fill="transparent"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="18"
            strokeDasharray="439.6"
            strokeDashoffset={radius}
          ></circle>
        </g>
      </svg>
      <div className={classNames('icon-action', { paused: isPaused })}>
        {isPaused ? <PlayIcon /> : <PauseIcon />}
      </div>
    </div>
  );
};

export default ProgressIcon;
