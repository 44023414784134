import React, { useState, useMemo } from 'react';
import {
  defaultGlobalValues,
  GlobalContext,
  GlobalContextType,
  UploadManagerPayload,
} from 'src/context/GlobalContext/index';
import { GlobalUploader } from 'src/components/Uploads/globalUploader';
import { Notification, NotificationPropsType } from 'src/components/Notification';

type TProps = {
  children: React.ReactNode;
};

export const GlobalProvider = ({ children }: TProps) => {
  const [state, setState] = useState<GlobalContextType>(defaultGlobalValues);

  const updateUploadManager = (values: Partial<UploadManagerPayload>) => {
    setState((state) => ({
      ...state,
      uploadManager: {
        ...state.uploadManager,
        ...values,
      },
    }));
  };

  const openNotification = (values: Partial<NotificationPropsType> & { open?: boolean }) => {
    setState((state) => ({
      ...state,
      notification: {
        ...defaultGlobalValues.notification,
        ...values,
        open: values.open ?? true,
      },
    }));
  };

  const toggleSection = (id: string) => {
    setState((state) => {
      const selectedIds = state.selectedIds.includes(id)
        ? state.selectedIds.filter((selectedId) => selectedId !== id)
        : [...state.selectedIds, id];
      return {
        ...state,
        selectedIds,
      };
    });
  };

  const addSection = (id: string) => {
    setState((state) => ({
      ...state,
      selectedIds: state.selectedIds.includes(id) ? state.selectedIds : [...state.selectedIds, id],
    }));
  };

  const clearSelectedIds = () => {
    setState((state) => ({
      ...state,
      selectedIds: [],
    }));
  };

  const updateState = (values: Partial<GlobalContextType>) => {
    setState((state) => ({
      ...state,
      ...values,
    }));
  };

  const isSelected = (ids: string[], id: string) => ids.includes(id);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      ...state,
      toggleSection,
      clearSelectedIds,
      updateUploadManager,
      openNotification,
      updateState,
      isSelected,
      addSection,
    }),
    [
      state,
      clearSelectedIds,
      toggleSection,
      updateUploadManager,
      openNotification,
      updateState,
      isSelected,
      addSection,
    ],
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
      {!!state.notification.open && (
        <Notification
          title={state.notification.title}
          notification={state.notification.notification}
          onClose={() => openNotification({ open: false })}
          type={state.notification.type}
          autoHideDuration={state.notification.autoHideDuration}
        />
      )}
      <GlobalUploader />
    </GlobalContext.Provider>
  );
};
